import CreditScoreTile from './CreditScoreTile';
import RunCreditCheckButton from './RunCreditCheckButton';
import CreditScoreList from './CreditScoreList';

const CreditScore = {
  Tile: CreditScoreTile,
  RunCheckButton: RunCreditCheckButton,
  List: CreditScoreList,
};

export default CreditScore;
