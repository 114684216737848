import React from 'react';
import {
  AccountState,
  EnablePolicyAction,
  InfoItem,
  RestrictPolicyAction,
  UnblockAction,
} from './types';
import accountStateApi from './api';
import {
  RestrictUserReason,
  UnrestrictUserReason,
} from '../../../../graphql/api';

interface AccountStateType {
  isLoading: boolean;
  isProcessing: boolean;
  error: string;
  data: {
    isRestricted: boolean;
    accountState: AccountState;
    actions: InfoItem[];
  };
}

interface AccountStateContext extends AccountStateType {
  getAccountState: () => Promise<void>;
  unblockUser: UnblockAction;
  restrictPolicyPurchase: RestrictPolicyAction;
  enablePolicyPurchase: EnablePolicyAction;
}

const Context = React.createContext<AccountStateContext | null>(null);

interface Props {
  userId: string;
}

const initialState = {
  accountState: AccountState.NoState,
  actions: [],
  isRestricted: false,
};

export const AccountStateController: React.FC<Props> = ({
  children,
  userId,
}) => {
  const [state, updateState] = React.useReducer(
    (prevState: AccountStateType, nextState: Partial<AccountStateType>) => {
      return { ...prevState, ...nextState };
    },
    {
      isProcessing: false,
      isLoading: false,
      error: '',
      data: initialState,
    },
  );

  React.useEffect(() => {
    getAccountState();
  }, []);

  const getAccountState = async () => {
    updateState({ isLoading: true });
    const result = await accountStateApi.getAccountState(userId);
    if (result.success) {
      updateState({
        isLoading: false,
        error: '',
        data: result.data,
      });
    } else {
      updateState({
        isLoading: false,
        error: result.error,
        data: initialState,
      });
    }
  };

  const unblockUser: UnblockAction = async payload => {
    updateState({ isProcessing: true });
    const response = await accountStateApi.unblockUser({
      userId,
      reason: payload.reason,
      notes: payload.description,
    });
    if (response.success) {
      await getAccountState();
    }
    updateState({
      isProcessing: false,
    });
    return response;
  };

  const restrictPolicyPurchase = async (payload: {
    notes: string;
    reason: RestrictUserReason;
  }) => {
    updateState({ isProcessing: true });
    const response = await accountStateApi.restrictPolicyPurchase({
      userId,
      ...payload,
    });

    updateState({
      isProcessing: false,
      data: {
        ...state.data,
        isRestricted: response.success ? true : state.data.isRestricted,
      },
    });
    return response;
  };

  const enablePolicyPurchase = async (payload: {
    notes: string;
    reason: UnrestrictUserReason;
  }) => {
    updateState({ isProcessing: true });
    const response = await accountStateApi.enablePolicyPurchase({
      userId,
      ...payload,
    });

    updateState({
      isProcessing: false,
      data: {
        ...state.data,
        isRestricted: response.success ? false : state.data.isRestricted,
      },
    });
    return response;
  };

  return (
    <Context.Provider
      value={{
        isLoading: state.isLoading,
        isProcessing: state.isProcessing,
        error: state.error,
        data: state.data,
        getAccountState,
        unblockUser,
        restrictPolicyPurchase,
        enablePolicyPurchase,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAccountState = () => {
  const context = React.useContext(Context);
  if (context === null) {
    throw new Error(
      'useAccountState must be used within a AccountStateProvider',
    );
  }
  return context;
};
