import React from 'react';
import FormSelect from '../../../components/FormSelect/FormSelect';
import {
  useGetOrganisationsQuery,
  GetOrganisationsQuery,
} from '../../../graphql/api';
import { GenericComponentError } from '../../../components/GenericComponentError';

interface Props {
  name: string;
  label: string;
  value?: string;
  editable?: boolean;
}

const OrganisationsSelect: React.FC<Props> = ({
  name,
  label,
  value,
  editable = true,
}) => {
  const { isLoading, isError, error, data } = useGetOrganisationsQuery<
    GetOrganisationsQuery,
    Error
  >();

  if (isError) {
    return <GenericComponentError error={error!} />;
  }

  const options = data
    ? [...data.organisations]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(organisation => ({
          label: organisation.name,
          value: organisation.id,
        }))
    : [];

  return (
    <FormSelect
      name={name}
      options={options}
      editable={!isLoading && editable}
      label={isLoading ? 'Loading organisations...' : label}
      value={value}
    />
  );
};

export default OrganisationsSelect;
