import React from 'react';
import { SvgIcon } from '@material-ui/core';

const VehicleIcon = () => {
  return (
    <SvgIcon viewBox="0 -2 24 16">
      <svg
        width="24"
        height="16"
        viewBox="0 0 24 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.4521 4.91354H19.4646C19.3266 4.91354 19.1974 4.81536 19.1359 4.6638L18.15 2.22943C17.601 0.875 16.3401 0 14.9375 0H7.48073C6.09063 0 4.83438 0.864063 4.28047 2.20104L3.41146 4.2987C3.25677 4.67214 2.91927 4.91354 2.55156 4.91354H2.54844C1.14323 4.91354 0 6.10729 0 7.57474V10.4701C0 11.5953 0.881771 12.5109 1.96563 12.5109H3.2195C3.45589 14.0092 4.75241 15.1596 6.31589 15.1596C7.87936 15.1596 9.17588 14.0092 9.41227 12.5109H14.588C14.8243 14.0092 16.1207 15.1596 17.6844 15.1596C19.2481 15.1596 20.5444 14.0092 20.7808 12.5109H22.0344C23.1182 12.5109 24 11.5953 24 10.4701V7.57474C24 6.10729 22.8568 4.91354 21.4521 4.91354ZM6.31589 13.5596C5.46745 13.5596 4.77734 12.8695 4.77734 12.0211C4.77734 11.1727 5.46745 10.4826 6.31589 10.4826C7.16432 10.4826 7.85443 11.1727 7.85443 12.0211C7.85443 12.8695 7.16432 13.5596 6.31589 13.5596ZM17.6844 13.5596C16.8359 13.5596 16.1458 12.8695 16.1458 12.0211C16.1458 11.1727 16.8359 10.4826 17.6844 10.4826C18.5328 10.4826 19.2229 11.1727 19.2229 12.0211C19.2229 12.8695 18.5328 13.5596 17.6844 13.5596ZM22.4 10.4701C22.4 10.7091 22.2328 10.9109 22.0344 10.9109H20.6163C20.1666 9.72747 19.0238 8.88255 17.6844 8.88255C16.3449 8.88255 15.2021 9.72747 14.7524 10.9109H9.24782C8.79801 9.72747 7.65511 8.88255 6.31589 8.88255C4.97666 8.88255 3.83376 9.72747 3.38395 10.9109H1.96563C1.76745 10.9109 1.6 10.7091 1.6 10.4701V7.57474C1.6 6.98958 2.02552 6.51354 2.54844 6.51354H2.55156C3.56875 6.51354 4.48672 5.88438 4.88958 4.91094L5.75859 2.81354C6.06406 2.0763 6.73984 1.6 7.48073 1.6H14.9375C15.6854 1.6 16.3641 2.08307 16.6667 2.83021L17.6531 5.26458C17.9604 6.02344 18.6719 6.51354 19.4646 6.51354H21.4521C21.975 6.51354 22.4 6.98958 22.4 7.57474V10.4701Z"
          fill="#4B4F60"
        />
      </svg>
    </SvgIcon>
  );
};

export default VehicleIcon;
