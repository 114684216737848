import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const TitleButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const BackButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.darkGrey} !important;
`;

export const BackText = styled.div`
  padding-top: 2px;
  padding-left: 5px;
`;

export const Group = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const TitleContainer = styled.div`
  cursor: pointer;
  margin-left: 40px;

  &&:hover {
    text-decoration-line: underline;
  }
`;

export const TallyTileContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 40px 0 50px 0;
`;
