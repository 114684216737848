import LuxonUtils from '@date-io/luxon';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useField } from 'formik';
import React from 'react';

interface Props
  extends Omit<
    KeyboardDatePickerProps,
    'onChange' | 'value' | 'onBlur' | 'error' | 'variant' | 'helperText'
  > {
  readonly editable?: boolean;
  readonly name: string;
}

const FormDatePicker: React.FC<Props> = props => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const { editable, ...rest } = props;

  const handleUpdateDate = (value: MaterialUiPickersDate) => {
    const formatted = value?.toFormat('MM/dd/yyyy');

    if (value?.isValid && formatted) {
      field.onChange(field.name)(formatted);
    } else {
      field.onChange(field.name)('');
    }
  };

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <KeyboardDatePicker
        {...rest}
        margin="normal"
        inputVariant={editable ? 'filled' : 'standard'}
        format="MM/dd/yyyy"
        disabled={!editable}
        readOnly={!editable}
        clearable={true}
        onBlur={field.onBlur}
        error={meta.touched ? !!meta.error : undefined}
        onChange={handleUpdateDate}
        value={field.value || null}
        helperText={meta.touched ? meta.error : undefined}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormDatePicker;
