import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { Body } from '../../../../../../components/Typography';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

type ValueTextProps = {
  isRestricted: boolean;
};
export const ValueText = styled(Body)<ValueTextProps>`
  color: ${({ theme, isRestricted }) =>
    isRestricted ? theme.palette.primary.red : theme.palette.primary.dark};
  font-weight: ${({ isRestricted }) => (isRestricted ? 'bold' : 'normal')};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const LinkButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.primary.bluePurple};
    font-family: ${({ theme }) => theme.typography.body1.fontFamily};
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.86px;
    justify-content: flex-start;
    padding: 0;
    text-transform: none;
  }
`;
