import { UserWallet } from './types';
import { GetUserWalletQuery } from '../../../../../graphql/api';

export const useGetUserWalletSelector = ({
  user,
}: GetUserWalletQuery): UserWallet => {
  return {
    funds: user.wallet.funds,
    isAutomaticPaymentsEnabled:
      user.wallet.automaticPayments?.isEnabled ?? false,
    isApprovalEnabled:
      user.wallet.automaticPayments?.isApprovalEnabled ?? false,
    token: user.wallet.automaticPayments?.payment?.token || '',
  };
};
