import Button from '@material-ui/core/Button';
import styled from 'styled-components';

interface ContentTabProps {
  hasAttachment: boolean;
}

export const ContentTab = styled.div<ContentTabProps>`
  border-top: 1px solid ${({ theme }) => theme.palette.primary.darkGrey};
  padding: 10px 0;
  margin: 0 10px;
  overflow-y: auto;
  height: ${({ hasAttachment }) =>
    hasAttachment ? 'calc(100% - 130px)' : 'calc(100% - 90px)'};
`;

export const PreviewContainer = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 10px;
  left: 20%;
  min-height: 500px;
  height: calc(100% - 20px);
  outline: none;
  padding: 10px 20px;
  min-width: 400px;
  width: 60%;
`;

export const Headline = styled.h2``;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Row = styled.div`
  && {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
  }
`;

export const StyledButton = styled(Button)``;

export const CancelButton = styled(Button)`
  && {
    margin-right: 10px;
  }
`;
