import { saveAs } from 'file-saver';
import { Paginated, QueryOptions, TripInfo } from '../../../types/interfaces';
import { getConfig } from '../../config';
import { api } from '../httpService';
import paginatedQueryString from '../paginatedQueryString';

const config = getConfig();
export const getTripData = <T>(
  tripId: string,
  version: '1' | '2',
): Promise<T> => {
  switch (version) {
    case '1':
      return api<T>('get', `/api/trips/${tripId}/data`);
    case '2':
      return api<T>('get', `/trips/${tripId}`, undefined, {
        baseUrl: config.REACT_APP_TRIP_ENDPOINT,
      });
    default:
      throw new Error('Trip version not implemented');
  }
};

export const getPaginatedTrips = <T>(
  query: QueryOptions,
  search: string,
  version: '1' | '2',
): Promise<T> => {
  switch (version) {
    case '1':
      return api<T>('get', `/api/trips?${paginatedQueryString(query, search)}`);
    case '2':
      return api<T>(
        'get',
        `/trips?${paginatedQueryString(query, search)}`,
        undefined,
        {
          baseUrl: config.REACT_APP_TRIP_ENDPOINT,
        },
      );
    default:
      throw new Error('Trip version not implemented');
  }
};

export const getUserTrips = (userId: string): Promise<Paginated<TripInfo>> =>
  api<Paginated<TripInfo>>('get', `/user/${userId}/trips`, undefined, {
    baseUrl: config.REACT_APP_TRIP_ENDPOINT,
  });

export const downloadTrip = async <T>(tripId: string, version: '1' | '2') => {
  const trip = await getTripData<T>(tripId, version);
  const blob = new Blob([JSON.stringify(trip)], {
    type: 'application/json',
  });
  saveAs(blob, `trip_${tripId}_data.json`);
};
