import { ActionsObservable, combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { api$ } from '../../../services/httpService';
import { onSubmitUserComplete, onSubmitUserError } from '../actions';
import { SUBMIT_USER_START } from '../constants';
import { ISubmitUserStartAction } from '../interfaces';

export const submitUserStart: Epic = (
  action$: ActionsObservable<ISubmitUserStartAction>,
) =>
  action$.pipe(
    filter(isOfType(SUBMIT_USER_START)),
    switchMap(action =>
      api$('post', '/api/admin', {
        firstName: action.payload.user.firstName,
        lastName: action.payload.user.lastName,
        email: action.payload.user.email,
      }).pipe(
        map((user: any) => onSubmitUserComplete(user)),
        catchError(err => of(onSubmitUserError(err))),
      ),
    ),
  );

export default combineEpics(submitUserStart);
