import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const ActionButton = styled(Button)`
  && {
    align-self: center;
    width: 220px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;
