import { FormControl, FormHelperText } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import React from 'react';
import style from './FormSelect.styled';

export interface SelectOption {
  label: string;
  value: string | number;
}

interface Props
  extends Omit<
    SelectProps,
    'onChange' | 'value' | 'onBlur' | 'error' | 'disabled'
  > {
  readonly classes: any;
  readonly editable?: boolean;
  readonly label?: string;
  readonly name: string;
  readonly options: SelectOption[];
  readonly hasMargin?: boolean;
  readonly value?: string;
}

const FormSelect: React.FC<Props> = props => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const {
    classes,
    editable,
    label,
    options,
    hasMargin = true,
    value = '',
    ...rest
  } = props;

  return (
    <FormControl
      variant={editable ? 'filled' : 'standard'}
      className={`${classes.formControl} ${
        hasMargin ? classes.formControlMargin : null
      }`}
      error={meta.touched ? !!meta.error : undefined}
      fullWidth
    >
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Select
        {...rest}
        disabled={!editable}
        inputProps={{
          readOnly: !editable,
        }}
        onChange={field.onChange(field.name)}
        onBlur={field.onBlur}
        value={field.value ?? value}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default withStyles(style)(FormSelect);
