import React from 'react';
import { useGetPolicyDetails } from './hooks/usePolicy';
import TransactionModal from './TransactionModal';

interface Props {
  userId: string;
  onClose: () => void;
  onSave: () => void;
}

const FiftyMileCharge: React.FC<Props> = ({ userId, onClose, onSave }) => {
  const { isLoading, error, data: policyDetails } = useGetPolicyDetails(userId);

  return (
    <TransactionModal
      userId={userId}
      onClose={onClose}
      onSave={onSave}
      isLoading={isLoading}
      errorMessage={error?.message}
      policyDetails={policyDetails}
      numberofCharges={1}
    />
  );
};

export default FiftyMileCharge;
