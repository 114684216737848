import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.darkGrey};
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 30px 0 20px;
`;

export const Group = styled.div`
  align-items: center;
  display: flex;
`;

export const FilterGroup = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const DateFilterLabel = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
`;

export const FilterLabel = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
`;

export const FilterImage = styled.img`
  margin-right: 7px;
  height: 12px;
  width: 16px;
`;

export const CommsFilter = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 6px 10px;
  border-radius: 13px;
  border: solid 2px ${({ theme }) => theme.palette.primary.darkGrey};
  margin-left: 10px;
  min-width: 50px;
`;

export const CommsLabel = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
`;

export const CrossImage = styled.img`
  margin-left: 5px;
  height: 10px;
  width: 10px;
`;
