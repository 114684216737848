import React from 'react';
import { Loader } from '../../../components/Loader';
import { withErrorBoundary } from '../../../components/withErrorBoundary';
import { DetailedInfoTile, InfoItem } from '../shared/DetailedInfoTile';
import { ErrorMessage } from '../shared/ErrorMessage';

interface Props {
  onRetry: () => Promise<void>;
  isLoading: boolean;
  title: string;
  actions: InfoItem[];
  errorMessage: string;
}

const AccountStateContainer: React.FC<Props> = ({
  onRetry,
  isLoading,
  title,
  actions,
  errorMessage,
}) => {
  if (isLoading) {
    return <Loader />;
  }

  if (errorMessage) {
    return <ErrorMessage message="There was an error." onRetry={onRetry} />;
  }

  return <DetailedInfoTile title={title} infoItems={actions} />;
};

export default withErrorBoundary(AccountStateContainer);
