import React, { memo } from 'react';
import { Trip } from '../../../../types/interfaces';
import { Table } from '../../../components/Table';
import * as tripService from '../../../services/repository/tripService';
import { tripV1ListColumnDefs } from './tripListColumnDefs';

interface Props {
  onNavigation: (url: string) => void;
  trips: Trip[];
  visible: boolean;
}

const TripDetailsV1List: React.FC<Props> = ({
  onNavigation,
  trips,
  visible,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Table
      columns={tripV1ListColumnDefs}
      data={trips}
      defaultOrder="start"
      defaultDir="desc"
      pageSize={10}
      onRowClick={trip =>
        trip.hasTripData ? onNavigation(`/mapping/${trip.id}/1`) : {}
      }
      rowActions={[
        {
          name: 'Download',
          icon: 'save_alt',
          isEnabled: trip => trip.hasTripData,
          onClick: trip => tripService.downloadTrip<Trip>(trip.id, '1'),
        },
      ]}
    />
  );
};

export default memo(TripDetailsV1List);
