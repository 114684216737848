import React from 'react';
import { UserVerificationController } from './Controller';
import UserVerificationScreen from './UserVerificationScreen';

type Props = {
  userId: string;
};

const UserVerification: React.FC<Props> = ({ userId }) => {
  return (
    <UserVerificationController userId={userId}>
      <UserVerificationScreen />
    </UserVerificationController>
  );
};

export default UserVerification;
