import styled from 'styled-components';
import { TextField, FormControl, Paper } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 20px;
  }
`;

export const SearchRow = styled(Paper)`
  display: flex;
  align-items: center;
  padding: 24px;
  margin-bottom: 24px;
`;

export const SearchContainer = styled.div`
  flex: 1;
`;

export const SearchTextField = styled(TextField)`
  && {
    min-width: 300px;
  }
`;

export const SearchTypeField = styled(FormControl)`
  && {
    min-width: 150px;
    margin-left: 20px;
  }
`;

export const Loading = styled(CircularProgress)`
  margin-left: 20px;
`;
