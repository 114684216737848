import { gql } from 'graphql-request';
import { DateTime } from 'luxon';
import {
  FilterCommsOption,
  FilterDateOption,
} from '../../features/userManagement/CommunicationsHistory/FilterSelector/filterOptions';
import { fetchGraphQLQuery } from '../../graphql/service';
import {
  AccountEmail as AccountEmailQL,
  AccountSms as AccountSmsQL,
  ForwardEmailInput,
  Query,
  SavedAttachment,
} from '../../graphql/types';
import { setDateTimezone } from '../../helpers/convertDate';
import { api } from '../httpService';

export interface CommunicationFilter {
  dateOption: FilterDateOption;
  commsOption: FilterCommsOption;
}

export enum EmailStatus {
  Sending = 'SENDING',
  Sent = 'SENT',
}

export type Attachment = Omit<SavedAttachment, 'url'> & {
  url: string;
};

export type AccountEmail = Omit<
  AccountEmailQL,
  'attachments' | 'sentAt' | 'sendingAt'
> & {
  type: 'email';
  attachments?: Attachment[];
  tags: string[];
  sendingAt: DateTime;
  sentAt?: DateTime;
};

export type AccountSms = Omit<AccountSmsQL, 'sentAt' | 'sendingAt'> & {
  type: 'sms';
  sendingAt: DateTime;
  sentAt?: DateTime;
};

export type Communication = AccountEmail | AccountSms;

export const getEmailAttachment = async (url: string): Promise<string> =>
  await api<string>('get', `/communication/attachment?url=${url}`, undefined, {
    responseType: 'blob',
  });

const convertFilterToParams = ({
  commsOption,
  dateOption,
}: CommunicationFilter) => {
  let since = DateTime.local();
  switch (dateOption.value) {
    case 'all':
      since = DateTime.fromISO('1970-01-01');
      break;
    case 'past30':
      since = DateTime.local().minus({ days: 30 });
      break;
    case 'pastyear':
      since = DateTime.local().minus({ year: 1 });
      break;
    default:
      throw new Error('Invalid communication filter');
  }

  return { since: since.toISO(), tag: commsOption.value };
};

export const getCommunicationHistory = async (
  userId: string,
  filter: CommunicationFilter,
): Promise<Communication[]> => {
  const { since, tag } = convertFilterToParams(filter);
  const response = await fetchGraphQLQuery<Query>(
    gql`
      query getUserCommunication($userId: ID!, $since: String!) {
        user(id: $userId) {
          emails {
            id
            to
            from
            templateId
            status
            subject
            attachments {
              sourceId
              filename
              type
              url
            }
            content
            comment
            sendingAt
            sentAt
            userId
          }
          sms(since: $since) {
            id
            to
            from
            content
            tags
            status
            sendingAt
            sentAt
            userId
          }
        }
      }
    `,
    {
      userId,
      since,
    },
  );

  // TODO: Email needs filter to be applied once it supports 'tags'
  const emailList = response.user!.emails.map(e => mapEmail(userId, e));
  const smsList = response
    .user!.sms.filter(({ tags }) => tag === 'all' || tags.includes(tag))
    .map(s => mapSms(userId, s));

  return [
    ...emailList,
    ...smsList.filter(({ tags }) => tag === 'all' || tags.includes(tag)),
  ].sort(sortCommunicationByDateToByNewestToOldest);
};

const mapEmail = (userId: string, email: AccountEmailQL): AccountEmail => {
  return {
    ...email,
    type: 'email',
    attachments: email.attachments?.map(mapAttachment),
    tags: [],
    sentAt: email.sentAt ? setDateTimezone(email.sentAt, userId) : undefined,
    sendingAt: setDateTimezone(email.sendingAt, userId),
  };
};

const mapSms = (userId: string, sms: AccountSmsQL): AccountSms => {
  return {
    ...sms,
    type: 'sms',
    sentAt: sms.sentAt ? setDateTimezone(sms.sentAt, userId) : undefined,
    sendingAt: setDateTimezone(sms.sendingAt!, userId),
  };
};

export const forwardEmail = async (email: ForwardEmailInput) => {
  const queryString = gql`
    mutation forwardEmail($email: ForwardEmailInput!) {
      forwardEmail(email: $email)
    }
  `;
  return fetchGraphQLQuery(queryString, { email });
};

const mapAttachment = (attachment: SavedAttachment): Attachment => {
  return {
    ...attachment,
    url: attachment.url!,
  };
};

const sortCommunicationByDateToByNewestToOldest = (
  a: AccountEmail | AccountSms,
  b: AccountEmail | AccountSms,
) => {
  return a.sendingAt.toSeconds() > b.sendingAt.toSeconds() ? -1 : 1;
};
