import React from 'react';
import { DateTime } from 'luxon';
import { Table } from '../../../../../../components/Table';
import { DrivingReport } from '../../../Controller/drivingHistory/types';

const parseViolationDate = (date: string, userTimezone: string) => {
  return DateTime.fromISO(date, { zone: userTimezone }).toFormat('d LLL yyyy');
};

const getColumns = (userTimezone: string) => [
  {
    id: 'reportedAt',
    title: 'Date',
    rowFormatter: (row: DrivingReport) => {
      return row.isAvailable
        ? DateTime.fromISO(row.reportedAt, { zone: userTimezone }).toFormat(
            'd LLL yyyy HH:mm:ss.SSS',
          )
        : 'Date not available';
    },
  },
  {
    title: 'Violations found',
    id: 'score',
    rowFormatter: (row: DrivingReport) => {
      return row.isAvailable ? (
        <ul>
          {row.violations.map(v => (
            <li>
              {parseViolationDate(v.date, userTimezone)}, {v.severity}{' '}
              violation, {v.description}
            </li>
          ))}
        </ul>
      ) : (
        'Not available contact dev team'
      );
    },
  },
];

interface Props {
  userTimezone: string;
  items: DrivingReport[];
  isVisible: boolean;
}

const DrivingHistoryList: React.FC<Props> = ({
  userTimezone,
  items,
  isVisible,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Table
      columns={getColumns(userTimezone)}
      data={items}
      defaultOrder="start"
      defaultDir="desc"
      pageSize={10}
    />
  );
};

export default DrivingHistoryList;
