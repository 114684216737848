import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const AddButton = styled(Button).attrs({
  color: 'secondary',
  variant: 'contained',
})`
  && {
    position: absolute;
    border-radius: 25px;
    font-size: 30px;
    right: 20px;
    bottom: 20px;
    height: 50px;
    min-width: 50px !important;
  }
`;
