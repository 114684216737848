import React, { useState } from 'react';
import {
  MenuItem,
  PopUpMenu,
} from '../../../../../components/PopUpMenu/PopUpMenu';
import { AccountEmail } from '../../../../../services/repository/communicationService';
import { filterCommsOptions } from '../../FilterSelector/filterOptions';
import { AttachmentList } from '../AttachmentList/AttachmentList';
import * as S from './EmailCardContent.styled';

interface Props {
  email: AccountEmail;
  menuItems: MenuItem[];
  onMenuItem: (item: MenuItem) => void;
  onOpenAttachment: (url: string) => void;
  onPreview: (email: AccountEmail) => void;
}

export const EmailCardContent: React.FC<Props> = ({
  email,
  menuItems,
  onMenuItem,
  onOpenAttachment,
  onPreview,
}) => {
  const [expand, setExpand] = useState(false);
  const hasExpander =
    (email.attachments && email.attachments.length > 0) || !!email.comment;
  return (
    <>
      <S.Container>
        <div>
          <S.Icon src="/images/icon-email.svg" alt="" />
        </div>
        <div>
          <S.Header>
            <S.HorizontalGroup>
              <S.SectionTitle>Email</S.SectionTitle>
              {email.tags.map(tag => {
                const option = filterCommsOptions.find(
                  ({ value }) => value === tag,
                );
                return (
                  <S.Tag key={tag} title={tag}>
                    <S.TagImage src="/images/icon-tag.svg" alt="" />
                    <div>{option?.label || tag}</div>
                  </S.Tag>
                );
              })}
            </S.HorizontalGroup>

            <S.HorizontalGroup>
              <S.UserLabel>{email.status}</S.UserLabel>
              <S.Timestamp>{email.sendingAt.toFormat('FF')}</S.Timestamp>
            </S.HorizontalGroup>
          </S.Header>
          <S.Content>
            <S.VerticalGroup>
              <S.EmailAddress>{email.to.join(', ')}</S.EmailAddress>
              <S.Subject>{email.subject}</S.Subject>
              <S.LinkButton variant="text" onClick={() => onPreview(email)}>
                Preview
              </S.LinkButton>

              {expand ? (
                <S.DetailsContent>
                  {email.attachments ? (
                    <>
                      <S.DetailsTitle>Attachments</S.DetailsTitle>
                      <AttachmentList
                        attachments={email.attachments}
                        type="small"
                        onOpenAttachment={onOpenAttachment}
                      />
                    </>
                  ) : null}
                  {email.comment ? (
                    <>
                      <S.DetailsTitle>Comment</S.DetailsTitle>
                      <S.Comment>{email.comment}</S.Comment>
                    </>
                  ) : null}
                </S.DetailsContent>
              ) : null}
            </S.VerticalGroup>

            <S.VerticalGroup>
              <PopUpMenu menuItems={menuItems} onClick={onMenuItem} />
              {hasExpander ? (
                <S.LinkButton variant="text" onClick={() => setExpand(!expand)}>
                  {`${expand ? 'Hide' : 'Show'} Details`}
                </S.LinkButton>
              ) : null}
            </S.VerticalGroup>
          </S.Content>
        </div>
      </S.Container>
    </>
  );
};
