import styled from 'styled-components';
import { TableCell, Divider } from '@material-ui/core';

export const StyledTableCell = styled(TableCell)`
  && {
    border-bottom: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  && {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const LoadingContainer = styled.div`
  text-align: center;
  margin: 10px;
`;
