import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.white};
  border: 1px solid ${({ theme }) => theme.palette.primary.grey};
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.palette.shades.boxBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 180px;
  width: 213px;
`;

export const TileText = styled.div`
  color: ${({ theme }) => theme.palette.primary.grey};
  font-size: 16px;
  text-align: center;
`;

export const TileValue = styled.div`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 64px;
  text-align: center;
`;
