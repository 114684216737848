import React from 'react';
import PolicyPurchaseStatusTile from './PolicyPurchaseStatusTile';
import PolicyPurchaseStatusModal from './PolicyPurchaseStatusModal';
import { useAccountState } from '../../Controller';
import {
  RestrictUserReason,
  UnrestrictUserReason,
} from '../../../../../graphql/api';

const PolicyPurchaseInfoTile = () => {
  const {
    isLoading,
    isProcessing,
    data: { isRestricted },
    getAccountState,
    restrictPolicyPurchase,
    enablePolicyPurchase,
    error,
  } = useAccountState();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const confirmRestrictPolicyPurchase = async (payload: {
    notes: string;
    reason: RestrictUserReason;
  }): Promise<void> => {
    const response = await restrictPolicyPurchase(payload);
    if (response.success) {
      hideModal();
    } else {
      throw new Error(response.error);
    }
  };

  const confirmEnablePolicyPurchase = async (payload: {
    notes: string;
    reason: UnrestrictUserReason;
  }): Promise<void> => {
    const response = await enablePolicyPurchase(payload);
    if (response.success) {
      hideModal();
    } else {
      throw new Error(response.error);
    }
  };

  return (
    <>
      <PolicyPurchaseStatusTile
        onRetry={getAccountState}
        onActionClick={showModal}
        isLoading={isLoading}
        isRestricted={isRestricted}
        errorMessage={error}
      />
      <PolicyPurchaseStatusModal
        enablePolicyPurchase={confirmEnablePolicyPurchase}
        restrictPolicyPurchase={confirmRestrictPolicyPurchase}
        isLoading={isProcessing}
        isOpen={isModalOpen}
        hideModal={hideModal}
        isRestricted={isRestricted}
      />
    </>
  );
};

export default PolicyPurchaseInfoTile;
