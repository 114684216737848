import React, { useState } from 'react';
import { Table } from '../../../components/Table';
import { salesPeopleColumnDefs } from './salesPeopleListColumnDefs';
import { useGetSalesPeopleQuery } from '../../../graphql/api';
import { withErrorBoundary } from '../../../components/withErrorBoundary';
import { Loading } from './SalesPeopleList.styled';
import { RouteComponentProps } from 'react-router-dom';
import { GetSalesPeopleQuery } from '../../../graphql/api';
import { GenericComponentError } from '../../../components/GenericComponentError';
import { filterList } from '../../../helpers/filterHelpers';
import { Salesperson } from '../../../../types/interfaces';

const SalesPeopleList: React.FC<RouteComponentProps> = ({ history }) => {
  const [filter, setFilter] = useState<string>('');

  const { isLoading, isError, error, data } = useGetSalesPeopleQuery<
    GetSalesPeopleQuery,
    Error
  >();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <GenericComponentError error={error!} />;
  }

  const filteredData = data
    ? filterList(data.salesPeople, ['id', 'firstName', 'lastName'], filter)
    : [];

  const navigateToSalesperson = (salesPerson: Salesperson) => {
    history.push(`/referrals/salesperson/${salesPerson.id}`);
  };

  return (
    <Table
      data={filteredData}
      total={filteredData.length}
      columns={salesPeopleColumnDefs}
      disablePagination={true}
      useAutoFocus
      searchLabel="Search by ID or name"
      searchOnClient
      onFilterData={setFilter}
      onRowClick={navigateToSalesperson}
    />
  );
};

export default withErrorBoundary(SalesPeopleList);
