import styled from 'styled-components';

export const Contain = styled.div`
  background: ${({ theme }) => theme.palette.background.white};
  padding-top: ${({ theme }) => theme.spacer.m};
  padding-bottom: ${({ theme }) => theme.spacer.l};

  && {
    div.radr-table {
      height: auto;
    }
  }

  table {
    tr {
      .MuiTableCell-root {
        padding: 16px 8px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      th {
        width: 200px;

        &:nth-child(1) {
          width: 250px;
        }

        &:last-child {
          width: auto;
        }
      }

      td {
        height: 75px;
      }
    }
  }
`;

export const Section = styled.section`
  margin-bottom: ${({ theme }) => theme.spacer.l};
`;
