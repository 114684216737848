import React, { useState } from 'react';
import {
  useChargeUserMutation,
  ChargeInput,
  ChargeUserMutation,
} from '../../../../../../graphql/api';
import {
  Button,
  DialogContent,
  TableBody,
  TableRow,
  InputAdornment,
} from '@material-ui/core';
import LoadingButton from '../../../../../../components/LoadingButton/LoadingButton';
import {
  StyledDialogTitle,
  StyledDialogActions,
} from '../../../../../../components/ConfirmationModalV2/ConfirmationModalV2.styled';
import { ErrorCode, logError } from '../../../../../../services/logging';
import ModalError from '../../../../../../components/ModalError/ModalError';
import { StyledTable, StyledTableCell, AmountField } from './Modal.styled';
import AutopaymentsRow from './AutopaymentsRow';
import BalanceRow from './BalanceRow';
import { Formik } from 'formik';
import schema from './schema';

interface Props {
  userId: string;
  funds: number;
  isAutomaticPaymentsEnabled: boolean;
  isNegativeFunds: boolean;
  token: string;
  onClose: () => void;
}

interface FormValues {
  amountInDollars: string;
}

const ModalForm: React.FC<Props> = ({
  userId,
  funds,
  isAutomaticPaymentsEnabled,
  isNegativeFunds,
  token,
  onClose,
}) => {
  const [mutationError, setMutationError] = useState<string | undefined>();

  const { isLoading, mutate } = useChargeUserMutation({
    onMutate: () => {
      setMutationError(undefined);
    },
    onSuccess: (res: ChargeUserMutation) => {
      if (res.charge.__typename === 'ChargeFailure') {
        setMutationError(res.charge.message);
      } else {
        onClose();
      }
    },
    onError: (err: Error) => {
      setMutationError(err.message);
      logError(ErrorCode.ChargeUser, err);
    },
    useErrorBoundary: false,
  });

  const onSubmit = async ({ amountInDollars }: FormValues) => {
    const amountInCents = parseFloat(amountInDollars) * 100;

    const input: ChargeInput = {
      userId: parseInt(userId, 10),
      amount: amountInCents,
      token,
    };

    mutate({ input });
  };

  return (
    <Formik
      validateOnMount={false}
      validationSchema={schema(funds)}
      onSubmit={onSubmit}
      initialValues={{ amountInDollars: '' }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
        errors: formErrors,
      }) => (
        <>
          <StyledDialogTitle>Take a payment</StyledDialogTitle>
          <DialogContent>
            <StyledTable size="small">
              <TableBody>
                <AutopaymentsRow
                  isAutomaticPaymentsEnabled={isAutomaticPaymentsEnabled}
                />
                <BalanceRow funds={funds} />
                <TableRow>
                  <StyledTableCell component="th">
                    Amount to charge
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <AmountField
                      variant="outlined"
                      name="amountInDollars"
                      label=""
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      autoFocus
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </StyledTable>
            {mutationError ? <ModalError error={mutationError} /> : null}
            {formErrors.amountInDollars ? (
              <ModalError error={formErrors.amountInDollars} />
            ) : null}
          </DialogContent>
          <StyledDialogActions disableSpacing>
            <LoadingButton
              onClick={() => handleSubmit()}
              isLoading={isLoading}
              buttonType="confirm"
              disabled={
                !isAutomaticPaymentsEnabled || !isNegativeFunds || !isValid
              }
            >
              Charge
            </LoadingButton>
            <Button onClick={onClose} variant="outlined" color="primary">
              Abort
            </Button>
          </StyledDialogActions>
        </>
      )}
    </Formik>
  );
};

export default ModalForm;
