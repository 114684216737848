import { DateTime } from 'luxon';

const timezone: Map<string, string> = new Map();

// NOTE: This is a hack to allow us to set timezone for a USER but we need to have a proper solutions coming
export const setUserTimezone = (userId: string, tz: string) => {
  timezone.set(userId, tz);
};

export const setDateTimezone = (date: string, userId?: string | number) => {
  return DateTime.fromISO(
    date,
    userId && timezone.has(`${userId}`)
      ? { zone: timezone.get(`${userId}`) }
      : {},
  );
};
