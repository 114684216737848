import * as yup from 'yup';
import { OrganisationType } from '../../../graphql/api';

const zipcodeRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export default yup.object().shape({
  addressLine1: yup.string().trim().required('Address line 1 is required'),
  addressLine2: yup.string(),
  city: yup.string().trim().required('City is required'),
  fein: yup.string(),
  name: yup.string().trim().required('Name is required'),
  parentName: yup.string(),
  state: yup
    .string()
    .trim()
    .length(2, 'State must be 2 characters')
    .matches(/^[A-Z]+$/, 'State must be uppercase letters')
    .required('State is required'),
  type: yup
    .mixed<OrganisationType>()
    .oneOf(Object.values(OrganisationType))
    .required('Organisation type is required'),
  website: yup.string().trim().required('Website is required'),
  zipCode: yup
    .string()
    .required('Zipcode is required')
    .matches(zipcodeRegExp, 'Zipcode is not valid'),
});
