import { DateTime } from 'luxon';
import { GetUserDiscoveryQuery } from '../../../../graphql/api';
import { DiscoveredVehiclesResult } from './DiscoverySuccess';
import { Vehicle } from './VehiclesTable';

export const useGetUserDiscoverySelector = ({
  user,
}: GetUserDiscoveryQuery): DiscoveredVehiclesResult => {
  const { discovered } = user;

  const insurableVehicles: Vehicle[] = [];
  const uninsurableVehicles: Vehicle[] = [];

  discovered?.vehicles.forEach(vehicle => {
    if (vehicle.insurable?.__typename === 'InsurableVehicle') {
      insurableVehicles.push({
        vin: vehicle.vin,
        plate: vehicle.plate ?? undefined,
        make: vehicle.insurable.insurableMake,
        model: vehicle.insurable.insurableModel,
        year: vehicle.insurable.insurableYear,
      });
    }

    if (vehicle.insurable?.__typename === 'UninsurableVehicle') {
      const discoveredMake = vehicle.make ?? undefined;
      const discoveredModel = vehicle.model ?? undefined;
      uninsurableVehicles.push({
        vin: vehicle.vin,
        plate: vehicle.plate ?? undefined,
        make: vehicle.insurable.uninsurableMake
          ? vehicle.insurable.uninsurableMake
          : discoveredMake,
        model: vehicle.insurable.uninsurableModel
          ? vehicle.insurable.uninsurableModel
          : discoveredModel,
        year: vehicle.insurable.uninsurableYear,
      });
    }

    if (vehicle.insurable?.__typename === 'VehicleError') {
      const discoveredMake = vehicle.make ?? undefined;
      const discoveredModel = vehicle.model ?? undefined;
      uninsurableVehicles.push({
        vin: vehicle.vin,
        plate: vehicle.plate ?? undefined,
        make: vehicle.insurable.make ? vehicle.insurable.make : discoveredMake,
        model: vehicle.insurable.model
          ? vehicle.insurable.model
          : discoveredModel,
      });
    }
  });

  return {
    lastUpdate: discovered?.lastUpdate
      ? DateTime.fromISO(discovered?.lastUpdate)
      : undefined,
    insurableVehicles,
    uninsurableVehicles,
  };
};
