import Modal from '@material-ui/core/Modal';
import React from 'react';
import Form from '../../../../../components/Form/Form';
import FormTextInput from '../../../../../components/FormTextInput/FormTextInput';
import * as S from './EditEmailModal.styled';
import schema from './schema';

export interface Props {
  email: string;
  isOpen: boolean;
  onSaveV1: (email: string) => Promise<void>;
  onClose: () => void;
}

const EditEmailModal: React.FC<Props> = ({
  isOpen,
  email,
  onSaveV1,
  onClose,
}) => {
  return (
    <Modal open={isOpen} onClose={() => onClose()}>
      <S.SaveModal>
        <Form
          initialValues={{ email }}
          validateOnMount={false}
          validationSchema={schema}
          submit={values => onSaveV1(values.email)}
        >
          {({ resetForm, handleSubmit }) => (
            <>
              <S.Headline>Update User Email Address</S.Headline>
              <S.Comment>
                WARNING: You are updating the users email address which is used
                to authenticate the account.
              </S.Comment>
              <div>
                <FormTextInput
                  name="email"
                  editable={true}
                  label="Email Address"
                  placeholder="Enter an email address"
                />
              </div>
              <S.Row>
                <S.CancelButton
                  variant="contained"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  ABORT
                </S.CancelButton>
                <S.StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  UPDATE
                </S.StyledButton>
              </S.Row>
            </>
          )}
        </Form>
      </S.SaveModal>
    </Modal>
  );
};

export default EditEmailModal;
