import { CoverageSelection, Maybe, Quote } from '../graphql/types';
import { optionsMap } from '../services/repository/policyService';

export const getSelectedCoverages = (
  price: Quote,
  selection?: Maybe<CoverageSelection>,
) => {
  if (!selection) {
    return;
  }

  const { packages, optionalCoverageGroups } = selection;

  const coverages: string[] = [];
  price.packages
    .filter(p => packages.indexOf(p.id) > -1)
    .forEach(p => {
      coverages.push(p.title);

      p.optionalCoverageGroups
        .filter(ocg => optionalCoverageGroups.indexOf(ocg.id) > -1)
        .forEach(ocg => coverages.push(ocg.title));
    });

  return coverages;
};

export interface QuoteCoverageDetail {
  id: string;
  name: string;
  prices: string[];
  selected: boolean;
  isOption?: boolean;
  hasOptions?: boolean;
}

export interface QuoteDetail {
  title: string;
  subtitle: string;
  selected: boolean;
  coverages: QuoteCoverageDetail[];
  optionalCoverages: QuoteCoverageDetail[];
}

export const getQuoteDetail = (
  price: Quote,
  selection?: Maybe<CoverageSelection>,
): QuoteDetail[] =>
  price.packages.map(p => {
    const packageSelected = selection?.packages.includes(p.id);

    const coverages: QuoteCoverageDetail[] = [];
    const optionalCoverages: QuoteCoverageDetail[] = [];
    p.coverageGroups.forEach(cg => {
      cg.coverages.forEach(c => {
        const optionsSelected =
          c.deductibles?.options?.filter(
            o => selection && selection.options.includes(o.id),
          ) || [];

        const prices = c.premium.value.map(
          ({ amount, unit }) => `${amount}c per ${unit}`,
        );
        coverages.push({
          id: c.id,
          name: c.name || cg.name,
          prices,
          selected: packageSelected || false,
          hasOptions: !!c.deductibles?.options,
        });

        c.deductibles?.options?.forEach(o => {
          const deductables: string[] = [];
          o.premium?.value.forEach(v => {
            deductables.push(`${v.amount}c per ${v.unit}`);
          });
          const optSelected = selection?.options?.includes(o.id);
          const optionTitle = optionsMap[o.id as keyof typeof optionsMap] || '';

          coverages.push({
            id: o.id,
            name: optionTitle,
            prices: deductables,
            selected:
              !!packageSelected &&
              ((optionsSelected.length === 0 && o.isDefault) ||
                optSelected ||
                false),
            isOption: true,
          });
        });
      });
    });

    p.optionalCoverageGroups.forEach(ocg => {
      const selected =
        packageSelected && selection?.optionalCoverageGroups.includes(ocg.id);
      const prices: string[] = [];
      ocg.coverageGroups.forEach(cg => {
        cg.coverages.forEach(c => {
          c.premium.value.forEach(({ amount, unit }) =>
            prices.push(`${amount} per ${unit}`),
          );
        });
      });

      optionalCoverages.push({
        id: ocg.id,
        name: ocg.title,
        prices,
        selected: selected || false,
        isOption: true,
      });
    });

    return {
      title: p.title,
      subtitle: p.subtitle,
      selected: packageSelected || false,
      coverages,
      optionalCoverages,
    };
  });
