import { SidebarContent } from '../../../../components/Sidebar';
import UserSidebar from './components/UserSidebar';
import PageContent from '../../../../components/PageContent';

type Props = {
  userId: string;
};

const ActiveUserPage: React.FC<Props> = ({ userId }) => (
  <>
    <UserSidebar userId={userId} />
    <SidebarContent>
      <PageContent>Todo: User Page</PageContent>
    </SidebarContent>
  </>
);

export default ActiveUserPage;
