import React from 'react';
import styled from 'styled-components';
import { Body } from './Typography';

const ErrorTitle = styled(Body)`
  color: ${({ theme }) => theme.palette.primary.red};
`;

const ErrorBody = styled(Body)`
  font-size: ${({ theme }) => theme.font.size.s};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  error: Error;
}

export const GenericComponentError: React.FC<Props> = ({ error }) => (
  <Container>
    <ErrorTitle>Error</ErrorTitle>
    <ErrorBody>{error.message}</ErrorBody>
  </Container>
);
