import { FormControlLabel, Radio } from '@material-ui/core';
import React from 'react';

export type Selection = 'edit' | 'remove' | null;
const RadioButton: React.FC<{
  selected: Selection;
  value: Selection;
  label: String;
  onChange: (s: Selection) => void;
}> = ({ onChange, selected, value, label }) => (
  <FormControlLabel
    value={value}
    control={
      <Radio
        color="primary"
        checked={selected === value}
        onChange={({ target: { value: newValue } }) =>
          onChange(newValue as Selection)
        }
      />
    }
    label={label}
    labelPlacement="end"
  />
);

export default RadioButton;
