import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import App from './App';
import { getConfig } from './config';
import { registerSentry } from './services/logging';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { history } from './App';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, muiTheme, theme } from './assets/theme';
import { configureStore } from './store';

const store = configureStore();
const config = getConfig();

const onRedirectCallback = (appState: AppState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

ReactGA.initialize([
  {
    trackingId: `${config.REACT_APP_GA_PAGE_VIEW}`,
    gaOptions: {
      name: 'page-view',
    },
  },
  {
    trackingId: `${config.REACT_APP_GA_CLICK_EVENTS}`,
    gaOptions: {
      name: 'click-events',
    },
  },
]);

registerSentry();

const hideLoading = () => {
  const loading = document.getElementById('loading');
  const loadingCss = document.getElementById('loading-css');
  loading?.remove();
  loadingCss?.remove();
};

ReactDOM.render(
  <Auth0Provider
    domain={config.REACT_APP_AUTH_DOMAIN}
    clientId={config.REACT_APP_AUTH_CLIENT_ID}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <GlobalStyle />
          <App />
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root'),
);
hideLoading();
