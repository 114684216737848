import { SelectOption } from '../../../../../components/FormSelect/FormSelect';
import { UpdateUserEmailReason } from '../../../../../graphql/api';

export const initialFormValues = {
  email: '',
  reason: '',
  description: '',
};

export const emailChangeReasons: Array<SelectOption> = [
  { label: 'Lost access', value: UpdateUserEmailReason.LostAccess },
  { label: 'Other', value: UpdateUserEmailReason.Other },
];
