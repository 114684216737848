import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.white};
  border: 1px solid ${({ theme }) => theme.palette.primary.grey};
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.palette.shades.boxBackground};
  display: flex;
  width: 340px;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  position: relative;
`;

export const RejectButtonWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const Heading = styled.div<{ color?: string }>`
  color: ${({ theme, color }) => color || theme.palette.primary.grey};
  font-size: 24px;
  padding-bottom: 15px;
  text-align: center;
`;

export const Caption = styled.div`
  color: ${({ theme }) => theme.palette.primary.grey};
  font-size: 16px;
  padding-bottom: 15px;
  text-align: center;
`;

export const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const SmallLabel = styled.div<{ marginRight?: number }>`
  color: ${({ theme }) => theme.palette.primary.grey};
  font-size: 14px;
  text-align: center;
  margin-right: ${({ marginRight = 0 }) => `${marginRight}px`};
`;

export const Row = styled.div<{ fullSize?: boolean; toLeft?: boolean }>`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: ${({ toLeft }) => (toLeft ? 'flex-start' : 'space-between')};
  margin-bottom: 10px;
  width: ${({ fullSize }) => (fullSize ? '100%' : '')};
`;

export const SplitRow = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.grey};
  display: flex;
  justify-content: space-between;
  padding: 7px 5px 3px 5px;
  width: 100%;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.palette.primary.grey};
  text-align: center;
`;

// TODO fix circular ref
export const InfoLabel = styled(Label as any)`
  font-size: 11px;
  text-align: left;
`;

interface ValueContainerProps {
  dim?: boolean;
}

export const ValueContainer = styled.div<ValueContainerProps>`
  background-color: ${({ theme }) => theme.palette.primary.black};
  color: ${({ dim, theme }) =>
    dim ? theme.palette.primary.grey : theme.palette.primary.white};
  border-radius: 4px;
  font-size: 24px;
  text-align: center;
  height: 40px;
  padding: 5px 10px;
  margin: 0 20px 5px 20px;
  min-width: 120px;
`;

export const ButtonContainer = styled.div`
  margin-top: 15px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.palette.primary.grey
      : theme.palette.primary.green} !important;
  height: 40px;
  padding-top: 11px !important;
  width: 100%;
`;

export const OutlinedButton = styled(Button)`
  height: 40px;
  margin-top: 6px !important;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.bluePurple} !important;
`;
