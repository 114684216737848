import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import * as S from './Loading.styled';

interface Props {
  text: string;
}

const Loading: React.FC<Props> = ({ text }) => {
  return (
    <S.LoadingContainer>
      <S.ProgressContainer>
        <CircularProgress color="primary" />
      </S.ProgressContainer>
      <S.Text>{text}</S.Text>
    </S.LoadingContainer>
  );
};

export default Loading;
