import * as S from './ForwardMailModal.styled';
import Form from '../../../../../components/Form/Form';
import FormTextInput from '../../../../../components/FormTextInput/FormTextInput';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import schema from './schema';
import { AccountEmail } from '../../../../../services/repository/communicationService';
import { ForwardEmailInput } from '../../../../../graphql/types';

interface Props {
  email: AccountEmail;
  userId: string;
  onClose: () => void;
  onSend: (email: ForwardEmailInput) => Promise<void>;
}

export const ForwardMailModal: React.FC<Props> = ({
  email,
  userId,
  onSend,
  onClose,
}) => {
  const handleSendEmail = async (
    to: string,
    subject: string,
    comment: string,
  ) => {
    await onSend({
      id: `${email.id}`,
      to: [to.trim()],
      subject,
      comment: comment.length > 0 ? comment : undefined,
      userId: parseInt(userId, 10),
    });
  };

  return (
    <Modal open={true} onClose={() => onClose()}>
      <S.ForwardModal>
        <Form
          initialValues={{
            to: email.to?.join(','),
            subject: email.subject || '',
            comment: '',
          }}
          showConfirmation={false}
          validateOnMount={false}
          validationSchema={schema}
          submit={values =>
            handleSendEmail(values.to, values.subject, values.comment)
          }
        >
          {({ resetForm, handleSubmit }) => (
            <>
              <S.Headline>Forward email</S.Headline>
              <div>
                <FormTextInput name="to" editable label="To" placeholder="To" />
              </div>
              <div>
                <FormTextInput
                  name="subject"
                  editable
                  label="Subject"
                  placeholder="Subject"
                />
              </div>
              <div>
                <FormTextInput
                  name="comment"
                  editable
                  multiline
                  label="Comment"
                  placeholder="Comment (optional)"
                />
              </div>
              <S.Row>
                <S.CancelButton
                  variant="contained"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  Abort
                </S.CancelButton>
                <S.StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Send
                </S.StyledButton>
              </S.Row>
            </>
          )}
        </Form>
      </S.ForwardModal>
    </Modal>
  );
};
