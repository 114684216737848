import React from 'react';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import {
  useRescindPolicyMutation,
  RescindPolicyInput,
} from '../../../graphql/api';
import { ErrorCode, logError } from '../../../services/logging';
import { withErrorBoundary } from '../../../components/withErrorBoundary';

interface Props {
  isOpen: boolean;
  userId: number;
  onSuccess: () => void;
  onClose: () => void;
}

const RescindPolicyModal: React.FC<Props> = ({
  isOpen,
  userId,
  onSuccess,
  onClose,
}) => {
  const { isLoading, error, mutate, reset } = useRescindPolicyMutation({
    onSuccess,
    onError: (e: Error) => logError(ErrorCode.UserPolicyRescind, e, { userId }),
  });

  const rescindPolicyInput: RescindPolicyInput = {
    userId: userId.toString(),
  };

  const onConfirm = () => mutate({ input: rescindPolicyInput });

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      confirmButtonText="Rescind policy"
      title="Confirm rescind policy"
      text={`Please confirm you would like to rescind policy for user ${userId}`}
      type="deleting"
      isLoading={isLoading}
      errorMessage={error?.message}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

export default withErrorBoundary(RescindPolicyModal);
