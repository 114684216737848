import React from 'react';
import { TableRow } from '@material-ui/core';
import { StyledTableCell } from './Modal.styled';
import { formatBalance } from '../../../../../../helpers/formatHelpers';

interface Props {
  funds: number;
}

const BalanceRow: React.FC<Props> = ({ funds }) => {
  return (
    <TableRow>
      <StyledTableCell component="th">Current balance</StyledTableCell>
      <StyledTableCell align="right">{formatBalance(funds)}</StyledTableCell>
    </TableRow>
  );
};

export default BalanceRow;
