import { AxiosError } from 'axios';
import React, { useState } from 'react';
import ErrorModal from '../../../../components/ErrorModal/ErrorModal';
import {
  convertKmToMiles,
  formatBalance,
  formatKmToMiles,
} from '../../../../helpers/formatHelpers';
import { TallyUp } from '../../../../services/repository/odometerService';
import {
  ButtonContainer,
  Container,
  Heading,
  Caption,
  InfoLabel,
  Label,
  RejectButtonWrapper,
  Row,
  SmallLabel,
  SplitRow,
  StyledButton,
  SummaryContainer,
  ValueContainer,
  OutlinedButton,
} from './MilesVerified.style';
import { RejectButton } from './Components.style';
import { TallyUpStatus } from '@just-insure/api';
import { theme } from '../../../../assets/theme';
import { OdometerStatus } from '@just-insure/api';
import { Tooltip } from '@material-ui/core';
import numeral from 'numeral';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';

interface MilesVerifiedProps {
  tallyUp: TallyUp;
  onAccept: () => void;
  onReject: () => void;
  onVoid: () => void;
  currentMilesDiff?: number; // in miles
  isProcessing?: boolean;
}

const MilesVerified: React.FC<MilesVerifiedProps> = ({
  tallyUp,
  onAccept,
  onReject,
  onVoid,
  currentMilesDiff,
  isProcessing,
}) => {
  const [error, setError] = useState<AxiosError>();
  const [isBillVoidModalOpen, setModalOpen] = useState(false);
  const startOdoState = tallyUp.startOdometer.status;
  const endOdoState = tallyUp.endOdometer.status;
  const canProcessTallyUp =
    startOdoState === OdometerStatus.Accepted &&
    endOdoState === OdometerStatus.Accepted;

  const actualMiles =
    canProcessTallyUp || currentMilesDiff === undefined
      ? convertKmToMiles(tallyUp.distanceUntracked || 0)
      : currentMilesDiff - tallyUp.startOdometer.readings.accepted!;

  const missingMilesValue = canProcessTallyUp
    ? convertKmToMiles(tallyUp.distanceUntracked || 0)
    : actualMiles! - convertKmToMiles(tallyUp.distanceTracked);

  const missingMilesFormatted = numeral(missingMilesValue).format('0,0');

  const appTrackedValue = formatKmToMiles(tallyUp.distanceTracked);
  const odoReadValue = canProcessTallyUp
    ? formatKmToMiles(tallyUp.odometerDistance)
    : '-';

  const charge = missingMilesValue * tallyUp.pricePerMile;
  const formattedCharge = formatBalance(charge < 0 ? charge * -1 : charge);
  const formattedPricePerMile = formatBalance(tallyUp.pricePerMile);
  let submitText = 'Process';
  let resolutionText = 'Processed';
  if (charge > 0) {
    submitText = `Charge ${formattedCharge}`;
    resolutionText = 'Charged';
  } else if (charge < 0) {
    submitText = `Refund ${formattedCharge}`;
    resolutionText = 'Refunded';
  }

  const acceptedText = tallyUp.isInitial
    ? 'Initial photo accepted'
    : 'TallyUp accepted';

  const rejectedText = tallyUp.isInitial
    ? 'Initial photo rejected'
    : 'TallyUp rejected';

  return (
    <>
      <Container>
        {(() => {
          switch (tallyUp.status) {
            case TallyUpStatus.Rejected:
              return (
                <Heading color={theme.palette.primary.red}>
                  {rejectedText}
                </Heading>
              );
            case TallyUpStatus.Verified:
              return (
                <>
                  <Heading color={theme.palette.primary.green}>
                    {acceptedText}
                  </Heading>
                  {!tallyUp.isInitial ? (
                    <SummaryContainer>
                      <Row fullSize toLeft>
                        <SmallLabel marginRight={8}>
                          {resolutionText}:
                        </SmallLabel>
                        <SmallLabel>{formatBalance(tallyUp.charge)}</SmallLabel>
                      </Row>
                      <Row fullSize toLeft>
                        <SmallLabel marginRight={8}>Distance:</SmallLabel>
                        <SmallLabel>
                          {`${formatKmToMiles(
                            tallyUp.distanceUntracked || 0,
                          )} miles`}
                        </SmallLabel>
                      </Row>
                    </SummaryContainer>
                  ) : null}
                </>
              );
            case TallyUpStatus.Pending:
              return (
                <>
                  {canProcessTallyUp ? (
                    <RejectButtonWrapper>
                      <RejectButton onClick={onReject}>reject</RejectButton>
                    </RejectButtonWrapper>
                  ) : null}
                  <Caption>Miles Verified</Caption>
                  <Row>
                    <div>
                      <ValueContainer dim>
                        {missingMilesFormatted}
                      </ValueContainer>
                      <Label>MISSING MILES</Label>
                    </div>
                    <div>
                      <ValueContainer>{formattedCharge}</ValueContainer>
                      <Label>{`${charge < 0 ? 'REFUND' : 'CHARGE'}`}</Label>
                    </div>
                  </Row>
                  <SplitRow>
                    <InfoLabel>Miles tracked based on odometer</InfoLabel>
                    <InfoLabel>{odoReadValue}</InfoLabel>
                  </SplitRow>
                  <SplitRow>
                    <InfoLabel>Miles tracked based on app</InfoLabel>
                    <InfoLabel>{appTrackedValue}</InfoLabel>
                  </SplitRow>
                  <SplitRow>
                    <InfoLabel>Price per mile</InfoLabel>
                    <InfoLabel>{formattedPricePerMile}</InfoLabel>
                  </SplitRow>
                  <ButtonContainer>
                    <WithTooltip
                      isEnabled={!canProcessTallyUp}
                      title="Please accept Odometer Reading first"
                    >
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={onAccept}
                        disabled={isProcessing || !canProcessTallyUp}
                      >
                        {submitText}
                      </StyledButton>
                    </WithTooltip>
                    <OutlinedButton
                      variant="outlined"
                      color="default"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                      disabled={isProcessing || !canProcessTallyUp}
                    >
                      ACCEPT WITH NO CHARGE
                    </OutlinedButton>
                  </ButtonContainer>
                </>
              );
          }
        })()}
      </Container>
      <ConfirmationModal
        isOpen={isBillVoidModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        confirmButtonText="Confirm"
        text="There will be no charge applied for this tally up. The latest odometer photo will be saved, and used in the subsequent tally up."
        onConfirm={() => {
          onVoid();
          setModalOpen(false);
        }}
        type="save"
      />
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </>
  );
};

const WithTooltip: React.FC<{ title: string; isEnabled: boolean }> = ({
  title,
  isEnabled,
  children,
}) => {
  return isEnabled ? (
    <Tooltip placement="bottom" title={title}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export default MilesVerified;
