import {
  ReinstateReason,
  RestrictUserReason,
  UnrestrictUserReason,
} from '../../../../graphql/api';

type GetAccountStateSuccess = {
  success: true;
  data: {
    accountState: AccountState;
    actions: Array<any>;
    isRestricted: boolean;
  };
};

export type InfoItem = {
  title: string;
  value?: string;
};

type GetAccountStateFailure = {
  success: false;
  error: string;
};

export type GetAccountStateResponse =
  | GetAccountStateSuccess
  | GetAccountStateFailure;

export interface AccountStateAndAction {
  accountState: AccountState;
  isRestricted: boolean;
  actions: Array<{
    title: string;
    value?: string;
  }>;
}

export type UnblockUserSuccess = {
  success: true;
};

export type UnblockUserFailure = {
  success: false;
  error: string;
};

export type UnblockUserResponse = UnblockUserSuccess | UnblockUserFailure;

export type UnblockAction = (payload: {
  reason: ReinstateReason;
  description: string;
}) => Promise<UnblockUserResponse>;

type SuccessResult = {
  success: true;
};

type FailureResult = {
  success: false;
  error: string;
};

export type RestrictPolicyResponse = SuccessResult | FailureResult;
export type RestrictPolicyAction = (payload: {
  reason: RestrictUserReason;
  notes: string;
}) => Promise<RestrictPolicyResponse>;

export type EnablePolicyResponse = SuccessResult | FailureResult;
export type EnablePolicyAction = (payload: {
  reason: UnrestrictUserReason;
  notes: string;
}) => Promise<EnablePolicyResponse>;

export enum AccountState {
  Active = 'Active',
  Lapsed = 'Lapsed',
  Registration = 'Registration',
  Suspended = 'Suspended',
  NoState = '',
}
