import { fetchGraphQLQuery } from '../../../../graphql/service';
import { getAuditHistoryQuery } from './gql';
import {
  ApiAuditHistoryResponse,
  AuditHistory,
  AuditHistoryResponse,
} from './types';
import { AuditHistoryOriginatorType } from '../../../../graphql/api';
import { DateTime } from 'luxon';

const dateFormat = 'd LLL yyyy HH:mm';

const parseAuditHistoryResponse = (
  response: ApiAuditHistoryResponse,
): AuditHistory => {
  const userTimezone = response.user.profile.timezone;
  return {
    pageInfo: response.user.auditHistory.pageInfo,
    items: response.user.auditHistory.nodes.map(node => {
      const actionName =
        node.operation.name.charAt(0).toUpperCase() +
        node.operation.name.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2');
      return {
        id: node.id,
        date: DateTime.fromISO(node.createdAt, { zone: userTimezone }).toFormat(
          dateFormat,
        ),
        originator:
          node.originator.type !== AuditHistoryOriginatorType.Service
            ? node.originator.id
            : node.originator.type,
        action: actionName,
        reason: node.reason,
        notes: node.notes,
      };
    }),
  };
};

const getAuditHistory = async (
  userId: string,
  payload: { first: number; after: string },
): Promise<AuditHistoryResponse> => {
  try {
    const response = await fetchGraphQLQuery<ApiAuditHistoryResponse>(
      getAuditHistoryQuery,
      {
        userId,
        input: payload,
      },
    );

    return {
      success: true,
      data: parseAuditHistoryResponse(response),
    };
  } catch (e: any) {
    return {
      success: false,
      error: e.message,
    };
  }
};

const auditTrailApi = {
  getAuditHistory,
};

export default auditTrailApi;
