import { DateTime, Interval } from 'luxon';
import { GetUserStatsQuery } from '../../../graphql/api';
import { formatKmToMiles } from '../../../helpers/formatHelpers';

export const userStatsSelector = ({ user }: GetUserStatsQuery) => {
  const { totalInsuredDays, firstInsuredDate, totalDistance } = user.stats;
  const items = [];

  if (firstInsuredDate) {
    const now = DateTime.utc();
    const daySinceFirstPolicy = Math.round(
      Interval.fromDateTimes(DateTime.fromISO(firstInsuredDate), now)
        .toDuration()
        .as('days'),
    );
    items.push({
      title: 'First policy',
      value: `${daySinceFirstPolicy.toString()} days ago`,
    });
  }

  items.push(
    { title: 'Total insured', value: `${totalInsuredDays} days` },
    {
      title: 'Total distance',
      value: `${formatKmToMiles(totalDistance)} miles`,
    },
  );

  return items;
};
