import styled from 'styled-components';
import { Loader } from '../../../components/Loader';

export const Loading = styled(Loader)`
  padding: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .MuiGrid-spacing-xs-4 {
    width: 75%;
  }
`;
