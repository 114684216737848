import { createSelector } from 'reselect';

const isFinishedState = (state: any) =>
  state.accountManagement.accountManagement.isFinished;

export const isFinishedSelector = createSelector(
  isFinishedState,
  (isFinished: boolean) => {
    return isFinished;
  },
);

const errorState = (state: any) =>
  state.accountManagement.accountManagement.error;

export const errorSelector = createSelector(
  errorState,
  (error: string | null) => {
    return error;
  },
);
