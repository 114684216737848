import React from 'react';
import { SalesPerson } from '../../../graphql/types';
import CopyToClipboard from '../../../components/CopyToClipboard/CopyToClipboard';
import { mapSalesPersonLevelLabel } from '../helpers/mappers';

export const salesPeopleColumnDefs = [
  {
    title: 'Salesperson Id',
    id: 'id',
    sortable: false,
  },
  {
    title: 'Name',
    id: 'name',
    rowFormatter: (salesPerson: SalesPerson) =>
      `${salesPerson.firstName} ${salesPerson.lastName}`,
    sortable: false,
  },
  {
    title: 'Level',
    id: 'level',
    rowFormatter: (salesPerson: SalesPerson) =>
      `${mapSalesPersonLevelLabel(salesPerson.level)}`,
    sortable: false,
  },
  {
    title: 'Phone Number',
    id: 'phoneNumber',
    sortable: false,
  },
  {
    title: 'Email Address',
    id: 'email',
    sortable: false,
  },
  {
    title: 'Organisation Name',
    id: 'organisation',
    rowFormatter: (salesPerson: SalesPerson) =>
      `${salesPerson.organisation.name}`,
    sortable: false,
  },
  {
    title: 'Personal Code',
    id: 'personalCode',
    rowFormatter: (salesPerson: SalesPerson) => {
      return salesPerson.personalCode ? (
        <>
          {salesPerson.personalCode}{' '}
          <CopyToClipboard text={salesPerson.personalCode} />
        </>
      ) : (
        ''
      );
    },
    sortable: false,
  },
];
