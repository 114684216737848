import styled from 'styled-components';

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ProgressContainer = styled.div`
  padding-bottom: 15px;
`;

export const Text = styled.div`
  font-size: 14px;
  text-align: center;
`;
