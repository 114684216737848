import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { AxiosError } from 'axios';
import { ClientError as GraphQLRequestError } from 'graphql-request';
import React from 'react';
import * as S from './ErrorModal.styled';

interface ErrorModalProps {
  error?: GraphQLRequestError | AxiosError | Error;
  onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ error, onClose }) => {
  function getErrorDetails() {
    let code = 'N/A';
    let message: string;
    if ((error as AxiosError)?.isAxiosError) {
      const err = (error as AxiosError)?.response?.data;
      code = err?.errorCode ?? 'N/A';
      message = err?.msg ?? 'N/A';
    } else if (error instanceof GraphQLRequestError) {
      message = error.response.errors?.[0].message ?? 'GraphQL Error';
      code = error.response.errorCode ?? 'N/A';
    } else {
      message = error?.message ?? 'N/A';
    }
    return { code, message };
  }

  const { code, message } = getErrorDetails();
  return (
    <Modal open={error !== undefined} onClose={onClose}>
      <S.ModalContainer data-testid="error-modal">
        <S.Headline>An error has occurred</S.Headline>
        <S.ErrorContainer>
          <S.ErrorInfoText>{`Error Code: ${code}`}</S.ErrorInfoText>
          <S.ErrorInfoText>{`Error Message: ${message}`}</S.ErrorInfoText>
        </S.ErrorContainer>
        <S.ErrorText>
          Unfortunately an error has occurred, please try again and if the error
          persists then please raise a ticket including a screenshot of this
          error.
        </S.ErrorText>
        <S.Row>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </S.Row>
      </S.ModalContainer>
    </Modal>
  );
};

export default ErrorModal;
