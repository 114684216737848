import CircularProgress from '@material-ui/core/CircularProgress';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import * as odometerService from '../../services/repository/odometerService';
import ErrorModal from '../ErrorModal/ErrorModal';
import {
  LoadingContainer,
  ModalContent,
  StyledCard,
  StyledCardMedia,
  StyledModal,
} from './OdometerImageViewer.styled';

interface Props {
  readingId: number;
  userId: number;
  visible: boolean;
  onClose: () => void;
}

const OdometerImageViewer: React.FC<Props> = ({
  userId,
  readingId,
  visible,
  onClose,
}) => {
  const [error, setError] = useState<AxiosError>();
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const viewOdometerReading = async () => {
      try {
        const resourceUrl: string = await odometerService.getOdometerImageById(
          readingId,
        );
        setUrl(resourceUrl);
      } catch (ex: any) {
        setError(ex);
        onClose();
      }
    };

    viewOdometerReading();
  }, [userId, readingId, onClose]);

  return (
    <>
      <StyledModal open={visible} onClose={onClose}>
        <ModalContent>
          {!url ? (
            <LoadingContainer>
              <CircularProgress color="primary" />
            </LoadingContainer>
          ) : (
            <StyledCard>
              <StyledCardMedia component="img" src={url} />
            </StyledCard>
          )}
        </ModalContent>
      </StyledModal>
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </>
  );
};

export default OdometerImageViewer;
