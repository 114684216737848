import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubTitle = styled.p`
  margin: 12px 0;
  font-size: 14px;
  text-transform: uppercase;
`;

export const Divider = styled.div<{
  spaceTop?: number;
  spaceBottom?: number;
}>`
  border-top-width: 1px;
  border-top-color: ${({ theme: t }) => t.palette.primary.paleGrey};
  border-top-style: solid;
  margin-bottom: ${({ spaceBottom = 0 }) => `${spaceBottom}px`};
  margin-top: ${({ spaceTop = 0 }) => `${spaceTop}px`};
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const TextRow = styled.p`
  font-size: 16px;
  margin: 0;
`;

export const MapLink = styled.a`
  font-size: 16px;
  color: ${({ theme: t }) => t.palette.primary.bluePurple};
  text-decoration: underline;
  cursor: pointer;
`;

export const Progress = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const MoreDetailsButton = styled.a`
  font-size: 14px;
  color: ${({ theme: t }) => t.palette.primary.bluePurple};
  cursor: pointer;
`;
