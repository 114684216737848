import { PolicyholderLocationEvent } from './types';
import { DateTime } from 'luxon';
import { PolicyDateFormat } from '../../../../services/repository/policyService';

export type InfoEntry = { title: string; value: string | number };

// creates a list of object containing title/value to display in column
function getInfoListForEvent(
  event: PolicyholderLocationEvent,
  timezone: string,
): InfoEntry[] {
  let infoList: InfoEntry[];
  if (!event.location) {
    infoList = [
      {
        title: 'Location permissions',
        value: event.permissionGranted ? 'Granted' : 'Not granted',
      },
      {
        title: 'Location service',
        value: event.locationEnabled ? 'Enabled' : 'Disabled',
      },
    ];
  } else {
    infoList = [
      {
        title: 'Longitude',
        value: event.location.longitude,
      },
      {
        title: 'Latitude',
        value: event.location.latitude,
      },
      {
        title: 'Accuracy',
        value: `${event.location.accuracy.toFixed(2)}m`,
      },
      {
        title: 'Speed',
        value: `${(event.location.speed || 0).toFixed(2)}m/h`,
      },
    ];
  }

  return [
    {
      title: 'Time',
      value: DateTime.fromMillis(
        event.location?.timestamp ?? event.eventTimestamp,
      )
        .setZone(timezone)
        .toFormat(PolicyDateFormat),
    },
    ...infoList,
  ];
}

type EventsHierarchy = {
  main?: PolicyholderLocationEvent;
  rest: PolicyholderLocationEvent[];
};

/**
 * Sorts events in ascending order and selects the earliest one as "main" event
 * and places other events in "rest" list
 * @param events
 */
function sortEventsIntoMainAndRest(events: PolicyholderLocationEvent[]) {
  return [...events]
    .sort((e1, e2) => (e1.eventTimestamp < e2.eventTimestamp ? -1 : 1))
    .reduce(
      (hierarchy, event) => {
        if (!hierarchy.main && !!event.location) {
          return {
            main: event,
            rest: hierarchy.rest,
          };
        }
        return {
          ...hierarchy,
          rest: [...hierarchy.rest, event],
        };
      },
      {
        main: undefined,
        rest: [],
      } as EventsHierarchy,
    );
}

const utils = {
  getInfoListForEvent,
  sortEventsIntoMainAndRest,
};

export default utils;
