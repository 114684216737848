import React from 'react';
import { FeatureFlags } from './types';
import useFeatureFlagsApi from './useFeatureFlagsApi';
import { Feature, isFeatureEnabled } from '../../../../config';

interface FeatureFlagContextType {
  isEnabled: boolean;
  isLoading: boolean;
  isError: boolean;
  featureFlags: FeatureFlags | null;
  getFeatureFlags: () => Promise<void>;
}

type State = {
  featureFlags: FeatureFlags | null;
  isLoading: boolean;
  isError: boolean;
};

const FeatureFlagContext = React.createContext<FeatureFlagContextType | null>(
  null,
);
export const FeatureFlagsController: React.FC<{ userId: string }> = ({
  children,
  userId,
}) => {
  const { current: isEnabled } = React.useRef(
    isFeatureEnabled(Feature.FeatureFlags),
  );

  const [featureFlagsState, updateFeatureFlagsState] = React.useReducer(
    (prev: State, next: Partial<State>) => {
      return { ...prev, ...next };
    },
    {
      featureFlags: null,
      isLoading: isEnabled,
      isError: false,
    },
  );

  const featureFlagsApi = useFeatureFlagsApi();

  const getFeatureFlags = async () => {
    updateFeatureFlagsState({ isLoading: true });
    const response = await featureFlagsApi.getFeatureFlagsData(userId);
    if (response.success) {
      updateFeatureFlagsState({
        featureFlags: response.result,
        isLoading: false,
        isError: false,
      });
    } else {
      updateFeatureFlagsState({ isLoading: false, isError: true });
    }
  };

  React.useEffect(() => {
    if (!isEnabled) {
      return;
    }

    getFeatureFlags();
  }, []);

  return (
    <FeatureFlagContext.Provider
      value={{
        isEnabled,
        isLoading: featureFlagsState.isLoading,
        isError: featureFlagsState.isError,
        featureFlags: isEnabled ? featureFlagsState.featureFlags : null,
        getFeatureFlags,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureFlagContext);
  if (context === null) {
    throw new Error(
      '[FeatureFlags]: useFeatureFlags must be used within a FeatureFlagsProvider',
    );
  }
  return context;
};
