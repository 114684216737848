import React, { useState } from 'react';
import { CopyButton } from './CopyToClipboard.styled';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  text: string;
}

const CopyToClipboard = ({ text }: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const debouncedCloseTooltip = useDebouncedCallback(
    () => setTooltipOpen(false),
    1000,
  );

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    setTooltipOpen(true);
    debouncedCloseTooltip();
  };

  if (!text) {
    return null;
  }

  return (
    <Tooltip
      title="Copied!"
      placement="top"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={tooltipOpen}
    >
      <CopyButton onClick={onClick}>
        <FileCopyOutlinedIcon color="primary" />
      </CopyButton>
    </Tooltip>
  );
};

export default CopyToClipboard;
