import Card from '@material-ui/core/Card';
import CardMedia, { CardMediaProps } from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';

export const ModalContent = styled.div`
  outline: none;
`;

export const LoadingContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.white};
  border-radius: 10px;
  padding: 30px;
`;

export const StyledModal = styled(Modal)`
  && {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export const StyledCard = styled(Card)`
  && {
    height: 'auto';
    width: 'auto';
  }
`;

type Props = CardMediaProps & {
  component: 'img';
};

export const StyledCardMedia = styled(CardMedia)<Props>`
  && {
    height: 'auto';
    width: 'auto';
  }
`;
