import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export interface StyleProps {
  rowContainer: string;
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CancelButton = styled(Button)`
  && {
    min-width: 60px;
    margin-right: 10px;
  }
`;

export const SaveButton = styled(Button)`
  && {
    min-width: 80px;
  }
`;

export const PrimaryVehicleButton = styled(Button)`
  && {
    min-width: 60px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Loading = styled(CircularProgress).attrs({
  size: 20,
})`
  && {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const styles = () => ({
  rowContainer: {
    backgroundColor: 'rgba(36,170,238,0.3)',
  },
});

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  button:nth-child(n + 2) {
    margin-top: 4px;
  }
`;

export const IconContainer = styled.div`
  margin-left: 2em;
  & svg {
    cursor: pointer;
  }
  & svg:active {
    opacity: 0.7;
  }
`;

export default styles;
