import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Column } from '../../../../components/Table/Table';
import { Claim } from '../../../../graphql/types';
import {
  MenuItem,
  PopUpMenu,
} from '../../../../components/PopUpMenu/PopUpMenu';
import { Feature, isFeatureEnabled } from '../../../../config';
import { PopUpMenuContainer } from '../common/RowTemplate.styled';
import { menuOptions } from '../common/helpers';

interface Props {
  columns: Column[];
  item: Claim;
  onMenuClick: (menuItem: MenuItem, claim: Claim) => void;
}

const RowTemplate: React.FC<Props> = ({ item, onMenuClick }) => {
  const amountOfLossLabel = (amountOfLoss: number): string | number => {
    switch (amountOfLoss) {
      case 0:
        return '$0';
      case 500:
        return '$500 and below';
      case 501:
        return 'Above $500';
      case 1001:
        return "I don't know";
      default:
        return amountOfLoss;
    }
  };

  return (
    <TableRow>
      <TableCell>{item.yearOccurred}</TableCell>
      <TableCell>{amountOfLossLabel(item.amountOfLoss)}</TableCell>
      <TableCell>
        {isFeatureEnabled(Feature.EditACV) ? (
          <PopUpMenuContainer>
            <PopUpMenu
              menuItems={menuOptions}
              onClick={menuItem => onMenuClick(menuItem, item)}
            />
          </PopUpMenuContainer>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default RowTemplate;
