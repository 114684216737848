export const vehicleListColumnDefs = [
  {
    title: 'VIN',
    id: 'vin',
    sortable: false,
  },
  {
    title: 'Make',
    id: 'make',
    sortable: false,
  },
  {
    title: 'Model',
    id: 'model',
    sortable: false,
  },
  {
    title: 'Year',
    id: 'year',
    sortable: false,
  },
  {
    title: 'Plate',
    id: 'plate',
    sortable: false,
  },
];
