import obdApi from './api';
import {
  DeactivateDeviceResponse,
  DeviceStatus,
  GetDeviceDetailsResponse,
  GetVinNumberResponse,
  ObdErrorType,
} from './types';

const useObdApi = () => {
  const getVinNumber = async (
    userId: string,
  ): Promise<GetVinNumberResponse> => {
    try {
      const vin = await obdApi.getVinNumber(userId);

      if (!vin) {
        return {
          success: false,
          error: {
            type: ObdErrorType.NO_FIRST_POlICY,
            message: 'VIN not found',
            isPermanent: true,
          },
        };
      }

      return {
        success: true,
        result: {
          vin,
        },
      };
    } catch (e: any) {
      return {
        success: false,
        error: {
          type: ObdErrorType.DEFAULT_ERROR,
          message: e.response.message,
          isPermanent: false,
        },
      };
    }
  };

  const getDeviceDetails = async (
    vin: string,
  ): Promise<GetDeviceDetailsResponse> => {
    try {
      const deviceDetails = await obdApi.getDeviceDetails(vin);

      if (deviceDetails.status === DeviceStatus.DEACTIVATED) {
        return {
          success: true,
          result: {
            ...deviceDetails,
            isConnected: false,
          },
        };
      }

      const { isConnected } = await obdApi.getDeviceStatus(
        deviceDetails.serialNumber,
      );

      return { success: true, result: { ...deviceDetails, isConnected } };
    } catch (e: any) {
      if (e.response.status === 404) {
        return {
          success: true,
          result: null,
        };
      } else {
        return {
          success: false,
          error: {
            type: ObdErrorType.DEFAULT_ERROR,
            message: e.response.message,
            isPermanent: false,
          },
        };
      }
    }
  };

  const deactivateDeviceForVin = async (
    vin: string,
  ): Promise<DeactivateDeviceResponse> => {
    try {
      const response = await obdApi.deactivateDeviceForVin(vin);

      return { success: true, result: response };
    } catch (e: any) {
      return {
        success: false,
        error: {
          type: ObdErrorType.DEFAULT_ERROR,
          message: e.response.message,
          isPermanent: false,
        },
      };
    }
  };

  return {
    getVinNumber,
    getDeviceDetails,
    deactivateDeviceForVin,
  };
};

export default useObdApi;
