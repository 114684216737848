import { Profile } from '@just-insure/api';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import { AxiosError } from 'axios';
import React, { memo, useState } from 'react';
import { User } from '../../../../types/interfaces';
import ErrorModal from '../../../components/ErrorModal/ErrorModal';
import Form from '../../../components/Form/Form';
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker';
import FormSelect from '../../../components/FormSelect/FormSelect';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import {
  AutoPaySettings,
  saveUserDetails,
  saveUserEmailV1,
  saveUserEmailV2,
} from '../../../services/repository/userService';
import EditEmailModal from './EditEmailModal';
import schema from './schema';
import * as S from './UserHeader.styled';
import AutoPayInfoTile from '../AutoPay/AutoPayInfoTile';
import { ErrorCode, logError } from '../../../services/logging';
import { UserBetaFlags, UserStats } from '../../userTiles';
import UserActions from '../UserActions';
import { DateTime } from 'luxon';
import { AccountState } from '../../userTiles/AccountState';
import { DuplicateTile } from '../DuplicateAccount';
import { UpdateUserEmailReason } from '../../../graphql/api';
import PolicyPurchaseInfoTile from '../accountState/components/PolicyPurchaseInfoTile';
import BalanceInfoTile from '../../userTiles/BalanceInfoTile';

export interface UserHeaderProps {
  autoPay: AutoPaySettings;
  balance?: number;
  funds?: number;
  editing: boolean;
  onEditChange: (canEdit: boolean) => void;
  userId: string;
  user: User;
  onUpdateUser: (user: User) => void;
  onUpdateUserProfile: (user: Profile) => void;
  onRefreshUserDetails: () => void;
}

const UserHeaderForm: React.FC<UserHeaderProps> = props => {
  const [error, setError] = useState<AxiosError>();
  const { editing, onEditChange, onUpdateUserProfile, user, userId } = props;
  const [editEmailOpen, setEditEmailOpen] = useState(false);

  const { balance, funds, autoPay } = props;

  const submitUser = async (values: Profile) => {
    try {
      const userPayload = { ...values };

      userPayload.dob = DateTime.fromFormat(
        userPayload.dob,
        'MM/dd/yyyy',
      ).toFormat('yyyy-MM-dd');

      const updatedUser = await saveUserDetails(userId, userPayload);
      onEditChange(false);
      onUpdateUserProfile(updatedUser);
    } catch (ex: any) {
      logError(ErrorCode.UserDetails, ex);
      setError(ex);
    }
  };

  const submitEmailV1 = async (email: string) => {
    try {
      const { onUpdateUser } = props;
      await saveUserEmailV1(userId, email);
      setEditEmailOpen(false);
      onUpdateUser({ ...user, email });
    } catch (ex: any) {
      logError(ErrorCode.UserDetails, ex);
      setError(ex);
    }
  };

  const submitEmailV2 = async (payload: {
    email: string;
    reason: UpdateUserEmailReason;
    notes: string;
  }) => {
    try {
      const { onUpdateUser } = props;
      await saveUserEmailV2({ ...payload, userId });
      setEditEmailOpen(false);
      onUpdateUser({ ...user, email: payload.email });
    } catch (ex: any) {
      logError(ErrorCode.UserDetails, ex);
      setError(ex);
    }
  };

  const initialValues: Profile = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    gender: user.gender || '',
    dob: user.dob
      ? DateTime.fromISO(user.dob, { zone: 'utc' }).toFormat('MM/dd/yyyy')
      : '',
    address1: user.address1 || '',
    address2: user.address2 || '',
    city: user.city || '',
    state: user.state || 'AZ',
    zipCode: user.zipCode || '',
    phoneNumber: user.phoneNumber || '',
  };

  return (
    <S.Container>
      <Form
        initialValues={initialValues}
        validationSchema={schema}
        submit={values => submitUser(values)}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <S.ActionTilesWrapper>
              <Grid container>
                <Grid container item spacing={2} sm={9}>
                  <Grid item>
                    <UserStats userId={props.userId} />
                  </Grid>
                  <Grid item>
                    <AccountState />
                  </Grid>
                  <Grid item>
                    <UserBetaFlags userId={props.userId} />
                  </Grid>
                  <Grid item>
                    <BalanceInfoTile
                      balance={balance}
                      funds={funds}
                      deposit={autoPay.threshold}
                      isLoading={balance === undefined || funds === undefined}
                    />
                  </Grid>
                  <Grid item>
                    <AutoPayInfoTile
                      onRefreshUserDetails={props.onRefreshUserDetails}
                      userId={userId}
                      autoPay={autoPay}
                    />
                  </Grid>
                  <Grid item>
                    <PolicyPurchaseInfoTile />
                  </Grid>
                  <Grid item>
                    <DuplicateTile />
                  </Grid>
                </Grid>
                <Grid item sm={3}>
                  <UserActions
                    userId={userId}
                    onEditUser={onEditChange}
                    onCancelEditUser={() => {
                      resetForm();
                      onEditChange(false);
                    }}
                    isEditingUser={editing}
                  />
                </Grid>
              </Grid>
            </S.ActionTilesWrapper>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={2} sm={2}>
                  <FormTextInput
                    label="First Name"
                    name="firstName"
                    editable={editing}
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormTextInput
                    label="Last Name"
                    name="lastName"
                    editable={editing}
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormTextInput
                    label="Phone Number"
                    name="phoneNumber"
                    editable={editing}
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <S.Row>
                    <TextField
                      label="Email Address"
                      fullWidth
                      value={user.email}
                      margin="normal"
                      InputProps={{
                        disabled: true,
                        readOnly: true,
                      }}
                    />
                    <S.EditButton
                      variant="text"
                      onClick={() => setEditEmailOpen(true)}
                      startIcon={<EditIcon />}
                    />
                  </S.Row>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormSelect
                    label="Gender"
                    name="gender"
                    editable={editing}
                    options={[
                      {
                        label: 'Female',
                        value: 'female',
                      },
                      {
                        label: 'Male',
                        value: 'male',
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormDatePicker
                    label="Date of Birth"
                    name="dob"
                    editable={editing}
                    disableFuture={true}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormTextInput
                    label="Address Line #1"
                    name="address1"
                    editable={editing}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormTextInput
                    label="Address Line #2"
                    name="address2"
                    editable={editing}
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormTextInput label="City" name="city" editable={editing} />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormTextInput
                    label="State"
                    name="state"
                    InputProps={{
                      disabled: true,
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormTextInput
                    label="Zipcode"
                    name="zipCode"
                    editable={editing}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <TextField
                    label="Driver's License Number"
                    fullWidth
                    disabled
                    value={user.driverLicenseNumber}
                  />
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Form>
      <EditEmailModal
        email={user.email}
        isOpen={editEmailOpen}
        onSaveV1={submitEmailV1}
        onSave={submitEmailV2}
        onClose={() => setEditEmailOpen(false)}
        isDuplicateUserFeatureEnabled={true}
      />
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </S.Container>
  );
};

export default memo(UserHeaderForm);
