import styled from 'styled-components';

export const TableContainer = styled.div`
  && {
    .clickable {
      cursor: pointer;
      font-weight: 700;
      text-decoration-line: underline;
    }

    table {
      tr {
        .MuiTableCell-root {
          padding: 16px 8px;
        }
        td:nth-child(1) {
          width: 100px;
        }
        td:nth-child(2) {
          width: 100px;
        }
        td:nth-child(3) {
          width: 100px;
        }
        td:nth-child(4) {
          width: 100px;
        }
        td:nth-child(7) {
          width: 130px;
        }
        td:nth-child(8) {
          width: 130px;
        }
      }
    }
  }
`;
