import styled from 'styled-components';
import { Body } from '../../../../../../components/Typography';

export const ModalContent = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 12px;
  top: 10%;
  bottom: 10%;
  left: calc(50% - 200px);
  outline: none;
  padding: 16px 32px;
  width: 600px;
  flex-direction: column;
  display: flex;
`;

export const Description = styled(Body)`
  text-align: center;
  line-height: 2;
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.bluePurple};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
