import styled from 'styled-components';

interface OdometerReadingProps {
  size?: 'small' | 'large';
}

export const OdometerReading = styled.div<OdometerReadingProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.black};
  border: 1px solid ${({ theme }) => theme.palette.primary.grey};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.primary.white};
  letter-spacing: 3px;
  display: flex;
  font-size: ${({ size }) => (size === 'large' ? 18 : 12)}px;
  justify-content: center;
  height: ${({ size }) => (size === 'large' ? 42 : 26)}px;
  text-align: center;
  padding: ${({ size }) => (size === 'large' ? '0px 5px' : '1px 5px 0 5px')};
  width: ${({ size }) => (size === 'large' ? 120 : 80)}px;
`;

export const OdometerPendingReading = styled(OdometerReading as any)`
  background-color: ${({ theme }) => theme.palette.primary.lighterGrey};
  color: ${({ theme }) => theme.palette.primary.black};
`;

export const OdometerVoided = styled(OdometerReading as any)`
  background-color: ${({ theme }) => theme.palette.primary.warning};
  color: ${({ theme }) => theme.palette.primary.white};
`;

// TODO fix circular ref
export const OdometerEmptyReading = styled(OdometerReading as any)`
  background-color: ${({ theme }) => theme.palette.primary.white};
  border: 1px solid ${({ theme }) => theme.palette.primary.grey};
  color: ${({ theme }) => theme.palette.primary.veryDarkGrey};
  cursor: pointer;
`;

// TODO fix circular ref
export const OdometerRejectedReading = styled(OdometerReading as any)`
  background-color: ${({ theme }) => theme.palette.primary.white};
  border: 1px solid ${({ theme }) => theme.palette.primary.grey};
  color: ${({ theme }) => theme.palette.primary.veryDarkGrey};
  letter-spacing: 0px;
`;

// TODO fix circular ref
export const OdometerRejectedRedReading = styled(OdometerReading as any)`
  background-color: ${({ theme }) => theme.palette.primary.white};
  border: 1px solid ${({ theme }) => theme.palette.primary.red};
  color: ${({ theme }) => theme.palette.primary.red};
  letter-spacing: 0px;
`;
