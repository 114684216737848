import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import React from 'react';

const TextInput: React.FC<StandardTextFieldProps> = props => {
  return (
    <TextField {...props} fullWidth margin="normal" value={props.value || ''} />
  );
};

export default TextInput;
