import React from 'react';
import { PolicyholderLocationEvent } from '../types';
import utils from '../utils';
import * as S from './Common.style';

const EventDetail: React.FC<{
  event: PolicyholderLocationEvent;
  timezone: string;
}> = ({ event, timezone }) => {
  const eventInfo = utils.getInfoListForEvent(event, timezone);
  return (
    <S.Container>
      <div>
        {eventInfo.map(info => (
          <S.TextRow key={info.title}>
            <S.Bold>{info.title}</S.Bold>: {info.value}
          </S.TextRow>
        ))}
      </div>
      {event.location ? (
        <div>
          <S.MapLink
            target="_blank"
            href={`https://maps.google.com/maps?q=${event.location.latitude},${event.location.longitude}`}
          >
            MAP
          </S.MapLink>
        </div>
      ) : null}
    </S.Container>
  );
};

export default EventDetail;
