import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

export const Text = styled.div`
  font-size: 14px;
  text-align: center;
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.palette.primary.red};
`;
