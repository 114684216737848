import Tab from '@material-ui/core/Tab';
import React from 'react';
import { Feature, isFeatureEnabled } from '../../../config';

const tabs: TabItem[] = [
  {
    id: 'policies',
    content: <Tab key="policies" label="Policy List" />,
  },
  {
    id: 'trips',
    content: <Tab key="trips" label="Trips" />,
  },
  {
    id: 'tripsV2',
    content: <Tab key="tripsV2" label="Trips V2" />,
  },
  {
    id: 'transactions',
    content: <Tab key="transactions" label="Transactions" />,
  },
  {
    id: 'tallyUps',
    content: <Tab key="tallyUps" label="Tally Ups" />,
  },
  {
    id: 'odometer',
    content: <Tab key="odometer" label="Odometer Readings" />,
  },
  {
    id: 'vehicle',
    content: <Tab key="vehicle" label="Vehicles" />,
  },
  {
    id: 'quotes',
    content: <Tab key="quotes" label="Quotes" />,
  },
  {
    id: 'acv',
    content: <Tab key="acv" label="Acv" />,
  },
  {
    id: 'paymentMethods',
    content: <Tab key="paymentMethods" label="Payment Methods" />,
  },
  {
    id: 'communications',
    content: <Tab key="communications" label="Comms History" />,
  },
];

interface TabItem {
  id:
    | 'trips'
    | 'tripsV2'
    | 'transactions'
    | 'policies'
    | 'tallyUps'
    | 'odometer'
    | 'vehicle'
    | 'quotes'
    | 'acv'
    | 'paymentMethods'
    | 'communications';
  content: React.ReactElement;
}

interface TabList {
  defaultIndex: number;
  tabs: TabItem[];
}

export const getTabs = (): TabList => {
  const tripsV2Enabled = isFeatureEnabled(Feature.TripsV2);
  if (!tripsV2Enabled) {
    const filteredTabs = tabs.filter(t => t.id !== 'tripsV2');
    return {
      defaultIndex: filteredTabs.findIndex(({ id }) => id === 'policies'),
      tabs: filteredTabs,
    };
  }

  return {
    defaultIndex: tabs.findIndex(({ id }) => id === 'policies'),
    tabs,
  };
};
