import React from 'react';
import UnblockAccountModal from './UnblockAccountModal';
import * as S from './UnblockAccountButton.styled';
import { ReinstateReason } from '../../../../../graphql/api';
import { useAccountState } from '../../../accountState';
import { AccountState } from '../../../accountState/Controller/types';

const UnblockAccountButton: React.FC = () => {
  const [isModalVisible, setModalVisibility] = React.useState(false);

  const { data, unblockUser } = useAccountState();
  const isAccountSuspended = data.accountState === AccountState.Suspended;

  const showModal = () => {
    setModalVisibility(true);
  };

  const hideModal = () => {
    setModalVisibility(false);
  };

  const handleSubmit = async (payload: {
    reason: ReinstateReason;
    description: string;
  }) => {
    const result = await unblockUser(payload);
    if (result.success) {
      hideModal();
    } else {
      throw new Error(result.error);
    }
  };

  return (
    <>
      <S.ActionButton
        onClick={showModal}
        color="primary"
        variant="contained"
        disabled={!isAccountSuspended}
      >
        UNBLOCK
      </S.ActionButton>
      <UnblockAccountModal
        isOpen={isModalVisible}
        hideModal={hideModal}
        onSubmit={handleSubmit}
        onClose={hideModal}
      />
    </>
  );
};

export default UnblockAccountButton;
