import { useMutation } from 'react-query';
import {
  createTransaction,
  RawUserTransaction,
} from '../../../../../services/repository/transactionService';
import {
  TransactionRequest,
  TransactionType,
  ActivityType,
} from '@just-insure/api';
import { TransactionInput } from '../types';
import { logError, ErrorCode } from '../../../../../services/logging';
import { AxiosError } from 'axios';

export const useCreateFiftyMileTransaction = (
  userId: string,
  onSuccess: () => void,
) => {
  return useMutation<
    RawUserTransaction[],
    Error | AxiosError,
    TransactionInput
  >(
    ({ reason, amount }: TransactionInput) => {
      const transaction: TransactionRequest = {
        reason,
        type: TransactionType.debit,
        activityType: ActivityType.owed,
        amount,
      };
      return createTransaction(userId, transaction);
    },
    {
      onSuccess,
      onError: e => logError(ErrorCode.FiftyMileCharge, e),
      useErrorBoundary: false,
    },
  );
};
