import { useState, useEffect } from 'react';
import * as odometerService from '../../../../services/repository/odometerService';
import {
  sortOdometerToByNewestToOldest,
  TallyUpOdometer,
} from '../../../../services/repository/odometerService';

const useGetUserOdometers = (userId: string) => {
  const [data, setData] = useState<TallyUpOdometer[]>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);
  const isError = !!error;

  const updateUserOdometers = async () => {
    setData(undefined);
    setError(undefined);
    setIsLoading(true);

    try {
      const odometers = await odometerService.getUserOdometer(userId);
      setData([...odometers].sort(sortOdometerToByNewestToOldest));
    } catch (ex: any) {
      setError(ex);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateUserOdometers();
  }, [userId]);

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

export default useGetUserOdometers;
