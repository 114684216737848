import { useField } from 'formik';
import { Button, FormHelperText, FormControl } from '@material-ui/core';
import { FileContainer, FileName } from './FormFileInput.styled';

// add file extensions or MIME types as needed
const fileTypes = {
  all: '*',
  image: 'image/*',
};

type Props = {
  name: string;
  label: string;
  fileType?: keyof typeof fileTypes;
};

const FormFileInput: React.FC<Props> = ({ name, label, fileType = 'all' }) => {
  const [field, meta, helpers] = useField<File | undefined>(name);

  return (
    <FormControl margin="normal">
      <FileContainer>
        <Button variant="contained" component="label">
          {label}
          <input
            type="file"
            accept={fileTypes[fileType]}
            hidden
            onChange={event => helpers.setValue(event.currentTarget.files?.[0])}
          />
        </Button>
        <FileName>{field.value?.name}</FileName>
      </FileContainer>
      {meta.error ? (
        <FormHelperText error variant="filled">
          {meta.error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default FormFileInput;
