import React from 'react';
import { useDuplicateAccount } from '../../Controller';
import * as S from './DuplciateTile.styled';
import { ControllerStatus } from '../../Controller/types';
import Content from './Content';
import DuplicateModal from './DuplicateModal';

const DuplicateTile: React.FC = () => {
  const { state, getDuplicateStatus } = useDuplicateAccount();
  const duplicateAccounts = state.data?.associatedUsers || [];
  const isError = state.status === ControllerStatus.Error;
  const [isModalVisible, setModalVisibility] = React.useState(false);

  const handleViewMore = () => {
    setModalVisibility(true);
  };

  const hideModal = () => {
    setModalVisibility(false);
  };

  const isTileVisible = duplicateAccounts.length > 0 || isError;

  if (!isTileVisible) {
    return null;
  }

  return (
    <>
      <S.CardContainer>
        <S.HeaderTitle>Duplicate Account</S.HeaderTitle>
        <Content
          isLoading={state.status === ControllerStatus.Loading}
          onRetry={getDuplicateStatus}
          errorMessage={isError ? state.error : null}
          duplicateAccounts={duplicateAccounts}
          onViewMore={handleViewMore}
        />
      </S.CardContainer>
      <DuplicateModal
        isOpen={isModalVisible}
        hideModal={hideModal}
        duplicateAccounts={duplicateAccounts}
      />
    </>
  );
};

export default DuplicateTile;
