import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Column } from '../../../components/Table/Table';
import { TallyUpOdometer } from '../../../services/repository/odometerService';
import TallyUpOdometerComponent from '../../odometer/TallyUpOdometer/TallyUpOdometer';

interface Props {
  columns: Column[];
  item: TallyUpOdometer;
  rowIndex: number;
  onImageClick: (reading: TallyUpOdometer) => void;
  serviceVersion: string;
}

const OdometerRowTemplate: React.FC<Props> = ({
  item,
  serviceVersion,
  onImageClick,
}) => {
  return (
    <TableRow>
      <TableCell>{item.createdAt.toFormat('FF')}</TableCell>
      <TableCell>
        <TallyUpOdometerComponent odometer={item} onImageClick={onImageClick} />
      </TableCell>
      <TableCell>{serviceVersion}</TableCell>
    </TableRow>
  );
};

export default OdometerRowTemplate;
