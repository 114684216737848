import React from 'react';
import FlagIcon from '@material-ui/icons/OutlinedFlag';
import { Modal } from '@material-ui/core';
import { ModalContent, ActionButton } from './UserFeatureFlags.style';
import FeatureFlagList from './FeatureFlagList';
import { useFeatureFlags } from '../Controller';

const UserFeatureFlags = () => {
  const { isEnabled, featureFlags, getFeatureFlags } = useFeatureFlags();
  const [isModalVisible, setModalVisible] = React.useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <ActionButton
        variant="contained"
        onClick={showModal}
        startIcon={<FlagIcon />}
      >
        Feature Flags
      </ActionButton>
      <Modal open={isModalVisible} onClose={hideModal}>
        <ModalContent>
          <FeatureFlagList
            featureFlags={featureFlags}
            onRetry={getFeatureFlags}
          />
          <ActionButton
            style={{ marginTop: '1rem' }}
            variant="contained"
            color="primary"
            onClick={hideModal}
          >
            Close
          </ActionButton>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserFeatureFlags;
