import React from 'react';
import { CircularProgress, ButtonProps } from '@material-ui/core';
import { StyledButton } from './LoadingButton.styled';

export type ButtonType = 'save' | 'confirm' | 'delete' | 'cancel';

interface Props extends ButtonProps {
  buttonType?: ButtonType;
  isLoading: boolean;
  onClick: () => void;
}

const LoadingButton: React.FC<Props> = ({
  buttonType = 'save',
  isLoading,
  onClick,
  children,
  disabled,
  ...rest
}) => {
  return (
    <StyledButton
      {...rest}
      variant="contained"
      color="primary"
      buttonType={buttonType}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <CircularProgress size={20} data-testid="circular-progress" />
      ) : (
        <>{children}</>
      )}
    </StyledButton>
  );
};

export default LoadingButton;
