import styled from 'styled-components';
import { DialogTitle, DialogActions } from '@material-ui/core';

export const StyledDialogTitle = styled(DialogTitle)`
  && {
    padding: 20px 24px 0;
    text-align: center;

    h2 {
      font-size: ${({ theme }) => theme.font.size.l};
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 8px 24px 20px;
    flex-direction: column;
  }

  button {
    width: 100%;
  }

  button:not(:first-child) {
    margin-top: 6px;
  }
`;
