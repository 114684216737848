import styled from 'styled-components';

export const Heading = styled.h2`
  padding: 20px;
  text-align: center;
`;

export const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 16px 24px 16px;
`;

export const Item = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.darkGrey};
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.palette.primary.bluePurple};
  font-size: 18px;
`;

export const Value = styled.div`
  color: ${({ color }) => color};
  font-size: 18px;
`;
