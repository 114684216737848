import { SavedVehicle, Vehicle } from '@just-insure/api';
import { Tooltip } from '@material-ui/core';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { AddButton } from '../../../../components/AddButton/AddButton';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import ErrorModal from '../../../../components/ErrorModal/ErrorModal';
import { Table } from '../../../../components/Table';
import EditRowTemplate from './EditRowTemplate';
import LienHolderModal from '../LienHolder/LienHolderModal';
import * as S from './VehicleList.styled';
import { vehicleListColumnDefs } from './vehicleListColumnDefs';
import ViewRowTemplate from './ViewRowTemplate';
import { PhotoResponse } from '../../../../services/repository/vehicleService';
import PhotoViewer from './PhotoViewer';

interface Props {
  userVehicles: SavedVehicle[];
  error: AxiosError | Error | undefined;
  clearError: () => void;
  updateVehicleInfo: (vehicle: Vehicle, updatePolicy: boolean) => Promise<void>;
  setDefaultVehicle: (vehicleId: number) => Promise<void>;
  addTemporaryVehicle: () => void;
  removeTemporaryVehicle: () => void;
  deleteVehicleById: (vehicleId: number) => Promise<void>;
  downloadVehicleImages: (vin: string) => Promise<void>;
  saveVehicle: (vehicle: SavedVehicle) => Promise<void>;
  getPhotosForVehicle: (vin: string) => Promise<PhotoResponse[]>;
  timezone: string;
}

const VehicleListContent: React.FC<Props> = ({
  error,
  clearError,
  userVehicles,
  updateVehicleInfo,
  setDefaultVehicle,
  addTemporaryVehicle,
  removeTemporaryVehicle,
  deleteVehicleById,
  downloadVehicleImages,
  saveVehicle,
  getPhotosForVehicle,
  timezone,
}) => {
  const [selectedVehicle, setSelectedVehicle] = React.useState<SavedVehicle>();
  const [photos, setPhotos] = React.useState<PhotoResponse[]>([]);
  const [toggler, setToggler] = React.useState<boolean>(false);
  const [lienHolderModalState, setLienHolderModalState] = useState<{
    isOpen: boolean;
    isLoading?: boolean;
    vehicle?: Vehicle;
  }>({ isOpen: false });

  async function handleVehicleInfoUpdate(
    vehicle: Vehicle,
    updatePolicy: boolean,
  ) {
    setLienHolderModalState(s => ({ ...s, isLoading: true }));
    await updateVehicleInfo(vehicle, updatePolicy);
    setLienHolderModalState({ isOpen: false });
  }

  async function presentPhotoGalleryForVehicle(vin: string) {
    const photosForVehicle = await getPhotosForVehicle(vin);
    if (photosForVehicle.length) {
      setPhotos(photosForVehicle);
      setToggler(s => !s);
    } else {
      alert('No photos found for this vehicle');
    }
  }

  const editMode = userVehicles.length > 0 && !userVehicles[0].id;
  return (
    <S.VehicleContainer>
      <Table
        columns={vehicleListColumnDefs}
        data={userVehicles}
        defaultOrder="date"
        defaultDir="desc"
        rowTemplate={({ rowIndex, ...rest }) =>
          !rest.item.id ? (
            <EditRowTemplate
              {...rest}
              key={rowIndex}
              onCancel={removeTemporaryVehicle}
              onSaveVehicle={saveVehicle}
              rowIndex={rowIndex}
            />
          ) : (
            <ViewRowTemplate
              {...rest}
              key={rowIndex}
              onImageDownloadPress={vin => {
                downloadVehicleImages(vin);
              }}
              onShowPhotos={vin => presentPhotoGalleryForVehicle(vin)}
              onMakePrimary={() => setDefaultVehicle(rest.item.id)}
              onDeleteVehicle={() => setSelectedVehicle(rest.item)}
              onLienHolderPress={(vehicle: Vehicle) =>
                setLienHolderModalState({ isOpen: true, vehicle })
              }
            />
          )
        }
      />

      {!selectedVehicle && !editMode ? (
        <Tooltip title="Add a new vehicle">
          <AddButton onClick={addTemporaryVehicle}>+</AddButton>
        </Tooltip>
      ) : null}
      <ErrorModal error={error} onClose={clearError} />
      {selectedVehicle ? (
        <ConfirmationModal
          isOpen={true}
          type="deleting"
          confirmButtonText="Delete"
          text="Are you sure you want to delete this vehicle?"
          onConfirm={() => deleteVehicleById(selectedVehicle.id)}
          onCancel={() => setSelectedVehicle(undefined)}
        />
      ) : null}
      <LienHolderModal
        selectedVehicle={lienHolderModalState.vehicle as Vehicle}
        isOpen={lienHolderModalState.isOpen}
        isLoading={lienHolderModalState.isLoading}
        onClose={() => setLienHolderModalState({ isOpen: false })}
        onLienHolderUpdate={handleVehicleInfoUpdate}
      />
      {photos.length ? (
        <PhotoViewer timezone={timezone} photos={photos} toggler={toggler} />
      ) : null}
    </S.VehicleContainer>
  );
};

export default VehicleListContent;
