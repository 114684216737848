import React from 'react';
import {
  StyledDialogTitle,
  StyledDialogActions,
} from '../../../../../../components/ConfirmationModalV2/ConfirmationModalV2.styled';
import { Button, DialogContent, TableBody } from '@material-ui/core';
import { StyledTable } from './Modal.styled';
import AutopaymentsRow from './AutopaymentsRow';
import BalanceRow from './BalanceRow';
import ModalError from '../../../../../../components/ModalError/ModalError';

interface Props {
  isNegativeFunds: boolean;
  isAutomaticPaymentsEnabled: boolean;
  funds: number;
  onClose: () => void;
}

const ModalRequirementError: React.FC<Props> = ({
  isNegativeFunds,
  isAutomaticPaymentsEnabled,
  funds,
  onClose,
}) => {
  return (
    <>
      <StyledDialogTitle>Take a payment</StyledDialogTitle>
      <DialogContent>
        <StyledTable size="small">
          <TableBody>
            <AutopaymentsRow
              isAutomaticPaymentsEnabled={isAutomaticPaymentsEnabled}
            />
            <BalanceRow funds={funds} />
          </TableBody>
        </StyledTable>
        {!isNegativeFunds ? (
          <ModalError error="Unable to take payment. The user balance is not outstanding." />
        ) : null}
        {!isAutomaticPaymentsEnabled ? (
          <ModalError error="Unable to take payment. The user has auto payments disabled." />
        ) : null}
      </DialogContent>
      <StyledDialogActions disableSpacing>
        <Button onClick={onClose} variant="outlined" color="primary">
          Abort
        </Button>
      </StyledDialogActions>
    </>
  );
};

export default ModalRequirementError;
