import { withStyles } from '@material-ui/core/styles';
import React, { useRef, useState, Suspense } from 'react';
import NotificationSystem from 'react-notification-system';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { SiteAppBar } from '../../../components/SiteAppBar';
import { AccountManagement } from '../../accountManagement';
import TallyUpListing from '../../odometer/TallyUpListing/TallyUpListing';
import TallyUpReading from '../../odometer/TallyUpReading/index';
import UserDetails from '../../userManagement/UserDetails/UserDetails';
import UserSearch from '../../userManagement/UserSearch';
import ReferralList from '../../referralManagement/ReferralList/ReferralList';
import MissingPage from '../404/MissingPage';
import styles, { Container, Content } from './Portal.styled';
import { Feature, isFeatureEnabled } from '../../../config';
import SalespersonDetails from '../../referralManagement/SalespersonDetails';
import OrganisationDetails from '../../referralManagement/OrganisationDetails';
import Productboard from '../../productboard/Productboard';
import { getCurrentEnv, Environment } from '../../../config';
import { PageLoader } from '../../../components/PageLoader';

type PortalProps = RouteComponentProps & {
  classes: any;
};

const Map = React.lazy(() => import('../../mapping/Map/Map'));

const Portal: React.FC<PortalProps> = ({ classes, location, history }) => {
  const notificationSystem = useRef<any>();
  const [openSideBar, setOpenSideBar] = useState(false);
  const isStaging = getCurrentEnv() === Environment.Staging;

  return (
    <Route
      component={() => (
        <Container>
          <SiteAppBar
            isSideBarOpen={openSideBar}
            pathname={location.pathname}
            onNavigateToUrl={url => history.push(url)}
            onSideBarOpen={open => setOpenSideBar(open)}
            isStaging={isStaging}
          />
          <NotificationSystem ref={notificationSystem} />

          <Content>
            <div className={classes.appBarSpacer} />

            <Suspense fallback={<PageLoader text="Loading..." />}>
              <Switch>
                <Route path="/mapping/:tripId/:version" exact component={Map} />
                <Route path="/tallyups" exact component={TallyUpListing} />
                <Route path="/users" exact component={UserSearch} />
                <Route path="/users/:userId" exact component={UserDetails} />
                <Route
                  path="/users/:userId/tallyups/:id"
                  exact
                  component={TallyUpReading}
                />
                <Route path="/accounts" exact component={AccountManagement} />
                {isFeatureEnabled(Feature.Referrals) ? (
                  <Route path="/referrals" exact component={ReferralList} />
                ) : null}
                {isFeatureEnabled(Feature.Referrals) ? (
                  <Route
                    path="/referrals/salesperson/:salespersonId"
                    exact
                    component={SalespersonDetails}
                  />
                ) : null}
                {isFeatureEnabled(Feature.Referrals) ? (
                  <Route
                    path="/referrals/organisation/:organisationId"
                    exact
                    component={OrganisationDetails}
                  />
                ) : null}
                <Route path="/productboard" exact component={Productboard} />
                {/*Root route now redirects to users, instead of dashboard*/}
                <Redirect exact to="/users" from="/" />
                <Route component={MissingPage} />
              </Switch>
            </Suspense>
          </Content>
        </Container>
      )}
    />
  );
};

export default withStyles(styles)(withRouter(Portal));
