import React from 'react';
import { useCreditCheck, UseCreditCheck } from './creditCheck';
import { UseDrivingHistory, useDrivingHistory } from './drivingHistory';
import { useUserDetails, UserTimezone } from '../../UserDetails/Controller';

interface UserVerificationContext {
  userTimezone: UserTimezone;
  drivingHistory: UseDrivingHistory;
  creditCheck: UseCreditCheck;
}

const Context = React.createContext<UserVerificationContext | null>(null);

export const UserVerificationController: React.FC<{ userId: string }> = ({
  children,
  userId,
}) => {
  const { timezone: userTimezone } = useUserDetails();
  const drivingHistory = useDrivingHistory(userId);
  const creditCheck = useCreditCheck(userId);

  React.useEffect(() => {
    drivingHistory.getDrivingHistoryReport();
    creditCheck.getCreditReports();
  }, []);

  return (
    <Context.Provider
      value={{
        userTimezone,
        drivingHistory,
        creditCheck,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useUserVerificationController = () => {
  const userVerificationContext = React.useContext(Context);

  if (userVerificationContext === null) {
    throw new Error(
      'useUserVerificationController must be used within a UserVerificationController',
    );
  }

  return userVerificationContext;
};
