import React from 'react';
import { QuoteCoverageDetail } from '../../helpers/quoteHelpers';

interface Props {
  coverages: QuoteCoverageDetail[];
}

export const Coverages: React.FC<Props> = ({ coverages }) => {
  return (
    <table>
      <tbody>
        {coverages.map(c => (
          <tr key={c.id}>
            <td>
              <div className={c.isOption ? 'optional' : ''}>{c.name}</div>
            </td>
            <td>
              {!c.hasOptions && c.prices.map(s => <div key={s}>{s}</div>)}
            </td>
            <td>
              {!c.hasOptions && c.selected ? (
                <img src="/images/check.png" alt="" />
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
