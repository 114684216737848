import * as yup from 'yup';

const schema = (funds: number) =>
  yup.object().shape({
    amountInDollars: yup
      .string()
      .required('Amount is required.')
      .test(
        'currency',
        'Amount must be a number with 2 decimal places.',
        function (item) {
          return /^\d+\.\d{2}$/.test(item);
        },
      )
      .test(
        'lessThanBalance',
        'Amount must be less than or equal to the balance.',
        function (item) {
          return parseFloat(item) * 100 <= Math.abs(funds);
        },
      ),
  });

export default schema;
