import React from 'react';
import { ActionButton } from '../UserActions.styled';
import VehicleIcon from './VehicleIcon';
import { Container, StyledModal } from './DiscoveredVehicles.style';
import Loading from './Loading';
import DiscoveryError from './DiscoveryError';
import { useGetUserDiscoveryQuery } from '../../../../graphql/api';
import { useGetUserDiscoverySelector } from './useGetUserDiscoverySelector';
import DiscoverySuccess, { DiscoveredVehiclesResult } from './DiscoverySuccess';
import { withErrorBoundary } from '../../../../components/withErrorBoundary';

interface Props {
  userId: string;
}

const DiscoveredVehicles: React.FC<Props> = ({ userId }) => {
  const { data, isLoading, isError, error, refetch } = useGetUserDiscoveryQuery<
    DiscoveredVehiclesResult,
    Error
  >(
    { userId },
    {
      queryKey: ['userDiscovery', userId],
      select: useGetUserDiscoverySelector,
    },
  );

  const [isModalVisible, setModalVisibility] = React.useState(false);

  return (
    <>
      <ActionButton
        onClick={() => {
          setModalVisibility(true);
        }}
        variant="contained"
        startIcon={<VehicleIcon />}
      >
        Discovered Vehicles
      </ActionButton>
      <StyledModal
        open={isModalVisible}
        onClose={() => {
          setModalVisibility(false);
        }}
      >
        <Container>
          {(() => {
            switch (true) {
              case isLoading: {
                return <Loading />;
              }
              case isError: {
                return <DiscoveryError error={error!} onRetry={refetch} />;
              }
              default: {
                return (
                  <DiscoverySuccess
                    closeModal={() => setModalVisibility(false)}
                    discoveredVehicles={data!}
                  />
                );
              }
            }
          })()}
        </Container>
      </StyledModal>
    </>
  );
};

export default withErrorBoundary(DiscoveredVehicles);
