import { SavedVehicle } from '@just-insure/api';

export const vehicleListColumnDefs = [
  {
    id: 'vin',
    title: 'VIN',
  },
  {
    id: 'make',
    title: 'Make',
  },
  {
    id: 'model',
    title: 'Model',
  },
  {
    id: 'year',
    title: 'Year',
  },
  {
    id: 'lienholder',
    title: 'Lienholder',
  },
  {
    id: 'isDefault',
    title: 'Primary Vehicle',
    rowFormatter: (vehicle: SavedVehicle) =>
      `${vehicle.isDefault ? 'Yes' : 'No'}`,
  },
  {
    id: 'actions',
    title: '',
  },
  {
    id: 'images',
    title: 'Vehicle images',
  },
];
