import { Organisation } from '../../../graphql/types';
import { mapOrganisationTypeLabel } from '../helpers/mappers';

export const organisationsListColumnDefs = [
  {
    title: 'Organisation ID',
    id: 'id',
    sortable: false,
  },
  {
    title: 'Type',
    id: 'type',
    rowFormatter: (organisation: Organisation) =>
      `${mapOrganisationTypeLabel(organisation.type)}`,
    sortable: false,
  },
  {
    title: 'Name',
    id: 'name',
    sortable: false,
  },
  {
    title: 'Website',
    id: 'website',
    sortable: false,
  },
  {
    title: 'Address',
    id: 'address',
    rowFormatter: (organisation: Organisation) =>
      `${organisation.addressLine1}, ${
        organisation.addressLine2 ? organisation.addressLine2 + ', ' : ''
      }${organisation.city}`,
    sortable: false,
  },
  {
    title: 'Zipcode',
    id: 'zipCode',
    sortable: false,
  },
  {
    title: 'State',
    id: 'state',
    sortable: false,
  },
  {
    title: 'FEIN',
    id: 'fein',
    sortable: false,
  },
  {
    title: 'Parent Name',
    id: 'parentName',
    sortable: false,
  },
];
