import React from 'react';
import { UserTimezone, useUserTimezone } from './useUserTimezone';

interface ContextType {
  timezone: UserTimezone;
}

const Context = React.createContext<ContextType | undefined>(undefined);

interface Props {
  userId: string;
}

// TODO: Move other "Controllers" here to separate and simplify business logic
export const UserDetailsController: React.FC<Props> = ({
  userId,
  children,
}) => {
  const timezone = useUserTimezone(userId);

  return <Context.Provider value={{ timezone }}>{children}</Context.Provider>;
};

export const useUserDetails = () => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error('useUserDetails must be used within a UserDetailsProvider');
  }
  return context;
};

export type { UserTimezone };
