import React from 'react';
import { TableBody, TableRow, Typography } from '@material-ui/core';
import { StyledTable, StyledTableCell } from './ModalTable.styled';

interface Props {
  isAutomaticPaymentsEnabled: boolean;
  isApprovalEnabled: boolean;
  formattedBalance: string;
}

const ModalTable: React.FC<Props> = ({
  isAutomaticPaymentsEnabled,
  isApprovalEnabled,
  formattedBalance,
}) => {
  return (
    <>
      <Typography align="center" variant="body2">
        After the final payment is taken, the balance account will be zero.
      </Typography>
      <StyledTable size="small">
        <TableBody>
          <TableRow>
            <StyledTableCell component="th">Auto payments</StyledTableCell>
            <StyledTableCell align="right">
              {isAutomaticPaymentsEnabled ? 'Enabled' : 'Disabled'}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell component="th">
              Approval for large payments
            </StyledTableCell>
            <StyledTableCell align="right">
              {isApprovalEnabled ? 'Enabled' : 'Disabled'}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell component="th">Current balance</StyledTableCell>
            <StyledTableCell align="right">{formattedBalance}</StyledTableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </>
  );
};

export default ModalTable;
