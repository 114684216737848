import styled from 'styled-components';

// TODO we really need reset css
const TextBase = styled.p`
  font-family: ${({ theme }) => theme.font.family.roboto};
  color: ${({ theme }) => theme.palette.primary.dark};
  margin: 0;
  padding: 0;
`;

export const H3 = styled(TextBase)`
  font-size: ${({ theme }) => theme.font.size.l};
`;

export const Body = styled(TextBase)`
  font-size: ${({ theme }) => theme.font.size.m};
`;
