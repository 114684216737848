import React from 'react';
import { Modal } from '@material-ui/core';
import * as S from './UnblockAccountModal.styled';
import Form from '../../../../../components/Form/Form';
import FormSelect, {
  SelectOption,
} from '../../../../../components/FormSelect/FormSelect';
import { ReinstateReason } from '../../../../../graphql/api';
import FormTextInput from '../../../../../components/FormTextInput/FormTextInput';
import validationSchema from './validationSchema';

interface Props {
  isOpen: boolean;
  hideModal: () => void;
  onSubmit: (payload: {
    reason: ReinstateReason;
    description: string;
  }) => Promise<void>;
  onClose: () => void;
}

const emailChangeReasons: Array<SelectOption> = [
  {
    label: 'Vehicle Purchased from Previous User',
    value: ReinstateReason.VehiclePurchasedFromPreviousUser,
  },
  {
    label: 'Vehicle Purchased from Dealership',
    value: ReinstateReason.VehiclePurchasedFromDealership,
  },
  {
    label: 'Family Member Change',
    value: ReinstateReason.FamilyMemberChange,
  },
  {
    label: 'Other',
    value: ReinstateReason.Other,
  },
];

const initialFormValues = {
  reason: '',
  description: '',
};

const UnblockAccountModal: React.FC<Props> = ({
  isOpen,
  hideModal,
  onSubmit,
  onClose,
}) => {
  return (
    <Modal open={isOpen} onClose={hideModal}>
      <S.ModalContent>
        <S.Title>Unblock user account</S.Title>
        <S.Body>
          Once the account is unblocked, the customer will need to close and
          open the app again to see the changes and proceed with the
          registration.
        </S.Body>
        <Form
          submit={values =>
            onSubmit({ description: values.description, reason: values.reason })
          }
          initialValues={initialFormValues}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => {
            return (
              <>
                <FormSelect
                  label="Reason"
                  name="reason"
                  editable
                  options={emailChangeReasons}
                />
                <FormTextInput
                  label="Description"
                  name="description"
                  editable
                />
                <S.Row>
                  <S.StyledButton variant={'contained'} onClick={onClose}>
                    Close
                  </S.StyledButton>
                  <S.StyledButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Update
                  </S.StyledButton>
                </S.Row>
              </>
            );
          }}
        </Form>
      </S.ModalContent>
    </Modal>
  );
};

export default UnblockAccountModal;
