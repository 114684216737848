import axios, { ResponseType } from 'axios';
import { Observable, Observer } from 'rxjs';
import { v4 } from 'uuid';
import { getConfig } from '../../config';
import { getIdTokenSilently } from '../auth';

type Methods = 'delete' | 'get' | 'patch' | 'post' | 'put';

axios.defaults.headers.common['Content-Type'] = 'application/json';

export interface ApiOptions {
  baseUrl?: string;
  isBypassAuth?: boolean;
  responseType?: ResponseType;
  version?: number;
  idempotencyKey?: string;
}

export const api$ = <T>(
  method: Methods,
  url: string,
  data?: any,
  options: ApiOptions = {},
): Observable<T> =>
  Observable.create(async (obs: Observer<T>) => {
    try {
      const result = await api<T>(method, url, data, options);
      obs.next(result);
      obs.complete();
    } catch (err: any) {
      if (typeof err === 'object' && err.name === 'TokenRefreshError') {
        obs.complete();
      }

      obs.error(err);
    }
  });

export const api = async <T>(
  method: Methods,
  url: string,
  data?: any,
  options: ApiOptions = {},
): Promise<T> => {
  try {
    const config = getConfig();
    const apiClient = axios.create({});
    let parsedUrl = url;

    const baseUrl = config.REACT_APP_BASE_SERVICE_URL;
    if (!options.baseUrl && baseUrl) {
      parsedUrl = `${baseUrl}${parsedUrl}`;
    } else {
      parsedUrl = `${options.baseUrl}${parsedUrl}`;
    }

    if (!options.isBypassAuth) {
      const token = await getIdTokenSilently();
      apiClient.defaults.headers.Authorization = `Bearer ${token}`;
    }

    const correlationId = v4();
    apiClient.defaults.headers['x-correlation-id'] = correlationId;

    if (options.idempotencyKey) {
      apiClient.defaults.headers['Idempotency-Key'] = options.idempotencyKey;
    }

    if (options.version !== undefined) {
      apiClient.defaults.headers.Accept = `application/vnd.api+json+v${options.version}`;
    }

    const response = await apiClient({
      method,
      url: parsedUrl,
      data,
      timeout: 180000,
      responseType: options.responseType,
    });

    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getImage = async (
  url: string,
  baseUrl?: string,
): Promise<string> => {
  const base = baseUrl || getConfig().REACT_APP_BASE_SERVICE_URL;
  const token = await getIdTokenSilently();
  return fetch(`${base}${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'image/jpeg',
    },
  })
    .then(response => {
      if (response.status >= 300) {
        throw new Error('Unable to get image');
      }
      return response.blob();
    })
    .then(blob => URL.createObjectURL(blob));
};
