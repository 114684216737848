import React from 'react';
import FormTextInput from '../../../../../../components/FormTextInput/FormTextInput';
import FormFileInput from '../../../../../../components/FormFileInput';
import schema from './schema';
import { UploadOdometerFormValues } from '../../types';
import { useUploadOdometer } from '../../../hooks/useOdometers';
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import Form from '../../../../../../components/Form/Form';
import LoadingButton from '../../../../../../components/LoadingButton/LoadingButton';
import { ErrorMessage } from '../ModalContent.styled';
import { getErrorMessage } from './util';
import { Vehicle } from '../../../../../../services/repository/policyService';

type Props = {
  userId: string;
  primaryVehicle: Vehicle;
  onClose: () => void;
};

const UploadOdometerForm: React.FC<Props> = ({
  userId,
  primaryVehicle,
  onClose,
}) => {
  const {
    mutate: uploadOdometer,
    isLoading,
    error,
  } = useUploadOdometer(userId, onClose);

  const onSubmit = async ({
    image,
    userReading,
    notes,
  }: UploadOdometerFormValues) => {
    uploadOdometer({
      vehicleId: `${primaryVehicle.id}`,
      image,
      userReading,
      notes,
    });
  };

  return (
    <Form
      validateOnMount={false}
      validationSchema={schema}
      submit={onSubmit}
      initialValues={{
        userReading: '',
        notes: '',
      }}
      showConfirmation={false}
    >
      {({ handleSubmit }) => (
        <>
          <DialogContent>
            <Typography variant="body1">
              Vehicle: {primaryVehicle.year} {primaryVehicle.make}{' '}
              {primaryVehicle.model}
            </Typography>
            <FormFileInput name="image" label="Choose image" fileType="image" />
            <FormTextInput
              name="userReading"
              editable
              label="Odometer Reading"
            />
            <FormTextInput name="notes" editable label="Note" />
            {error ? (
              <ErrorMessage>{getErrorMessage(error)}</ErrorMessage>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained">
              Close
            </Button>
            <LoadingButton isLoading={isLoading} onClick={handleSubmit}>
              Upload
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Form>
  );
};

export default UploadOdometerForm;
