import React from 'react';
import ConfirmationModalV2 from '../../../../../components/ConfirmationModalV2/ConfirmationModalV2';
import {
  Table,
  TableBody,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import {
  StyledTableCell,
  StyledDivider,
  LoadingContainer,
} from './BalanceFinalisationModal.styled';
import useCalculateFinalBalance from '../useCalculateFinalBalance';
import ModalError from '../../../../../components/ModalError/ModalError';
import { formatCalculation } from '../util/createIntercomNote';

interface Props {
  userId: string;
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm: (amount: number, note: string) => void;
  onCancel: () => void;
  error?: string;
  isIntercomError?: boolean;
}

const BalanceFinalisationModal: React.FC<Props> = ({
  userId,
  isOpen,
  onConfirm,
  onCancel,
  isLoading = false,
  error,
  isIntercomError = false,
}) => {
  const finalBalance = useCalculateFinalBalance(userId);

  return (
    <ConfirmationModalV2
      isOpen={isOpen}
      isLoading={isLoading}
      confirmButtonText={
        finalBalance.status === 'success'
          ? `Charge ${finalBalance.data.balanceFinalisationCharge}`
          : ''
      }
      title="Balance finalization charge"
      hideContent={isIntercomError}
      hideSubmit={
        finalBalance.status === 'loading' ||
        finalBalance.status === 'fail' ||
        isIntercomError
      }
      onConfirm={() =>
        finalBalance.status === 'success'
          ? onConfirm(
              finalBalance.data.balanceFinalisationChargeInCents,
              formatCalculation(finalBalance.data),
            )
          : null
      }
      onCancel={onCancel}
      confirmButtonDisabled={
        finalBalance.status === 'success' &&
        Math.round(finalBalance.data.balanceFinalisationChargeInCents) <= 0
      }
      error={error}
    >
      {(() => {
        switch (finalBalance.status) {
          case 'loading':
            return (
              <LoadingContainer>
                <CircularProgress size={20} />
              </LoadingContainer>
            );
          case 'fail':
            return <ModalError error={finalBalance.error} />;
          case 'success':
            return (
              <>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <StyledTableCell component="th">
                        Date of Cancellation/Expiration
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.policyTerminationDate}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="th">
                        Date of latest odometer photo
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.latestOdometerDate}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="th">
                        Time duration
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.fiftyMilePerDayPeriod}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <StyledDivider />
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <StyledTableCell component="th">
                        50 mile per-day period
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.fiftyMilePerDayPeriodMiles}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="th">
                        50 mile per-day already charged
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.fiftyMileAlreadyChargedMiles}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="th">
                        Total 50 mile per-day charge
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.totalFiftyMileChargeInMiles}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <StyledDivider />
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <StyledTableCell component="th">
                        Tracked trips
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.trackedTripMiles}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="th">
                        Disputed trips
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.disputedTripMiles}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="th">
                        Total tracked trips
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.totalTrackedTripMiles}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <StyledDivider />
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <StyledTableCell component="th">
                        Total mileage charge
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.totalChargeableMiles}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="th">
                        Price per-mile
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {finalBalance.data.pricePerMile}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            );
        }
      })()}
    </ConfirmationModalV2>
  );
};

export default BalanceFinalisationModal;
