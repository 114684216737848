import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const Attachment = styled.a`
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.darkGrey};
  color: ${({ theme }) => theme.typography.body1.color};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  padding: 10px;
`;

export const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentTab = styled.div`
  padding: 10px;
`;

export const PreviewContainer = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 30%;
  left: calc(50% - 150px);
  display: flex;
  flex-direction: column;
  height: 280px;
  outline: none;
  padding: 10px 20px;
  min-width: 300px;
  width: 400px;
`;

export const Headline = styled.h2`
  & {
    text-align: center;
    margin-bottom: 0;
  }
`;

export const HeaderContainer = styled.div`
  align-self: flex-end;
`;

export const Row = styled.div`
  && {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
  }
`;

export const StyledButton = styled(Button)``;

export const CancelButton = styled(Button)`
  && {
    margin-right: 10px;
  }
`;

export const AllDoneImage = styled.img`
  align-self: center;
  height: 86px;
  width: 86px;
  margin-bottom: 16px;
`;

export const Comment = styled.p`
  && {
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    text-align: center;
  }
`;
