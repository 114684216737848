import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const PrimaryButton = styled(Button)`
  && {
    width: 130px;
  }
`;

export const PopUpMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
