import styled from 'styled-components';

export const ErrorText = styled.h2`
  color: ${props => props.theme.palette.primary.red};
  padding-bottom: 1rem;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 3rem 2rem 2rem 2rem;
  align-self: center;
  flex-direction: row;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionsContainer = styled(ContentContainer)`
  justify-content: flex-end;
`;

export const CenterContentContainer = styled(ContentContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const ActionButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  &:last-child {
    padding-right: 0;
  }
`;
