import React, { useState } from 'react';
import { Acv, Accident, Claim } from '../../../graphql/api';
import { SpeedDialAction } from '../../../../types/interfaces';
import * as S from './AcvList.styled';
import { Container } from '@material-ui/core';
import SpeedDialNav from '../../../components/SpeedDialNav/SpeedDialNav';
import { Feature, isFeatureEnabled } from '../../../config';
import { createACV } from '../../../services/repository/acvService';
import { ErrorCode, logError } from '../../../services/logging';
import { ACVOption } from './common/types';
import Accidents from './Accidents/Accidents';
import Claims from './Claims/Claims';
import Violations from './Violations/Violations';
import {
  addItemToArray,
  editItemInArray,
  removeItemFromArray,
} from './common/helpers';
import { Violation } from '../../../graphql/types';

interface Props {
  acv: Acv;
  userId: string;
  visible: boolean;
  onRefresh: () => void;
}

const AcvList: React.FC<Props> = ({ acv, userId, visible, onRefresh }) => {
  const [openFormModalType, setOpenFormModalType] = useState<
    ACVOption | undefined
  >();
  const [openConfirmModalType, setConfirmModalType] = useState<
    ACVOption | undefined
  >();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  if (!visible) {
    return null;
  }

  const speedDialActions: SpeedDialAction[] = [
    {
      icon: <>V</>,
      name: 'Add an violation',
      onClick: () => setOpenFormModalType(ACVOption.Violation),
    },
    {
      icon: <>C</>,
      name: 'Add an claim',
      onClick: () => setOpenFormModalType(ACVOption.Claim),
    },
    {
      icon: <>A</>,
      name: 'Add an accident',
      onClick: () => setOpenFormModalType(ACVOption.Accident),
    },
  ];

  const saveACV = async (changedAcv: Acv, successCallback = () => {}) => {
    try {
      setError(undefined);
      setLoading(true);
      await createACV(userId, changedAcv);
      successCallback();
      onClose();
      onRefresh();
    } catch (err: any) {
      setError(err);
      logError(ErrorCode.EditACV, err);
    } finally {
      setLoading(false);
    }
  };

  const onCreateAccident = async (accident: Accident) => {
    const accidents = addItemToArray<Accident>(acv.accidents, accident);
    saveACV({ ...acv, accidents });
  };

  const onEditAccident = async (
    oldAccident: Accident,
    newAccident: Accident,
    successCallback: () => void,
  ) => {
    const accidents = editItemInArray<Accident>(
      acv.accidents,
      newAccident,
      acv.accidents.indexOf(oldAccident),
    );
    saveACV({ ...acv, accidents }, successCallback);
  };

  const onDeleteAccident = async (
    accident: Accident,
    successCallback: () => void,
  ) => {
    const accidents = removeItemFromArray<Accident>(
      acv.accidents,
      acv.accidents.indexOf(accident),
    );
    saveACV({ ...acv, accidents }, successCallback);
  };

  const onCreateClaim = async (claim: Claim) => {
    const claims = addItemToArray<Claim>(acv.claims, claim);
    saveACV({ ...acv, claims });
  };

  const onEditClaim = async (
    oldClaim: Claim,
    newClaim: Claim,
    successCallback: () => void,
  ) => {
    const claims = editItemInArray<Claim>(
      acv.claims,
      newClaim,
      acv.claims.indexOf(oldClaim),
    );
    saveACV({ ...acv, claims }, successCallback);
  };

  const onDeleteClaim = async (claim: Claim, successCallback: () => void) => {
    const claims = removeItemFromArray<Claim>(
      acv.claims,
      acv.claims.indexOf(claim),
    );
    saveACV({ ...acv, claims }, successCallback);
  };

  const onCreateViolation = async (violation: Violation) => {
    const violations = addItemToArray<Violation>(acv.violations, violation);
    saveACV({ ...acv, violations });
  };

  const onEditViolation = async (
    oldViolation: Violation,
    newViolation: Violation,
    successCallback: () => void,
  ) => {
    const violations = editItemInArray<Violation>(
      acv.violations,
      newViolation,
      acv.violations.indexOf(oldViolation),
    );
    saveACV({ ...acv, violations }, successCallback);
  };

  const onDeleteViolation = async (
    violation: Violation,
    successCallback: () => void,
  ) => {
    const violations = removeItemFromArray<Violation>(
      acv.violations,
      acv.violations.indexOf(violation),
    );
    saveACV({ ...acv, violations }, successCallback);
  };

  const onClose = () => {
    setError(undefined);
    setOpenFormModalType(undefined);
    setConfirmModalType(undefined);
  };

  return (
    <S.Contain>
      <Container maxWidth="xl">
        <Accidents
          accidents={acv.accidents}
          isFormModalOpen={openFormModalType === ACVOption.Accident}
          isConfirmModalOpen={openConfirmModalType === ACVOption.Accident}
          isLoading={isLoading}
          onOpenFormModal={() => setOpenFormModalType(ACVOption.Accident)}
          onOpenConfirmModal={() => setConfirmModalType(ACVOption.Accident)}
          onClose={onClose}
          onCreate={onCreateAccident}
          onEdit={onEditAccident}
          onDelete={onDeleteAccident}
          error={error}
        />
        <Claims
          claims={acv.claims}
          isFormModalOpen={openFormModalType === ACVOption.Claim}
          isConfirmModalOpen={openConfirmModalType === ACVOption.Claim}
          isLoading={isLoading}
          onOpenFormModal={() => setOpenFormModalType(ACVOption.Claim)}
          onOpenConfirmModal={() => setConfirmModalType(ACVOption.Claim)}
          onClose={onClose}
          onCreate={onCreateClaim}
          onEdit={onEditClaim}
          onDelete={onDeleteClaim}
          error={error}
        />
        <Violations
          violations={acv.violations}
          isFormModalOpen={openFormModalType === ACVOption.Violation}
          isConfirmModalOpen={openConfirmModalType === ACVOption.Violation}
          isLoading={isLoading}
          onOpenFormModal={() => setOpenFormModalType(ACVOption.Violation)}
          onOpenConfirmModal={() => setConfirmModalType(ACVOption.Violation)}
          onClose={onClose}
          onCreate={onCreateViolation}
          onEdit={onEditViolation}
          onDelete={onDeleteViolation}
          error={error}
        />
        {isFeatureEnabled(Feature.EditACV) ? (
          <SpeedDialNav actions={speedDialActions} />
        ) : null}
      </Container>
    </S.Contain>
  );
};

export default AcvList;
