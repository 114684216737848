import { GetUserBetaFlagsQuery } from '../../../graphql/api';

export const betaFlagsSelector = ({ user }: GetUserBetaFlagsQuery) => {
  const flags = user.betaFlagContent.flags;
  if (!flags) {
    return [];
  }
  return flags.map(b => ({
    title: b.title,
    value: b.value ? 'ON' : 'OFF',
  }));
};
