import React, { useState } from 'react';
import {
  AmountsContainer,
  ItemLabel,
  ItemAmount,
  Container,
  HeaderContainer,
  HeaderTitle,
  StatusContainer,
  StatusText,
  DisableLink,
} from './AutoPayInfoTile.styled';
import { AutoPaySettings } from '../../../services/repository/userService';
import { useDisableAutoPayMutation } from '../../../graphql/api';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { ErrorCode, logError } from '../../../services/logging';

type Props = {
  onRefreshUserDetails?: () => void;
  autoPay: AutoPaySettings;
  userId: string;
};

function toDollars(cents: number) {
  return cents / 100;
}

const AutoPayInfoTile: React.FC<Props> = ({
  autoPay,
  userId,
  onRefreshUserDetails,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const { isEnabled, threshold, amount, isApprovalEnabled } = autoPay;

  const { mutate, isLoading, error } = useDisableAutoPayMutation({
    onSuccess: () => {
      setModalVisible(false);
      onRefreshUserDetails?.();
    },
    onError: (e: Error) => {
      logError(ErrorCode.AutoPay, e);
    },
  });

  const disableAutoPay = async () => {
    const input = { userId: parseInt(userId, 10) };
    mutate({ input });
  };

  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle variant="body1">Auto Payments</HeaderTitle>
      </HeaderContainer>
      <StatusContainer isEnabled={isEnabled}>
        <StatusText>{isEnabled ? 'Enabled' : 'Disabled'}</StatusText>
        {isEnabled ? (
          <DisableLink
            onClick={e => {
              e.preventDefault();
              setModalVisible(true);
            }}
          >
            DISABLE
          </DisableLink>
        ) : null}
      </StatusContainer>
      {isEnabled ? (
        <>
          {threshold !== 0 ? (
            <AmountsContainer>
              <ItemLabel>Min. threshold:</ItemLabel>
              <ItemAmount>${toDollars(threshold)}</ItemAmount>
            </AmountsContainer>
          ) : null}
          <AmountsContainer>
            <ItemLabel>Approval:</ItemLabel>
            <ItemAmount>
              {isApprovalEnabled ? 'Enabled' : 'Disabled'}
            </ItemAmount>
          </AmountsContainer>
          <AmountsContainer>
            <ItemLabel>Top up amount:</ItemLabel>
            <ItemAmount>${toDollars(amount)}</ItemAmount>
          </AmountsContainer>
        </>
      ) : null}
      <ConfirmationModal
        isOpen={isModalVisible}
        type="save"
        confirmButtonText="Confirm"
        title="Are you sure you want to disable auto payments?"
        text="Once you proceed, automatic payments will stop when the account balance falls below $0, meaning the user will need to add funds manually."
        isLoading={isLoading}
        errorMessage={error?.message}
        onConfirm={() => disableAutoPay()}
        onCancel={() => setModalVisible(false)}
      />
    </Container>
  );
};

export default AutoPayInfoTile;
