import React from 'react';
import FsLightbox from 'fslightbox-react';
import { PhotoResponse } from '../../../../services/repository/vehicleService';
import { DateTime } from 'luxon';
import { CaptionText } from './PhotoViewer.styled';

interface Props {
  timezone: string;
  photos: PhotoResponse[];
  toggler: boolean;
}

const photoAngles = {
  'driver-side-front': 'Driver side front',
  'driver-side-rear': 'Driver side rear',
  'passenger-side-front': 'Passenger side front',
  'passenger-side-rear': 'Passenger side rear',
  'vehicle-front': 'Vehicle front',
  'vehicle-rear': 'Vehicle rear',
  'windshield-front': 'Windshield front',
};

const PhotoCaption: React.FC<{ date: string; photoAngle: string }> = ({
  date,
  photoAngle,
}) => {
  return (
    <div>
      <CaptionText>{`Date: ${date}`}</CaptionText>
      <CaptionText>{`Photo angle: ${photoAngle}`}</CaptionText>
    </div>
  );
};

const PhotoViewer: React.FC<Props> = ({ photos, toggler, timezone }) => {
  const getCaptions = () => {
    return photos.map(p => (
      <PhotoCaption
        date={DateTime.fromISO(p.metadata.date, { zone: timezone }).toFormat(
          'd LLL yyyy HH:mm:ss.SSS',
        )}
        photoAngle={
          photoAngles[p.metadata.photoAngle as keyof typeof photoAngles]
        }
      />
    ));
  };

  const getPhotos = () => {
    return photos.map(p => p.url);
  };

  return (
    <FsLightbox
      toggler={toggler}
      sources={getPhotos()}
      captions={getCaptions()}
    />
  );
};

export default PhotoViewer;
