import { AxiosError } from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { Trip, TripInfo } from '../../../../types/interfaces';
import ErrorModal from '../../../components/ErrorModal/ErrorModal';
import { Table } from '../../../components/Table';
import { ErrorCode, logError } from '../../../services/logging';
import * as tripService from '../../../services/repository/tripService';
import { tripListColumnDefs } from './tripListColumnDefs';

interface Props {
  onNavigation: (url: string) => void;
  userId: string;
  visible: boolean;
}

const TripDetailsList: React.FC<Props> = ({
  onNavigation,
  visible,
  userId,
}) => {
  const [error, setError] = useState<AxiosError>();
  const [trips, setTrips] = useState<TripInfo[]>([]);
  useEffect(() => {
    const getTrips = async () => {
      try {
        const results = await tripService.getUserTrips(userId);
        if (results.items) {
          // @ts-ignore - Inconsistency with pagination and older APIs
          setTrips(results.items.results);
        }
      } catch (ex: any) {
        logError(ErrorCode.UserTripDetails, ex);
        setError(ex);
      }
    };
    getTrips();
  }, [userId]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Table
        columns={tripListColumnDefs}
        data={trips}
        defaultOrder="start"
        defaultDir="desc"
        pageSize={10}
        onRowClick={trip => onNavigation(`/mapping/${trip.id}/2`)}
        rowActions={[
          {
            name: 'Download',
            icon: 'save_alt',
            onClick: trip => tripService.downloadTrip<Trip>(trip.id, '2'),
          },
        ]}
      />
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </>
  );
};

export default memo(TripDetailsList);
