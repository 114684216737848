import styled from 'styled-components';
import { Card, Typography, Link } from '@material-ui/core';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  min-height: 150px;
  padding: 12px;
`;

/* parent holding Card title + menu icon */
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const HeaderTitle = styled(Typography)`
  text-align: center;
  flex: 1;
`;

export const StatusContainer = styled.div<{ isEnabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: ${({ isEnabled }) =>
    isEnabled ? 'space-between' : 'center'};
`;

/* Enabled/Disabled status text */
export const StatusText = styled.h3`
  font-family: ${props => props.theme.typography.h5.fontFamily};
  color: ${props => props.theme.palette.primary.main};
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const DisableLink = styled(Link)`
  && {
    color: ${props => props.theme.palette.primary.red};
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }
`;

export const AmountsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ItemLabel = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.grey};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.36px;
  }
`;

export const ItemAmount = styled(ItemLabel)`
  && {
    color: ${props => props.theme.palette.primary.main};
  }
`;
