export const auditTrailColumnDefs = [
  {
    title: 'Time',
    id: 'date',
    sortable: false,
  },
  {
    title: 'User',
    id: 'originator',
    sortable: false,
  },
  {
    title: 'Action',
    id: 'action',
    sortable: false,
  },
  {
    title: 'Reason',
    id: 'reason',
    sortable: false,
  },
  {
    title: 'Description',
    id: 'notes',
    sortable: false,
  },
];
