import React from 'react';
import { HeaderLink, HeaderLinkImage } from './UserDetails.styled';
import { getConfig } from '../../../config';

interface Props {
  userId: string;
}

const Braintree: React.FC<Props> = ({ userId }) => {
  const navigateToBraintree = () => {
    window.open(`${getConfig().REACT_APP_BRAINTREE_URL}/${userId}`, '_blank');
  };

  return (
    <HeaderLink onClick={navigateToBraintree}>
      <HeaderLinkImage src="/images/braintree.png" alt="" />
      Braintree
    </HeaderLink>
  );
};

export default Braintree;
