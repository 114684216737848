import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import * as S from './ErrorModal.styled';

interface ErrorModalProps {
  error: string;
  onClose: () => void;
}

const ErrorModalCustom: React.FC<ErrorModalProps> = ({ error, onClose }) => {
  return (
    <Modal open={error !== undefined} onClose={onClose}>
      <S.ModalContainer data-testid="error-modal">
        <S.Headline>Oops, something went wrong!</S.Headline>
        <S.ErrorText>{error}</S.ErrorText>
        <S.Row>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </S.Row>
      </S.ModalContainer>
    </Modal>
  );
};

export default ErrorModalCustom;
