import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string()
    .ensure()
    .min(2, 'Name is too short')
    .required('Name is required'),
  email: Yup.string().email().required('Email is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(/^\+\d{11}$/, 'Must be valid US number (ex. +1234567890)'),
});

export default schema;
