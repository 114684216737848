import { gql } from 'graphql-request';

const creditReportFragment = gql`
  fragment creditReportFragment on CreditReport {
    keyFactors
    reportedAt
    score
    status
  }
`;

export const getCreditReportsQuery = gql`
  query getCreditReport($userId: ID!) {
    getCreditReport(userId: $userId) {
      ...creditReportFragment
    }
  }
  ${creditReportFragment}
`;

export const runCreditReportMutation = gql`
  mutation rerunCreditReport($input: RunCreditCheckInput!) {
    runCreditCheck(input: $input) {
      ...creditReportFragment
    }
  }
  ${creditReportFragment}
`;
