import React from 'react';
import {
  ErrorContainer,
  Text,
  Title,
  StyledButton,
} from './DiscoveredVehicles.style';

interface Props {
  error: Error;
  onRetry: () => void;
}

const DiscoveryError: React.FC<Props> = ({ error, onRetry }) => {
  return (
    <ErrorContainer>
      <div>
        <Title>User discovery error</Title>
        <Text>{error.message}</Text>
      </div>
      <StyledButton variant="contained" color="primary" onClick={onRetry}>
        Retry
      </StyledButton>
    </ErrorContainer>
  );
};

export default DiscoveryError;
