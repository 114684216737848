import { DateTime } from 'luxon';
import { convertKmToMiles } from '../../../../../helpers/formatHelpers';
import { UserTrips } from '../types';

interface TrackedTrips {
  trackedTripMiles: number;
  disputedTripMiles: number;
}

const getTrackedTrips = (
  data: UserTrips,
  startDate: DateTime,
  endDate: DateTime,
): TrackedTrips => {
  let trackedTripKm = 0;
  let disputedTripKm = 0;

  data.trips.forEach(trip => {
    const tripStart = DateTime.fromISO(trip.start, {
      zone: data.timezone,
    });

    if (tripStart >= startDate && tripStart <= endDate) {
      trackedTripKm += trip.distance;

      if (trip.disputeReason) {
        disputedTripKm += trip.distance;
      }
    }
  });

  const trackedTripMiles = convertKmToMiles(trackedTripKm);
  const disputedTripMiles = convertKmToMiles(disputedTripKm);

  return {
    trackedTripMiles,
    disputedTripMiles,
  };
};

export default getTrackedTrips;
