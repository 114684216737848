import React, { useState } from 'react';
import { CommunicationFilter } from '../../../../services/repository/communicationService';
import { getDefaultCommsOption } from '../FilterSelector/filterOptions';
import { FilterSelector } from '../FilterSelector/FilterSelector';
import * as S from './HistoryHeader.styled';

interface Props {
  filter: CommunicationFilter;
  onFilterChange: (filter: CommunicationFilter) => void;
}

export const HistoryHeader: React.FC<Props> = ({ filter, onFilterChange }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const { commsOption, dateOption } = filter;

  const handleClearCommsFilter = () => {
    onFilterChange({
      ...filter,
      commsOption: getDefaultCommsOption(),
    });
  };

  return (
    <S.Container>
      <S.Group>
        <S.DateFilterLabel>{dateOption.label}</S.DateFilterLabel>
        {commsOption.value !== 'all' ? (
          <S.CommsFilter onClick={handleClearCommsFilter}>
            <S.CommsLabel>{commsOption.label}</S.CommsLabel>
            <S.CrossImage src="/images/cross.svg" alt="" />
          </S.CommsFilter>
        ) : null}
      </S.Group>

      <S.FilterGroup onClick={() => setFilterOpen(true)}>
        <S.FilterImage src="/images/filter.svg" alt="" />
        <S.FilterLabel>Filters</S.FilterLabel>
      </S.FilterGroup>
      {filterOpen ? (
        <FilterSelector
          filter={filter}
          onFilterChange={onFilterChange}
          onClose={() => setFilterOpen(false)}
        />
      ) : null}
    </S.Container>
  );
};
