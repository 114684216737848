import { Organisation, OrganisationType } from '../../../graphql/api';

export const organisationInitialValues = (organisation?: Organisation) => {
  return {
    name: organisation?.name || '',
    type: (organisation?.type as OrganisationType) || '',
    addressLine1: organisation?.addressLine1 || '',
    addressLine2: organisation?.addressLine2 || '',
    city: organisation?.city || '',
    state: organisation?.state || '',
    zipCode: organisation?.zipCode || '',
    website: organisation?.website || '',
    fein: organisation?.fein || '',
    parentName: organisation?.parentName || '',
  };
};
