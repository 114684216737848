import React from 'react';
import { DetailedInfoTile } from './shared/DetailedInfoTile';
import numeral from 'numeral';
import { formatBalance } from '../../helpers/formatHelpers';
import { TileShell } from './shared/TileShell';

interface Props {
  balance?: number;
  funds?: number;
  deposit: number;
  isLoading: boolean;
  className?: string;
}

const formatValue = (value?: number): string => {
  return value !== undefined
    ? `${numeral(formatBalance(value)).format('$0,0.00')}`
    : 'No data';
};

const BalanceInfoTile: React.FC<Props> = ({
  isLoading,
  balance,
  funds,
  deposit,
  className,
}) => {
  return (
    <TileShell title="User Balance" className={className}>
      <DetailedInfoTile
        isLoading={isLoading}
        title={formatValue(balance)}
        isLargeTitle
        infoItems={[
          {
            title: 'Deposit',
            value: formatValue(deposit),
          },
          {
            title: 'Total Funds',
            value: formatValue(funds),
          },
        ]}
      />
    </TileShell>
  );
};

export default BalanceInfoTile;
