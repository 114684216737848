import { GetUserPaymentMethodsQuery } from '../../../graphql/types';
import { Wallet } from './types';

export const useGetUserPaymentMethodsSelector = ({
  user,
}: GetUserPaymentMethodsQuery): Wallet => {
  return {
    autoPay: {
      token: user.wallet.automaticPayments?.payment?.token,
      isEnabled: user.wallet.automaticPayments?.isEnabled ?? false,
    },
    paymentMethods: user.wallet.paymentMethods,
  };
};
