import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import React from 'react';
import {
  IconContainer,
  ImageContainer,
  OdometerImage,
} from './Components.style';

type Props = {
  imgSource?: string;
  onImageClick: () => void;
};

const OdometerImageWithHover: React.FC<Props> = ({
  imgSource,
  onImageClick,
}) => {
  const [isHovering, setHover] = React.useState(false);

  return (
    <ImageContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onImageClick}
    >
      {isHovering ? (
        <IconContainer>
          <ZoomOutMapIcon color="inherit" />
        </IconContainer>
      ) : null}
      <OdometerImage src={imgSource} />
    </ImageContainer>
  );
};

export default OdometerImageWithHover;
