import {
  InputContainer,
  Reading,
  SaveButton,
  StyledTextInput,
} from './Components.style';
import React from 'react';
import { TallyUpOdometer } from '../../../../services/repository/odometerService';
import { OdometerStatus } from '@just-insure/api';

type Props = {
  odometer: TallyUpOdometer;
  value?: number | null;
  onValueChange: (newValue: number) => void;
  onSave: () => void;
  disabled?: boolean;
};

const OdometerReading: React.FC<Props> = ({
  odometer,
  value,
  onValueChange,
  onSave,
  disabled,
}) => {
  const status = odometer.status;

  return (
    <Reading.Row>
      <Reading.Group>
        <Reading.Entry>
          <Reading.Label>OCR</Reading.Label>
          <Reading.Label isValue>
            {odometer.readings.ocr || 'N/A'}
          </Reading.Label>
        </Reading.Entry>
        <Reading.Entry spaceFromLeft={16}>
          <Reading.Label>Submitted</Reading.Label>
          <Reading.Label isValue>
            {odometer.readings.user || 'N/A'}
          </Reading.Label>
        </Reading.Entry>
      </Reading.Group>
      <Reading.Entry maxSize>
        {status === OdometerStatus.Accepted ? (
          <>
            <Reading.FinalLabel variant="accepted">
              {odometer.statusAuthority === 'ocr'
                ? 'Auto accepted'
                : 'Accepted'}
            </Reading.FinalLabel>
            <Reading.FinalLabel variant="value">
              {odometer.readings.accepted}
            </Reading.FinalLabel>
          </>
        ) : null}
        {status === OdometerStatus.Rejected ? (
          <>
            <Reading.FinalLabel variant="rejected">rejected</Reading.FinalLabel>
          </>
        ) : null}
        {status === OdometerStatus.Pending ? (
          <>
            <Reading.Row moveToRight>
              <InputContainer>
                <StyledTextInput
                  type="number"
                  onChange={e =>
                    onValueChange(parseInt(e.currentTarget.value, 10))
                  }
                  value={value}
                />
              </InputContainer>
              <SaveButton
                variant="contained"
                color="primary"
                onClick={disabled ? undefined : onSave}
                disabled={disabled}
                data-testid="odometer-save-button"
              >
                SAVE
              </SaveButton>
            </Reading.Row>
          </>
        ) : null}
      </Reading.Entry>
    </Reading.Row>
  );
};

export default OdometerReading;
