import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 20px;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 300px
  border: .5px solid #E1E1FF;
  border-radius: 7.5px;
`;

export const FormItem = styled.div`
  margin: 10px;
`;

export const SubmitButton = styled.div`
  text-align: right;
  margin: 10px;
`;
