import * as yup from 'yup';
import { SalesPersonLevel } from '../../../graphql/api';

export default yup.object().shape({
  firstName: yup.string().trim().required('First name is required'),
  lastName: yup.string().trim().required('Last name is required'),
  phoneNumber: yup.string().trim().required('Phone number is required'),
  email: yup
    .string()
    .trim()
    .email('Invalid email address')
    .required('Email is required'),
  level: yup
    .mixed<SalesPersonLevel>()
    .oneOf(Object.values(SalesPersonLevel))
    .required('Level is required'),
  taxId: yup.string(),
  organisationId: yup.number().required('Organisation is required'),
});
