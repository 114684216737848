import {
  ContentTab,
  HeaderContainer,
  Headline,
  PreviewContainer,
} from './PreviewModal.styled';
import { Icon, IconButton, Tab, Tabs } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import { AccountEmail } from '../../../../../services/repository/communicationService';
import { AttachmentList } from '../AttachmentList/AttachmentList';

interface Props {
  email: AccountEmail;
  onOpenAttachment: (url: string) => void;
  onClose: () => void;
}

export const PreviewModal: React.FC<Props> = ({
  email,
  onOpenAttachment,
  onClose,
}) => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const handleTabChange = (_: React.ChangeEvent<{}>, index: number) => {
    setTabIndex(index);
  };

  const hasAttachments = !!(email.attachments && email.attachments.length > 0);
  return (
    <Modal open={true} onClose={() => onClose()}>
      <PreviewContainer>
        <HeaderContainer>
          <Headline>{`Preview email${
            hasAttachments ? ' and attachments' : ''
          }`}</Headline>
          <IconButton color="primary" onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </HeaderContainer>
        {hasAttachments ? (
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab label="Email" />
            <Tab label="Attachments" />
          </Tabs>
        ) : null}
        <ContentTab hasAttachment={hasAttachments} hidden={tabIndex !== 0}>
          <div dangerouslySetInnerHTML={{ __html: email.content! }} />
        </ContentTab>
        <ContentTab
          hasAttachment={hasAttachments}
          hidden={!hasAttachments || tabIndex !== 1}
        >
          {email.attachments ? (
            <AttachmentList
              attachments={email.attachments}
              onOpenAttachment={onOpenAttachment}
            />
          ) : null}
        </ContentTab>
      </PreviewContainer>
    </Modal>
  );
};
