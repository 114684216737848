import { SavedVehicle } from '@just-insure/api';
import { TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { ReferenceData } from '../../../../../types/interfaces';
import Form from '../../../../components/Form/Form';
import FormTextInput from '../../../../components/FormTextInput/FormTextInput';
import { Column, RowAction } from '../../../../components/Table/Table';
import styles, {
  CancelButton,
  SaveButton,
  StyleProps,
} from './rowTemplate.style';
import VinField from './VinField';
import schema from '../schema';
import { convertFieldError } from '../../../../helpers/serverErrToFormik';
import { ErrorCode, logError } from '../../../../services/logging';
import * as vehicleService from '../../../../services/repository/vehicleService';
import { AxiosError } from 'axios';

interface Props {
  classes: StyleProps;
  item: SavedVehicle;
  rowIndex: number;
  columns: Column[];
  rowActions?: RowAction[];
  referenceData?: ReferenceData;
  onCancel: () => void;
  onSaveVehicle: (vehicle: SavedVehicle) => Promise<void>;
}

const EditRowTemplate: React.FC<Props> = ({
  classes,
  item,
  onSaveVehicle,
  onCancel,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Form
      initialValues={item}
      validateOnMount={true}
      validateOnBlur={false}
      validationSchema={schema}
      submit={onSaveVehicle}
    >
      {({
        handleSubmit,
        isValid,
        values,
        resetForm,
        setValues,
        setErrors,
        setFieldTouched,
      }) => {
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({ vin: e.target.value }, false);
        };

        const onUpdate = (vin: string) => {
          if (vin.length === 17) {
            updateVehicleByVin(vin);
            setFieldTouched('vin');
          }
        };

        const updateVehicleByVin = async (vin: string) => {
          try {
            setIsLoading(true);
            const vehicle = await vehicleService.getVehicleByVin(vin);
            setValues({ ...vehicle, vin }, true);
          } catch (ex) {
            logError(ErrorCode.UserVehicle, ex as Error | AxiosError);
            setErrors(convertFieldError('vin', ex));
          } finally {
            setIsLoading(false);
          }
        };

        return (
          <TableRow className={classes.rowContainer}>
            <TableCell>
              <VinField
                value={values.vin}
                onChange={onChange}
                onUpdate={onUpdate}
                isLoading={isLoading}
              />
            </TableCell>
            <TableCell>
              <FormTextInput name="make" type="string" editable={false} />
            </TableCell>
            <TableCell>
              <FormTextInput name="model" type="string" editable={false} />
            </TableCell>
            <TableCell>
              <FormTextInput name="year" type="number" editable={false} />
            </TableCell>
            <TableCell />
            <TableCell>{item.isDefault ? 'Yes' : 'No'}</TableCell>
            <TableCell colSpan={2}>
              <CancelButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                disabled={!isValid}
                variant="outlined"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Save
              </SaveButton>
            </TableCell>
          </TableRow>
        );
      }}
    </Form>
  );
};

export default withStyles(styles)(EditRowTemplate);
