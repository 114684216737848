import { useGetUserPoliciesPricePerMileQuery } from '../../../../../graphql/api';
import { PolicyDetails } from '../types';
import { sortPoliciesByDate } from '../../../../../helpers/policyHelpers';
import { logError, ErrorCode } from '../../../../../services/logging';

export const useGetPolicyDetails = (userId: string) => {
  return useGetUserPoliciesPricePerMileQuery<PolicyDetails | undefined, Error>(
    { userId },
    {
      queryKey: ['user-policy-penalty', { userId }],
      onError: e => logError(ErrorCode.FiftyMileCharge, e),
      useErrorBoundary: false,
      select: ({ user }) => {
        const policies = sortPoliciesByDate(user.policies);
        const latestPolicy = policies[0];
        if (!latestPolicy) return undefined;

        return {
          policyNumber: latestPolicy.policyNumberDetails.value,
          premiumPerMile: latestPolicy.totalPremium.perMile,
        };
      },
    },
  );
};
