import React from 'react';
import { TileShell } from '../shared/TileShell';
import AccountStateContainer from './AccountStateContainer';
import { useAccountState } from '../../userManagement/accountState';

export interface Props {
  className?: string;
}

export const AccountState: React.FC<Props> = ({ className }) => {
  const { isLoading, error, data, getAccountState } = useAccountState();

  return (
    <TileShell title={'Account Status'} className={className}>
      <AccountStateContainer
        isLoading={isLoading}
        onRetry={getAccountState}
        errorMessage={error}
        title={data.accountState}
        actions={data.actions}
      />
    </TileShell>
  );
};
