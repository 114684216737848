import React from 'react';
import {
  OdometerEmptyReading,
  OdometerPendingReading,
  OdometerReading,
  OdometerRejectedReading,
  OdometerVoided,
} from '../../../components/OdometerReading/OdometerReading';
import { TallyUpOdometer } from '../../../services/repository/odometerService';
import { ImageButton } from './TallyUpOdometer.styled';
import { OdometerStatus } from '@just-insure/api';

interface Props {
  odometer: TallyUpOdometer;
  isTallyUpVoid?: boolean;
  onImageClick: (odometer: TallyUpOdometer) => void;
}

const getReading = (odometer: TallyUpOdometer): number | undefined | null => {
  const status = odometer.status;

  switch (status) {
    case OdometerStatus.Accepted:
      return odometer.readings.accepted;
    case OdometerStatus.Pending:
      return odometer.readings.user;
  }

  return odometer.reading;
};

const TallyUpOdometerComponent: React.FC<Props> = ({
  odometer,
  onImageClick,
  isTallyUpVoid = false,
}) => {
  const isRejected = odometer.status === OdometerStatus.Rejected;

  const reading = getReading(odometer);
  const isPending = odometer.status === OdometerStatus.Pending;

  if (isRejected) {
    return (
      <ImageButton onClick={() => onImageClick(odometer)}>
        <OdometerRejectedReading>REJECTED</OdometerRejectedReading>
      </ImageButton>
    );
  }

  if (reading) {
    let OdometerComponentType: React.FC = OdometerReading;
    if (isTallyUpVoid) {
      OdometerComponentType = OdometerVoided;
    }
    if (isPending) {
      OdometerComponentType = OdometerPendingReading;
    }

    return (
      <ImageButton onClick={() => onImageClick(odometer)}>
        <OdometerComponentType>{reading}</OdometerComponentType>
      </ImageButton>
    );
  }
  return (
    <OdometerEmptyReading onClick={() => onImageClick(odometer)}>
      - - - - -
    </OdometerEmptyReading>
  );
};

export default TallyUpOdometerComponent;
