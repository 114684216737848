import React from 'react';
import auditTrailApi from './api';
import { AuditHistoryItem, PageInfo } from './types';

interface Props {
  userId: string;
}

type ReducerState = {
  pageInfo: PageInfo;
  elementsPerPage: number;
  data: AuditHistoryItem[];
  isLoading: boolean;
  isError: boolean;
};

interface ContextType {
  state: ReducerState;
  getAuditTrail: () => Promise<void>;
}

const Context = React.createContext<ContextType | null>(null);

export const AuditTrailController: React.FC<Props> = ({ userId, children }) => {
  const [auditState, updateAuditState] = React.useReducer(
    (prevState: ReducerState, nextState: Partial<ReducerState>) => {
      return { ...prevState, ...nextState };
    },
    {
      pageInfo: {
        hasNextPage: true,
        endCursor: '',
      },
      isError: false,
      elementsPerPage: 10,
      data: [],
      isLoading: false,
    },
  );

  const getAuditTrail = async () => {
    if (!auditState.pageInfo.hasNextPage) {
      return;
    }

    updateAuditState({ isLoading: true });
    const result = await auditTrailApi.getAuditHistory(userId, {
      first: auditState.elementsPerPage,
      after: auditState.pageInfo.endCursor,
    });

    if (result.success) {
      updateAuditState({
        data: [...auditState.data, ...result.data.items],
        pageInfo: result.data.pageInfo,
        isLoading: false,
        isError: false,
      });
    } else {
      updateAuditState({
        isLoading: false,
        isError: true,
        data: [],
        pageInfo: { hasNextPage: true, endCursor: '' },
      });
    }
  };

  return (
    <Context.Provider
      value={{
        state: auditState,
        getAuditTrail,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAuditTrail = () => {
  const context = React.useContext(Context);
  if (context === null) {
    throw new Error('useAuditTrail must be used within a AuditTrailController');
  }
  return context;
};
