import { ButtonProps } from '@material-ui/core/Button';
import { connect, FormikProps } from 'formik';
import React from 'react';
import { ActionButton } from './UserActions.styled';

interface Props extends Omit<ButtonProps, 'disabled' | 'onClick'> {
  children: React.ReactNode;
  onClick?: (isSubmitting: boolean) => void;
  toggleEdit?: boolean;
  toggleState?: boolean;
}

const FormSubmitButton: React.FC<Props & { formik: FormikProps<any> }> = ({
  children,
  formik: { handleSubmit, isValid },
  onClick,
  toggleEdit = false,
  toggleState,
  ...rest
}) => {
  const isActive = (toggleEdit && !toggleState) || isValid;
  return (
    <ActionButton
      variant="contained"
      color="primary"
      {...rest}
      disabled={!isActive}
      onClick={() => {
        if (toggleEdit && !toggleState && onClick) {
          return onClick(false);
        }

        handleSubmit();
        if (onClick) {
          onClick(true);
        }
      }}
    >
      {children}
    </ActionButton>
  );
};

export default connect<Props, any>(FormSubmitButton);
