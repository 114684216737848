import { matchPath } from 'react-router-dom';

export const Destinations = {
  User: '/v2/users/:userId',
} as const;

export type Destination = keyof typeof Destinations;

/**
 * Utility functions
 */
export const getDestinationFromPathname = (
  pathname: string,
): Destination | undefined =>
  (Object.keys(Destinations) as Destination[]).find(destination =>
    matchPath(pathname, { path: Destinations[destination], exact: true }),
  );
