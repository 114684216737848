import Box from '@material-ui/core/Box';
import React from 'react';
import { Table } from '../../../../components/Table';
import { Text } from './DiscoveredVehicles.style';
import { vehicleListColumnDefs } from './vehicleListColumnDefs';

export interface Vehicle {
  vin: string;
  plate?: string;
  make?: string;
  model?: string;
  year?: number;
}

interface Props {
  header?: string;
  vehicles: Vehicle[];
}
const VehiclesTable: React.FC<Props> = ({ header, vehicles = [] }) => {
  return (
    <Box paddingBottom={4}>
      {header ? <Text>{header}</Text> : null}
      <Table
        disableGutters
        disableElevation
        disablePagination
        columns={vehicleListColumnDefs}
        data={vehicles}
      />
    </Box>
  );
};

export default VehiclesTable;
