import { SelectOption } from '../../../components/FormSelect/FormSelect';
import { GetOrganisationsQuery } from '../../../graphql/types';

export const useGetOrganisationsSelectOptions = ({
  organisations,
}: GetOrganisationsQuery): SelectOption[] => {
  return [...organisations]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(organisation => {
      return {
        label: organisation.name,
        value: organisation.id.toString(),
      };
    });
};
