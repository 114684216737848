import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 25px;
`;

export const ActionTilesWrapper = styled.div`
  margin-bottom: 25px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EditButton = styled(Button)`
  && {
    min-width: 30px;
    padding: 7px 7px 7px 15px;
    height: 30px;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin-bottom: 10px;
    margin-left: 5px;
    position: 'absolute';
  }
`;

export const ButtonColumn = styled.div`
  margin-bottom: 12px;
`;

export const ActionButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 30px;

  > button:not(.MuiButton-containedPrimary) {
    background-color: ${({ theme }) => theme.palette.primary.darkGrey};
    border: none;
    box-shadow: none;
    color: ${({ theme }) => theme.typography.body1.color};
    font-size: ${({ theme }) => theme.typography.body1.fontSize}px;
    margin-right: 20px;

    :last-child {
      margin-right: 0;
    }
  }

  > button.MuiButton-containedPrimary {
    border: none;
    box-shadow: none;
    font-size: ${({ theme }) => theme.typography.body1.fontSize}px;
    margin-right: 20px;

    :last-child {
      margin-right: 0;
    }
  }
`;
