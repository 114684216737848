export const tallyUpColumnDefs = [
  {
    title: 'User Id',
    id: 'user.id',
    sortable: false,
  },
  {
    title: 'Date',
    id: 'startOdometer.createdAt',
    sortable: false,
  },
  {
    title: 'Start Photo',
    id: 'startOdometer.reading',
    sortable: false,
  },
  {
    title: 'End Photo',
    id: 'endOdometer.reading',
    sortable: false,
  },
  {
    title: 'Note',
    id: 'note',
    sortable: false,
  },
  {
    title: '',
    id: 'spacer',
    sortable: false,
  },
];
