import { Vehicle } from '@just-insure/api';
import { CircularProgress, Modal } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import FormCheckBox from '../../../../components/FormCheckBox/FormCheckBox';
import Input from './components/Input';
import RadioButton, { Selection } from './components/RadioButton';
import {
  ActionButtonContainer,
  Content,
  InputContainer,
  ModalButton,
  ModalContainer,
  Paper,
  RadioContainer,
  Title,
} from './LienHolderModal.styled';
import lienHolderSchema from './utils/schema';

type Props = {
  isOpen: boolean;
  isLoading?: boolean;
  selectedVehicle: Vehicle;
  onClose: () => void;
  onLienHolderUpdate: (LienHolderInfo: Vehicle, updatePolicy: boolean) => void;
};

type FormikValues = {
  name: string;
  email: string;
  phone: string;
  updatePolicy: boolean;
};

const LienHolderModal: React.FC<Props> = ({
  isOpen,
  isLoading,
  onClose,
  onLienHolderUpdate,
  selectedVehicle,
}) => {
  const [mode, setMode] = React.useState<Selection>(null);

  function onSubmit(values: FormikValues) {
    if (!mode) {
      return;
    }

    const payload = {
      vin: selectedVehicle.vin,
      makeId: selectedVehicle.makeId,
      modelId: selectedVehicle.modelId,
      year: selectedVehicle.year,
      lienholder: {
        name: values.name,
        email: values.email,
        phone: values.phone,
      },
    };

    if (mode === 'remove') {
      // TODO fix
      delete (payload as any).lienholder;
    }
    onLienHolderUpdate(payload, values.updatePolicy);
  }

  return (
    <Modal onClose={onClose} open={isOpen}>
      <ModalContainer>
        <Paper>
          <Content>
            <Title>Lienholder information</Title>
            <RadioContainer>
              <RadioButton
                selected={mode}
                value="edit"
                onChange={setMode}
                label="Edit"
              />
              <RadioButton
                label="Remove"
                selected={mode}
                value="remove"
                onChange={setMode}
              />
            </RadioContainer>
            <Formik
              initialValues={{
                name: selectedVehicle?.lienholder?.name || '',
                email: selectedVehicle?.lienholder?.email || '',
                phone: selectedVehicle?.lienholder?.phone || '',
                updatePolicy: false,
              }}
              validationSchema={lienHolderSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                touched,
              }) => {
                return (
                  <>
                    <InputContainer>
                      <Input
                        onBlur={handleBlur('name')}
                        error={touched.name ? errors.name : undefined}
                        disabled={mode !== 'edit'}
                        value={values.name}
                        onChange={handleChange('name')}
                        label="Name"
                      />
                      <Input
                        onBlur={handleBlur('email')}
                        error={touched.email ? errors.email : undefined}
                        disabled={mode !== 'edit'}
                        value={values.email}
                        onChange={handleChange('email')}
                        label="Email"
                        type="email"
                      />
                      <Input
                        onBlur={handleBlur('phone')}
                        error={touched.phone ? errors.phone : undefined}
                        disabled={mode !== 'edit'}
                        value={values.phone}
                        onChange={handleChange('phone')}
                        label="Phone number"
                      />
                      <FormCheckBox
                        disabled={mode !== 'edit'}
                        name="updatePolicy"
                        label="Update current active policy"
                      />
                    </InputContainer>
                    <ActionButtonContainer>
                      {isLoading ? (
                        <CircularProgress size={32} color="primary" />
                      ) : (
                        <ModalButton
                          onClick={() => {
                            if (mode === 'remove') {
                              onSubmit({} as FormikValues);
                            } else {
                              handleSubmit();
                            }
                          }}
                          disabled={!mode}
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </ModalButton>
                      )}
                      <ModalButton
                        disabled={isLoading}
                        onClick={() => onClose()}
                      >
                        Cancel
                      </ModalButton>
                    </ActionButtonContainer>
                  </>
                );
              }}
            </Formik>
          </Content>
        </Paper>
      </ModalContainer>
    </Modal>
  );
};

const ModalWrapper = (props: Props) => {
  if (!props.isOpen) {
    return null;
  }
  return <LienHolderModal {...props} />;
};

export default ModalWrapper;
