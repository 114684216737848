import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormModal from '../../../components/FormModal/FormModal';
import { ReferralModal } from '../../../../types/interfaces';
import schema from '../schema/organisationSchema';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import FormSelect from '../../../components/FormSelect/FormSelect';
import {
  useAddOrganisationMutation,
  OrganisationInput,
} from '../../../graphql/api';
import { ErrorCode, logError } from '../../../services/logging';
import { organisationInitialValues } from '../helpers/initialValues';
import { organisationTypeOptions } from '../helpers/selectOptions';

const AddOrganisationModal: React.FC<ReferralModal> = ({
  isOpen,
  onSave,
  onClose,
}) => {
  const { isLoading, isError, error, mutate, reset } =
    useAddOrganisationMutation({
      onSuccess: onSave,
      onError: (e: Error) => logError(ErrorCode.ReferralCreateOrganisation, e),
    });

  const onSubmit = async (organisation: OrganisationInput) => {
    mutate({ organisation });
  };

  return (
    <FormModal
      isOpen={isOpen}
      isLoading={isLoading}
      isError={isError}
      onSubmit={onSubmit}
      onClose={() => {
        reset();
        onClose();
      }}
      initialValues={organisationInitialValues()}
      validationSchema={schema}
      error={error}
      title="Add a new organisation"
    >
      <FormSelect
        name="type"
        options={organisationTypeOptions}
        editable
        label="Type"
      />
      <FormTextInput name="name" editable label="Organisation Name" />
      <FormTextInput name="website" editable label="Website" />
      <FormTextInput name="addressLine1" editable label="Address 1" />
      <FormTextInput
        name="addressLine2"
        editable
        label="Address 2 (optional)"
      />
      <FormTextInput name="city" editable label="City" />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormTextInput
            name="state"
            editable
            label="State"
            inputProps={{ maxLength: 2 }}
          />
        </Grid>
        <Grid item xs={8}>
          <FormTextInput name="zipCode" editable label="Zipcode" />
        </Grid>
      </Grid>

      <FormTextInput name="fein" editable label="FEIN (optional)" />
      <FormTextInput
        name="parentName"
        editable
        label="Parent Company Name (optional)"
      />
    </FormModal>
  );
};

export default AddOrganisationModal;
