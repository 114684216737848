import React from 'react';
import { Container } from './UserDetailsV2.styled';
import BalanceFinalisation from './BalanceFinalisation/BalanceFinalisation';
import TakeFinalPayment from './TakePayment/TakeFinalPayment/TakeFinalPayment';
import TakePaymentCustomAmount from './TakePayment/TakePaymentCustomAmount/TakePaymentCustomAmount';
import { Feature, isFeatureEnabled } from '../../../config';
import ObdDeactivateButton from '../Obd/ObdTile/ObdDeactivateButton';

interface Props {
  userId: string;
}

const UserDetailsV2: React.FC<Props> = ({ userId }) => {
  return (
    <Container>
      {isFeatureEnabled(Feature.BalanceFinalisation) ? (
        <BalanceFinalisation userId={userId} />
      ) : null}
      {isFeatureEnabled(Feature.TakePayment) ? (
        <>
          <TakeFinalPayment userId={userId} />
          <TakePaymentCustomAmount userId={userId} />
        </>
      ) : null}
      <ObdDeactivateButton />
    </Container>
  );
};

export default UserDetailsV2;
