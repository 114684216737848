import Sidebar from '../../../../../components/Sidebar';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';

type Props = {
  userId: string;
};

const UserSidebar: React.FC<Props> = ({ userId }) => (
  <Sidebar>
    <div className="text-sm text-grey-dark border-grey-accent border-b pb-6 mb-6">
      <span className="font-circular text-grey-pale">User id:</span> #{userId}
    </div>
    <div className="flex">
      <div className="flex items-center text-blue font-circular text-sm font-bold">
        <ShieldCheckIcon className="w-6 h-6 mr-2" /> Policy
      </div>
    </div>
  </Sidebar>
);

export default UserSidebar;
