import Typography from '@material-ui/core/Typography';
import React, { memo, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ErrorCode, logError } from '../../../services/logging';
import ErrorModal from '../../../components/ErrorModal/ErrorModal';
import TabsList from '../../../components/TabsList/TabsList';
import { sendPageview } from '../../../services';
import {
  Container,
  HeaderContainer,
  Row,
  UserV2Icon,
  UserV2Button,
} from './UserDetails.styled';
import Intercom from './Intercom';
import Braintree from './Braintree';
import UserDetailsV1 from '../UserDetailsV1/UserDetailsV1';
import UserDetailsV2 from '../UserDetailsV2/UserDetailsV2';
import { AxiosError } from 'axios';
import { ClientError as GraphQLRequestError } from 'graphql-request';
import { ObdController } from '../Obd';
import { FeatureFlagsController } from '../featureFlags';
import { AccountStateController, SuspendedIndicator } from '../accountState';
import { DuplicateAccountController } from '../DuplicateAccount';
import { AuditTrailTab } from '../auditTrail';
import UserVerificationTab from '../userVerification';
import { UserDetailsController } from './Controller';
import { Feature, isFeatureEnabled } from '../../../config';

interface MatchParams {
  userId: string;
}

type Props = RouteComponentProps<MatchParams>;

const UserDetails: React.FC<Props> = ({ location, match, history }) => {
  const { userId } = match.params;
  const [error, setError] = useState<Error>();

  const onError = (
    err: Error | AxiosError | GraphQLRequestError,
    errorCode: ErrorCode,
  ) => {
    logError(errorCode, err);
    setError(err);
  };

  useEffect(() => {
    if (Number.isNaN(Number.parseInt(userId, 10))) {
      setError(
        new Error('User id introduced is not a valid user id: not a number'),
      );
    }

    sendPageview(location.pathname);
  }, [location.pathname, userId]);

  const tabs = [
    {
      label: 'Current page',
      component: <UserDetailsV1 userId={userId} onError={onError} />,
    },
    {
      label: 'New page (v2)',
      component: <UserDetailsV2 userId={userId} />,
    },
  ];

  tabs.push({
    label: 'User Verification',
    component: <UserVerificationTab userId={userId} />,
  });

  tabs.push({
    label: 'Audit Trail',
    component: <AuditTrailTab userId={userId} />,
  });

  return (
    <FeatureFlagsController userId={userId}>
      <UserDetailsController userId={userId}>
        <AccountStateController userId={userId}>
          <ObdController userId={userId}>
            <DuplicateAccountController userId={userId}>
              <Container>
                <HeaderContainer>
                  <SuspendedIndicator>
                    <Typography variant="h4">{`User Details - ID ${userId}`}</Typography>
                    {isFeatureEnabled(Feature.UserPageV2) ? (
                      <UserV2Button
                        onClick={() => history.push(`/v2/users/${userId}`)}
                      >
                        <UserV2Icon />
                        Try new layout
                      </UserV2Button>
                    ) : null}
                  </SuspendedIndicator>
                  <Row>
                    <Intercom userId={userId} onError={onError} />
                    <Braintree userId={userId} />
                  </Row>
                </HeaderContainer>
                <TabsList tabs={tabs} size="small" isFlush />
              </Container>
              <ErrorModal error={error} onClose={() => setError(undefined)} />
            </DuplicateAccountController>
          </ObdController>
        </AccountStateController>
      </UserDetailsController>
    </FeatureFlagsController>
  );
};

export default memo(withRouter(UserDetails));
