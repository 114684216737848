import { AccountState } from './types';
import { AccountStateFragment } from '../../../../graphql/types';

export const mapAccountState = (
  accountState: AccountStateFragment | undefined | null,
): AccountState => {
  switch (accountState?.__typename) {
    case 'ActiveState':
      return AccountState.Active;
    case 'LapsedState':
      return AccountState.Lapsed;
    case 'RegistrationState':
      return AccountState.Registration;
    case 'SuspendedState':
      return AccountState.Suspended;
    default:
      return AccountState.NoState;
  }
};
