import React from 'react';
import * as S from './VerifyAccountButton.styled';
import { useDuplicateAccount } from '../../Controller';
import { ControllerStatus } from '../../Controller/types';

const VerifyAccountButton: React.FC = () => {
  const { state, validateUserStatus, isFeatureEnabled } = useDuplicateAccount();
  const isDisabled =
    state.status === ControllerStatus.Loading ||
    state.status === ControllerStatus.Processing;

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <S.ActionButton
      onClick={validateUserStatus}
      color="primary"
      variant="contained"
      disabled={isDisabled}
    >
      VALIDATE USER STATUS
    </S.ActionButton>
  );
};

export default VerifyAccountButton;
