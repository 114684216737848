import React from 'react';
import * as S from './SuspendedIndicator';
import { useAccountState } from '../../Controller';
import { AccountState } from '../../Controller/types';

const SuspendedIndicator: React.FC = ({ children }) => {
  const { data } = useAccountState();

  return (
    <S.Row>
      {children}
      {data.accountState === AccountState.Suspended ? (
        <S.Indicator>
          <S.FlagIcon src="/images/flag.svg" alt="" />
          Blocked
        </S.Indicator>
      ) : null}
    </S.Row>
  );
};

export default SuspendedIndicator;
