import { RegistrationResponse } from '@just-insure/api';
import { CircularProgress, Modal } from '@material-ui/core';
import TocIcon from '@material-ui/icons/Toc';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Maybe } from '../../../../../types/interfaces';
import ErrorModal from '../../../../components/ErrorModal/ErrorModal';
import { convertCase } from '../../../../helpers/convertCase';
import { ErrorCode, logError } from '../../../../services/logging';
import { getUserRegistrationProgress } from '../../../../services/repository/userService';
import * as S from './UserProgress.style';
import { ActionButton } from '../UserActions.styled';

interface Props {
  userId: string;
}

const UserProgress: React.FC<Props> = ({ userId }) => {
  const [error, setError] = useState<Maybe<AxiosError>>();
  const [modalShown, setModalShown] = useState(false);
  const [progress, setProgress] = useState<RegistrationResponse>();

  useEffect(() => {
    const getProgress = async () => {
      try {
        const response = await getUserRegistrationProgress(userId);
        setProgress(response);
      } catch (ex: any) {
        logError(ErrorCode.UserDetails, ex);
        setModalShown(false);
        setError(ex);
      }
    };

    getProgress();
  }, [userId]);

  return (
    <>
      <ActionButton
        variant="contained"
        onClick={() => setModalShown(true)}
        startIcon={<TocIcon />}
      >
        Registration Process
      </ActionButton>
      <Modal open={modalShown} onClose={() => setModalShown(false)}>
        <S.ModalContent>
          <S.Content>
            <S.Heading>Customer Registration Progress</S.Heading>
            {progress ? (
              <S.ProgressList>
                {Object.keys(progress).map(key => (
                  <S.ProgressItem key={key}>
                    <S.Label>{convertCase(key)}</S.Label>
                    {/* TODO Fix implicit any */}
                    <S.Value>{convertCase((progress as any)[key])}</S.Value>
                  </S.ProgressItem>
                ))}
              </S.ProgressList>
            ) : (
              <CircularProgress size={40} />
            )}
            <S.StyledButton
              variant="contained"
              color="primary"
              onClick={() => setModalShown(false)}
            >
              Close
            </S.StyledButton>
          </S.Content>
        </S.ModalContent>
      </Modal>
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </>
  );
};

export default UserProgress;
