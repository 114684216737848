import { Vehicle } from '../../../../../services/repository/policyService';
import UploadOdometerForm from './UploadOdometerForm';

type Props = {
  userId: string;
  primaryVehicle: Vehicle;
  onClose: () => void;
};

const ModalContent: React.FC<Props> = ({ userId, primaryVehicle, onClose }) => {
  return (
    <UploadOdometerForm
      userId={userId}
      onClose={onClose}
      primaryVehicle={primaryVehicle}
    />
  );
};

export default ModalContent;
