import { AppBar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { AxiosError } from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Maybe, OdometerImageRequest } from '../../../../types/interfaces';
import ErrorModal from '../../../components/ErrorModal/ErrorModal';
import OdometerImageViewer from '../../../components/OdometerImageViewer/OdometerImageViewer';
import { Table } from '../../../components/Table';
import { TallyUpStatus } from '@just-insure/api';
import { sendPageview } from '../../../services';
import { ErrorCode, logError } from '../../../services/logging';
import * as odometerService from '../../../services/repository/odometerService';
import { TallyUp } from '../../../services/repository/odometerService';
import TallyTile from '../TallyTile/TallyTile';
import { tallyUpColumnDefs } from './tallyUpColumnDefs';
import {
  Container,
  PendingHeader,
  TallyTileContainer,
  UserContent,
} from './TallyUpListing.styled';
import TallyUpRowTemplate from './TallyUpRowTemplate';
import { sortTallyUpDateToByOldestToNewest } from '../../../services/repository/odometerService';

type Props = RouteComponentProps & {};

const TallyUpListing: React.FC<Props> = ({ location, history }) => {
  const [error, setError] = useState<Maybe<AxiosError>>();
  const [selectedReading, setSelectedReading] =
    useState<OdometerImageRequest>();
  const [tallyUps, setTallyUps] = useState<TallyUp[]>([]);

  useEffect(() => {
    const getTallyUps = async () => {
      try {
        const tallyUpsResult = await odometerService.getTallyups({
          status: TallyUpStatus.Pending,
        });
        setTallyUps(tallyUpsResult.sort(sortTallyUpDateToByOldestToNewest));
      } catch (ex: any) {
        logError(ErrorCode.TallyUp, ex);
        setError(ex);
      }
    };

    getTallyUps();
    sendPageview(location.pathname);
  }, [location]);

  const onVerify = (tallyUp: TallyUp) => {
    history.push(`/users/${tallyUp.userId}/tallyups/${tallyUp.id}`);
  };
  const onNavigateToUser = (userId: number) => {
    history.push(`/users/${userId}`);
  };

  return (
    <Container>
      <Typography variant="h4">Tally up odometer readings</Typography>

      <TallyTileContainer>
        <TallyTile text="Pending actions" value={tallyUps.length} />
      </TallyTileContainer>

      <UserContent>
        <AppBar position="static">
          <PendingHeader>PENDING ACTIONS</PendingHeader>
        </AppBar>
        <Table
          data={tallyUps}
          total={tallyUps.length}
          columns={tallyUpColumnDefs}
          rowTemplate={p => (
            <TallyUpRowTemplate
              {...p}
              key={p.item.id}
              onUserClick={onNavigateToUser}
              onImageClick={setSelectedReading}
              onVerify={onVerify}
            />
          )}
        />
      </UserContent>
      {selectedReading ? (
        <OdometerImageViewer
          userId={selectedReading.userId}
          readingId={selectedReading.readingId}
          visible
          onClose={() => setSelectedReading(undefined)}
        />
      ) : null}
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </Container>
  );
};

export default memo(withRouter(TallyUpListing));
