import styled from 'styled-components';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

export const Dial = styled(SpeedDial)`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;

export const DialIcon = styled(SpeedDialIcon)`
  && {
    height: 21px;
  }
`;

/*
`FabProps` on `SpeedDialAction` does not work, so we're setting the colors with CSS instead.
*/
export const Action = styled(SpeedDialAction)`
  && {
    background: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.primary.white};
    &:hover {
      background: ${({ theme }) => theme.palette.secondary.dark};
    }
  }
`;
