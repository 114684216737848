import { TallyUpOdometer } from '../../../../../services/repository/odometerService';
import { OdometerStatus } from '@just-insure/api';

const getLatestActiveOdometer = (
  odometers: TallyUpOdometer[],
): TallyUpOdometer | undefined => {
  const policyAcceptedOdometers = odometers.filter(
    odometer => odometer.status === OdometerStatus.Accepted,
  );

  return policyAcceptedOdometers[0];
};

export default getLatestActiveOdometer;
