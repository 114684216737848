import React from 'react';
import {
  CenterContentContainer,
  ErrorText,
} from '../UserVerificationScreen.styled';
import Button from '@material-ui/core/Button';
import { theme } from '../../../../../assets/theme';

type Props = {
  onRetry: () => void;
  title?: string;
  body?: string;
};

const ErrorContent: React.FC<Props> = ({
  onRetry,
  title = 'Something went wrong... Please try again',
  body,
}) => {
  return (
    <CenterContentContainer>
      <ErrorText>{title}</ErrorText>
      {body ? <p color={theme.palette.primary.red}>{body}</p> : null}
      <Button variant="outlined" color="primary" onClick={() => onRetry()}>
        Retry
      </Button>
    </CenterContentContainer>
  );
};

export default ErrorContent;
