export type FilterDateValue = 'all' | 'past30' | 'pastyear';
export type FilterCommsValue = 'all' | 'expiring' | 'negative' | 'odometer';

export interface FilterDateOption {
  label: string;
  value: FilterDateValue;
}

export interface FilterCommsOption {
  label: string;
  value: FilterCommsValue;
}

export const filterDateOptions: FilterDateOption[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Past 30 days',
    value: 'past30',
  },
  {
    label: 'Past year',
    value: 'pastyear',
  },
];

export const filterCommsOptions: FilterCommsOption[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Expiring policy',
    value: 'expiring',
  },
  {
    label: 'Negative balance',
    value: 'negative',
  },
  {
    label: 'Odometer reading',
    value: 'odometer',
  },
];

export const getDefaultDateOption = (): FilterDateOption => {
  const option = filterDateOptions.find(({ value }) => value === 'all');
  if (!option) {
    throw new Error('Missing default date filter');
  }
  return option;
};

export const getDefaultCommsOption = (): FilterCommsOption => {
  const option = filterCommsOptions.find(({ value }) => value === 'all');
  if (!option) {
    throw new Error('Missing default comms filter');
  }
  return option;
};
