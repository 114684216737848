import React, { memo, useEffect, useState, ChangeEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { sendPageview } from '../../../services';
import {
  Container,
  SearchTextField,
  SearchRow,
  SearchContainer,
  SearchTypeField,
  Loading,
} from './UserSearch.styled';
import { Select, MenuItem, InputLabel, Button } from '@material-ui/core';
import UserList from './UserList';
import PolicyList from './PolicyList';
import LogInToJustWebsiteModal from '../LogInToJustWebsite/LogInToJustWebsiteModal';

enum SearchType {
  Users = 'Users',
  Policies = 'Policies',
}

const UserSearch: React.FC<RouteComponentProps> = ({ location, history }) => {
  const [isLoading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState<SearchType>(SearchType.Users);
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  useEffect(() => {
    sendPageview(location.pathname);
  }, [location]);

  const getSearchLabel = () => {
    switch (searchType) {
      case SearchType.Users:
        return 'Search by name or email';
      case SearchType.Policies:
        return 'Search by policy number';
      default:
        return '';
    }
  };

  const navigateToUserPage = (userId: string) => {
    history.push(`/users/${userId}`);
  };

  return (
    <Container>
      <SearchRow>
        <SearchContainer>
          <SearchTextField
            autoFocus
            label={getSearchLabel()}
            variant="outlined"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(event.target.value)
            }
            InputLabelProps={{ shrink: true }}
          />
          <SearchTypeField>
            <InputLabel variant="outlined">Search type</InputLabel>
            <Select
              label="Search type"
              value={searchType}
              variant="outlined"
              onChange={event =>
                setSearchType(event.target.value as SearchType)
              }
            >
              {Object.values(SearchType).map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </SearchTypeField>
          {isLoading ? <Loading /> : null}
        </SearchContainer>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setLoginModalOpen(true)}
          >
            Log in to website as customer
          </Button>
        </div>
      </SearchRow>
      {searchType === SearchType.Users ? (
        <UserList
          searchTerm={searchTerm}
          navigateToUserPage={navigateToUserPage}
          setSearchLoading={setLoading}
        />
      ) : null}
      {searchType === SearchType.Policies ? (
        <PolicyList
          searchTerm={searchTerm}
          navigateToUserPage={navigateToUserPage}
          setSearchLoading={setLoading}
        />
      ) : null}
      {loginModalOpen ? (
        <LogInToJustWebsiteModal onClose={() => setLoginModalOpen(false)} />
      ) : null}
    </Container>
  );
};

export default memo(withRouter(UserSearch));
