import { Salesperson } from '../../../../types/interfaces';
import {
  GetSalesPersonQuery,
  SalesPersonInput,
  SalesPersonLevel,
} from '../../../graphql/types';

export const useGetSalespersonSelector = ({
  salesPerson,
}: GetSalesPersonQuery): Salesperson => {
  return {
    id: salesPerson?.id || 0,
    firstName: salesPerson?.firstName || '',
    lastName: salesPerson?.lastName || '',
    email: salesPerson?.email || '',
    level: salesPerson?.level || '',
    organisationName: salesPerson?.organisation?.name || '',
    organisationId: salesPerson?.organisation?.id || 0,
    taxId: salesPerson?.taxId || '',
    personalCode: salesPerson?.personalCode || '',
    phoneNumber: salesPerson?.phoneNumber || '',
  };
};

export const mapSalespersonToSalespersonInput = (
  salesPerson: Salesperson,
): SalesPersonInput => {
  return {
    firstName: salesPerson?.firstName || '',
    lastName: salesPerson?.lastName || '',
    email: salesPerson?.email || '',
    level: salesPerson?.level as SalesPersonLevel,
    organisationId: Number.parseInt(salesPerson.organisationId.toString(), 10),
    taxId: salesPerson?.taxId || '',
    phoneNumber: salesPerson?.phoneNumber || '',
  };
};
