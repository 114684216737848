import React from 'react';
import { useUserVerificationController } from '../Controller';
import UserVerificationScreen from './UserVerificationScreen';
import ErrorContent from './components/ErrorContent';
import LoadingContent from './components/LoadingContent';

const IdentityCheckScreen: React.FC = () => {
  const {
    userTimezone,
    drivingHistory: {
      state: drivingHistoryState,
      runDrivingHistoryCheck,
      getDrivingHistoryReport,
    },
    creditCheck: { state: creditState, runCreditCheck, getCreditReports },
  } = useUserVerificationController();

  if (userTimezone.error) {
    return (
      <ErrorContent
        title="Something went wrong, please try again"
        body={userTimezone.error.message}
        onRetry={userTimezone.refetch}
      />
    );
  }
  if (creditState.type === 'error') {
    return (
      <ErrorContent
        title={'Something went wrong, please try again'}
        body={creditState.message}
        onRetry={getCreditReports}
      />
    );
  }

  if (drivingHistoryState.report.error) {
    return (
      <ErrorContent
        title={'Something went wrong, please try again'}
        body={drivingHistoryState.report.error}
        onRetry={getDrivingHistoryReport}
      />
    );
  }

  if (drivingHistoryState.status.error) {
    return (
      <ErrorContent
        title={'Something went wrong, please try again'}
        body={drivingHistoryState.status.error.message}
        onRetry={drivingHistoryState.status.refetch}
      />
    );
  }

  if (
    userTimezone.isLoading ||
    creditState.type === 'loading' ||
    drivingHistoryState.report.isLoading ||
    drivingHistoryState.status.isLoading
  ) {
    return <LoadingContent />;
  }

  if (!userTimezone.timezone) {
    return (
      <ErrorContent
        title="Something went wrong, please try again"
        body="Failed to get user timezone"
        onRetry={userTimezone.refetch}
      />
    );
  }

  if (!drivingHistoryState.status.data) {
    return (
      <ErrorContent
        title="Something went wrong, please try again"
        body="Failed to get user driving history status"
        onRetry={drivingHistoryState.status.refetch}
      />
    );
  }

  return (
    <UserVerificationScreen
      userTimezone={userTimezone.timezone}
      isCreditCheckAvailable={creditState.data.isCreditCheckAvailable}
      onRunCreditCheck={runCreditCheck}
      onRunDrivingHistoryCheck={runDrivingHistoryCheck}
      creditHistoryItems={
        creditState.data.isCreditCheckAvailable
          ? creditState.data.creditReports
          : []
      }
      drivingHistoryStatus={drivingHistoryState.status.data}
      drivingHistoryItems={drivingHistoryState.report.drivingReports}
      lastCreditCheck={
        creditState.data.isCreditCheckAvailable
          ? creditState.data.lastCreditCheck
          : undefined
      }
    />
  );
};

export default IdentityCheckScreen;
