import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Column } from '../Table/Table';

interface Props {
  column: Column;
  row: any;
}

const AsyncImage: React.FC<Props> = ({ column, row }) => {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const getUrl = async () => {
      const response = column.contentQuery
        ? await column.contentQuery(row)
        : row[column.id];
      setUrl(response);
    };

    getUrl();
  }, [column, row]);

  return (
    <>
      {!url ? <CircularProgress color="primary" /> : null}
      {url ? <img style={column.cellStyle || {}} src={url} alt="" /> : null}
    </>
  );
};

export default AsyncImage;
