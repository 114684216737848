import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import LoadingButton, {
  ButtonType,
} from '../../components/LoadingButton/LoadingButton';
import Form from '../../components/Form/Form';
import { StyledDialogTitle, StyledDialogActions } from './FormModal.styled';
import { GenericComponentError } from '../../components/GenericComponentError';
import * as yup from 'yup';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  isError: boolean;
  onSubmit: (values: any) => Promise<any>;
  onClose: () => void;
  initialValues: object;
  validationSchema: yup.ObjectSchema;
  error?: Error | null;
  title: string;
  confirmButtonType?: ButtonType;
  confirmLabel?: string;
  cancelLabel?: string;
  centerTitle?: boolean;
}

const FormModal: React.FC<Props> = ({
  isOpen,
  isLoading,
  isError,
  onSubmit,
  onClose,
  initialValues,
  validationSchema,
  error,
  title,
  centerTitle = false,
  confirmButtonType = 'save',
  confirmLabel = 'Create',
  cancelLabel = 'Abort',
  children,
}) => {
  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <Form
        validateOnMount={false}
        validationSchema={validationSchema}
        submit={onSubmit}
        initialValues={initialValues}
        showConfirmation={false}
      >
        {({ handleSubmit }) => (
          <>
            <StyledDialogTitle centerTitle={centerTitle}>
              {title}
            </StyledDialogTitle>
            <DialogContent>
              {children}
              {isError && error ? (
                <GenericComponentError error={error} />
              ) : null}
            </DialogContent>
            <StyledDialogActions>
              {!isLoading ? (
                <Button onClick={onClose} variant="contained">
                  {cancelLabel}
                </Button>
              ) : null}
              <LoadingButton
                buttonType={confirmButtonType}
                onClick={() => handleSubmit()}
                isLoading={isLoading}
              >
                {confirmLabel}
              </LoadingButton>
            </StyledDialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default FormModal;
