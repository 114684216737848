import { api } from '../../../../../services/httpService';
import { User } from '../../../../../../types/interfaces';

export const getUserTrips = async (userId: string) => {
  const data = await api<User>('get', `/api/users/${userId}/details`);

  return {
    trips: data.trips || [],
    timezone: data.timezone,
  };
};
