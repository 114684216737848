import styled from 'styled-components';

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FileName = styled.span`
  flex: 1;
  margin-left: 10px;
`;
