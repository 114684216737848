import React from 'react';
import { ErrorText, ErrorLink } from './ErrorMessage.styled';

interface Props {
  message: string;
  onRetry: () => void;
}

export const ErrorMessage: React.FC<Props> = ({ message, onRetry }) => {
  return (
    <>
      <ErrorText>{message}</ErrorText>
      <ErrorLink onClick={onRetry}>Try again</ErrorLink>
    </>
  );
};
