import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Paper = styled.div`
  display: flex;
  background: white;
  border: 0;
  border-radius: 6px;
  width: 50vw;
  height: 50vh;
  max-width: 600px;
`;

export const Content = styled.div`
  padding: 18px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

export const InputContainer = styled.div`
  padding: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

export const ActionButtonContainer = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ModalButton = styled(Button)`
  margin: 8px;
`;

export const Title = styled.h4`
  color: #4b4f60;
  font-size: 22px;
  margin-bottom: 18px;
  margin-top: 0;
  font-weight: 500;
`;
