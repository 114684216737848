export function filterList<T>(
  objects: T[],
  properties: Array<keyof T>,
  query: string,
): T[] {
  return objects.filter(object => {
    if (!query) {
      return true;
    }

    return properties.some(property => {
      const value = object[property];

      if (typeof value === 'string' || typeof value === 'number') {
        return value.toString().toLowerCase().includes(query.toLowerCase());
      }

      return false;
    });
  });
}
