import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Table } from '../../../../components/Table';
import { columnDefs } from './columnDefs';
import { sortByYear } from '../common/helpers';
import { Accident } from '../../../../graphql/api';
import RowTemplate from './RowTemplate';
import AccidentModal from './AccidentModal';
import { MenuItem } from '../../../../components/PopUpMenu/PopUpMenu';
import { MenuOption } from '../common/types';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import * as S from '../AcvList.styled';

interface Props {
  accidents: Accident[];
  isFormModalOpen: boolean;
  isConfirmModalOpen: boolean;
  isLoading: boolean;
  onOpenFormModal: () => void;
  onOpenConfirmModal: () => void;
  onClose: () => void;
  onCreate: (accident: Accident) => void;
  onEdit: (
    oldAccident: Accident,
    newAccident: Accident,
    successCallback: () => void,
  ) => void;
  onDelete: (accident: Accident, successCallback: () => void) => void;
  error?: Error;
}

const Accidents: React.FC<Props> = ({
  accidents,
  isFormModalOpen,
  isConfirmModalOpen,
  isLoading,
  onOpenFormModal,
  onOpenConfirmModal,
  onClose,
  onCreate,
  onEdit,
  onDelete,
  error,
}) => {
  const [activeAccident, setActiveAccident] = useState<Accident | undefined>();

  const onMenuClick = (menuItem: MenuItem, accident: Accident) => {
    setActiveAccident(accident);

    if (menuItem.type === MenuOption.Edit) {
      onOpenFormModal();
    } else if (menuItem.type === MenuOption.Delete) {
      onOpenConfirmModal();
    }
  };

  const onSave = (accident: Accident) => {
    if (activeAccident) {
      onEdit(activeAccident, accident, () => setActiveAccident(undefined));
    } else {
      onCreate(accident);
    }
  };

  const onConfirmDelete = () => {
    if (!activeAccident) {
      return;
    }

    onDelete(activeAccident, () => setActiveAccident(undefined));
  };

  const onCloseModal = () => {
    setActiveAccident(undefined);
    onClose();
  };

  return (
    <S.Section>
      <Typography variant="h6" color="primary">
        Accidents
      </Typography>
      <Table
        disableGutters
        disableElevation
        disablePagination
        columns={columnDefs}
        data={sortByYear(accidents)}
        rowTemplate={({ rowIndex: _, ...rest }) => (
          <RowTemplate {...rest} onMenuClick={onMenuClick} />
        )}
        noDataMessage="No accidents"
      />
      <AccidentModal
        activeAccident={activeAccident}
        isOpen={isFormModalOpen}
        isLoading={isLoading}
        error={error}
        onSave={onSave}
        onClose={onCloseModal}
      />
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        isLoading={isLoading}
        confirmButtonText="Remove Accident"
        text="Please confirm you would like to remove this accident from the driving history."
        type="deleting"
        errorMessage={error?.message}
        onConfirm={onConfirmDelete}
        onCancel={onCloseModal}
      />
    </S.Section>
  );
};

export default Accidents;
