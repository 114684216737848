import styled from 'styled-components';

export const VehicleContainer = styled.div`
  table {
    tr {
      .MuiTableCell-root {
        padding: 16px 8px;
      }
      td:nth-child(1) {
        height: 80px;
        width: 180px;
      }
      td:nth-child(2),
      td:nth-child(3),
      td:nth-child(4) {
        width: 140px;
      }
      td:nth-child(5) {
        width: 170px;
      }
      td:nth-child(6) {
        width: 100px;
      }
      td:nth-child(7) {
        width: 100px;
      }
      td:nth-child(8) {
        width: 200px;
      }
    }

    input,
    .MuiSelect-select {
      font-size: 0.875rem;
    }

    .MuiFormHelperText-contained {
      margin: 0;
    }

    .Mui-error {
      position: relative;
      top: 10px;
    }

    div.MuiFilledInput-input {
      padding: 6px 6px;
    }
    input.MuiFilledInput-input {
      padding: 8px 6px;
    }

    .MuiFormControl-marginNormal,
    .FormSelect-formControl-356 {
      margin: 0px 0;
    }
  }
`;
