import {
  Container,
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { memo } from 'react';
import { QueryOptions } from '../../../types/interfaces';
import NoData from '../NoData/NoData';
import DefaultRowTemplate from './DefaultRowTemplate';
import VisibilitySensor from 'react-visibility-sensor';
import { Column, RowAction } from './Table';
import * as S from './InfiniteScrollTable.styled';
import CircularProgress from '@material-ui/core/CircularProgress';

interface RowTemplateProps {
  columns: Column[];
  item: any;
  rowActions?: RowAction[];
  rowIndex: number;
}

interface Props {
  data: any[];
  columns: Column[];
  hasMoreItems: boolean;
  fetchMoreItems: () => Promise<void>;
  onFilterData?: (filter: string) => void;
  onFetchData?: (options: QueryOptions, search: string) => void;
  rowTemplate?: (props: RowTemplateProps) => React.ReactNode;
  onRowClick?: (data: any) => void;
  rowActions?: RowAction[];
  disableElevation?: boolean;
  disableGutters?: boolean;
  isLoading?: boolean;
  noDataMessage?: string;
}

const ContentWrapper: React.FC = ({ children }) => <div>{children}</div>;

const Table: React.FC<Props> = ({
  columns,
  data,
  hasMoreItems,
  fetchMoreItems,
  isLoading,
  rowTemplate,
  rowActions = [],
  disableElevation = false,
  disableGutters = false,
  noDataMessage = 'No rows found',
}) => {
  React.useEffect(() => {
    fetchMoreItems();
  }, []);

  const Wrapper = disableElevation ? ContentWrapper : Paper;

  const onSensorVisibilityChange = (isVisible: boolean) => {
    if (isVisible && hasMoreItems && !isLoading) {
      fetchMoreItems();
    }
  };

  const itemsCount = data.length;

  return (
    <S.StyledTable>
      <Wrapper>
        <Container disableGutters={disableGutters} maxWidth="xl">
          <MUITable>
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell
                    key={`${column.id}-${idx}`}
                    style={{ userSelect: 'none' }}
                  >
                    <>{column.title}</>
                  </TableCell>
                ))}
                {rowActions.map((action, idx) => (
                  <TableCell key={`${action.name}_${idx}`}>
                    {action.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, rowIndex) => {
                const template =
                  rowTemplate &&
                  rowTemplate({
                    columns,
                    item,
                    rowIndex,
                    rowActions,
                  });

                return (
                  <>
                    {template || (
                      <DefaultRowTemplate
                        key={rowIndex}
                        columns={columns}
                        item={item}
                        rowActions={rowActions}
                        rowIndex={rowIndex}
                        onRowClick={() => {}}
                      />
                    )}
                    {hasMoreItems && itemsCount - 1 === rowIndex && (
                      <VisibilitySensor onChange={onSensorVisibilityChange}>
                        <S.Row>
                          <CircularProgress size={16} color="primary" />
                          <S.LoadingMoreRow>
                            Loading more items...
                          </S.LoadingMoreRow>
                        </S.Row>
                      </VisibilitySensor>
                    )}
                  </>
                );
              })}
            </TableBody>
          </MUITable>
          {data && data.length === 0 ? <NoData text={noDataMessage} /> : null}
        </Container>
      </Wrapper>
    </S.StyledTable>
  );
};

export default memo(Table);
