import styled from 'styled-components';

export const LoadingMoreRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

export const StyledTable = styled.div`
  && {
    .MuiContainer-root {
      margin-bottom: 40px !important;
    }
  }
`;
