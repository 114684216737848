import React from 'react';
import { Dialog, CircularProgress } from '@material-ui/core';
import { LoadingContainer } from './TakePaymentModal.styled';
import { useGetUserWalletQuery } from '../../../../../graphql/api';
import ModalForm from './components/TakePaymentModal';
import ModalError from '../../../../../components/ModalError/ModalError';
import { useGetUserWalletSelector } from '../common/useGetUserWalletSelector';
import { UserWallet } from '../common/types';
import ModalRequirementError from './components/ModalRequirementError';

interface Props {
  userId: string;
  onClose: () => void;
}

const TakePaymentModal: React.FC<Props> = ({ userId, onClose }) => {
  const { isLoading, data, error } = useGetUserWalletQuery<UserWallet, Error>(
    { userId },
    {
      select: useGetUserWalletSelector,
      useErrorBoundary: false,
    },
  );

  return (
    <Dialog open onClose={onClose} scroll="body" fullWidth maxWidth="xs">
      {(() => {
        if (isLoading) {
          return (
            <LoadingContainer>
              <CircularProgress size={20} />
            </LoadingContainer>
          );
        }

        if (error) {
          return <ModalError error={error.message} />;
        }

        if (!data) {
          return <ModalError error={'Error loading user data'} />;
        }

        const { funds, isAutomaticPaymentsEnabled, token } = data;
        const isNegativeFunds = funds < 0;

        if (!isAutomaticPaymentsEnabled || !isNegativeFunds) {
          return (
            <ModalRequirementError
              funds={funds}
              isAutomaticPaymentsEnabled={isAutomaticPaymentsEnabled}
              isNegativeFunds={isNegativeFunds}
              onClose={onClose}
            />
          );
        }

        return (
          <ModalForm
            userId={userId}
            funds={funds}
            isAutomaticPaymentsEnabled={isAutomaticPaymentsEnabled}
            isNegativeFunds={isNegativeFunds}
            token={token}
            onClose={onClose}
          />
        );
      })()}
    </Dialog>
  );
};

export default TakePaymentModal;
