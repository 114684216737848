import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import ErrorModal from '../../../../components/ErrorModal/ErrorModal';
import { ErrorCode, logError } from '../../../../services/logging';
import {
  getOdometerImageById,
  TallyUp,
  TallyUpOdometer,
} from '../../../../services/repository/odometerService';
import { Container, HeaderRow, Label, RejectButton } from './Components.style';
import OdometerImageWithHover from './OdometerImageWithHover';
import OdometerReading from './Readings';
import { OdometerStatus } from '@just-insure/api';

export type ValidateType = 'start' | 'end';

interface Props {
  tallyUp: TallyUp;
  isRevertible?: boolean;
  type: ValidateType;
  userId?: number;
  onValueSet?: (v: number | undefined) => void;
  onImageClick: (odometer: TallyUpOdometer) => void;
  onAccept: (id: string, value: number) => void;
  onReject: (id: string) => void;
  onRevert?: (id: string) => void;
}

const ValidateOdometer: React.FC<Props> = ({
  tallyUp,
  isRevertible,
  type,
  userId,
  onImageClick,
  onAccept,
  onReject,
  onRevert,
  onValueSet,
}) => {
  const odometer =
    type === 'start' ? tallyUp.startOdometer : tallyUp.endOdometer;

  const [error, setError] = useState<AxiosError>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [value, _setValue] = useState(odometer.readings.user);

  function setValue(v: number | undefined) {
    _setValue(v);
  }

  React.useEffect(() => {
    onValueSet?.(value);
  }, [value]);

  useEffect(() => {
    const getImage = async () => {
      try {
        const url = await getOdometerImageById(parseInt(odometer.id, 10));
        setImageUrl(url);
      } catch (ex: any) {
        logError(ErrorCode.TallyUpReading, ex, {
          tallyUpId: tallyUp.id,
          userId: `${userId}`,
        });
        setError(ex);
      }
    };

    getImage();
  }, [odometer.id, tallyUp.id, userId]);

  const readingDate = odometer.createdAt.toFormat('dd MMM');
  const status = odometer.status!;

  return (
    <>
      <Container>
        <HeaderRow>
          <Label data-testid="odometer-reading-label">
            {`Odometer reading ${readingDate}`}
          </Label>
          {status === OdometerStatus.Pending ? (
            <RejectButton
              onClick={() => {
                onReject(odometer.id);
              }}
            >
              REJECT
            </RejectButton>
          ) : null}
          {isRevertible && (
            <RejectButton
              onClick={() => {
                onRevert?.(odometer.id);
              }}
            >
              REVERT
            </RejectButton>
          )}
        </HeaderRow>
        <OdometerImageWithHover
          imgSource={imageUrl}
          onImageClick={() => onImageClick(odometer)}
        />
        <OdometerReading
          disabled={!value}
          odometer={odometer}
          value={value}
          onValueChange={v => setValue(v)}
          onSave={() => {
            onAccept(odometer.id, value!);
          }}
        />
      </Container>
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </>
  );
};

export default ValidateOdometer;
