import keplerGlReducer from 'kepler.gl/reducers';

const customizedKeplerGlReducer = keplerGlReducer.initialState({
  mapStyle: {
    bottomMapStyle:
      'mapbox://styles/davidsfinsuretech/cjwzf4pqb20741crum21s8du7',
    styleType: 'streetView',
  },
  uiState: {
    // hide side panel to disallow user customize the map
    currentModal: null,
    readOnly: false,

    // customize which map control button to show
    mapControls: {
      mapDraw: {
        show: true,
      },
      mapLocale: {
        show: true,
      },
      mapLegend: {
        active: true,
        show: true,
      },
      splitMap: {
        show: false,
      },
      toggle3d: {
        show: false,
      },
      visibleLayers: {
        show: false,
      },
    },
  },
});

export default customizedKeplerGlReducer;
