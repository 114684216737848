import React from 'react';
import { CenterContentContainer } from '../UserVerificationScreen.styled';
import Loading from '../../../../../components/Loading/Loading';

const LoadingContent: React.FC = () => {
  return (
    <CenterContentContainer>
      <Loading text="Getting Identity Report..." />
    </CenterContentContainer>
  );
};

export default LoadingContent;
