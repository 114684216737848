import React from 'react';
import * as intercomService from '../../../services/repository/intercomService';
import { ErrorCode } from '../../../services/logging';
import { HeaderLink, HeaderLinkImage } from './UserDetails.styled';

interface Props {
  userId: string;
  onError: (err: Error, errorCode: ErrorCode) => void;
}

const Intercom: React.FC<Props> = ({ userId, onError }) => {
  const navigateToIntercomUser = async () => {
    try {
      const intercom = await intercomService.getIntercomUserDetails(userId);
      window.open(
        `https://app.intercom.com/a/apps/cbijgr2p/users/${intercom.id}/all-conversations`,
        '_blank',
      );
    } catch (ex: any) {
      onError(ex, ErrorCode.UserDetails);
    }
  };

  return (
    <HeaderLink onClick={navigateToIntercomUser}>
      <HeaderLinkImage src="/images/intercom.png" alt="" />
      Intercom
    </HeaderLink>
  );
};

export default Intercom;
