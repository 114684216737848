import styled from 'styled-components';

export const Icon = styled.img`
  width: 21px;
`;

export const IframeContainer = styled.div`
  height: calc(100% - 64px);
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;
