import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const ModalContent = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 10%;
  left: calc(50% - 200px);
  outline: none;
  padding: 30px;
  width: 500px;
`;

export const Title = styled.h1`
  text-align: center;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.palette.primary.bluePurple};
`;

export const Body = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
`;

export const StyledButton = styled(Button)``;
