import styled from 'styled-components';
import { DialogTitle } from '@material-ui/core';

export const StyledDialogTitle = styled(DialogTitle)`
  && {
    padding: 20px 24px 0;
    text-align: center;
    font-size: 28px;
    user-select: none;
  }
`;

export const StyledDialogDescription = styled.p`
  padding: 24px;
  text-align: center;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  user-select: none;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.primary.red};
  font-size: 24px;
  padding: 24px;
  text-align: center;
`;
