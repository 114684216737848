import Loading from './Loading';

type Props = {
  message?: string;
};

const PageLoading: React.FC<Props> = ({ message }) => (
  <div className="flex w-full h-1/2 items-center justify-center">
    <div className="flex flex-col items-center">
      <div>
        <Loading color="#162d8b" className="w-10 h-10" />
      </div>
      <div className="text-blue font-circular font-bold mt-2">
        {message ?? 'Loading...'}
      </div>
    </div>
  </div>
);

export default PageLoading;
