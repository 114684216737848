import React from 'react';
import FormSelect from '../../../../components/FormSelect/FormSelect';
import { useGetPolicyCancellationReasonsQuery } from '../../../../graphql/api';
import { cancellationReasonsSelector } from './cancellationReasonsSelector';
import { ErrorCode, logError } from '../../../../services/logging';

interface Props {
  onError: (e: Error) => void;
}

const CancellationReasonsSelect: React.FC<Props> = ({ onError }) => {
  const { isLoading, isError, data } = useGetPolicyCancellationReasonsQuery(
    {},
    {
      select: cancellationReasonsSelector,
      onError: (e: Error) => {
        logError(ErrorCode.UserPolicyCancellationReasons, e);
        onError(e);
      },
    },
  );

  if (isError) {
    return null;
  }

  return (
    <FormSelect
      name="cancellationExplanationCode"
      options={data || []}
      editable
      label={isLoading ? 'Loading...' : 'Cancellation Reason'}
    />
  );
};

export default CancellationReasonsSelect;
