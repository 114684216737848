import Typography from '@material-ui/core/Typography';
import React, { memo } from 'react';
import { Policy } from './types';
import { Table } from '../../../../components/Table';
import {
  useSearchPoliciesQuery,
  PoliciesSearchInput,
} from '../../../../graphql/api';
import { useSearchPoliciesSelector } from './useSearchPoliciesSelector';
import { logError, ErrorCode } from '../../../../services/logging';
import { QueryOptions } from '../../../../../types/interfaces';
import { policyListColumnDefs } from './policyListColumnDefs';

type Props = {
  searchTerm: string;
  navigateToUserPage: (userId: string) => void;
  setSearchLoading: (isLoading: boolean) => void;
};

const PolicyList: React.FC<Props> = ({
  searchTerm,
  navigateToUserPage,
  setSearchLoading,
}) => {
  const [input, setInput] = React.useState<PoliciesSearchInput>({
    search: { policyNr: searchTerm },
  });

  // There is no pagination for the policy list query,
  // so this is to prevent a long list of policies when
  // using a short search term.
  const queryEnabled = input.search.policyNr.length >= 3;

  const {
    isLoading,
    error,
    data: policies,
  } = useSearchPoliciesQuery<Policy[], Error>(
    { input },
    {
      select: useSearchPoliciesSelector,
      onError: (e: Error) => logError(ErrorCode.SearchPolicies, e),
      useErrorBoundary: false,
      enabled: queryEnabled,
    },
  );

  const onFetchData = (_: QueryOptions, searchTerm: string) =>
    setInput({ search: { policyNr: searchTerm } });

  const onRowClick = (policy: Policy) => {
    navigateToUserPage(policy.userId);
  };

  React.useEffect(() => {
    setSearchLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <Typography variant="h4">Policy List</Typography>
      <Table
        data={policies || []}
        columns={policyListColumnDefs}
        onRowClick={onRowClick}
        disablePagination
        onFetchData={onFetchData}
        searchTerm={searchTerm}
        error={error?.message}
        isLoading={isLoading}
      />
    </>
  );
};

export default memo(PolicyList);
