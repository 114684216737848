import { QueryOptions } from '../../types/interfaces';

interface IKeyValue {
  [key: string]: string;
}

const remap = (key: string, mapping: IKeyValue) =>
  mapping[key] ? mapping[key] : key;

const paginateQueryString = (
  query: QueryOptions,
  search: string,
  columnMapping: IKeyValue = {},
) =>
  Object.entries({ ...query, search })
    .map(([key, value]) =>
      value
        ? `${key}=${
            key === 'orderBy' ? remap(value as string, columnMapping) : value
          }`
        : undefined,
    )
    .filter(Boolean)
    .join('&');

export default paginateQueryString;
