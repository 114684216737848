import * as yup from 'yup';

export default yup.object().shape({
  to: yup
    .string()
    .trim()
    .email('Invalid email address')
    .required('To email address is required'),
  subject: yup.string().required('Subject is required'),
  comment: yup.string(),
});
