import React, { useState } from 'react';
import { QuoteDetailModal } from '../../../components/QuoteDetailModal/QuoteDetailModal';
import { Table } from '../../../components/Table';
import { QuoteOverview } from '../../../graphql/types';
import { UserPolicy } from '../../../services/repository/policyService';
import * as S from './PolicyList.styled';
import { policyListColumnDefs } from './policyListColumnDefs';
import PolicyRowTemplate from './PolicyRowTemplate';
import RescindPolicyModal from './RescindPolicyModal';
import CancelPolicyModal from './CancelPolicyModal/CancelPolicyModal';
import LocationInfoModal from './LocationInfoModal/LocationInfoModal';
import { MenuItem } from '../../../components/PopUpMenu/PopUpMenu';
import { MenuOption } from './types';

enum ModalType {
  Cancel = 'cancel',
  Rescind = 'rescind',
}

interface Props {
  quoteOverviews: QuoteOverview[];
  policies: UserPolicy[];
  visible: boolean;
  userId: number;
  onRefresh: () => void;
}

const PolicyList: React.FC<Props> = ({
  quoteOverviews,
  policies,
  visible,
  userId,
  onRefresh,
}) => {
  const [openModal, setOpenModal] = useState<ModalType>();
  const [selectedQuote, setSelectedQuote] = useState<QuoteOverview>();
  const [selectedPolicy, setSelectedPolicy] = useState<UserPolicy>();

  const handleSelectedQuote = (quoteId: number) => {
    const quote = quoteOverviews.find(({ id }) => id === quoteId);
    setSelectedQuote(quote);
  };

  if (!visible) {
    return null;
  }

  const onSuccess = () => {
    setOpenModal(undefined);
    onRefresh();
  };

  const onClose = () => setOpenModal(undefined);

  const handlePressPolicyId = (policy: UserPolicy) => {
    setSelectedPolicy(policy);
  };

  const onMenuClick = (menuItem: MenuItem) => {
    if (menuItem.type === MenuOption.CancelByCarrier) {
      setOpenModal(ModalType.Cancel);
    } else if (menuItem.type === MenuOption.Rescind) {
      setOpenModal(ModalType.Rescind);
    }
  };

  return (
    <>
      <S.TableContainer>
        <Table
          columns={policyListColumnDefs}
          data={policies}
          defaultOrder="date"
          defaultDir="desc"
          rowTemplate={({ rowIndex, item, ...rest }) => (
            <PolicyRowTemplate
              key={item.policyNr}
              rowIndex={rowIndex}
              onPressPolicyId={handlePressPolicyId}
              onSelectQuote={handleSelectedQuote}
              onMenuClick={onMenuClick}
              item={item}
              {...rest}
            />
          )}
        />
        <CancelPolicyModal
          isOpen={openModal === ModalType.Cancel}
          userId={userId}
          onSuccess={onSuccess}
          onClose={onClose}
        />

        <RescindPolicyModal
          isOpen={openModal === ModalType.Rescind}
          userId={userId}
          onSuccess={onSuccess}
          onClose={onClose}
        />

        {selectedQuote ? (
          <QuoteDetailModal
            quote={selectedQuote}
            onClose={() => setSelectedQuote(undefined)}
          />
        ) : null}

        {selectedPolicy ? (
          <LocationInfoModal
            policy={selectedPolicy}
            userId={userId}
            onClose={() => setSelectedPolicy(undefined)}
          />
        ) : null}
      </S.TableContainer>
    </>
  );
};

export default PolicyList;
