import React from 'react';
import AuditTrailScreen from './AuditTrailScreen';
import { AuditTrailController } from './Controller';

interface Props {
  userId: string;
}

const AuditTrailTab: React.FC<Props> = ({ userId }) => {
  return (
    <AuditTrailController userId={userId}>
      <AuditTrailScreen />
    </AuditTrailController>
  );
};

export default AuditTrailTab;
