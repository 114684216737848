import React from 'react';
import { EditVehicleRequest, SavedVehicle, Vehicle } from '@just-insure/api';
import * as vehicleService from '../../../services/repository/vehicleService';
import { ErrorCode, logError } from '../../../services/logging';
import { AxiosError } from 'axios';
import * as policyService from '../../../services/repository/policyService';
import fileSaver from 'file-saver';
import { PhotoResponse } from '../../../services/repository/vehicleService';
import { UserPolicy } from '../../../services/repository/policyService';

interface UseVehicleList {
  selectedVehicle?: SavedVehicle;
  userVehicles: SavedVehicle[];
  error: AxiosError | Error | undefined;
  clearError: () => void;
  // actions
  getUserVehicles: () => Promise<void>;
  getPhotosForVehicle: (vin: string) => Promise<PhotoResponse[]>;
  updateVehicleInfo: (vehicle: Vehicle, updatePolicy: boolean) => Promise<void>;
  setDefaultVehicle: (vehicleId: number) => Promise<void>;
  saveVehicle: (vehicle: Vehicle) => Promise<void>;
  deleteVehicleById: (vehicleId: number) => Promise<void>;
  downloadVehicleImages: (vin: string) => Promise<void>;
  addTemporaryVehicle: () => void;
  removeTemporaryVehicle: () => void;
}

const useVehicleList = (
  userId: string,
  policyVehicles: UserPolicy['vehicles'],
): UseVehicleList => {
  const [userVehicles, setUserVehicles] = React.useState<SavedVehicle[]>([]);
  const [error, setError] = React.useState<AxiosError | Error>();
  const [photos, setPhotos] = React.useState<{
    [vehicleId: string]: PhotoResponse[];
  }>({});

  const clearError = () => setError(undefined);

  const getUserVehicles = async () => {
    try {
      const vehicles = await vehicleService.getUserVehicles(userId);
      setUserVehicles(vehicles);
    } catch (ex: any) {
      logError(ErrorCode.UserVehicle, ex);
      setError(ex);
    }
  };

  const updateVehicleInfo = async (vehicle: Vehicle, updatePolicy: boolean) => {
    try {
      await vehicleService.updateUserVehicle(userId, vehicle);

      if (updatePolicy && vehicle.lienholder) {
        await policyService.amendLienholder({
          vin: vehicle.vin,
          userId,
          lienholder: {
            name: vehicle.lienholder.name,
            email: vehicle.lienholder.email,
            phone: vehicle.lienholder.phone,
          },
        });
      }
      await getUserVehicles();
    } catch (e: any) {
      logError(ErrorCode.UserVehicle, e);
      setError(e);
    }
  };

  const setDefaultVehicle = async (vehicleId: number) => {
    try {
      await vehicleService.setUserVehicleDefault(userId, vehicleId);
      const vehicles = userVehicles.map(v => ({
        ...v,
        isDefault: v.id === vehicleId,
      }));
      setUserVehicles(vehicles);
    } catch (ex: any) {
      logError(ErrorCode.UserVehicle, ex);
      setError(ex);
    }
  };

  const addTemporaryVehicle = () => {
    setUserVehicles([{ vin: '', isDefault: true } as any, ...userVehicles]);
  };

  const removeTemporaryVehicle = () => {
    setUserVehicles([...userVehicles.filter(v => v.id !== undefined)]);
  };

  const saveVehicle = async (vehicle: Vehicle) => {
    try {
      const payload: EditVehicleRequest = {
        active: true,
        make: vehicle.make,
        model: vehicle.model,
        makeId: vehicle.makeId,
        modelId: vehicle.modelId,
        vin: vehicle.vin,
        year: vehicle.year,
      };
      await vehicleService.createUserVehicle(userId, payload);
      const vehicles = await vehicleService.getUserVehicles(userId);
      setUserVehicles(vehicles);
    } catch (ex: any) {
      logError(ErrorCode.UserVehicle, ex);
      setError(ex);
    }
  };

  const deleteVehicleById = async (vehicleId: number) => {
    try {
      await vehicleService.deleteUserVehicle(userId, vehicleId);
      const vehicles = await vehicleService.getUserVehicles(userId);
      setUserVehicles(vehicles);
    } catch (ex: any) {
      logError(ErrorCode.UserVehicle, ex);
      setError(ex);
    }
  };

  const getPhotosForVehicle = async (
    vin: string,
  ): Promise<Array<PhotoResponse>> => {
    const vehicle = policyVehicles.find(vehicle => vehicle.vin === vin);
    if (!vehicle) {
      logError(ErrorCode.UserVehicle, new Error('Vehicle not found'));
      setError(new Error('Vehicle not found'));
      return [];
    }
    const vehicleId = Number(vehicle.id);
    if (!photos[vehicleId]) {
      try {
        const results = await vehicleService.downloadVehicleImages(
          userId,
          vehicleId,
        );
        setPhotos({
          ...photos,
          [vehicleId]: results.photos,
        });

        return results.photos;
      } catch (e: any) {
        logError(ErrorCode.UserVehicle, e);
        setError(e);
      }
    }

    return photos[vehicleId];
  };

  const downloadVehicleImages = async (vin: string) => {
    try {
      const photos = await getPhotosForVehicle(vin);

      if (!photos.length) {
        setError(new Error(`No photos for vehicle ${vin}`));
      } else {
        await Promise.all(
          photos.map(photo =>
            fileSaver.saveAs(photo.url, photo.metadata.photoAngle),
          ),
        );
      }
    } catch (e: any) {
      logError(ErrorCode.UserVehicle, e);
      setError(e);
    }
  };

  return {
    userVehicles,
    getPhotosForVehicle,
    getUserVehicles,
    updateVehicleInfo,
    setDefaultVehicle,
    saveVehicle,
    deleteVehicleById,
    addTemporaryVehicle,
    downloadVehicleImages,
    removeTemporaryVehicle,
    error,
    clearError,
  };
};

export default useVehicleList;
