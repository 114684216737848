import { Card } from '../../../components/Card';
import styled from 'styled-components';

export const Tile = styled(Card)`
  width: 200px;
  height: 150px;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
`;
