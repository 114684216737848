import styled from 'styled-components';
import { Table, TableCell } from '@material-ui/core';

export const StyledTable = styled(Table)`
  && {
    margin: 25px 0 15px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    border-bottom: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;
