import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { fetchData } from './fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**  Banking account number is a string of 5 to 17 alphanumeric values for representing an generic account number  */
  AccountNumber: { input: any; output: any; }
  /**  The `BigInt` scalar type represents non-fractional signed whole numeric values.  */
  BigInt: { input: any; output: any; }
  /** The `Byte` scalar type represents byte value as a Buffer */
  Byte: { input: any; output: any; }
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: { input: any; output: any; }
  /**  A field whose value conforms to the standard cuid format as specified in https://github.com/ericelliott/cuid#broken-down  */
  Cuid: { input: any; output: any; }
  /**  A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217.  */
  Currency: { input: any; output: any; }
  /**  A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.  */
  Date: { input: any; output: any; }
  /**  A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.  */
  DateTime: { input: any; output: any; }
  /**  A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format.  */
  DateTimeISO: { input: any; output: any; }
  /**  A field whose value conforms to the standard DeweyDecimal format as specified by the OCLC https://www.oclc.org/content/dam/oclc/dewey/resources/summaries/deweysummaries.pdf  */
  DeweyDecimal: { input: any; output: any; }
  /**  A field whose value conforms to the standard DID format as specified in did-core: https://www.w3.org/TR/did-core/.  */
  DID: { input: any; output: any; }
  /**
   *
   *  A string representing a duration conforming to the ISO8601 standard,
   *  such as: P1W1DT13H23M34S
   *  P is the duration designator (for period) placed at the start of the duration representation.
   *  Y is the year designator that follows the value for the number of years.
   *  M is the month designator that follows the value for the number of months.
   *  W is the week designator that follows the value for the number of weeks.
   *  D is the day designator that follows the value for the number of days.
   *  T is the time designator that precedes the time components of the representation.
   *  H is the hour designator that follows the value for the number of hours.
   *  M is the minute designator that follows the value for the number of minutes.
   *  S is the second designator that follows the value for the number of seconds.
   *
   *  Note the time designator, T, that precedes the time value.
   *
   *  Matches moment.js, Luxon and DateFns implementations
   *  ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: { input: any; output: any; }
  /**  A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address.  */
  EmailAddress: { input: any; output: any; }
  /**  A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier.  */
  GUID: { input: any; output: any; }
  /**  A field whose value is a hexadecimal: https://en.wikipedia.org/wiki/Hexadecimal.  */
  Hexadecimal: { input: any; output: any; }
  /**  A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors.  */
  HexColorCode: { input: any; output: any; }
  /**  A field whose value is a CSS HSL color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla().  */
  HSL: { input: any; output: any; }
  /**  A field whose value is a CSS HSLA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla().  */
  HSLA: { input: any; output: any; }
  /**  A field whose value is an International Bank Account Number (IBAN): https://en.wikipedia.org/wiki/International_Bank_Account_Number.  */
  IBAN: { input: any; output: any; }
  /**  A field whose value is either an IPv4 or IPv6 address: https://en.wikipedia.org/wiki/IP_address.  */
  IP: { input: any; output: any; }
  /**  A field whose value is an IPC Class Symbol within the International Patent Classification System: https://www.wipo.int/classifications/ipc/en/  */
  IPCPatent: { input: any; output: any; }
  /**  A field whose value is a IPv4 address: https://en.wikipedia.org/wiki/IPv4.  */
  IPv4: { input: any; output: any; }
  /**  A field whose value is a IPv6 address: https://en.wikipedia.org/wiki/IPv6.  */
  IPv6: { input: any; output: any; }
  /**  A field whose value is a ISBN-10 or ISBN-13 number: https://en.wikipedia.org/wiki/International_Standard_Book_Number.  */
  ISBN: { input: any; output: any; }
  /**
   *
   *  A string representing a duration conforming to the ISO8601 standard,
   *  such as: P1W1DT13H23M34S
   *  P is the duration designator (for period) placed at the start of the duration representation.
   *  Y is the year designator that follows the value for the number of years.
   *  M is the month designator that follows the value for the number of months.
   *  W is the week designator that follows the value for the number of weeks.
   *  D is the day designator that follows the value for the number of days.
   *  T is the time designator that precedes the time components of the representation.
   *  H is the hour designator that follows the value for the number of hours.
   *  M is the minute designator that follows the value for the number of minutes.
   *  S is the second designator that follows the value for the number of seconds.
   *
   *  Note the time designator, T, that precedes the time value.
   *
   *  Matches moment.js, Luxon and DateFns implementations
   *  ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: { input: any; output: any; }
  /**  The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf).  */
  JSON: { input: any; output: any; }
  /**  The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf).  */
  JSONObject: { input: any; output: any; }
  /**  A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction.  */
  JWT: { input: any; output: any; }
  /**  A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude  */
  Latitude: { input: any; output: any; }
  /**  A field whose value conforms to the Library of Congress Subclass Format ttps://www.loc.gov/catdir/cpso/lcco/  */
  LCCSubclass: { input: any; output: any; }
  /**  A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`.  */
  LocalDate: { input: any; output: any; }
  /**  A local date-time string (i.e., with no associated timezone) in `YYYY-MM-DDTHH:mm:ss` format, e.g. `2020-01-01T00:00:00`.  */
  LocalDateTime: { input: any; output: any; }
  /** The locale in the format of a BCP 47 (RFC 5646) standard string */
  Locale: { input: any; output: any; }
  /**  A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`. This scalar is very similar to the `LocalTime`, with the only difference being that `LocalEndTime` also allows `24:00` as a valid value to indicate midnight of the following day. This is useful when using the scalar to represent the exclusive upper bound of a time block.  */
  LocalEndTime: { input: any; output: any; }
  /**  A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`.  */
  LocalTime: { input: any; output: any; }
  /**  The `BigInt` scalar type represents non-fractional signed whole numeric values.  */
  Long: { input: any; output: any; }
  /**  A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude  */
  Longitude: { input: any; output: any; }
  /**  A field whose value is a IEEE 802 48-bit MAC address: https://en.wikipedia.org/wiki/MAC_address.  */
  MAC: { input: any; output: any; }
  /** Floats that will have a value less than 0. */
  NegativeFloat: { input: any; output: any; }
  /** Integers that will have a value less than 0. */
  NegativeInt: { input: any; output: any; }
  /** A string that cannot be passed as an empty value */
  NonEmptyString: { input: any; output: any; }
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: any; output: any; }
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: any; output: any; }
  /** Floats that will have a value of 0 or less. */
  NonPositiveFloat: { input: any; output: any; }
  /** Integers that will have a value of 0 or less. */
  NonPositiveInt: { input: any; output: any; }
  /**  A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c  */
  ObjectID: { input: any; output: any; }
  /**  A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234.  */
  PhoneNumber: { input: any; output: any; }
  /**  A field whose value is a valid TCP port within the range of 0 to 65535: https://en.wikipedia.org/wiki/Transmission_Control_Protocol#TCP_ports  */
  Port: { input: any; output: any; }
  /** Floats that will have a value greater than 0. */
  PositiveFloat: { input: any; output: any; }
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: any; output: any; }
  /**  A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg.  */
  PostalCode: { input: any; output: any; }
  /**  A field whose value is a CSS RGB color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba().  */
  RGB: { input: any; output: any; }
  /**  A field whose value is a CSS RGBA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba().  */
  RGBA: { input: any; output: any; }
  /**  In the US, an ABA routing transit number (`ABA RTN`) is a nine-digit code to identify the financial institution.  */
  RoutingNumber: { input: any; output: any; }
  /**  The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification.  */
  SafeInt: { input: any; output: any; }
  /** A field whose value is a Semantic Version: https://semver.org */
  SemVer: { input: any; output: any; }
  /**  A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.  */
  Time: { input: any; output: any; }
  /**  The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch.  */
  Timestamp: { input: any; output: any; }
  /**  A field whose value exists in the standard IANA Time Zone Database: https://www.iana.org/time-zones  */
  TimeZone: { input: any; output: any; }
  /** Floats that will have a value of 0 or more. */
  UnsignedFloat: { input: any; output: any; }
  /** Integers that will have a value of 0 or more. */
  UnsignedInt: { input: any; output: any; }
  /**  A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt.  */
  URL: { input: any; output: any; }
  /** A currency string, such as $21.25 */
  USCurrency: { input: any; output: any; }
  /**  A field whose value is a UTC Offset: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones  */
  UtcOffset: { input: any; output: any; }
  /**  A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier.  */
  UUID: { input: any; output: any; }
  /** Represents NULL values */
  Void: { input: any; output: any; }
};

export type AccelerometerConfig = {
  __typename?: 'AccelerometerConfig';
  readIntervalInMs: Scalars['Int']['output'];
};

export type AcceptQuoteInput = {
  quoteId: Scalars['ID']['input'];
};

export type Accident = {
  __typename?: 'Accident';
  amountOfLoss: Scalars['Int']['output'];
  anyoneInjured: Scalars['Boolean']['output'];
  atFault: Scalars['Boolean']['output'];
  yearOccurred: Scalars['Int']['output'];
};

export type AccidentInput = {
  amountOfLoss: Scalars['Int']['input'];
  anyoneInjured: Scalars['Boolean']['input'];
  atFault: Scalars['Boolean']['input'];
  yearOccurred: Scalars['Int']['input'];
};

export type AccountEmail = {
  __typename?: 'AccountEmail';
  attachments?: Maybe<Array<SavedAttachment>>;
  bcc?: Maybe<Array<Scalars['EmailAddress']['output']>>;
  cc?: Maybe<Array<Scalars['EmailAddress']['output']>>;
  comment?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  from: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sendingAt: Scalars['String']['output'];
  sentAt?: Maybe<Scalars['String']['output']>;
  status: EmailStatus;
  subject?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['String']['output']>;
  to: Array<Scalars['EmailAddress']['output']>;
  userId: Scalars['Int']['output'];
};

/** Union of separate types for different account finalisation states allows for extension of each state type without introducing breaking changes to other states */
export type AccountFinalisation = AccountFinalisationComplete | AccountFinalisationSettlementPending | AccountFinalisationTallyUpDue | AccountFinalisationTallyUpRejected | AccountFinalisationTallyUpSubmitted;

export type AccountFinalisationComplete = {
  __typename?: 'AccountFinalisationComplete';
  name: AccountFinalisationState;
};

export type AccountFinalisationSettlementPending = {
  __typename?: 'AccountFinalisationSettlementPending';
  name: AccountFinalisationState;
  state: AccountFinalisationSettlementPendingState;
};

export enum AccountFinalisationSettlementPendingState {
  BalanceSettlementComplete = 'BALANCE_SETTLEMENT_COMPLETE',
  BalanceSettlementRequired = 'BALANCE_SETTLEMENT_REQUIRED'
}

export enum AccountFinalisationState {
  Complete = 'COMPLETE',
  SettlementPending = 'SETTLEMENT_PENDING',
  TallyUpSubmission = 'TALLY_UP_SUBMISSION'
}

export type AccountFinalisationTallyUpDue = {
  __typename?: 'AccountFinalisationTallyUpDue';
  name: AccountFinalisationState;
  tallyUp: TallyUpSubmissionDue;
};

export type AccountFinalisationTallyUpRejected = {
  __typename?: 'AccountFinalisationTallyUpRejected';
  name: AccountFinalisationState;
  tallyUp: TallyUpSubmissionDue;
};

export type AccountFinalisationTallyUpSubmitted = {
  __typename?: 'AccountFinalisationTallyUpSubmitted';
  name: AccountFinalisationState;
  tallyUp: TallyUpSubmission;
};

export type AccountSms = {
  __typename?: 'AccountSms';
  content: Scalars['String']['output'];
  from: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sendingAt?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['String']['output']>;
  status: SmsStatus;
  tags: Array<Scalars['String']['output']>;
  to: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type Action = NegativeBalanceAction | NewQuoteAvailableAction | OdometerActionV2 | OdometerDueAction | PaymentAction;

export type ActionFilter = {
  state: ActionState;
};

export enum ActionState {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type ActiveState = BaseUnderwritingAccountState & {
  __typename?: 'ActiveState';
  actions: Array<Action>;
  isRestricted: Scalars['Boolean']['output'];
};

export enum ActivityType {
  AutoTopup = 'autoTopup',
  Daily = 'daily',
  Driving = 'driving',
  Owed = 'owed',
  Refund = 'refund',
  Tallyup = 'tallyup',
  Topup = 'topup'
}

export type Acv = {
  __typename?: 'ACV';
  accidents: Array<Accident>;
  claims: Array<Claim>;
  violations: Array<Violation>;
};

export type AcvInput = {
  accidents: Array<AccidentInput>;
  claims: Array<ClaimInput>;
  violations: Array<ViolationInput>;
};

export type AddAddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  state: StateCode;
  zipCode: Scalars['String']['input'];
};

export type AddDrivingLicenseInput = {
  barcodeRaw?: InputMaybe<Scalars['String']['input']>;
  licenseNumber: Scalars['String']['input'];
  state: StateCode;
};

export type AddPaymentMethodInput = {
  nonce: Scalars['String']['input'];
};

export type AddPersonalInfoInput = {
  dob: Scalars['String']['input'];
  driverLicenseBarcodeRaw?: InputMaybe<Scalars['String']['input']>;
  driverLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  driverLicenseState?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
};

export type AddPhoneNumberInput = {
  phoneNumber: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type AdverseAction = {
  __typename?: 'AdverseAction';
  reports: Array<AdverseActionReport>;
};

export type AdverseActionReason = {
  factors: Array<Scalars['String']['output']>;
};

export type AdverseActionReport = CreditAdverseActionReport | DrivingAdverseActionReport | SelfReport;

export type AdverseActionReportCms = {
  address: Array<Scalars['String']['output']>;
  contact: CmsAction;
  description: Array<Scalars['String']['output']>;
  disclaimer: Array<Scalars['String']['output']>;
  reasons: Array<QuoteReasonCms>;
  title: Scalars['String']['output'];
};

export type AdverseActions = {
  __typename?: 'AdverseActions';
  increasedPremium?: Maybe<AdverseAction>;
};

/** A business entity who is selling policies on behalf of Carrier Also known as broker ## Notes Currently we only have one Agent Just Insure and will always return this entity atm, but in the future this will be used to represent different agents */
export type Agent = {
  __typename?: 'Agent';
  address: Address;
  company: Scalars['String']['output'];
  /** Phone number in US format (e.g. (888) 787-5878) */
  phone: Scalars['String']['output'];
};

export type AllocatedPremium = {
  __typename?: 'AllocatedPremium';
  perDay: Scalars['Int']['output'];
  perMile: Scalars['Int']['output'];
};

export type AnnualEstimateInput = {
  userId: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type AnnualEstimateSummary = {
  __typename?: 'AnnualEstimateSummary';
  averageMonthlyCost: Scalars['String']['output'];
  averageMonthlyMileage: Scalars['String']['output'];
  currentYear: Scalars['Int']['output'];
  monthlyEstimates: MonthlyEstimates;
};

export type App = {
  __typename?: 'App';
  config?: Maybe<Config>;
};


export type AppConfigArgs = {
  appVersion?: InputMaybe<Scalars['Int']['input']>;
  platform: Platform;
};

export type AssociateAutoStartUserInput = {
  externalUserId: Scalars['ID']['input'];
};

export type AssociateInsuranceApplicationsToUserResult = AssociateInsuranceApplicationsToUserSuccess | UnexpectedError;

export type AssociateInsuranceApplicationsToUserSuccess = {
  __typename?: 'AssociateInsuranceApplicationsToUserSuccess';
  updatedInsuranceApplicationIds: Array<Scalars['ID']['output']>;
};

export type AssociateToEmailInput = {
  email: Scalars['String']['input'];
  insuranceApplicationId: Scalars['ID']['input'];
};

export type AuditHistoryConnection = {
  __typename?: 'AuditHistoryConnection';
  nodes: Array<AuditHistoryMessage>;
  pageInfo: PageInfo;
};

export type AuditHistoryInput = {
  /** A cursor, usually the end cursor from a previous query, which specifies the point at which the previous page ended. Thus, audit entries returned will be after the audit entry specified by this cursor. */
  after?: InputMaybe<Scalars['ID']['input']>;
  /** The number of audit changes to display in a page - i.e. "the first n audit changes"; or if a cursor is specified in the `after` arg, "the first n audit changes after m". */
  first: Scalars['Int']['input'];
};

export type AuditHistoryMessage = {
  __typename?: 'AuditHistoryMessage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  operation: AuditHistoryOperation;
  originator: AuditHistoryOriginator;
  payload?: Maybe<Scalars['JSON']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type AuditHistoryOperation = {
  __typename?: 'AuditHistoryOperation';
  name: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type AuditHistoryOriginator = {
  __typename?: 'AuditHistoryOriginator';
  id: Scalars['ID']['output'];
  type: AuditHistoryOriginatorType;
};

export enum AuditHistoryOriginatorType {
  Admin = 'ADMIN',
  Service = 'SERVICE',
  User = 'USER'
}

export type AutomaticPayment = {
  __typename?: 'AutomaticPayment';
  approval?: Maybe<AutomaticPaymentApproval>;
  error?: Maybe<AutomaticPaymentError>;
  /** Payment method token which we use to make a payment with. */
  token: Scalars['String']['output'];
};

/** Indicates if we need approval from a user to charge a specific amount. This can happen in scenario like this: User set auto pay `amount` to __$20.00__ and with a current balance of $30.00 later on tally up happened with an amount of $100.00. If we take only __$20.00__ as per user auto topup config, this will leave user in negative balance. To avoid this we will request to approve specific large amounts ot get user back to positive. In this case it would be __$90.00__. */
export type AutomaticPaymentApproval = {
  __typename?: 'AutomaticPaymentApproval';
  amount: Scalars['Int']['output'];
};

export type AutomaticPaymentConfig = {
  __typename?: 'AutomaticPaymentConfig';
  /** At what threshold cents value should automatically take the payment */
  balanceThreshold: Scalars['Int']['output'];
  /** Minimum cents amount user should be automatically filled by on balance threshold */
  minimumAmount: Scalars['Int']['output'];
};

export type AutomaticPaymentError = {
  __typename?: 'AutomaticPaymentError';
  /** Amount which we tried to process when payment failed. Amount is in cents */
  amount: Scalars['Int']['output'];
  /** Check if error is permanent or transient. For permanent errors user will need to change payment method to re-enable auto pay */
  isTransient: Scalars['Boolean']['output'];
  /** Error reason, this is a human readable explanation why payment failed */
  reason: Scalars['String']['output'];
};

export type AutomaticPaymentRefill = {
  __typename?: 'AutomaticPaymentRefill';
  /** Amount we will refill user account __with__. Amount is in cents */
  amount: Scalars['Int']['output'];
  /** Threshold at which we will trigger refill. Amount is in cents */
  threshold: Scalars['Int']['output'];
};

/** Use automatic payments setup. It will be present after running `setupAutomaticPayments` */
export type AutomaticPayments = {
  __typename?: 'AutomaticPayments';
  isApprovalEnabled: Scalars['Boolean']['output'];
  /** Is automatic payment enabled. This can be changed by user, but also by system in situations where users removes a payment method which was associated with automatic payments */
  isEnabled: Scalars['Boolean']['output'];
  payment?: Maybe<AutomaticPayment>;
  /** Configuration on how to perform a refill */
  refill: AutomaticPaymentRefill;
};

/** Configuration information on how to setup auto pay */
export type AutomaticPaymentsConfig = {
  __typename?: 'AutomaticPaymentsConfig';
  /** Default threshold where we will refill user balances */
  defaultRefillThreshold: Scalars['Int']['output'];
  /** Minimum refill amount user is allowed to select when setting up auto pay. Value is in cents */
  minRefillAmount: Scalars['Int']['output'];
};

export type AutomaticPaymentsRequirement = BindRequirement & {
  __typename?: 'AutomaticPaymentsRequirement';
  currentRefillAmount: Scalars['Int']['output'];
  isEnabled: Scalars['Boolean']['output'];
  minimumRefillAmount: Scalars['Int']['output'];
  refillThreshold: Scalars['Int']['output'];
  status: BindRequirementStatus;
  type: BindRequirementType;
};

export type AutoStart = {
  __typename?: 'AutoStart';
  /** Retrieves info about external Sentiance user linked to userId */
  linkedUser?: Maybe<LinkedUser>;
};


export type AutoStartLinkedUserArgs = {
  input: LinkedUserInput;
};

export type AutoTripsConfig = {
  __typename?: 'AutoTripsConfig';
  autoStopTimeout: Scalars['Int']['output'];
  debug: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  syncInterval: Scalars['Int']['output'];
};

export type BaseCms = {
  description: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type BaseError = {
  message: Scalars['String']['output'];
};

export type BaseUnderwritingAccountState = {
  isRestricted: Scalars['Boolean']['output'];
};

export type BasicCompletedAddress = {
  __typename?: 'BasicCompletedAddress';
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type BasicCompletedInsuranceApplication = {
  __typename?: 'BasicCompletedInsuranceApplication';
  acv?: Maybe<CompletedAcv>;
  acvCount?: Maybe<Scalars['Int']['output']>;
  checkResults: Array<CheckResult>;
  completenessState: CompletenessState;
  estimatedMonthlyMileage: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  personalDetails: BasicCompletedPersonalDetails;
  policyStartDate?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  vehicles: Array<InsuranceApplicationVehicle>;
  verificationState: VerificationState;
};

export type BasicCompletedInsuranceApplicationResult = {
  __typename?: 'BasicCompletedInsuranceApplicationResult';
  basicCompletedInsuranceApplication: BasicCompletedInsuranceApplication;
};

export type BasicCompletedPersonalDetails = {
  __typename?: 'BasicCompletedPersonalDetails';
  address: BasicCompletedAddress;
  age: Scalars['Int']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  driverLicense?: Maybe<InsuranceApplicationDriverLicense>;
  fullName?: Maybe<DraftFullName>;
  gender?: Maybe<Gender>;
};

export type BetaFlagContent = {
  __typename?: 'BetaFlagContent';
  description: Scalars['String']['output'];
  flags?: Maybe<Array<BetaFlagEntry>>;
};

export type BetaFlagEntry = {
  __typename?: 'BetaFlagEntry';
  description?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  title: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type BetaFlagInput = {
  isAutoTrip?: InputMaybe<Scalars['Boolean']['input']>;
  isImprovedWalletPayments?: InputMaybe<Scalars['Boolean']['input']>;
  isVehicleDetection?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Bill = {
  __typename?: 'Bill';
  /** The total amount on the bill */
  amount: Scalars['Int']['output'];
  /** The date of the bill when it is due */
  dueDate: Scalars['String']['output'];
  /** The invoice details */
  invoice: Invoice;
  /** The date the bill was paid if it was paid */
  paidAt?: Maybe<Scalars['String']['output']>;
  /** The status of the bill */
  status: Scalars['String']['output'];
};

export type Billing = {
  __typename?: 'Billing';
  /** Upcoming or next bill of the user */
  next: Bill;
  /** Previous bills of the user */
  previous: Array<Maybe<Bill>>;
};


export type BillingNextArgs = {
  userId: Scalars['ID']['input'];
};


export type BillingPreviousArgs = {
  input: BillInput;
};

export enum BillingInterval {
  /** BiWeekly billing */
  Biweekly = 'BIWEEKLY',
  /** Daily billing */
  Daily = 'DAILY',
  /** Monthly billing */
  Monthly = 'MONTHLY',
  /** Weekly billing */
  Weekly = 'WEEKLY'
}

export type BillingSetUpInput = {
  /** Interval to bill the user defaults to bi-weekly */
  interval?: InputMaybe<BillingInterval>;
  /** ID of the user to set up billing. If not provided, current user will be used */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type BillingSetUpResponse = {
  __typename?: 'BillingSetUpResponse';
  /** The ID Stripe uses to uniquely identify each user which is the customer ID in Stripe */
  customerId: Scalars['String']['output'];
  /** The ephemeral key to be used for the customer */
  ephemeralKey: Scalars['String']['output'];
  /** The first invoice to be paid */
  invoice: InitialInvoice;
  publishableKey: Scalars['String']['output'];
};

export type BillInput = {
  /** limit on the number of bills to get. If not provided, most recent one will be assumed */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** ID of the user to get info for. If not provided, current user will be used */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type BindRequirement = {
  status: BindRequirementStatus;
  type: BindRequirementType;
};

export type BindRequirementRunInput = {
  quoteId: Scalars['ID']['input'];
};

export enum BindRequirementStatus {
  Completed = 'COMPLETED',
  Required = 'REQUIRED'
}

export enum BindRequirementsType {
  Registration = 'REGISTRATION',
  Upgrade = 'UPGRADE'
}

export type BindRequirementsVehicleEvidencePhoto = {
  __typename?: 'BindRequirementsVehicleEvidencePhoto';
  angle: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  status: BindRequirementStatus;
};

export enum BindRequirementType {
  AutomaticPayments = 'AUTOMATIC_PAYMENTS',
  Odometer = 'ODOMETER',
  Payment = 'PAYMENT',
  VisualInspection = 'VISUAL_INSPECTION'
}

export type CancelCurrentActivePolicyInput = {
  /** Explanation for cancellation, like "no longer need insurance" */
  explanation?: InputMaybe<Scalars['String']['input']>;
  /** Reason for cancellation */
  reason: CancellationReason;
  /** User ID for the user who's policy needs to be cancelled. If not specified will be derived from JWT token */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CancellationExplanation = {
  __typename?: 'CancellationExplanation';
  /** This code is a unique code we should use when cancelling user */
  code: Scalars['String']['output'];
  /** User friendly message we should show to the user */
  message: Scalars['String']['output'];
};

export enum CancellationReason {
  /** Cancelled by Just due to not complying with one of the UW rules */
  Carrier = 'CARRIER',
  /** Policy cancelled for non payment, this can be from radr or automatically by our system */
  NonPayment = 'NON_PAYMENT',
  /** Policy been rescinded, meaning coverages never had effect and future claims will not be paid out */
  Rescind = 'RESCIND',
  /** Policy cancelled due to system error */
  SystemError = 'SYSTEM_ERROR',
  /** Policy cancelled by user, this can be from app or Radr */
  User = 'USER'
}

export type CancelUserPolicyInput = {
  cancellationExplanationCode: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Carrier is a legal entity who is providing insurance coverage. Also known as underwriter */
export type Carrier = {
  __typename?: 'Carrier';
  address: Address;
  ambest: Scalars['String']['output'];
  company: Scalars['String']['output'];
  /** Carrier code, this is unique code to identify the carrier in a state. For example, in Arizona this is known as the "ADOT Code". */
  dmvCode: Scalars['String']['output'];
  naic: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type Charge = {
  __typename?: 'Charge';
  actual?: Maybe<MileageCharge>;
  createdAt: Scalars['String']['output'];
  daily?: Maybe<EstimationDailyCharge>;
  daysCovered: Scalars['Int']['output'];
  period: Scalars['String']['output'];
  policy: EstimatePolicy;
  prorated?: Maybe<MileageCharge>;
  pseudo?: Maybe<MileageCharge>;
  rate: ChargeRate;
  reason?: Maybe<EstimateChargeReason>;
  vehicle: EstimateInsurableVehicle;
};

export type ChargeFailure = {
  __typename?: 'ChargeFailure';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  providerMessage: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  type: PaymentMethodErrorType;
};

export type ChargeInput = {
  /** Amount in cents */
  amount: Scalars['Int']['input'];
  /** Payment method token */
  token: Scalars['String']['input'];
  /** User which we are charging */
  userId: Scalars['Int']['input'];
};

export type ChargeRate = {
  __typename?: 'ChargeRate';
  period: Scalars['String']['output'];
  pricePerDay: Scalars['String']['output'];
  pricePerMile: Scalars['String']['output'];
};

export type ChargeResponse = ChargeFailure | ChargeSuccess;

export type Charges = {
  __typename?: 'Charges';
  /** The total amount of the everyday charges of a user */
  daily?: Maybe<DailyCharge>;
  /** The total tracked and untracked charges of a user over the search period */
  driving?: Maybe<DrivingCharges>;
};

export type ChargesSummary = {
  __typename?: 'ChargesSummary';
  /** The total tracked and untracked charges billed to the user over the search period */
  billed?: Maybe<Charges>;
  /** The total tracked and untracked estimated charges over the search period */
  estimated?: Maybe<Charges>;
};

export type ChargeSuccess = {
  __typename?: 'ChargeSuccess';
  amount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  requester: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type CheckResult = {
  id?: Maybe<Scalars['ID']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Claim = {
  __typename?: 'Claim';
  amountOfLoss: Scalars['Int']['output'];
  yearOccurred: Scalars['Int']['output'];
};

export type ClaimInput = {
  amountOfLoss: Scalars['Int']['input'];
  yearOccurred: Scalars['Int']['input'];
};

export type Clarification = {
  __typename?: 'Clarification';
  info: ClarificationInfo;
  path: Array<Scalars['String']['output']>;
};

export type ClarificationInfo = {
  __typename?: 'ClarificationInfo';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CmsAction = {
  __typename?: 'CmsAction';
  action?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: CmsActionType;
};

export enum CmsActionType {
  Intercom = 'INTERCOM',
  Telephone = 'TELEPHONE',
  Uri = 'URI'
}

export type ComparisonPackage = {
  __typename?: 'ComparisonPackage';
  /** Coverage id as in full-cover-az, minimum-liability-az, etc */
  packageId: Scalars['String']['output'];
  prices: Array<CompetitorPrice>;
};

export type CompetitorPrice = {
  __typename?: 'CompetitorPrice';
  carrier: Scalars['String']['output'];
  monthlyPriceInCents: Scalars['Int']['output'];
};

export type CompletedAccident = {
  __typename?: 'CompletedAccident';
  amountOfLoss: Scalars['Int']['output'];
  isAnyoneInjured: Scalars['Boolean']['output'];
  isAtFault: Scalars['Boolean']['output'];
  occurredOn: Scalars['Date']['output'];
};

export type CompletedAcv = {
  __typename?: 'CompletedACV';
  accidents: Array<CompletedAccident>;
  claims: Array<CompletedClaim>;
  violations: Array<CompletedViolation>;
};

export type CompletedAddress = {
  __typename?: 'CompletedAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type CompletedClaim = {
  __typename?: 'CompletedClaim';
  amountOfLoss: Scalars['Int']['output'];
  occurredOn: Scalars['Date']['output'];
};

export type CompletedFullName = {
  __typename?: 'CompletedFullName';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type CompletedInsuranceApplication = {
  __typename?: 'CompletedInsuranceApplication';
  acv: CompletedAcv;
  acvCount?: Maybe<Scalars['Int']['output']>;
  checkResults: Array<CheckResult>;
  completenessState: CompletenessState;
  estimatedMonthlyMileage: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  personalDetails: CompletedPersonalDetails;
  policyStartDate?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  vehicles: Array<InsuranceApplicationVehicle>;
  verificationState: VerificationState;
};

export type CompletedInsuranceApplicationResult = {
  __typename?: 'CompletedInsuranceApplicationResult';
  completedInsuranceApplication: CompletedInsuranceApplication;
};

export type CompletedPersonalDetails = {
  __typename?: 'CompletedPersonalDetails';
  address: CompletedAddress;
  age?: Maybe<Scalars['Int']['output']>;
  dateOfBirth: Scalars['Date']['output'];
  driverLicense: InsuranceApplicationDriverLicense;
  fullName: CompletedFullName;
  gender: Gender;
};

export type CompletedViolation = {
  __typename?: 'CompletedViolation';
  occurredOn: Scalars['Date']['output'];
  violationType: ViolationType;
};

export enum CompletenessState {
  BasicCompleted = 'BASIC_COMPLETED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT'
}

export type Config = {
  __typename?: 'Config';
  accelerometer: AccelerometerConfig;
  autoTrips: AutoTripsConfig;
  debug: DebugConfig;
  fullCoverage: FullCoverageConfig;
  home: HomeConfig;
  isAutoPayApprovalOptional: Scalars['Boolean']['output'];
  isAutoTopup: Scalars['Boolean']['output'];
  isImprovedOdo: Scalars['Boolean']['output'];
  isImprovedRegistrationPayments: Scalars['Boolean']['output'];
  isImprovedWalletPayments: Scalars['Boolean']['output'];
  isNewCancellation: Scalars['Boolean']['output'];
  isOdometerV2: Scalars['Boolean']['output'];
  isQuoteAcceptable: Scalars['Boolean']['output'];
  isUserAttributesEnabled: Scalars['Boolean']['output'];
  isUserEstimations: Scalars['Boolean']['output'];
  isValueComms: Scalars['Boolean']['output'];
  locationTracking: LocationTrackingConfig;
  logger: LoggerConfig;
  odometer: OdometerConfig;
  payments: PaymentsConfig;
  permissions: PermissionsConfig;
  referrals: ReferralsConfig;
  registration: RegistrationConfig;
  sentianceTracking: SentianceTrackingConfig;
  tripDispute: TripDisputeConfig;
  upgradeInfo: UpgradeInfoConfig;
  vehicleDetection: VehicleDetectionConfig;
  vehicleSelection: VehicleSelectionConfig;
};

export type Coverage = {
  __typename?: 'Coverage';
  /** Unique coverage code, guaranteed to stay the same for same coverage. Once any aspect of coverage changes, for example limits or deductibles code will change as well */
  code: Scalars['String']['output'];
  /** Coverage deductible insured will pay in case of the claim. Unit is US Dollars */
  deductible?: Maybe<Scalars['Int']['output']>;
  limits: Array<CoverageLimit>;
  /** Name of the coverage, this is not a stable identifier and can change for the same coverage */
  name: Scalars['String']['output'];
  premium: PolicyPremium;
};

export type CoverageComposition = {
  allocatedPremium: AllocatedPremium;
  deductible?: Maybe<Scalars['Int']['output']>;
  limits: Array<LimitV2>;
};

export type CoverageLimit = StructuredLimit | UnstructuredLimit;

export type CoverageOption = CoverageComposition & {
  __typename?: 'CoverageOption';
  allocatedPremium: AllocatedPremium;
  content: CoverageOptionCms;
  deductible?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  limits: Array<LimitV2>;
};

export type CoverageOptionCms = {
  __typename?: 'CoverageOptionCms';
  summary: Array<SummaryCms>;
  title: Scalars['String']['output'];
};

export type CoveragePremium = {
  __typename?: 'CoveragePremium';
  title?: Maybe<Scalars['String']['output']>;
  value: Array<Premium>;
};

export type CoverageSelection = {
  __typename?: 'CoverageSelection';
  optionalCoverageGroups: Array<Scalars['String']['output']>;
  options: Array<Scalars['String']['output']>;
  packages: Array<Scalars['String']['output']>;
};

export enum CoverageType {
  Bi = 'BI',
  Coll = 'COLL',
  Comp = 'COMP',
  Glass = 'GLASS',
  Pd = 'PD',
  Uim = 'UIM',
  Um = 'UM'
}

export type CoverageV2 = {
  id: Scalars['ID']['output'];
  isOptional: Scalars['Boolean']['output'];
  type: CoverageType;
};

export type CoverageWithOptions = CoverageV2 & {
  __typename?: 'CoverageWithOptions';
  content: CoverageWithOptionsCms;
  id: Scalars['ID']['output'];
  isOptional: Scalars['Boolean']['output'];
  options: Array<CoverageOption>;
  type: CoverageType;
};

export type CoverageWithOptionsCms = BaseCms & {
  __typename?: 'CoverageWithOptionsCms';
  description: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CoverageWithoutOptions = CoverageComposition & CoverageV2 & {
  __typename?: 'CoverageWithoutOptions';
  allocatedPremium: AllocatedPremium;
  content: CoverageWithoutOptionsCms;
  deductible?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isOptional: Scalars['Boolean']['output'];
  limits: Array<LimitV2>;
  type: CoverageType;
};

export type CoverageWithoutOptionsCms = BaseCms & {
  __typename?: 'CoverageWithoutOptionsCms';
  description: Array<Scalars['String']['output']>;
  summary: Array<SummaryCms>;
  title: Scalars['String']['output'];
};

export type CreateEndorsementQuoteInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type CreateGuestUserInput = {
  guestUserId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGuestUserResult = {
  __typename?: 'CreateGuestUserResult';
  accessToken: Scalars['String']['output'];
  email: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type CreateNewInsuranceApplicationResult = BasicCompletedInsuranceApplicationResult | CompletedInsuranceApplicationResult | DraftInsuranceApplicationResult | UnexpectedError;

export type CreateQuoteForUserInput = {
  productId: Scalars['ID']['input'];
  productType?: InputMaybe<Scalars['String']['input']>;
};

export type CreditAdverseActionReport = {
  __typename?: 'CreditAdverseActionReport';
  content: QuoteCreditReportCms;
  creditReport: QuoteCreditReport;
};

export type CreditCard = PaymentMethodBase & {
  __typename?: 'CreditCard';
  cardType: Scalars['String']['output'];
  error?: Maybe<PaymentMethodError>;
  expirationMonth: Scalars['String']['output'];
  expirationYear: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  last4: Scalars['String']['output'];
  lastUsed?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentType: PaymentType;
  token: Scalars['String']['output'];
};

export type CreditCheckResult = CheckResult & {
  __typename?: 'CreditCheckResult';
  id?: Maybe<Scalars['ID']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CreditReport = {
  __typename?: 'CreditReport';
  keyFactors: Array<Scalars['String']['output']>;
  reportedAt: Scalars['DateTime']['output'];
  score?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export enum CreditReportStatus {
  Available = 'AVAILABLE',
  InsufficientData = 'INSUFFICIENT_DATA',
  Unavailable = 'UNAVAILABLE'
}

export type CurrentEstimate = Estimate & {
  __typename?: 'CurrentEstimate';
  /** breakdown.actual + breakdown.pseudo + daily charges */
  assumed: Scalars['String']['output'];
  breakdown: SummaryBreakdown;
  charges: Array<Charge>;
  /** Indicates if the estimate is open to changes or not. That mainly indicates if there could be changes to totals and charges. */
  final: Scalars['Boolean']['output'];
  level?: Maybe<EstimationLevel>;
  odometerAction?: Maybe<OdometerAction>;
  /** ISO8601 Interval */
  period: Scalars['String']['output'];
  /** breakdown.actual + breakdown.prorated + daily charges */
  projected: Scalars['String']['output'];
};

export type DailyCharge = {
  __typename?: 'DailyCharge';
  /** Total fixed daily charge over the search period */
  charge?: Maybe<Scalars['Int']['output']>;
};

export type DebugConfig = {
  __typename?: 'DebugConfig';
  batchInSeconds: Scalars['Int']['output'];
  enabled: Scalars['Boolean']['output'];
};

export type DecodeVehicleVinInput = {
  vin: Scalars['String']['input'];
};

export type DecodeVehicleVinResult = DecodeVehicleVinSuccess | InputFieldValidationError | UnexpectedError;

export type DecodeVehicleVinSuccess = {
  __typename?: 'DecodeVehicleVinSuccess';
  isInsurable: Scalars['Boolean']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  vin: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type Deductibles = {
  __typename?: 'Deductibles';
  options?: Maybe<Array<QuoteOption>>;
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DeniedQuote = QuoteV2 & {
  __typename?: 'DeniedQuote';
  createdAt: Scalars['DateTime']['output'];
  denialOfCoverage: AdverseAction;
  driver: QuoteDriver;
  estimatedMonthlyMileage: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  insuranceApplication?: Maybe<CompletedInsuranceApplication>;
  insuranceApplicationId?: Maybe<Scalars['ID']['output']>;
  policyStartDate?: Maybe<Scalars['String']['output']>;
  product: Product;
  status: QuoteStatus;
  vehicles: Array<QuoteVehicle>;
};

export type DisableAutomaticPaymentsForUserInput = {
  userId: Scalars['Int']['input'];
};

export type DisableAutomaticPaymentsInput = {
  token: Scalars['String']['input'];
};

export type DisableAutoTopupInput = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Information about the discovered vehicle. This might be all partial as depending on the provider/data we might have some info and in other cases we might have everything. So make sure to cater for cases where it's info is partial */
export type DiscoveredVehicle = {
  __typename?: 'DiscoveredVehicle';
  /** Information about vehicle "insurability". This provides info if we can insure it as well as stable unique identifiers we should use to refer to that vehicle */
  insurable?: Maybe<Vehicle>;
  /** Make name as provided by external service **Important: do not use this field as a unique identifier, make names can change** */
  make?: Maybe<Scalars['String']['output']>;
  /** Model name as provided by external service **Important: do not use this field as a unique identifier, model names can change** */
  model?: Maybe<Scalars['String']['output']>;
  /** Vehicle number plate */
  plate?: Maybe<Scalars['String']['output']>;
  vin: Scalars['String']['output'];
  /** Year of manufacturing */
  year?: Maybe<Scalars['Int']['output']>;
};

export type Distances = {
  __typename?: 'Distances';
  /** Total km recorded through the App */
  tracked?: Maybe<Scalars['Float']['output']>;
  /** Total km recorded through odometer readings */
  untracked?: Maybe<Scalars['Float']['output']>;
};

export type DistancesSummary = {
  __typename?: 'DistancesSummary';
  /** Total km user was charged for. */
  billed?: Maybe<Distances>;
  /** Total km of estimated distance */
  estimated?: Maybe<Distances>;
};

export type DraftAddress = {
  __typename?: 'DraftAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type DraftFullName = {
  __typename?: 'DraftFullName';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type DraftInsuranceApplication = {
  __typename?: 'DraftInsuranceApplication';
  acv?: Maybe<CompletedAcv>;
  acvCount?: Maybe<Scalars['Int']['output']>;
  checkResults: Array<CheckResult>;
  completenessState: CompletenessState;
  estimatedMonthlyMileage?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  personalDetails: DraftPersonalDetails;
  policyStartDate?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  vehicles: Array<InsuranceApplicationVehicle>;
  verificationState: VerificationState;
};

export type DraftInsuranceApplicationResult = {
  __typename?: 'DraftInsuranceApplicationResult';
  draftInsuranceApplication?: Maybe<DraftInsuranceApplication>;
};

export type DraftPersonalDetails = {
  __typename?: 'DraftPersonalDetails';
  address?: Maybe<DraftAddress>;
  age?: Maybe<Scalars['Int']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  driverLicense?: Maybe<InsuranceApplicationDriverLicense>;
  fullName?: Maybe<DraftFullName>;
  gender?: Maybe<Gender>;
};

export type Driver = {
  __typename?: 'Driver';
  address: Address;
  /** ISO8601 Date only e.g. `2000-01-01` */
  dob: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Name;
};

export type DriverAddress = {
  __typename?: 'DriverAddress';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  state: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type DriverLicense = {
  __typename?: 'DriverLicense';
  barcodeRaw?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type DriverProfile = {
  __typename?: 'DriverProfile';
  address: DriverAddress;
  dateOfBirth: Scalars['Date']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  lastName: Scalars['String']['output'];
};

export type DrivingAdverseActionReport = {
  __typename?: 'DrivingAdverseActionReport';
  content: QuoteDrivingReportCms;
  drivingReport: QuoteDrivingReport;
};

export type DrivingCharges = {
  __typename?: 'DrivingCharges';
  /** Total cost of miles recorded through the App over the search period */
  tracked?: Maybe<Scalars['Int']['output']>;
  /** Total cost of miles recorded through odometer readings over the search period */
  untracked?: Maybe<Scalars['Int']['output']>;
};

export type DrivingCheckResult = CheckResult & {
  __typename?: 'DrivingCheckResult';
  id?: Maybe<Scalars['ID']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type DrivingHistoryReportReason = AdverseActionReason & {
  __typename?: 'DrivingHistoryReportReason';
  factors: Array<Scalars['String']['output']>;
};

export type DrivingReport = DrivingReportFailure | DrivingReportSuccess;

/** The union type describing both the failure and success response. */
export type DrivingReportFailure = {
  __typename?: 'DrivingReportFailure';
  description: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type DrivingReportSuccess = {
  __typename?: 'DrivingReportSuccess';
  reportedAt: Scalars['DateTime']['output'];
  /** Driving report type definition. Violations is an array of violations. */
  violations: Array<DrivingViolation>;
};

export type DrivingSummary = {
  __typename?: 'DrivingSummary';
  /** Distance in km of much user drove. This excludes disputed trips */
  trackedDistance: Scalars['Float']['output'];
  /** Miles not tracked through app but accounted for through odometer */
  untrackedDistance: Scalars['Float']['output'];
};

export type DrivingViolation = {
  __typename?: 'DrivingViolation';
  /** avd is the code used for the violation (mainly for internal use) */
  avd: Scalars['String']['output'];
  /** Describes when the violation occurred */
  date: Scalars['String']['output'];
  description: Scalars['String']['output'];
  /** Severity describes how grave the violation is (minor|minor) */
  severity: DrivingViolationSeverity;
};

/** Remove when all definitions are merged (copied from driving checks gql) */
export enum DrivingViolationSeverity {
  Major = 'MAJOR',
  Minor = 'MINOR'
}

export type DuplicateUser = {
  __typename?: 'DuplicateUser';
  account: UnderwritingAccount;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  status: DuplicateUserStatus;
};

export type DuplicateUserAssociation = {
  __typename?: 'DuplicateUserAssociation';
  relation: DuplicateUserRelation;
  user: DuplicateUser;
};

export type DuplicateUserCheckResult = CheckResult & {
  __typename?: 'DuplicateUserCheckResult';
  associatedEmail?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type DuplicateUserInfo = {
  __typename?: 'DuplicateUserInfo';
  /** This the obfuscated email in the format: ja*****@g****.*** It is only returned when there is exactly one user marked as a duplicate of this one */
  associatedEmail?: Maybe<Scalars['String']['output']>;
  associations: Array<DuplicateUserAssociation>;
  hasDuplicates: Scalars['Boolean']['output'];
  status: DuplicateUserStatus;
};

export type DuplicateUserRelation = {
  __typename?: 'DuplicateUserRelation';
  type: DuplicateUserRelationType;
  value?: Maybe<Scalars['String']['output']>;
};

export enum DuplicateUserRelationType {
  Vin = 'VIN'
}

export type DuplicateUsersSeedingInput = {
  endUserId: Scalars['ID']['input'];
  startUserId: Scalars['ID']['input'];
};

export type DuplicateUsersSeedingResult = {
  __typename?: 'DuplicateUsersSeedingResult';
  duplicateUsers: ValuesWithCount;
  errors: ValuesWithCount;
  numUsersToSeed: Scalars['Int']['output'];
  success: Scalars['Int']['output'];
  timeElapsed: Scalars['Int']['output'];
};

export enum DuplicateUserStatus {
  NotOverriden = 'NOT_OVERRIDEN',
  Overriden = 'OVERRIDEN'
}

export type DuplicateUsersValidationInput = {
  userId: Scalars['ID']['input'];
};

export type EmailMarketing = {
  __typename?: 'EmailMarketing';
  /** List of available email marketing preferences */
  preferences: Array<MarketingPreference>;
};


export type EmailMarketingPreferencesArgs = {
  filter?: InputMaybe<EmailMarketingPreferencesFilter>;
};

export type EmailMarketingPreferencesFilter = {
  type: MarketingPreferenceType;
};

export enum EmailStatus {
  Sending = 'SENDING',
  Sent = 'SENT'
}

export type EnableAutomaticPaymentsInput = {
  token: Scalars['String']['input'];
};

export type Estimate = {
  assumed: Scalars['String']['output'];
  /** ISO8601 Interval */
  period: Scalars['String']['output'];
  projected: Scalars['String']['output'];
};

export enum EstimateChargeReason {
  Address = 'ADDRESS',
  Adjustment = 'ADJUSTMENT',
  CreditScore = 'CREDIT_SCORE',
  DrivingRecord = 'DRIVING_RECORD',
  /** First charge calculated for the bill period requested */
  Initial = 'INITIAL',
  /** If it cannot be determined why the charge was calculated */
  Other = 'OTHER',
  Vehicle = 'VEHICLE'
}

export type EstimateInput = {
  /** Default is false, if true, the estimate will not take into account any odometer actions therefore return will not contain odometer actions and level */
  ignoreOdometerActions?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['Int']['input'];
};

export type EstimateInsurableVehicle = {
  __typename?: 'EstimateInsurableVehicle';
  id: Scalars['String']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  vin: Scalars['String']['output'];
  year: Scalars['String']['output'];
};

export type EstimatePolicy = {
  __typename?: 'EstimatePolicy';
  date: Scalars['String']['output'];
  policyNr: Scalars['String']['output'];
  status: EstimatePolicyStatus;
};

export enum EstimatePolicyStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Future = 'FUTURE',
  Void = 'VOID'
}

export type EstimationDailyCharge = {
  __typename?: 'EstimationDailyCharge';
  daysCovered: Scalars['String']['output'];
  pricePerDay: Scalars['String']['output'];
  totalCharge: Scalars['String']['output'];
};

export enum EstimationLevel {
  Assumed = 'ASSUMED',
  None = 'NONE',
  Projected = 'PROJECTED',
  ProjectedAssumed = 'PROJECTED_ASSUMED'
}

/** Current estimate describes the current bill period. Future estimate describes the next bill period based on the latest charge from the current bill period. */
export type EstimationMutationResult = {
  __typename?: 'EstimationMutationResult';
  current: CurrentEstimate;
  next?: Maybe<NextPeriodEstimate>;
};

export type EstimationQueryResult = {
  __typename?: 'EstimationQueryResult';
  current?: Maybe<CurrentEstimate>;
  next?: Maybe<NextPeriodEstimate>;
};

export type FailedToDecodeVinError = {
  __typename?: 'FailedToDecodeVinError';
  message: Scalars['String']['output'];
};

export type FeedItem = QuoteAcceptedFeedItem;

export type FieldValidationError = {
  __typename?: 'FieldValidationError';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ForwardEmailInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to: Array<Scalars['EmailAddress']['input']>;
  userId: Scalars['Int']['input'];
};

/** Information about the discovered vehicle. This might be all partial as depending on the provider/data we might have some info and in other cases we might have everything. So make sure to cater for cases where it's info is partial */
export type FoundVehicle = {
  __typename?: 'FoundVehicle';
  /** This vehicle is mostly likely the vehicle customer is trying to insure */
  isDefault: Scalars['Boolean']['output'];
  /** Name of the lienholder */
  lienholderName?: Maybe<Scalars['String']['output']>;
  /** Make name as provided by external service */
  make: Scalars['String']['output'];
  /** Model name as provided by external service */
  model: Scalars['String']['output'];
  /** Vehicle number plate */
  plate?: Maybe<Scalars['String']['output']>;
  vin: Scalars['String']['output'];
  /** Year of manufacturing */
  year: Scalars['Int']['output'];
};

export type FullCoverageConfig = {
  __typename?: 'FullCoverageConfig';
  enabled: Scalars['Boolean']['output'];
};

export type FundsInfo = {
  __typename?: 'FundsInfo';
  balance: Scalars['Int']['output'];
  funds: Scalars['Int']['output'];
};

export type FundsInfoInput = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type FuturePolicyDriver = {
  __typename?: 'FuturePolicyDriver';
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type FuturePolicyVehicle = {
  __typename?: 'FuturePolicyVehicle';
  id: Scalars['ID']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  vin: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export enum Gender {
  Female = 'female',
  Male = 'male'
}

/** Required fields plus optional ones for generating a quick quote. Only required fields are needed for quick quote to be generated, but any field passed and valid will be persisted in insurance application */
export type GenerateExpandedQuickQuoteInput = {
  acv?: InputMaybe<InsuranceApplicationAcvInput>;
  acvCount: Scalars['Int']['input'];
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  age: Scalars['Int']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  context: QuickQuoteContext;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  driverLicenseBarcodeRawData?: InputMaybe<Scalars['String']['input']>;
  driverLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  driverLicenseState?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  estimatedMonthlyMileage: Scalars['Int']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender: Gender;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** ISO 8601 date for when policy should start. This should be date only i.e. 2023-01-01 without time */
  policyStartDate?: InputMaybe<Scalars['Date']['input']>;
  selectedPackageId?: InputMaybe<Scalars['String']['input']>;
  selfReportedCreditScore: Scalars['Int']['input'];
  shortVin: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

/** Minimum required fields for generating a quick quote */
export type GenerateQuickQuoteInput = {
  acvCount: Scalars['Int']['input'];
  age: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  estimatedMonthlyMileage: Scalars['Int']['input'];
  gender: Gender;
  selfReportedCreditScore: Scalars['Int']['input'];
  shortVin: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type GenerateQuickQuoteResponse = {
  __typename?: 'GenerateQuickQuoteResponse';
  quote: QuoteV2;
  /** @deprecated Use quote.id; this is here because stitching wasn't working, now it is :) */
  quoteId: Scalars['ID']['output'];
};

export type GenerateQuickQuoteResult = GenerateQuickQuoteResponse | InputFieldValidationError | UnexpectedError | UninsurableVinError;

export type GenerateQuoteForUserInput = {
  estimatedMonthlyMileage: Scalars['Int']['input'];
  insuranceApplicationId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

export type GetAccessCodeForUserInput = {
  email: Scalars['String']['input'];
};

export type GetCompetitorPriceInput = {
  quoteId: Scalars['ID']['input'];
};

export type GetCompetitorPriceResult = {
  __typename?: 'GetCompetitorPriceResult';
  packages: Array<ComparisonPackage>;
};

export type GetDrivingReportInput = {
  /** To get a Driving Report provide user ID */
  userId: Scalars['ID']['input'];
};

export type GetFullUrlInput = {
  id: Scalars['ID']['input'];
};

export type GetQuoteInput = {
  id: Scalars['ID']['input'];
};

export type GetQuotesFilter = {
  status?: InputMaybe<QuoteStatus>;
};

export type GetQuotesInput = {
  /** A cursor, usually the end cursor from a previous query, which specifies the point at which the previous page ended. Thus, quotes returned will be after the quote specified by this cursor. */
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<GetQuotesFilter>;
  /** The number of quotes to display in a page - i.e. "the first n quotes"; or if a cursor is specified in the `after` arg, "the first n quotes after m". */
  first: Scalars['Int']['input'];
};

export type HomeConfig = {
  __typename?: 'HomeConfig';
  version: Scalars['String']['output'];
};

export type Identity = {
  __typename?: 'Identity';
  address?: Maybe<Address>;
  createdAt: Scalars['DateTime']['output'];
  /** RFC 3339/ISO-8601 [Date](https://the-guild.dev/graphql/scalars/docs/scalars/date) Eg, 1978-06-17 */
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** 9 digits where first maybe alphnumeric: 123456789 or D12345678 */
  driverLicense?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Name>;
  /** E.164 format [PhoneNumber](https://the-guild.dev/graphql/scalars/docs/scalars/phone-number) Eg, +15205550100 */
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>;
};

export type IdentityCheckReport = {
  __typename?: 'IdentityCheckReport';
  /** Indicates that a customer can retry an identity check */
  canRetry: Scalars['Boolean']['output'];
  /** Indicates that this report has been overridden by PS */
  overridden: Scalars['Boolean']['output'];
  result: IdentityCheckResult;
  resultBreakdown: IdentityCheckResultBreakdown;
  status: IdentityCheckStatus;
};

export enum IdentityCheckResult {
  MatchFound = 'MATCH_FOUND',
  NoMatchFound = 'NO_MATCH_FOUND',
  None = 'NONE'
}

export type IdentityCheckResultBreakdown = {
  __typename?: 'IdentityCheckResultBreakdown';
  addressMatched?: Maybe<Scalars['Boolean']['output']>;
  dobMatched?: Maybe<Scalars['Boolean']['output']>;
};

export enum IdentityCheckStatus {
  /** No identity check has been initiated */
  Initial = 'INITIAL',
  /** An identity check has been initiated and is processing. */
  Processing = 'PROCESSING',
  /** An identity check was initiated, finished processing and has a result set. */
  Ready = 'READY',
  /** No more attempts left to run an identity check */
  RetriesMaxedOut = 'RETRIES_MAXED_OUT'
}

export type IncludedCoverageDescriptionCms = {
  __typename?: 'IncludedCoverageDescriptionCms';
  description: Array<Scalars['String']['output']>;
  isIncluded: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type IncludedCoverageSummaryCms = {
  __typename?: 'IncludedCoverageSummaryCms';
  coverages: Array<IncludedCoverageDescriptionCms>;
  title: Scalars['String']['output'];
};

export type InitialInvoice = {
  __typename?: 'InitialInvoice';
  /** The total amount due for the invoice */
  amountDue: Scalars['String']['output'];
  /** The line items in the invoice */
  invoiceItems: InvoiceItems;
  /** The payment intent secret to be used to pay for the invoice */
  paymentIntentSecret: Scalars['String']['output'];
  /** The link to pay for the invoice if necessary */
  paymentLink?: Maybe<Scalars['String']['output']>;
};

export type InitialPayment = {
  __typename?: 'InitialPayment';
  /** Sum of downpayment + deposit */
  amount: Scalars['Int']['output'];
  /** Amount in cents we will put aside as a deposit to settle final bill when customer leaves */
  deposit: Scalars['Int']['output'];
  /** Amount in cents we require from the customer to get a policy */
  downpayment: Scalars['Int']['output'];
};

export type InputFieldValidationError = {
  __typename?: 'InputFieldValidationError';
  errors: Array<FieldValidationError>;
};

export type InputObjectValidationError = {
  __typename?: 'InputObjectValidationError';
  errors: Array<ObjectValidationError>;
};

/** We can insure vehicle and we found our internal IDs for it */
export type InsurableVehicle = {
  __typename?: 'InsurableVehicle';
  make: Scalars['String']['output'];
  /** Stable and Unique model ID */
  makeId: Scalars['Int']['output'];
  model: Scalars['String']['output'];
  /** Stable and Unique make ID */
  modelId: Scalars['Int']['output'];
  vin: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type InsuranceApplication = BasicCompletedInsuranceApplicationResult | CompletedInsuranceApplicationResult | DraftInsuranceApplicationResult;

export type InsuranceApplicationAccidentInput = {
  amountOfLoss: Scalars['Int']['input'];
  isAnyoneInjured: Scalars['Boolean']['input'];
  isAtFault: Scalars['Boolean']['input'];
  occurredOn: Scalars['Date']['input'];
};

export type InsuranceApplicationAcvInput = {
  accidents: Array<InsuranceApplicationAccidentInput>;
  claims: Array<InsuranceApplicationClaimInput>;
  violations: Array<InsuranceApplicationViolationInput>;
};

export type InsuranceApplicationClaimInput = {
  amountOfLoss: Scalars['Int']['input'];
  occurredOn: Scalars['Date']['input'];
};

export type InsuranceApplicationDriverLicense = {
  __typename?: 'InsuranceApplicationDriverLicense';
  number: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type InsuranceApplicationFullName = {
  __typename?: 'InsuranceApplicationFullName';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type InsuranceApplicationResult = BasicCompletedInsuranceApplicationResult | CompletedInsuranceApplicationResult | DraftInsuranceApplicationResult | UnexpectedError;

export type InsuranceApplicationsPage = {
  __typename?: 'InsuranceApplicationsPage';
  nodes: Array<InsuranceApplication>;
  pageInfo: PageInfo;
};

export type InsuranceApplicationsResult = InsuranceApplicationsPage | UnexpectedError;

export type InsuranceApplicationVehicle = {
  __typename?: 'InsuranceApplicationVehicle';
  id: Scalars['ID']['output'];
  lienholder?: Maybe<InsuranceApplicationVehicleLienholder>;
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  vin: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type InsuranceApplicationVehicleLienholder = {
  __typename?: 'InsuranceApplicationVehicleLienholder';
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type InsuranceApplicationViolationInput = {
  occurredOn: Scalars['Date']['input'];
  violationType: ViolationType;
};

export type InsuranceSummary = {
  __typename?: 'InsuranceSummary';
  billing: InsuranceSummaryBilling;
  driving: DrivingSummary;
};

export type InsuranceSummaryBilling = {
  __typename?: 'InsuranceSummaryBilling';
  /** How much user already used */
  earnedPremium: Scalars['Int']['output'];
  /** How much we charged if fees */
  fees: Scalars['Int']['output'];
  tallyupCharge: Scalars['Int']['output'];
  tallyupEstimatedCharge: Scalars['Int']['output'];
  tripsCost: Scalars['Int']['output'];
};

export type InsuranceSummaryInput = {
  /** ISO8601 String for end date, defaults to current point in time */
  end?: InputMaybe<Scalars['String']['input']>;
  /** ISO8601 String for start date, defaults to start of Just Auto */
  start?: InputMaybe<Scalars['String']['input']>;
};

export type InsuredVehicle = {
  __typename?: 'InsuredVehicle';
  /**
   * Coverages this vehicle has, will always has at least one coverage
   * @deprecated Use vehicleDetails instead
   */
  coverages: Array<Coverage>;
  /** Lienholder of the vehicle */
  lienholder?: Maybe<Lienholder>;
  /**
   * Make of a vehicle. This is not a stable identifier and if brand name changes this can change
   * @deprecated Use vehicleDetails instead
   */
  make: Scalars['String']['output'];
  /**
   * Model of a vehicle. This is not a stable identifier and if model name changes this can change
   * @deprecated Use vehicleDetails instead
   */
  model: Scalars['String']['output'];
  /** Information about insured vehicle */
  vehicleDetails: VehicleDetails;
  /**
   * Vehicle VIN, can be 15 or 17 characters
   * @deprecated Use vehicleDetails instead
   */
  vin: Scalars['ID']['output'];
  /**
   * Vehicle manufacturing year (e.g. 2015)
   * @deprecated Use vehicleDetails instead
   */
  year: Scalars['Int']['output'];
};

export type InvalidInputError = BaseError & {
  __typename?: 'InvalidInputError';
  message: Scalars['String']['output'];
  property: Scalars['String']['output'];
};

export type InvalidInputsError = {
  __typename?: 'InvalidInputsError';
  errors: Array<InvalidInputError>;
};

export type InvalidQuoteStateError = {
  __typename?: 'InvalidQuoteStateError';
  message: Scalars['String']['output'];
  quoteId: Scalars['ID']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  /** Adjustments from the last invoice */
  adjustment?: Maybe<InvoiceAdjustment>;
  /** The details of the breakdown of the invoice */
  breakdown: InvoiceBreakdown;
  /** The last digits of the payment card used for the invoice */
  paymentCard: Scalars['String']['output'];
  /** The period invoice is for */
  period: Scalars['String']['output'];
};

export type InvoiceAdjustment = {
  __typename?: 'InvoiceAdjustment';
  /** The total driven miles from the last invoice */
  actualMiles: Scalars['String']['output'];
  /** The amount of the adjustment */
  amount: Scalars['String']['output'];
  /** The reason for the adjustment */
  reason: Scalars['String']['output'];
};

export type InvoiceBreakdown = {
  __typename?: 'InvoiceBreakdown';
  /** Adjustments from the last invoice */
  adjustments?: Maybe<Scalars['String']['output']>;
  /** The number of days covered for the invoice period */
  daysCovered: Scalars['Int']['output'];
  /** Estimated miles for the period */
  estimatedMiles: Scalars['String']['output'];
  /** Per mile cost for the period */
  pricePerMile: Scalars['String']['output'];
};

export type InvoiceItems = {
  __typename?: 'InvoiceItems';
  /** The details of the breakdown of the invoice */
  breakdown: InvoiceBreakdown;
  /** The total cost of the daily charges for the period */
  dailyCharges: Scalars['String']['output'];
  /** The cost of the estimated miles */
  estimatedMilesCost: Scalars['String']['output'];
  /** The amount that is taken as security deposit for the first invoice */
  securityDeposit?: Maybe<Scalars['String']['output']>;
};

export type IssuePolicyEndorsementInput = {
  quoteId: Scalars['ID']['input'];
  reason: IssuePolicyEndorsementReason;
  userId: Scalars['ID']['input'];
};

export enum IssuePolicyEndorsementReason {
  CreditScoreChange = 'CREDIT_SCORE_CHANGE',
  DrivingReportChange = 'DRIVING_REPORT_CHANGE',
  PolicyDetailsUpdate = 'POLICY_DETAILS_UPDATE'
}

export type LapsedState = BaseUnderwritingAccountState & {
  __typename?: 'LapsedState';
  finalisation: AccountFinalisation;
  isRestricted: Scalars['Boolean']['output'];
};

export type LicenseDetails = {
  __typename?: 'LicenseDetails';
  number?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type Lienholder = {
  __typename?: 'Lienholder';
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type Limit = {
  __typename?: 'Limit';
  options?: Maybe<Array<QuoteOption>>;
  title: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export enum LimitUnit {
  Accident = 'ACCIDENT',
  Occurrence = 'OCCURRENCE',
  Person = 'PERSON'
}

export type LimitV2 = StructuredLimitV2 | UnstructuredLimitV2;

export type LinkedUser = {
  __typename?: 'LinkedUser';
  externalUserId: Scalars['ID']['output'];
  installId: Scalars['ID']['output'];
  userId: Scalars['Int']['output'];
};

export type LinkedUserInput = {
  userId: Scalars['ID']['input'];
};

export type LocationTrackingConfig = {
  __typename?: 'LocationTrackingConfig';
  autoSync: Scalars['Boolean']['output'];
  batchSync: Scalars['Boolean']['output'];
  debug: Scalars['Boolean']['output'];
  desiredAccuracy: Scalars['String']['output'];
  disableElasticity: Scalars['Boolean']['output'];
  distanceFilter: Scalars['Int']['output'];
  enableHeadless: Scalars['Boolean']['output'];
  foregroundService: Scalars['Boolean']['output'];
  logLevel: Scalars['String']['output'];
  reset: Scalars['Boolean']['output'];
  startOnBoot: Scalars['Boolean']['output'];
  stopOnTerminate: Scalars['Boolean']['output'];
  stopTimeout: Scalars['Int']['output'];
};

export type LoggerConfig = {
  __typename?: 'LoggerConfig';
  level: Scalars['Int']['output'];
};

export type MakePaymentInput = {
  /** cents */
  amount: Scalars['Int']['input'];
  token: Scalars['String']['input'];
};

export type MakePaymentWithDeposit = {
  /** Payment method which will be used for auto pay, it can be different from paymentToken */
  autoPayToken: Scalars['String']['input'];
  /** Amount in cents of much we will charge user for downpayment using paymentToken */
  downpaymentAmount: Scalars['Int']['input'];
  /** Payment method token to be used for downpayment deposit + initial payment */
  paymentToken: Scalars['String']['input'];
  /** Amount in cents of much we will charge user for initial payment using paymentToken */
  refillAmount: Scalars['Int']['input'];
};

export type Marketing = {
  __typename?: 'Marketing';
  email: EmailMarketing;
  sms: SmsMarketing;
};

export type MarketingPreference = {
  __typename?: 'MarketingPreference';
  /** Text to show in frontend for this marketing preference */
  description: Scalars['String']['output'];
  /** Internal marketing preference name */
  name: Scalars['String']['output'];
  type: MarketingPreferenceType;
};

export type MarketingPreferenceInput = {
  /** true if user has opted-in to this marketing preference, false otherwise */
  isEnabled: Scalars['Boolean']['input'];
  /** Internal marketing preference name */
  name: Scalars['String']['input'];
};

export enum MarketingPreferenceType {
  DefaultComms = 'DEFAULT_COMMS'
}

export type MigrateUsersInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MileageCharge = {
  __typename?: 'MileageCharge';
  daysCovered: Scalars['Int']['output'];
  odometer?: Maybe<Odometer>;
  pricePerMile: Scalars['String']['output'];
  totalMileage: Scalars['String']['output'];
};

export type MonthlyEstimate = {
  __typename?: 'MonthlyEstimate';
  estimatedCost: Scalars['String']['output'];
  estimatedMileage: Scalars['String']['output'];
  month: Scalars['Int']['output'];
};

export type MonthlyEstimates = {
  __typename?: 'MonthlyEstimates';
  apr?: Maybe<MonthlyEstimate>;
  aug?: Maybe<MonthlyEstimate>;
  dec?: Maybe<MonthlyEstimate>;
  feb?: Maybe<MonthlyEstimate>;
  jan?: Maybe<MonthlyEstimate>;
  jul?: Maybe<MonthlyEstimate>;
  jun?: Maybe<MonthlyEstimate>;
  mar?: Maybe<MonthlyEstimate>;
  may?: Maybe<MonthlyEstimate>;
  nov?: Maybe<MonthlyEstimate>;
  oct?: Maybe<MonthlyEstimate>;
  sep?: Maybe<MonthlyEstimate>;
};

export type MultiFormatBaseCms = {
  description: MultiFormatText;
  title: MultiFormatText;
};

export type MultiFormatText = {
  __typename?: 'MultiFormatText';
  markdown: Array<Scalars['String']['output']>;
  plain: Array<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Accept a quote and choose specific package and coverage groups as well as options
   * @deprecated Replaced by `acceptQuoteForUser` as part of quote module rewrite
   */
  acceptQuote: Quote;
  /** Accepts the given quote for the logged in user */
  acceptQuoteForUser: PricedQuote;
  /** Activate a referral when a new user enters a referral code during the first registration process */
  activateReferral?: Maybe<Referral>;
  addAddress: ProfileInputResult;
  addDrivingLicense: ProfileInputResult;
  /** Add a new organisation */
  addOrganisation?: Maybe<Organisation>;
  addPaymentMethod: PaymentMethod;
  addPersonalInfo: ProfileInputResult;
  addPhone: ProfileInputResult;
  /** Add a new sales person to Organisation */
  addSalesPerson?: Maybe<SalesPerson>;
  amendActivePolicyLienholder?: Maybe<Scalars['ID']['output']>;
  applyPendingDailyCharges?: Maybe<Scalars['ID']['output']>;
  /** link user to external Sentiance user using auth code to our user */
  associateAutoStartUser?: Maybe<Scalars['ID']['output']>;
  associateInsuranceApplicationsToUser: AssociateInsuranceApplicationsToUserResult;
  /** A list of actions customer has to complete before policy will be issued */
  BindRequirements_run: QuoteBindRequirementsResult;
  calculateEstimate?: Maybe<EstimationMutationResult>;
  /** Cancel current active policy for a user */
  cancelCurrentActivePolicy?: Maybe<Scalars['ID']['output']>;
  /**
   * Cancel user policy by Just for breaking our UW rules, you need to pass correct cancellationReason or cancellation will be rejected
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  cancelUserPolicy?: Maybe<Scalars['ID']['output']>;
  /**
   * Charge specific user
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  charge: ChargeResponse;
  /** Check if the user has duplicates during sign up */
  checkUserDuplicateStatus: User;
  /**
   * undefined
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  createEndorsementQuote?: Maybe<Scalars['ID']['output']>;
  createGuestUserSession: CreateGuestUserResult;
  createNewInsuranceApplication: CreateNewInsuranceApplicationResult;
  /** Create Braintree payment token, associated with user */
  createPaymentToken?: Maybe<PaymentToken>;
  /** @deprecated Replaced by `generateQuoteForUser` as part of quote module rewrite */
  createQuoteForUser: Quote;
  /** Create a new referral code for new users */
  createReferralCodes?: Maybe<Array<Maybe<ReferralCode>>>;
  createVehicle?: Maybe<InsurableVehicle>;
  decodeVehicleVin: DecodeVehicleVinResult;
  /** Delete user vehicle. This is a soft delete */
  deleteVehicle?: Maybe<Scalars['ID']['output']>;
  /** Disabled automatic payments for current user */
  disableAutomaticPayments: AutomaticPayments;
  /** Disabled automatic payments for a given user */
  disableAutomaticPaymentsForUser: AutomaticPayments;
  disableWorkflow?: Maybe<Scalars['ID']['output']>;
  /** Marks trip as disputed by the users, with given reason. */
  disputeTrip?: Maybe<Scalars['ID']['output']>;
  /** Once automatic payment is setup we can enable if previously disabled through `disableAutomaticPayments` using this mutation */
  enableAutomaticPayments: AutomaticPayments;
  enableWorkflow?: Maybe<Scalars['ID']['output']>;
  evaluateUnderwritingUserState?: Maybe<Scalars['ID']['output']>;
  executeWorkflowAction?: Maybe<Scalars['ID']['output']>;
  /** Forward email */
  forwardEmail?: Maybe<Scalars['ID']['output']>;
  /** Generate a quick quote using the minimum required fields plus any optional ones. Input requires `context` for better tracking of requests. */
  generateExpandedQuickQuote: GenerateQuickQuoteResult;
  /** @deprecated Use generateQuickQuoteV2 instead with form error handling. */
  generateQuickQuote: GenerateQuickQuoteResponse;
  /** Generate a quick quote using the minimum required fields. To include optional fields, use `generateExpandedQuickQuote`. */
  generateQuickQuoteV2: GenerateQuickQuoteResult;
  /** Generates a quote for the logged in user. Returns either a `PricedQuote` if the user meets our underwriting rules and we can offer them at least 1 package, or a `DeniedQuote` if not. */
  generateQuoteForUser: QuoteV2;
  getAccessCode: Scalars['String']['output'];
  getAccessCodeForUser: Scalars['String']['output'];
  /**
   * undefined
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  issuePolicyEndorsement?: Maybe<Scalars['ID']['output']>;
  /** Links user to external Sentiance user. */
  linkAutoStartUser: LinkedUser;
  makePayment?: Maybe<Scalars['ID']['output']>;
  /**
   * undefined
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  makePaymentWithDeposit?: Maybe<Scalars['ID']['output']>;
  /** Make a vehicle default, this will unset previous default vehicle */
  makeVehicleDefault?: Maybe<InsurableVehicle>;
  /** @deprecated for migration purposes only */
  migrateUnderwritingServiceUsers?: Maybe<Scalars['ID']['output']>;
  /** Enables PS to override an identity check */
  overrideIdentityCheck: OverrideIdentityCheckResult;
  /** Start upgrade process for given policy. If requirements met the policy will be upgraded from minimum to full cover. */
  PolicyUpgrade_start: PolicyUpgradeResult;
  /** Process a referral and send rewards to users during the first registration process */
  processReferral?: Maybe<Referral>;
  reassignGuestQuickQuoteOwnershipToCurrentUser: ReassignGuestQuickQuoteOwnershipToCurrentUserResponse;
  refundPayments: RefundSuccessResponse;
  /** Runs a driving check for a given user, for radr use */
  regenerateDrivingReport?: Maybe<Array<DrivingReport>>;
  /**
   * undefined
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  reinstateUser?: Maybe<Scalars['ID']['output']>;
  reissueBulkRenewals?: Maybe<Scalars['ID']['output']>;
  /**
   * undefined
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  reissueRenewal?: Maybe<Scalars['ID']['output']>;
  removePaymentMethod?: Maybe<Scalars['ID']['output']>;
  /** Remove an active referral after has entered a code during the first registration process */
  removeReferral?: Maybe<Scalars['ID']['output']>;
  replayDomainEvent?: Maybe<Scalars['ID']['output']>;
  replayWorkflowEvent?: Maybe<Scalars['ID']['output']>;
  /** Request auth code from Sentiance API */
  requestAutoStartAuthCode?: Maybe<Scalars['ID']['output']>;
  /**
   * Request full balance refund
   * @deprecated refunds are done through radr
   */
  requestRefund?: Maybe<Scalars['ID']['output']>;
  /**
   * Rescind policy (as if it never existed)
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  rescindPolicy?: Maybe<Scalars['ID']['output']>;
  resendAdverseActionEmail: ResendAdverseActionEmailResponse;
  /** Starts user odometer workflow from the beginning */
  resetOdometerWorkflow?: Maybe<Scalars['ID']['output']>;
  /** Resets a customer's identity check retry attempts */
  resetRetryAttempts: ResetRetryAttemptsResult;
  /**
   * Disable user's ability to purchase policies regardless of their account state
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  restrictUser?: Maybe<Scalars['ID']['output']>;
  runChecks: RunChecksResult;
  /** Runs a credit check for a given user. */
  runCreditCheck: CreditReport;
  /** Runs a driving check for a given user, for radr use */
  runDrivingCheck: DrivingReport;
  runIdentityCheck: RunIdentityCheckResult;
  sayHi?: Maybe<Scalars['String']['output']>;
  searchForVehicles?: Maybe<SearchForVehiclesResult>;
  /**
   * Endpoint to seed duplicate-users service with required base data for all duplicate users currently in the system
   * @deprecated Only to be used during seeding of duplicate-users service
   */
  seedDuplicateUsers: DuplicateUsersSeedingResult;
  /** Selects coverages on the given quote for the logged in user */
  selectCoveragesOnQuote: PricedQuote;
  selectQuickQuotePackage: SelectQuickQuotePackageResponse;
  sendPolicyDocuments?: Maybe<Scalars['ID']['output']>;
  /** Setup automatic payment. Before setup querying auto payments will return null. We also need to setup auto pay before we try disable or enable it. */
  setupAutomaticPayments: AutomaticPayments;
  /** Sets up customer in Stripe and returns the initial invoice */
  setUpBilling?: Maybe<BillingSetUpResponse>;
  /** Set beta flag options for a user */
  setUserBetaFlags?: Maybe<Scalars['ID']['output']>;
  setVehicleFromVin: SetVehicleFromVinResult;
  shortenUrl: Scalars['ID']['output'];
  submitACV: SubmitAcvResult;
  submitPersonalDetails: SubmitInsuranceApplicationFormResult;
  /**
   * Re-enable user's ability to purchase policies
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  unrestrictUser?: Maybe<Scalars['ID']['output']>;
  updateACV?: Maybe<Scalars['ID']['output']>;
  updateDrivingLicense: ProfileInputResult;
  updateEmailMarketingPreferencesForUser?: Maybe<UserEmailMarketing>;
  /** Updated future policy start date */
  updateFuturePolicyStartDate?: Maybe<Scalars['ID']['output']>;
  /** Update an existing organisation */
  updateOrganisation?: Maybe<Organisation>;
  updatePhoneNumber: UpdatePhoneNumberResult;
  /** Update existing sales person */
  updateSalesPerson?: Maybe<SalesPerson>;
  updateSmsMarketingPreferencesForUser?: Maybe<UserSmsMarketing>;
  /** Update the user level config setting made in app and persisted server-side. */
  updateUserConfig: UserConfig;
  updateUserEmail: User;
  updateUserPhoneNumber: UpdatePhoneNumberResult;
  updateVehicle: UpdateVehicleResult;
  /** Manually check the user for duplicates (and block if applicable) */
  validateUserDuplicateStatus: DuplicateUserInfo;
  /** @deprecated We should use quick quote or insurance application instead */
  validateZipCode?: Maybe<InputFieldValidationError>;
  /**
   * undefined
   *
   * ### Idempotency key required
   * This mutation requires for __Idempotency key__ to be passed in as part of HTTP header. The value should be a UUID which is **globally unique**.
   * For requests which are retried we should include same idempotency key.
   *
   * Example header: `Idempotency-Key: c70ab15f-4d4b-4da0-81f5-665ea352185f`.
   *
   * Follow [this spec](https://tools.ietf.org/id/draft-idempotency-header-01.html) to learn more about how this fields works
   */
  voidFuturePolicy?: Maybe<Scalars['ID']['output']>;
  /** Admin mutation for voiding the given quote */
  voidQuote: PricedQuote;
};


export type MutationAcceptQuoteArgs = {
  quoteAcceptance: QuoteAcceptanceInput;
};


export type MutationAcceptQuoteForUserArgs = {
  input: AcceptQuoteInput;
};


export type MutationActivateReferralArgs = {
  referralInput: ReferralInput;
};


export type MutationAddAddressArgs = {
  input: AddAddressInput;
};


export type MutationAddDrivingLicenseArgs = {
  input: AddDrivingLicenseInput;
};


export type MutationAddOrganisationArgs = {
  organisationInput: OrganisationInput;
};


export type MutationAddPaymentMethodArgs = {
  input: AddPaymentMethodInput;
};


export type MutationAddPersonalInfoArgs = {
  input: AddPersonalInfoInput;
};


export type MutationAddPhoneArgs = {
  input: AddPhoneNumberInput;
};


export type MutationAddSalesPersonArgs = {
  salesPersonInput: SalesPersonInput;
};


export type MutationAmendActivePolicyLienholderArgs = {
  input: UpdateActivePolicyLienholderInput;
};


export type MutationAssociateAutoStartUserArgs = {
  input: AssociateAutoStartUserInput;
};


export type MutationBindRequirements_RunArgs = {
  input: BindRequirementRunInput;
};


export type MutationCalculateEstimateArgs = {
  input: EstimateInput;
};


export type MutationCancelCurrentActivePolicyArgs = {
  cancellationReasonInput: CancelCurrentActivePolicyInput;
};


export type MutationCancelUserPolicyArgs = {
  input: CancelUserPolicyInput;
};


export type MutationChargeArgs = {
  input: ChargeInput;
};


export type MutationCreateEndorsementQuoteArgs = {
  input: CreateEndorsementQuoteInput;
};


export type MutationCreateGuestUserSessionArgs = {
  input: CreateGuestUserInput;
};


export type MutationCreateQuoteForUserArgs = {
  input: CreateQuoteForUserInput;
};


export type MutationCreateReferralCodesArgs = {
  referralCodesInput: ReferralCodesInput;
};


export type MutationCreateVehicleArgs = {
  vehicle: VehicleInput;
};


export type MutationDecodeVehicleVinArgs = {
  input: DecodeVehicleVinInput;
};


export type MutationDeleteVehicleArgs = {
  vehicleId: Scalars['Int']['input'];
};


export type MutationDisableAutomaticPaymentsArgs = {
  input?: InputMaybe<DisableAutomaticPaymentsInput>;
};


export type MutationDisableAutomaticPaymentsForUserArgs = {
  input: DisableAutomaticPaymentsForUserInput;
};


export type MutationDisableWorkflowArgs = {
  workflowInput?: InputMaybe<WorkflowInput>;
};


export type MutationDisputeTripArgs = {
  tripDisputeInput: TripDisputeInput;
};


export type MutationEnableAutomaticPaymentsArgs = {
  input?: InputMaybe<EnableAutomaticPaymentsInput>;
};


export type MutationEnableWorkflowArgs = {
  workflowInput?: InputMaybe<WorkflowInput>;
};


export type MutationEvaluateUnderwritingUserStateArgs = {
  input: UnderwritingEvaluateInput;
};


export type MutationExecuteWorkflowActionArgs = {
  input?: InputMaybe<WorkflowActionInput>;
};


export type MutationForwardEmailArgs = {
  email: ForwardEmailInput;
};


export type MutationGenerateExpandedQuickQuoteArgs = {
  input: GenerateExpandedQuickQuoteInput;
};


export type MutationGenerateQuickQuoteArgs = {
  input: GenerateQuickQuoteInput;
};


export type MutationGenerateQuickQuoteV2Args = {
  input: GenerateQuickQuoteInput;
};


export type MutationGenerateQuoteForUserArgs = {
  input: GenerateQuoteForUserInput;
};


export type MutationGetAccessCodeForUserArgs = {
  input: GetAccessCodeForUserInput;
};


export type MutationIssuePolicyEndorsementArgs = {
  input: IssuePolicyEndorsementInput;
};


export type MutationLinkAutoStartUserArgs = {
  installId: Scalars['ID']['input'];
};


export type MutationMakePaymentArgs = {
  input: MakePaymentInput;
};


export type MutationMakePaymentWithDepositArgs = {
  input: MakePaymentWithDeposit;
};


export type MutationMakeVehicleDefaultArgs = {
  vehicleId: Scalars['Int']['input'];
};


export type MutationMigrateUnderwritingServiceUsersArgs = {
  input?: InputMaybe<MigrateUsersInput>;
};


export type MutationOverrideIdentityCheckArgs = {
  input: OverrideIdentityCheckInput;
};


export type MutationPolicyUpgrade_StartArgs = {
  input: PolicyUpgradeStartInput;
};


export type MutationProcessReferralArgs = {
  processReferralInput: ProcessReferralInput;
};


export type MutationReassignGuestQuickQuoteOwnershipToCurrentUserArgs = {
  input: ReassignGuestQuickQuoteOwnershipToCurrentUserInput;
};


export type MutationRefundPaymentsArgs = {
  input: RefundPaymentInput;
};


export type MutationRegenerateDrivingReportArgs = {
  input: RegenerateDrivingReportInput;
};


export type MutationReinstateUserArgs = {
  input: ReinstateUserInput;
};


export type MutationReissueBulkRenewalsArgs = {
  input: ReissueBulkRenewalsReasonInput;
};


export type MutationReissueRenewalArgs = {
  input: ReissueRenewalReasonInput;
};


export type MutationRemovePaymentMethodArgs = {
  input: RemovePaymentMethodInput;
};


export type MutationRemoveReferralArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationReplayDomainEventArgs = {
  replayDomainEventInput: ReplayDomainEventInput;
};


export type MutationReplayWorkflowEventArgs = {
  input: WorkflowEventInput;
};


export type MutationRequestAutoStartAuthCodeArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationRequestRefundArgs = {
  requestRefundInput: RequestRefundInput;
};


export type MutationRescindPolicyArgs = {
  input: RescindPolicyInput;
};


export type MutationResendAdverseActionEmailArgs = {
  input: ResendAdverseActionEmailInput;
};


export type MutationResetOdometerWorkflowArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationResetRetryAttemptsArgs = {
  input: ResetRetryAttemptsInput;
};


export type MutationRestrictUserArgs = {
  input: RestrictUserInput;
};


export type MutationRunCreditCheckArgs = {
  input: RunCreditCheckInput;
};


export type MutationRunDrivingCheckArgs = {
  input: RunDrivingCheckInput;
};


export type MutationSayHiArgs = {
  name: Scalars['String']['input'];
};


export type MutationSearchForVehiclesArgs = {
  input: SearchForVehiclesInput;
};


export type MutationSeedDuplicateUsersArgs = {
  input: DuplicateUsersSeedingInput;
};


export type MutationSelectCoveragesOnQuoteArgs = {
  input: SelectCoveragesInput;
};


export type MutationSelectQuickQuotePackageArgs = {
  input: SelectQuickQuotePackageInput;
};


export type MutationSendPolicyDocumentsArgs = {
  sendPolicyDocumentsInput: SendPolicyDocumentsInput;
};


export type MutationSetupAutomaticPaymentsArgs = {
  input: SetupAutomaticPaymentsInput;
};


export type MutationSetUpBillingArgs = {
  input: BillingSetUpInput;
};


export type MutationSetUserBetaFlagsArgs = {
  betaFlagInput: BetaFlagInput;
};


export type MutationSetVehicleFromVinArgs = {
  input: SetVehicleFromVinInput;
};


export type MutationShortenUrlArgs = {
  input: ShortenUrlInput;
};


export type MutationSubmitAcvArgs = {
  input: SubmitAcvInput;
};


export type MutationSubmitPersonalDetailsArgs = {
  input: SubmitInsuranceApplicationFormInput;
};


export type MutationUnrestrictUserArgs = {
  input: UnrestrictUserInput;
};


export type MutationUpdateAcvArgs = {
  acv: AcvInput;
};


export type MutationUpdateDrivingLicenseArgs = {
  input: UpdateDrivingLicenseInput;
};


export type MutationUpdateEmailMarketingPreferencesForUserArgs = {
  input: UpdateEmailMarketingPreferencesInput;
};


export type MutationUpdateFuturePolicyStartDateArgs = {
  input: UpdateFuturePolicyStartDateInput;
};


export type MutationUpdateOrganisationArgs = {
  id: Scalars['ID']['input'];
  organisationInput: OrganisationInput;
};


export type MutationUpdatePhoneNumberArgs = {
  input: UpdatePhoneNumberInput;
};


export type MutationUpdateSalesPersonArgs = {
  id: Scalars['ID']['input'];
  salesPersonInput: SalesPersonInput;
};


export type MutationUpdateSmsMarketingPreferencesForUserArgs = {
  input: UpdateSmsMarketingPreferencesInput;
};


export type MutationUpdateUserConfigArgs = {
  userConfigInput: UserConfigInput;
};


export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};


export type MutationUpdateUserPhoneNumberArgs = {
  input: UpdateUserPhoneNumberInput;
};


export type MutationUpdateVehicleArgs = {
  input: UpdateVehicleInput;
};


export type MutationValidateUserDuplicateStatusArgs = {
  input: DuplicateUsersValidationInput;
};


export type MutationValidateZipCodeArgs = {
  input: ValidateZipCodeInput;
};


export type MutationVoidFuturePolicyArgs = {
  input: VoidFuturePolicyInput;
};


export type MutationVoidQuoteArgs = {
  input: VoidQuoteInput;
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  last: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type NegativeBalanceAction = {
  __typename?: 'NegativeBalanceAction';
  payload?: Maybe<NegativeBalanceActionPayload>;
  title: Scalars['String']['output'];
};

export type NegativeBalanceActionPayload = {
  __typename?: 'NegativeBalanceActionPayload';
  settlementDate: Scalars['String']['output'];
};

export type NewQuoteAvailableAction = {
  __typename?: 'NewQuoteAvailableAction';
  payload?: Maybe<NewQuoteAvailableActionPayload>;
  title: Scalars['String']['output'];
};

export type NewQuoteAvailableActionPayload = {
  __typename?: 'NewQuoteAvailableActionPayload';
  /** Date when getting close to the new policy period */
  renewalDueWarningDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type NextPeriodEstimate = Estimate & {
  __typename?: 'NextPeriodEstimate';
  assumed: Scalars['String']['output'];
  breakdown: SummaryBreakdown;
  /** ISO8601 Interval */
  period: Scalars['String']['output'];
  projected: Scalars['String']['output'];
};

export type ObjectValidationError = {
  __typename?: 'ObjectValidationError';
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
};

export type Odometer = {
  __typename?: 'Odometer';
  firstTallyUp: TallyUp;
  lastTallyUp: TallyUp;
};

export type OdometerAction = {
  __typename?: 'OdometerAction';
  status?: Maybe<OdometerActionStatus>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export type OdometerActionPayload = {
  __typename?: 'OdometerActionPayload';
  dueAtStartDay: Scalars['Int']['output'];
  isInitial: Scalars['Boolean']['output'];
  type: OdometerActionType;
  vehicleId: Scalars['String']['output'];
};

export enum OdometerActionStatus {
  Due = 'DUE',
  Pending = 'PENDING'
}

export enum OdometerActionType {
  OdometerDue = 'ODOMETER_DUE',
  OdometerExpired = 'ODOMETER_EXPIRED',
  OdometerRejected = 'ODOMETER_REJECTED'
}

export type OdometerActionV2 = {
  __typename?: 'OdometerActionV2';
  payload: OdometerActionPayload;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type OdometerConfig = {
  __typename?: 'OdometerConfig';
  version: Scalars['String']['output'];
};

export type OdometerDueAction = {
  __typename?: 'OdometerDueAction';
  title: Scalars['String']['output'];
};

export type OdometerOptions = {
  __typename?: 'OdometerOptions';
  /** Days at which the odometer becomes due in the first policy of a renewal cycle in days */
  firstPolicy: Array<Scalars['Int']['output']>;
  /**
   * How often this occurs in a policy period
   * @deprecated use firstPolicy or subsequentPolicy intervals
   */
  frequency: Scalars['Int']['output'];
  /**
   * Maximum number of days grace to upload before invoking charges
   * @deprecated not used
   */
  maxDueDay: Scalars['Int']['output'];
  /**
   * When first periodic odometer photo due (excluding initial)
   * @deprecated use firstPolicy or subsequentPolicy intervals
   */
  startDay: Scalars['Int']['output'];
  /** Days at which the odometer becomes due in a subsequent policy of a renewal cycle in days */
  subsequentPolicy: Array<Scalars['Int']['output']>;
};

export type OdometerRequirement = BindRequirement & {
  __typename?: 'OdometerRequirement';
  expiresIn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  startedAt?: Maybe<Scalars['String']['output']>;
  status: BindRequirementStatus;
  type: BindRequirementType;
};

export type OfferedPackage = {
  __typename?: 'OfferedPackage';
  adverseActions?: Maybe<AdverseActions>;
  content: OfferedPackageCms;
  coverages: Array<CoverageV2>;
  id: Scalars['ID']['output'];
  subtotal: Total;
};

export type OfferedPackageCms = BaseCms & {
  __typename?: 'OfferedPackageCms';
  description: Array<Scalars['String']['output']>;
  disclaimer: Array<Scalars['String']['output']>;
  optionalCoveragesDisclaimer: Array<Scalars['String']['output']>;
  price: TotalCms;
  summary: Array<SummaryCms>;
  summaryV2: OfferedPackageSummaryCms;
  title: Scalars['String']['output'];
};

export type OfferedPackageSummaryCms = {
  __typename?: 'OfferedPackageSummaryCms';
  baseCoverages: IncludedCoverageSummaryCms;
  coveragesWithOptions: IncludedCoverageSummaryCms;
  includedCoverages: IncludedCoverageSummaryCms;
  whatsCovered: IncludedCoverageSummaryCms;
};

export enum OrderDir {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Organisation = {
  __typename?: 'Organisation';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  fein?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentName?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
  website: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type OrganisationInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  fein?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentName?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  type: OrganisationType;
  website: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export enum OrganisationType {
  CommunityCollege = 'COMMUNITY_COLLEGE',
  CommunityOrganisation = 'COMMUNITY_ORGANISATION',
  Dealership = 'DEALERSHIP',
  University = 'UNIVERSITY'
}

export type Override = {
  __typename?: 'Override';
  at: Scalars['DateTime']['output'];
  by: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type OverrideIdentityCheckInput = {
  note: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type OverrideIdentityCheckResult = {
  __typename?: 'OverrideIdentityCheckResult';
  id: Scalars['ID']['output'];
};

export type Package = {
  __typename?: 'Package';
  coverages: Array<CoverageV2>;
  id: Scalars['ID']['output'];
};

export enum PackageType {
  FullCover = 'FULL_COVER',
  MinimumLiability = 'MINIMUM_LIABILITY'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor pointing at the last result in `nodes`, to be used as the input for `after` when querying for the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type PaymentAction = {
  __typename?: 'PaymentAction';
  payload: PaymentActionPayload;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PaymentActionPayload = {
  __typename?: 'PaymentActionPayload';
  amount: Scalars['Int']['output'];
  errorDescription: Scalars['String']['output'];
  type?: Maybe<PaymentErrorType>;
};

export type PaymentConfig = {
  __typename?: 'PaymentConfig';
  /** Amount in cents we will put aside as a deposit to settle final bill when customer leaves This is will default to 0 if IS_DEPOSIT_ENABLED FF is disabled */
  depositAmount: Scalars['Int']['output'];
  /** This is the amount user need to have to purchase a policy both initial one and after lapse */
  minimumInitialBalance: Scalars['Int']['output'];
  /** This is used in the wallet */
  minRefillAmount: Scalars['Int']['output'];
  /** The amount we require from the customer to get a policy. This can be for initial policy or after lapse. We will take user balance and subtract this amount to get the amount we need to charge. This is rounded to a whole dollar. This is will default to 0 if IS_DEPOSIT_ENABLED FF is disabled */
  requiredDownpaymentAmount: Scalars['Int']['output'];
};

export enum PaymentErrorType {
  ApprovalRequired = 'APPROVAL_REQUIRED',
  Permanent = 'PERMANENT',
  Transient = 'TRANSIENT'
}

export type PaymentInfo = {
  id: Scalars['ID']['input'];
  refundMessage?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type PaymentMethod = CreditCard | PayPal | Venmo;

export type PaymentMethodBase = {
  /** Error associated with the payment method. This is the last payment encountered when using this payment. If a successful payment was made with this payment method error will be set to null */
  error?: Maybe<PaymentMethodError>;
  /** Icon URL, the link to the payment method image. */
  imageUrl: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  /** ISO8601 Date when last transaction was made with using this payment method */
  lastUsed?: Maybe<Scalars['String']['output']>;
  /** Short name of the payment method which can help identify payment method */
  name: Scalars['String']['output'];
  paymentType: PaymentType;
  token: Scalars['String']['output'];
};

export type PaymentMethodError = {
  __typename?: 'PaymentMethodError';
  description: Scalars['String']['output'];
  message: Scalars['String']['output'];
  type: PaymentMethodErrorType;
};

export enum PaymentMethodErrorType {
  Permanent = 'PERMANENT',
  Transient = 'TRANSIENT'
}

export type PaymentRequirement = BindRequirement & {
  __typename?: 'PaymentRequirement';
  /** User funds in cents */
  funds: Scalars['Int']['output'];
  /** @deprecated Use AutomaticPaymentsRequirement type instead. This always returns true and is to be removed once app stops including it in queries. */
  isAutoPayEnabled: Scalars['Boolean']['output'];
  /** Payment amount in cents required to bring user funds up to quoted initial payment amount */
  payToday: Scalars['Int']['output'];
  status: BindRequirementStatus;
  /** Quoted initial payment */
  total: PaymentRequirementTotal;
  type: BindRequirementType;
};

export type PaymentRequirementTotal = {
  __typename?: 'PaymentRequirementTotal';
  /** Sum of downpayment + deposit */
  amount: Scalars['Int']['output'];
  /** Amount in cents we will put aside as a deposit to settle final bill when customer leaves */
  deposit: Scalars['Int']['output'];
  /** Amount in cents we require from the customer to get a policy */
  downpayment: Scalars['Int']['output'];
};

export type PaymentsConfig = {
  __typename?: 'PaymentsConfig';
  automaticPayment: AutomaticPaymentConfig;
  singlePayment: SinglePaymentConfig;
};

export type PaymentToken = {
  __typename?: 'PaymentToken';
  clientToken?: Maybe<Scalars['ID']['output']>;
};

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  error?: Maybe<PaymentMethodError>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export enum PaymentType {
  ApplePay = 'APPLE_PAY',
  CreditCard = 'CREDIT_CARD',
  PayPal = 'PAY_PAL',
  Venmo = 'VENMO'
}

export type PayPal = PaymentMethodBase & {
  __typename?: 'PayPal';
  email: Scalars['String']['output'];
  error?: Maybe<PaymentMethodError>;
  imageUrl: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  lastUsed?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentType: PaymentType;
  token: Scalars['String']['output'];
};

export type PermissionsConfig = {
  __typename?: 'PermissionsConfig';
  enabled: Scalars['Boolean']['output'];
};

export type PersonalInfo = {
  __typename?: 'PersonalInfo';
  dob: Scalars['String']['output'];
  /** @deprecated Use `PolicyholderApplicationSnapshot.driverLicense`. */
  driverLicense?: Maybe<LicenseDetails>;
  /** @deprecated Use `PolicyholderApplicationSnapshot.driverLicense`. */
  driverLicenseNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `PolicyholderApplicationSnapshot.driverLicense in shap`. */
  driverLicenseState?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender: Gender;
  lastName: Scalars['String']['output'];
};

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type PoliciesInput = {
  status?: InputMaybe<PolicyStatus>;
};

export type PoliciesSearchInput = {
  search: PolicySearchInput;
};

export type Policy = {
  __typename?: 'Policy';
  agent?: Maybe<Agent>;
  bindRequirements?: Maybe<QuoteBindRequirements>;
  cancellationDate?: Maybe<Scalars['String']['output']>;
  cancellationReason?: Maybe<CancellationReason>;
  carrier: Carrier;
  disclaimer: Scalars['String']['output'];
  /** Currently always empty array, for future use */
  drivers: Array<Driver>;
  /** Currently always empty array, for future use */
  excludedDrivers: Array<Driver>;
  id: Scalars['ID']['output'];
  isFutureStartPolicy: Scalars['Boolean']['output'];
  /** Did we consider new policy renewal, currently logic is if policy is within 24 hours of previous one We make start straight after first finished, meaning we could move policy start date to the past. This is due some technical limitations which we do not have time to address, in the future we'll differentiate based if user did all the actions before expiry of their current policy */
  isRenewal: Scalars['Boolean']['output'];
  /** Person in whose name policy is created */
  policyholder: Driver;
  /** Policyholder USER ID */
  policyholderId: Scalars['Int']['output'];
  policyNumberDetails: PolicyNumberDetails;
  product: Product;
  /** @deprecated use quoteV2 */
  quote: Quote;
  /** ID of the quote which was used to issue this policy */
  quoteId: Scalars['String']['output'];
  quoteV2: PricedQuote;
  status: PolicyStatus;
  /** Policy term which is policy inception date to expiry date, it is represented by ISO8601 Interval e.g. `2020-01-01/2020-02-01` */
  term: Scalars['String']['output'];
  /** Timezone for which policy was issued, this is based on user insured address atm */
  timezone: Scalars['String']['output'];
  totalPremium: TotalPremium;
  /** Will be non-null for policies which have or had an upgrade from minimum to full cover available */
  upgrade?: Maybe<PolicyUpgrade>;
  vehicles: Array<InsuredVehicle>;
};

export enum PolicyDocumentType {
  CancellationNonPayment = 'CANCELLATION_NON_PAYMENT',
  CancellationUser = 'CANCELLATION_USER',
  CoverageLapsed = 'COVERAGE_LAPSED',
  Endorsement = 'ENDORSEMENT',
  Renewal = 'RENEWAL',
  Welcome = 'WELCOME'
}

export enum PolicyEvent {
  Cancelled = 'CANCELLED',
  /** New policy is created */
  Created = 'CREATED',
  Expired = 'EXPIRED'
}

export enum PolicyEventType {
  Amended = 'AMENDED',
  Cancelled = 'CANCELLED',
  CoverageLapsed = 'COVERAGE_LAPSED',
  Created = 'CREATED',
  Endorsement = 'ENDORSEMENT',
  Expired = 'EXPIRED'
}

export type PolicyholderApplication = {
  __typename?: 'PolicyholderApplication';
  current: PolicyholderApplicationSnapshot;
  history: Array<PolicyholderApplicationSnapshot>;
};

export type PolicyholderApplicationSnapshot = {
  __typename?: 'PolicyholderApplicationSnapshot';
  address?: Maybe<ProfileAddress>;
  driverLicense?: Maybe<DriverLicense>;
  isCompleted: Scalars['Boolean']['output'];
  personalInfo?: Maybe<PersonalInfo>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type PolicyNumberDetails = {
  __typename?: 'PolicyNumberDetails';
  base: Scalars['String']['output'];
  issue: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PolicyOptions = {
  __typename?: 'PolicyOptions';
  /** Number of days product lasts */
  duration: Scalars['Int']['output'];
  /** Number of days into product when next month will be offered */
  offerDay: Scalars['Int']['output'];
};

/** How much do we charge user per mile and day. This is not premium strictly speaking, but this maps most closely to premium in traditional insurance worlds */
export type PolicyPremium = {
  __typename?: 'PolicyPremium';
  perDay?: Maybe<Scalars['Int']['output']>;
  perMile?: Maybe<Scalars['Int']['output']>;
};

export type PolicySearchInput = {
  /** Part of the policy number to search by */
  policyNr: Scalars['String']['input'];
};

export enum PolicyStatus {
  /** Policy is in force. This is eventually consistent. A policy which is active, but very soon to expire no longer be active by the time it is received by another system. ## Notes We update all policies periodically and raise domain events, also when querying through API we update the status before returning the policy */
  Active = 'ACTIVE',
  /** Policy is cancelled by us or customer. Once policy is cancelled it won't change it's status */
  Cancelled = 'CANCELLED',
  /** Policy is expired, meaning it's in the past and no longer in force ## Notes We update all policies periodically and when querying for policyholder. Once policy is expired it won't change it's status */
  Expired = 'EXPIRED',
  /** Policy is Future if a user has scheduled a policy to be created in the future or is scheduled internally as part of a renewal. */
  Future = 'FUTURE',
  /** Policy is void, meaning it was not issued aka cancellation before it became active */
  Void = 'VOID'
}

export type PolicySubscriptionInput = {
  event: PolicyEvent;
};

export type PolicyUpgrade = {
  __typename?: 'PolicyUpgrade';
  /** ISO8601 Interval during which the policy upgrade process can be started */
  activeBetween: Scalars['String']['output'];
  /** Contains a list of actions customer has to complete before policy is upgraded to full cover. Will be null before upgrade is started. */
  bindRequirements?: Maybe<QuoteBindRequirements>;
  status: PolicyUpgradeStatus;
};

export type PolicyUpgradeResult = PolicyUpgrade | UnexpectedError;

export type PolicyUpgradeStartInput = {
  policyNumber: Scalars['String']['input'];
};

export enum PolicyUpgradeStatus {
  /** User can see upgrade flow */
  Active = 'ACTIVE',
  /** Cancelled due to policy cancelled/void/expired */
  Cancelled = 'CANCELLED',
  /** User completed */
  Completed = 'COMPLETED',
  /** Not visible but due */
  Scheduled = 'SCHEDULED',
  /** User started the upgrade flow */
  Started = 'STARTED'
}

export type Premium = {
  __typename?: 'Premium';
  amount: Scalars['Int']['output'];
  unit: Scalars['String']['output'];
};

export type PricedQuote = QuoteV2 & {
  __typename?: 'PricedQuote';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  content: PricedQuoteCms;
  createdAt: Scalars['DateTime']['output'];
  driver: QuoteDriver;
  estimatedMonthlyMileage: Scalars['Int']['output'];
  expiryDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  insuranceApplication?: Maybe<CompletedInsuranceApplication>;
  insuranceApplicationId?: Maybe<Scalars['ID']['output']>;
  offeredPackages: Array<OfferedPackage>;
  policyStartDate: Scalars['String']['output'];
  product: Product;
  selectedPackage?: Maybe<SelectedPackage>;
  status: QuoteStatus;
  vehicles: Array<QuoteVehicle>;
  voidedAt?: Maybe<Scalars['DateTime']['output']>;
  voidReason?: Maybe<QuoteVoidReason>;
};

export type PricedQuoteCms = {
  __typename?: 'PricedQuoteCms';
  disclaimer: Array<Scalars['String']['output']>;
  packagesDisplayOrder: Array<Scalars['String']['output']>;
  policyStartDate: Scalars['String']['output'];
};

export type ProcessReferralInput = {
  /** The user or referee with an activated referral to send rewards */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Product = {
  __typename?: 'Product';
  availablePackages: Array<Package>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  odometerOptions: OdometerOptions;
  /** @deprecated Unused. Use `availablePackages` instead. */
  packages: Array<PackageType>;
  policyOptions: PolicyOptions;
  state: State;
  supersededBy?: Maybe<Product>;
  type: ProductType;
};

export type ProductFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ProductType {
  Refresh = 'REFRESH',
  Renew = 'RENEW'
}

export type Profile = {
  __typename?: 'Profile';
  address: ProfileAddress;
  createdAt: Scalars['String']['output'];
  /** ID of the profile. In the future we will support multiple profiles associated with a single user ID (Accountholder) */
  id: Scalars['ID']['output'];
  personalInfo: PersonalInfo;
  /** Some legacy users might not have phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
};

export type ProfileAddress = {
  __typename?: 'ProfileAddress';
  city: Scalars['String']['output'];
  /** Line 1 of insured address */
  line1: Scalars['String']['output'];
  /** Line 2 of insured address */
  line2?: Maybe<Scalars['String']['output']>;
  /** State of the insured */
  state: State;
  /** zipCode of insured */
  zipCode: Scalars['String']['output'];
};

export type ProfileInputResult = InvalidInputsError | PolicyholderApplicationSnapshot;

export type Query = {
  __typename?: 'Query';
  annualEstimateSummary?: Maybe<AnnualEstimateSummary>;
  app?: Maybe<App>;
  autostart: AutoStart;
  /**
   * Discover vehicles associated with a specific user ID using external data providers
   * @deprecated gateway access only
   */
  discoveredByUserId?: Maybe<UserDiscovered>;
  estimate?: Maybe<EstimationQueryResult>;
  getCompetitorPrice: GetCompetitorPriceResult;
  /** Returns the credit report for a given user */
  getCreditReport: Array<CreditReport>;
  /** Returns the driving report for a given user, for radr use */
  getDrivingReport: Array<DrivingReport>;
  getFullUrl?: Maybe<Scalars['String']['output']>;
  /** Returns an identity check report for a given user */
  getIdentityCheckReport: IdentityCheckReport;
  /** @deprecated gateway access only */
  identityByUserId?: Maybe<Identity>;
  /** @deprecated gateway access only */
  identityHistoryByUserId: Array<Maybe<Identity>>;
  /** @deprecated gateway access only */
  identityReportHistoryByUserId: Array<Maybe<Report>>;
  marketing: Marketing;
  me: User;
  /** Fetch details of an organisation by ID */
  organisation: Organisation;
  /** Fetch all organisations */
  organisations: Array<Organisation>;
  /** A way to query all policies in our system. This is only exposed for Radr search. Do not use for any other purpose */
  policies: Array<Policy>;
  products: Array<Product>;
  quote: QuoteV2;
  /** @deprecated gateway access only */
  quotesByIds: Array<Quote>;
  /** Fetch all sales people */
  salesPeople: Array<SalesPerson>;
  /** Fetch details of a sales person by ID */
  salesPerson: SalesPerson;
  states: Array<State>;
  trip?: Maybe<Trip>;
  underwriting: Underwriting;
  user: User;
  users: Array<User>;
  vehicles: Array<InsurableVehicle>;
};


export type QueryAnnualEstimateSummaryArgs = {
  input: AnnualEstimateInput;
};


export type QueryDiscoveredByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryEstimateArgs = {
  input: EstimateInput;
};


export type QueryGetCompetitorPriceArgs = {
  input: GetCompetitorPriceInput;
};


export type QueryGetCreditReportArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetDrivingReportArgs = {
  input: GetDrivingReportInput;
};


export type QueryGetFullUrlArgs = {
  input: GetFullUrlInput;
};


export type QueryGetIdentityCheckReportArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryIdentityByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryIdentityHistoryByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryIdentityReportHistoryByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryOrganisationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPoliciesArgs = {
  input: PoliciesSearchInput;
};


export type QueryProductsArgs = {
  input?: InputMaybe<ProductFilterInput>;
};


export type QueryQuoteArgs = {
  input: GetQuoteInput;
};


export type QueryQuotesByIdsArgs = {
  quotes: Array<InputMaybe<QuoteByIdsInput>>;
};


export type QuerySalesPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTripArgs = {
  tripInput: TripInput;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<OrderDir>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type QuickQuoteContext = {
  /** Source of the request to allow us to track where quick quote requests are coming from, akin to `utm_source` query string parameter, e.g. "Compare.com" */
  source: Scalars['String']['input'];
};

export type Quote = {
  __typename?: 'Quote';
  acceptedAt?: Maybe<Scalars['String']['output']>;
  clarifications: Array<Clarification>;
  conditionsText: Scalars['String']['output'];
  /** Denial of coverage lists all available reasons pointing why the quote was denied */
  denialOfCoverage?: Maybe<QuoteDenialOfCoverage>;
  filterSelection?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** Packages can be an empty array when all possible packages were denied */
  packages: Array<QuotePackage>;
  policyConditions: Scalars['String']['output'];
  product: Product;
  score?: Maybe<ScoreSafe>;
  selection?: Maybe<QuoteAcceptance>;
  /** Use Denied status to check if all packages are denied in the quote */
  status: QuoteStatus;
  vehicleId?: Maybe<Scalars['Int']['output']>;
};

export type QuoteAcceptance = {
  __typename?: 'QuoteAcceptance';
  optionalCoverageGroups: Array<Scalars['ID']['output']>;
  options: Array<Scalars['ID']['output']>;
  packages: Array<Scalars['ID']['output']>;
};

export type QuoteAcceptanceInput = {
  optionalCoverageGroups: Array<Scalars['ID']['input']>;
  options: Array<Scalars['ID']['input']>;
  packages: Array<Scalars['ID']['input']>;
  quoteId: Scalars['ID']['input'];
};

export type QuoteAcceptedFeedItem = {
  __typename?: 'QuoteAcceptedFeedItem';
  payload: QuoteAcceptedFeedItemPayload;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type QuoteAcceptedFeedItemPayload = {
  __typename?: 'QuoteAcceptedFeedItemPayload';
  quoteId: Scalars['ID']['output'];
};

export type QuoteAcv = {
  __typename?: 'QuoteAcv';
  accidents: Array<Accident>;
  claims: Array<Claim>;
  violations: Array<ViolationV2>;
};

export type QuoteAdverseActionReason = DrivingHistoryReportReason | QuoteCreditReportReason | SelfDeclaredAcvReason;

export type QuoteAdverseActions = {
  __typename?: 'QuoteAdverseActions';
  denialOfCoverage?: Maybe<QuoteDenialOfCoverage>;
  increasedPremium?: Maybe<QuoteIncreasedPremium>;
};

export type QuoteBindRequirements = {
  __typename?: 'QuoteBindRequirements';
  id: Scalars['ID']['output'];
  quoteId: Scalars['ID']['output'];
  requirements: Array<BindRequirement>;
  status: BindRequirementStatus;
  type: BindRequirementsType;
  vehicleId: Scalars['ID']['output'];
};

export type QuoteBindRequirementsResult = InvalidQuoteStateError | QuoteBindRequirements | UnexpectedError;

export type QuoteByIdsInput = {
  filterSelection?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type QuoteCoverage = {
  __typename?: 'QuoteCoverage';
  deductibles?: Maybe<Deductibles>;
  id: Scalars['String']['output'];
  limit?: Maybe<Limit>;
  name?: Maybe<Scalars['String']['output']>;
  premium: CoveragePremium;
  type: Scalars['String']['output'];
};

export type QuoteCoverageGroup = {
  __typename?: 'QuoteCoverageGroup';
  coverages: Array<QuoteCoverage>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  summary: Scalars['String']['output'];
};

export type QuoteCreditReport = {
  __typename?: 'QuoteCreditReport';
  id: Scalars['ID']['output'];
  keyFactors: Array<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export type QuoteCreditReportCms = AdverseActionReportCms & BaseCms & {
  __typename?: 'QuoteCreditReportCms';
  address: Array<Scalars['String']['output']>;
  contact: CmsAction;
  description: Array<Scalars['String']['output']>;
  disclaimer: Array<Scalars['String']['output']>;
  reasons: Array<QuoteReasonCms>;
  title: Scalars['String']['output'];
};

export type QuoteCreditReportReason = AdverseActionReason & {
  __typename?: 'QuoteCreditReportReason';
  factors: Array<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  status: CreditReportStatus;
};

export type QuoteDenialOfCoverage = {
  __typename?: 'QuoteDenialOfCoverage';
  isAgencyReported: Scalars['Boolean']['output'];
  reasons: Array<QuoteAdverseActionReason>;
};

export type QuoteDriver = {
  __typename?: 'QuoteDriver';
  acv: QuoteAcv;
  creditReport?: Maybe<QuoteCreditReport>;
  drivingReport?: Maybe<QuoteDrivingReport>;
  id: Scalars['ID']['output'];
  profile: DriverProfile;
};

export type QuoteDrivingReport = {
  __typename?: 'QuoteDrivingReport';
  id: Scalars['ID']['output'];
  violations: Array<ViolationV2>;
};

export type QuoteDrivingReportCms = AdverseActionReportCms & BaseCms & {
  __typename?: 'QuoteDrivingReportCms';
  address: Array<Scalars['String']['output']>;
  contact: CmsAction;
  description: Array<Scalars['String']['output']>;
  disclaimer: Array<Scalars['String']['output']>;
  reasons: Array<QuoteReasonCms>;
  title: Scalars['String']['output'];
};

export type QuoteFilter = {
  quoteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuoteIncreasedPremium = {
  __typename?: 'QuoteIncreasedPremium';
  isAgencyReported: Scalars['Boolean']['output'];
  reasons: Array<QuoteAdverseActionReason>;
};

export type QuoteOption = {
  __typename?: 'QuoteOption';
  id: Scalars['ID']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  premium?: Maybe<CoveragePremium>;
  value: Scalars['String']['output'];
};

export type QuoteOptional = {
  __typename?: 'QuoteOptional';
  coverageGroups: Array<QuoteCoverageGroup>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type QuoteOverview = {
  __typename?: 'QuoteOverview';
  acceptedAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isAccepted: Scalars['Boolean']['output'];
  issueDate: Scalars['String']['output'];
  price: Quote;
  selectedPrice?: Maybe<QuotePrice>;
  selection?: Maybe<CoverageSelection>;
  status: QuoteOverviewStatus;
  validUntil: Scalars['String']['output'];
};

export enum QuoteOverviewStatus {
  Active = 'ACTIVE',
  Denied = 'DENIED',
  Expired = 'EXPIRED'
}

export type QuotePackage = {
  __typename?: 'QuotePackage';
  adverseActions?: Maybe<QuoteAdverseActions>;
  coverageGroups: Array<QuoteCoverageGroup>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  isRecommended?: Maybe<Scalars['Boolean']['output']>;
  optionalCoverageGroups: Array<QuoteOptional>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type QuotePrice = {
  __typename?: 'QuotePrice';
  pricePerDay?: Maybe<Scalars['Int']['output']>;
  pricePerMile?: Maybe<Scalars['Int']['output']>;
};

export type QuoteReasonCms = QuoteReasonDescriptionCms | QuoteReasonUnorderedListCms;

export type QuoteReasonDescriptionCms = {
  __typename?: 'QuoteReasonDescriptionCms';
  description: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type QuoteReasonUnorderedListCms = {
  __typename?: 'QuoteReasonUnorderedListCms';
  bulletPoints: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type QuoteSelfReportCms = AdverseActionReportCms & BaseCms & {
  __typename?: 'QuoteSelfReportCms';
  address: Array<Scalars['String']['output']>;
  contact: CmsAction;
  description: Array<Scalars['String']['output']>;
  disclaimer: Array<Scalars['String']['output']>;
  reasons: Array<QuoteReasonCms>;
  title: Scalars['String']['output'];
};

export type QuotesPage = {
  __typename?: 'QuotesPage';
  /** Page of quotes */
  nodes: Array<QuoteV2>;
  /** Info about the page of quotes, use this to continue pagination */
  pageInfo: PageInfo;
};

export enum QuoteStatus {
  Active = 'ACTIVE',
  /** A quote is in this status when it was used to bind a policy - in other words, a policy was issued using the details in the quote */
  Bound = 'BOUND',
  /** All coverages in the quote are denied */
  Denied = 'DENIED',
  Expired = 'EXPIRED',
  Voided = 'VOIDED'
}

export type QuoteV2 = {
  createdAt: Scalars['DateTime']['output'];
  driver: QuoteDriver;
  estimatedMonthlyMileage: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** The insurance application which was used to create this quote. Insurance application is immutable at this point */
  insuranceApplication?: Maybe<CompletedInsuranceApplication>;
  /**
   * The insurance application which was used to create this quote. Insurance application is immutable at this point
   * @deprecated use insuranceApplication instead
   */
  insuranceApplicationId?: Maybe<Scalars['ID']['output']>;
  policyStartDate?: Maybe<Scalars['String']['output']>;
  product: Product;
  status: QuoteStatus;
  vehicles: Array<QuoteVehicle>;
};

export type QuoteV2ByIdsInput = {
  id: Scalars['ID']['input'];
};

export type QuoteVehicle = {
  __typename?: 'QuoteVehicle';
  id?: Maybe<Scalars['ID']['output']>;
  lienholder?: Maybe<Lienholder>;
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  vin: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export enum QuoteVoidReason {
  AnotherQuoteAccepted = 'ANOTHER_QUOTE_ACCEPTED',
  Other = 'OTHER',
  PolicyCancelled = 'POLICY_CANCELLED'
}

export type ReassignGuestQuickQuoteOwnershipToCurrentUserInput = {
  guestUserEmail: Scalars['String']['input'];
};

export type ReassignGuestQuickQuoteOwnershipToCurrentUserResponse = {
  __typename?: 'ReassignGuestQuickQuoteOwnershipToCurrentUserResponse';
  isSuccess: Scalars['Boolean']['output'];
};

export type Referral = {
  __typename?: 'Referral';
  /**
   * The referral code
   * @deprecated Use `referrer`.
   */
  code?: Maybe<Scalars['String']['output']>;
  /** The personal details of a user */
  personal?: Maybe<ReferralPersonalDetails>;
  /** The user who's referral code was used */
  referrer?: Maybe<Referrer>;
  /**
   * The status to determine whether a a referral is activated, processed or maxed out
   * @deprecated Use `referrer`.
   */
  status?: Maybe<Scalars['String']['output']>;
};

export type ReferralCode = {
  __typename?: 'ReferralCode';
  /** The referral code generated */
  code?: Maybe<Scalars['String']['output']>;
  /** The id of the referrer a code should be generated for. */
  referrerId: Scalars['Int']['output'];
};

export type ReferralCodesInput = {
  /** List of ids for customers or sales people referral codes should be generated for type */
  referrerIds: Array<Scalars['Int']['input']>;
  /** The type of referral code to be generated */
  type: ReferralType;
};

export type ReferralInput = {
  /** The referral code entered by new user */
  code: Scalars['String']['input'];
  /** The device id of new user */
  deviceId: Scalars['String']['input'];
};

export type ReferralPersonalDetails = {
  __typename?: 'ReferralPersonalDetails';
  /** The personal code for a user */
  code?: Maybe<Scalars['String']['output']>;
};

export type ReferralsConfig = {
  __typename?: 'ReferralsConfig';
  /** Amount paid for each referral */
  amountPerReferral: Scalars['Int']['output'];
  /** Maximum number of customers a user can refer in order to be paid */
  referralLimit: Scalars['Int']['output'];
};

export enum ReferralType {
  Customer = 'CUSTOMER',
  SalesPerson = 'SALES_PERSON'
}

export type Referrer = {
  __typename?: 'Referrer';
  /** The referral code */
  code?: Maybe<Scalars['String']['output']>;
  /** The status to determine whether a a referral is activated, processed or maxed out */
  status?: Maybe<Scalars['String']['output']>;
  /** The user id of the user who's referral code was used */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type RefundPaymentInput = {
  payments: Array<PaymentInfo>;
};

export type RefundSuccessResponse = {
  __typename?: 'RefundSuccessResponse';
  errors: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RegenerateDrivingReportInput = {
  /** List of user ids with reports to be regenerated */
  userIds: Array<Scalars['ID']['input']>;
};

export type Registration = {
  __typename?: 'Registration';
  currentStep: RegistrationStep;
  nextStep: RegistrationStep;
};

export type RegistrationConfig = {
  __typename?: 'RegistrationConfig';
  version: Scalars['String']['output'];
};

export type RegistrationState = BaseUnderwritingAccountState & {
  __typename?: 'RegistrationState';
  futurePolicy?: Maybe<RegistrationStateFuturePolicy>;
  /** @deprecated use upcomingPolicy property instead */
  isRestricted: Scalars['Boolean']['output'];
  /** @deprecated use __typename instead */
  name: Scalars['String']['output'];
  upcomingPolicy?: Maybe<Policy>;
};

export type RegistrationStateFuturePolicy = {
  __typename?: 'RegistrationStateFuturePolicy';
  issuedAt: Scalars['String']['output'];
  policyholder: FuturePolicyDriver;
  policyNumber: Scalars['ID']['output'];
  term: Scalars['String']['output'];
  vehicles: Array<FuturePolicyVehicle>;
};

export enum RegistrationStep {
  Acv = 'ACV',
  Completed = 'COMPLETED',
  Profile = 'PROFILE',
  Quote = 'QUOTE',
  Topup = 'TOPUP',
  Vehicle = 'VEHICLE'
}

export enum ReinstateReason {
  FamilyMemberChange = 'FAMILY_MEMBER_CHANGE',
  Other = 'OTHER',
  /** @deprecated Use VEHICLE_PURCHASED_FROM_PREVIOUS_USER or VEHICLE_PURCHASED_FROM_DEALERSHIP */
  VehiclePurchased = 'VEHICLE_PURCHASED',
  VehiclePurchasedFromDealership = 'VEHICLE_PURCHASED_FROM_DEALERSHIP',
  VehiclePurchasedFromPreviousUser = 'VEHICLE_PURCHASED_FROM_PREVIOUS_USER'
}

export type ReinstateUserInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: ReinstateReason;
  userId: Scalars['ID']['input'];
};

export type ReissueBulkRenewalsReasonInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: ReissueRenewalReason;
  userIds: Array<Scalars['ID']['input']>;
};

export enum ReissueRenewalReason {
  CreditScoreChange = 'CREDIT_SCORE_CHANGE',
  DrivingReportChange = 'DRIVING_REPORT_CHANGE',
  PolicyDetailsUpdate = 'POLICY_DETAILS_UPDATE'
}

export type ReissueRenewalReasonInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: ReissueRenewalReason;
  userId: Scalars['ID']['input'];
};

export type RemovePaymentMethodInput = {
  token: Scalars['String']['input'];
};

export type Renewal = {
  __typename?: 'Renewal';
  isAcceptable: Scalars['Boolean']['output'];
  offers: Array<RenewalOffer>;
  quote: Quote;
  /** @deprecated use quotes property, main quote offer, has property isPrimary = true */
  startDate: Scalars['String']['output'];
};

export type RenewalOffer = {
  __typename?: 'RenewalOffer';
  isPrimary: Scalars['Boolean']['output'];
  quote: Quote;
};

export type ReplayDomainEventInput = {
  domainEvent: PolicyEventType;
  policyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type Report = {
  __typename?: 'Report';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dateOfBirth: Scalars['String']['output'];
  driverLicense: Scalars['String']['output'];
  /** Following have same possible options as "result" */
  name: Scalars['String']['output'];
  overridden?: Maybe<Override>;
  phoneNumber: Scalars['String']['output'];
  /** One of: - unchecked - we haven't checked the identity - clear - the user has successfully been verified - uncertain - we could not verify the identity */
  result: Scalars['String']['output'];
  /** One of: - identity_uncertain - initial state - confirming_identity - internal state won't be observed when querying - confirming_address [PHASE 3] - requires_documentation [PHASE 3] - overridden - final state - declined - final state - complete - final state */
  status: Scalars['String']['output'];
};

export type RequestRefundInput = {
  /** ID of the user to request refund for. If not specified inferred from JWT */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type RescindPolicyInput = {
  userId: Scalars['ID']['input'];
};

export type ResendAdverseActionEmailInput = {
  userId: Scalars['ID']['input'];
};

export type ResendAdverseActionEmailResponse = {
  __typename?: 'ResendAdverseActionEmailResponse';
  success: Scalars['Boolean']['output'];
};

export type ResetRetryAttemptsInput = {
  note: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type ResetRetryAttemptsResult = {
  __typename?: 'ResetRetryAttemptsResult';
  success: Scalars['Boolean']['output'];
};

export type RestrictUserInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: RestrictUserReason;
  userId: Scalars['ID']['input'];
};

export enum RestrictUserReason {
  DuplicateUser = 'DUPLICATE_USER',
  MultipleDisputedPayments = 'MULTIPLE_DISPUTED_PAYMENTS',
  NegativeBalances = 'NEGATIVE_BALANCES',
  Other = 'OTHER'
}

export enum Role {
  Admin = 'ADMIN',
  Public = 'PUBLIC',
  User = 'USER'
}

export type RunChecksResult = BasicCompletedInsuranceApplicationResult | CompletedInsuranceApplicationResult | DraftInsuranceApplicationResult | UnexpectedError;

export type RunCreditCheckInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type RunDrivingCheckInput = {
  /** Allows to force detailed check (skip indicator stage) by setting forceDetailedCheck bool to true */
  forceDetailedCheck: Scalars['Boolean']['input'];
  /** Provide justification for running the action */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Driving report type definition with user ID. */
  userId: Scalars['ID']['input'];
};

export type RunIdentityCheckResult = {
  __typename?: 'RunIdentityCheckResult';
  status: IdentityCheckStatus;
};

export type SalesPerson = {
  __typename?: 'SalesPerson';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  level: SalesPersonLevel;
  organisation: Organisation;
  personalCode?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  taxId?: Maybe<Scalars['String']['output']>;
};

export type SalesPersonInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  level: SalesPersonLevel;
  organisationId: Scalars['Int']['input'];
  phoneNumber: Scalars['String']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export enum SalesPersonLevel {
  Bronze = 'BRONZE',
  Platinum = 'PLATINUM'
}

export type SavedAttachment = {
  __typename?: 'SavedAttachment';
  filename?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ScoreFactors = {
  __typename?: 'ScoreFactors';
  roadConditions: Scalars['Int']['output'];
  speeding: Scalars['Int']['output'];
};

export type ScorePeriod = {
  __typename?: 'ScorePeriod';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type ScoreRatio = {
  __typename?: 'ScoreRatio';
  bi: Scalars['Int']['output'];
  pd: Scalars['Int']['output'];
};

export type ScoreSafe = {
  __typename?: 'ScoreSafe';
  aggregate: Scalars['Int']['output'];
  factors: ScoreFactors;
  id: Scalars['String']['output'];
  period: ScorePeriod;
  priceRatio: ScoreRatio;
  timestamp: Scalars['Int']['output'];
};

export type SearchForVehiclesInput = {
  insuranceApplicationId: Scalars['String']['input'];
};

export type SearchForVehiclesResult = SearchForVehiclesSuccess | UnexpectedError;

export type SearchForVehiclesSuccess = {
  __typename?: 'SearchForVehiclesSuccess';
  vehicles: Array<FoundVehicle>;
};

export type SelectCoveragesInput = {
  quoteId: Scalars['ID']['input'];
  selection: Selection;
};

export type SelectedCoverage = CoverageComposition & {
  __typename?: 'SelectedCoverage';
  allocatedPremium: AllocatedPremium;
  deductible?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isOptional: Scalars['Boolean']['output'];
  limits: Array<LimitV2>;
  optionId?: Maybe<Scalars['ID']['output']>;
  type: CoverageType;
};

export type SelectedOption = {
  coverageId: Scalars['ID']['input'];
  optionId: Scalars['ID']['input'];
};

export type SelectedPackage = {
  __typename?: 'SelectedPackage';
  adverseActions?: Maybe<AdverseActions>;
  content: SelectedPackageCms;
  coverages: Array<SelectedCoverage>;
  id: Scalars['ID']['output'];
  total: Total;
};

export type SelectedPackageCms = {
  __typename?: 'SelectedPackageCms';
  disclaimer: MultiFormatText;
  packageName: Scalars['String']['output'];
  price: TotalCms;
  summary: SelectedPackageSummaryCms;
};

export type SelectedPackageSummaryCms = {
  __typename?: 'SelectedPackageSummaryCms';
  baseCoverages: Array<SummaryCms>;
  coveragesWithOptions: Array<SummaryCms>;
  includedCoverages: Array<SummaryCms>;
};

export type Selection = {
  options?: InputMaybe<Array<SelectedOption>>;
  packageId: Scalars['ID']['input'];
};

export type SelectQuickQuotePackageInput = {
  quoteId: Scalars['ID']['input'];
  selectedPackageId: Scalars['String']['input'];
};

export type SelectQuickQuotePackageResponse = {
  __typename?: 'SelectQuickQuotePackageResponse';
  quote: PricedQuote;
  quoteId: Scalars['ID']['output'];
};

export type SelfDeclaredAcvReason = AdverseActionReason & {
  __typename?: 'SelfDeclaredACVReason';
  factors: Array<Scalars['String']['output']>;
};

export type SelfReport = {
  __typename?: 'SelfReport';
  content: QuoteSelfReportCms;
  reasons: Array<Scalars['String']['output']>;
};

export type SendPolicyDocsInput = {
  sendToCustomer: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type SendPolicyDocumentsInput = {
  policyNr: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  to: Scalars['String']['input'];
  type: PolicyDocumentType;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type SentianceTrackingConfig = {
  __typename?: 'SentianceTrackingConfig';
  autoStartEnabled: Scalars['Boolean']['output'];
  bluetoothScanEnabled: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  extraLoggingEnabled: Scalars['Boolean']['output'];
  userLinkingEnabled: Scalars['Boolean']['output'];
};

export type SetupAutomaticPaymentsInput = {
  /** Is large payment amount approval enabled. If disabled we will charge user without asking to approve. If not specified we will default to `true`. Field is not required because of backwards compatibility */
  isApprovalEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethodToken: Scalars['String']['input'];
  refillWithAmount: Scalars['Int']['input'];
  /** Threshold (in cents) of when we should trigger automatic payments. This is exposed for deposit PoC only */
  threshold?: InputMaybe<Scalars['Int']['input']>;
};

export type SetVehicleFromVinInput = {
  vin: Scalars['String']['input'];
};

export type SetVehicleFromVinResult = BasicCompletedInsuranceApplicationResult | CompletedInsuranceApplicationResult | DraftInsuranceApplicationResult | InputFieldValidationError | UnexpectedError | UninsurableVinError;

export type ShortenUrlInput = {
  url: Scalars['String']['input'];
};

export type SinglePaymentConfig = {
  __typename?: 'SinglePaymentConfig';
  /** Minimum cents amount a user can topup for their first payment */
  minimumInitialAmount: Scalars['Int']['output'];
};

export type SmsMarketing = {
  __typename?: 'SmsMarketing';
  /** List of available SMS marketing preferences */
  preferences: Array<MarketingPreference>;
};


export type SmsMarketingPreferencesArgs = {
  filter?: InputMaybe<SmsMarketingPreferencesFilter>;
};

export type SmsMarketingPreferencesFilter = {
  type: MarketingPreferenceType;
};

export enum SmsStatus {
  Accepted = 'ACCEPTED',
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  Processing = 'PROCESSING',
  Queued = 'QUEUED',
  Read = 'READ',
  Received = 'RECEIVED',
  Receiving = 'RECEIVING',
  Scheduled = 'SCHEDULED',
  Sending = 'SENDING',
  Sent = 'SENT',
  Undeliverable = 'UNDELIVERABLE',
  Undelivered = 'UNDELIVERED'
}

/** Information about the state */
export type State = {
  __typename?: 'State';
  /** State code, this is a unique stable identifier we use in other systems */
  code: StateCode;
  /** Are we currently selling policies in this state */
  isSupported: Scalars['Boolean']['output'];
  /** Human readable name of the sate */
  name: Scalars['String']['output'];
};

export enum StateCode {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

/** Coverage Limit of how much we will pay in the case of accident. */
export type StructuredLimit = {
  __typename?: 'StructuredLimit';
  amount: Scalars['Int']['output'];
  unit: LimitUnit;
};

export enum StructuredLimitUnit {
  Accident = 'ACCIDENT',
  Person = 'PERSON'
}

export type StructuredLimitV2 = {
  __typename?: 'StructuredLimitV2';
  amount: Scalars['Int']['output'];
  unit: StructuredLimitUnit;
};

export type SubmitAcvInput = {
  acv: InsuranceApplicationAcvInput;
};

export type SubmitAcvResult = BasicCompletedInsuranceApplicationResult | CompletedInsuranceApplicationResult | DraftInsuranceApplicationResult | InputObjectValidationError | UnexpectedError;

export type SubmitInsuranceApplicationFormInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  driverLicenseBarcodeRawData?: InputMaybe<Scalars['String']['input']>;
  driverLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  driverLicenseState?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  estimatedMonthlyMileage?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  policyStartDate?: InputMaybe<Scalars['Date']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitInsuranceApplicationFormResult = BasicCompletedInsuranceApplicationResult | CompletedInsuranceApplicationResult | DraftInsuranceApplicationResult | InputFieldValidationError | UnexpectedError;

export type Subscription = {
  __typename?: 'Subscription';
  currentInsuranceApplication?: Maybe<InsuranceApplicationResult>;
  fundsInfo?: Maybe<FundsInfo>;
  heartbeat: Scalars['String']['output'];
};


export type SubscriptionFundsInfoArgs = {
  input: FundsInfoInput;
};

export type SummaryBreakdown = {
  __typename?: 'SummaryBreakdown';
  /** This contains only info about the odometer tracked mileage */
  actual: SummaryDetail;
  /** Sum of daily charges */
  dailyCharge: Scalars['String']['output'];
  /** This contains only info about the odometer tracked mileage + projected mileage based on the usage */
  prorated: SummaryDetail;
  /** This contains only info about the pseudo mileage when odometer readings are not available */
  pseudo: SummaryDetail;
};

export type SummaryCms = BaseCms & {
  __typename?: 'SummaryCms';
  description: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type SummaryDetail = {
  __typename?: 'SummaryDetail';
  averageMileage: Scalars['String']['output'];
  daysCharged: Scalars['String']['output'];
  totalCharge: Scalars['String']['output'];
  totalMileage: Scalars['String']['output'];
};

export type SummaryMultiFormatCms = MultiFormatBaseCms & {
  __typename?: 'SummaryMultiFormatCms';
  description: MultiFormatText;
  title: MultiFormatText;
};

export type SuspendDetails = {
  __typename?: 'SuspendDetails';
  notes?: Maybe<Scalars['String']['output']>;
  reason: SuspendReason;
};

export type SuspendedState = BaseUnderwritingAccountState & {
  __typename?: 'SuspendedState';
  date: Scalars['String']['output'];
  details: Array<SuspendDetails>;
  isRestricted: Scalars['Boolean']['output'];
};

export enum SuspendReason {
  DuplicateVin = 'DUPLICATE_VIN'
}

export enum SuspensionReason {
  NonPayment = 'NON_PAYMENT'
}

export type TallyUp = {
  __typename?: 'TallyUp';
  createdAt: Scalars['String']['output'];
  reading: Scalars['String']['output'];
};

export type TallyUpSubmission = {
  __typename?: 'TallyUpSubmission';
  state: TallyUpSubmissionState;
};

export type TallyUpSubmissionDue = {
  __typename?: 'TallyUpSubmissionDue';
  dueDate: Scalars['String']['output'];
  state: TallyUpSubmissionState;
  vehicleId: Scalars['ID']['output'];
};

export enum TallyUpSubmissionState {
  Due = 'DUE',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type TimePassedInput = {
  userId: Scalars['ID']['input'];
};

export type Total = {
  __typename?: 'Total';
  estimatedMonthlyCost: Scalars['Int']['output'];
  estimatedYearlyCost: Scalars['Int']['output'];
  initialPayment: InitialPayment;
  premium: AllocatedPremium;
};

export type TotalCms = {
  __typename?: 'TotalCms';
  estimatedMonthlyCost: SummaryCms;
  estimatedYearlyCost: SummaryCms;
  initialPayment: SummaryCms;
  premium: SummaryCms;
};

export type TotalPremium = {
  __typename?: 'TotalPremium';
  perDay?: Maybe<Scalars['Int']['output']>;
  perMile: Scalars['Int']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  activityType?: Maybe<ActivityType>;
  amount: Scalars['Float']['output'];
  balance: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type: TransactionType;
};

export enum TransactionType {
  Credit = 'credit',
  Debit = 'debit'
}

export type Trip = {
  __typename?: 'Trip';
  costBreakdown: TripChargeBreakdown;
  disputeDetails?: Maybe<TripDispute>;
  distance: TripDistance;
  hasTripData: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interval: Scalars['String']['output'];
  tripDataUrl?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export enum TripBillType {
  CapAdjusted = 'CAP_ADJUSTED',
  Charge = 'CHARGE',
  Refund = 'REFUND'
}

export type TripChargeBreakdown = {
  __typename?: 'TripChargeBreakdown';
  cost: TripCost;
  mileageCap?: Maybe<Scalars['Float']['output']>;
  policy: TripPolicy;
};

export type TripCost = {
  __typename?: 'TripCost';
  charged: Scalars['Int']['output'];
  trip: Scalars['Int']['output'];
};

export type TripDispute = {
  __typename?: 'TripDispute';
  feedback?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

export type TripDisputeConfig = {
  __typename?: 'TripDisputeConfig';
  enabled: Scalars['Boolean']['output'];
};

export type TripDisputeInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
};

export type TripDistance = {
  __typename?: 'TripDistance';
  capped?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type TripInput = {
  id: Scalars['ID']['input'];
  mock?: InputMaybe<TripInputMock>;
};

export type TripInputMock = {
  responseCapType?: InputMaybe<TripResponseMockCapType>;
  type?: InputMaybe<TripResponseMockType>;
};

export type TripPolicy = {
  __typename?: 'TripPolicy';
  pricePerMile: Scalars['Int']['output'];
};

export enum TripResponseMockCapType {
  FullCap = 'FULL_CAP',
  NoCap = 'NO_CAP',
  PartialCap = 'PARTIAL_CAP'
}

export enum TripResponseMockType {
  SuccessPartialMock = 'SUCCESS_PARTIAL_MOCK'
}

export type TripsLegacyPaginationInput = {
  orderBy: Scalars['String']['input'];
  orderDir: OrderDir;
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export type TripsMockInput = {
  mock: TripInputMock;
};

export type Underwriting = {
  __typename?: 'Underwriting';
  /** All reasons Just can cancel user for */
  cancellationReasons: Array<CancellationExplanation>;
};

export type UnderwritingAccount = {
  __typename?: 'UnderwritingAccount';
  isRestricted: Scalars['Boolean']['output'];
  state: UnderwritingAccountState;
};

export type UnderwritingAccountState = ActiveState | LapsedState | RegistrationState | SuspendedState;

export type UnderwritingEvaluateInput = {
  userId: Scalars['ID']['input'];
};

export type UnderwritingUser = {
  __typename?: 'UnderwritingUser';
  account?: Maybe<UnderwritingAccount>;
};

export type UnexpectedError = BaseError & {
  __typename?: 'UnexpectedError';
  message: Scalars['String']['output'];
  type: UnexpectedErrorType;
};

export enum UnexpectedErrorType {
  Client = 'CLIENT',
  Server = 'SERVER'
}

/** We can decode vehicle and VIN is correct but we do not have this vehicle in our insurable vehicles list. */
export type UninsurableVehicle = {
  __typename?: 'UninsurableVehicle';
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  vin: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type UninsurableVinError = {
  __typename?: 'UninsurableVinError';
  message: Scalars['String']['output'];
};

export type UnrestrictUserInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: UnrestrictUserReason;
  userId: Scalars['ID']['input'];
};

export enum UnrestrictUserReason {
  DuplicateUserOverride = 'DUPLICATE_USER_OVERRIDE',
  Other = 'OTHER'
}

/** Unstructured Limit is the one which can not be expressed for specfic `LimitUnit`, instead it will be free text like `cost of replacement` */
export type UnstructuredLimit = {
  __typename?: 'UnstructuredLimit';
  value: Scalars['String']['output'];
};

export enum UnstructuredLimitUnit {
  CashValue = 'CASH_VALUE',
  ReplacementCost = 'REPLACEMENT_COST'
}

export type UnstructuredLimitV2 = {
  __typename?: 'UnstructuredLimitV2';
  value: UnstructuredLimitUnit;
};

export type UpdateActivePolicyLienholderInput = {
  lienholder: VehicleLienholder;
  userId: Scalars['String']['input'];
  /** VIN of the vehicle which lienholder we want to modify */
  vin: Scalars['String']['input'];
};

export type UpdateDrivingLicenseInput = {
  barcodeRaw?: InputMaybe<Scalars['String']['input']>;
  licenseNumber: Scalars['String']['input'];
  state: StateCode;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmailMarketingPreferencesInput = {
  marketingPreferences: Array<MarketingPreferenceInput>;
  /** ID of the user to update SMS marketing preferences for. Inferred from JWT if omitted. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateFuturePolicyStartDateInput = {
  /** Future policy id to update start date for */
  policyId: Scalars['ID']['input'];
  /** ISO8601 date of the new policy start date this is both date and time. Any date in the past will make the policy start immediately */
  startDate: Scalars['String']['input'];
  /** User id to update policy start date for */
  userId: Scalars['ID']['input'];
};

export type UpdatePhoneNumberInput = {
  /** The new phone to replace the old one with */
  phoneNumber: Scalars['String']['input'];
};

export type UpdatePhoneNumberResult = FieldValidationError | UnexpectedError | User;

export type UpdateSmsMarketingPreferencesInput = {
  /** The language in which the user should receive SMS marketing. Defaults to en_US if omitted. */
  language?: InputMaybe<Scalars['String']['input']>;
  marketingPreferences: Array<MarketingPreferenceInput>;
  /** The user's phone number. This number will receive SMS marketing for enabled preferences. */
  phoneNumber: Scalars['String']['input'];
  /** ID of the user to update SMS marketing preferences for. Inferred from JWT if omitted. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateUserEmailInput = {
  /** The new email to replace the old one with */
  email: Scalars['EmailAddress']['input'];
  /** Optional accompanying notes about why the update was made */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Reason about why the update was made */
  reason: UpdateUserEmailReason;
  /** ID of the user whose email to update */
  userId: Scalars['ID']['input'];
};

export enum UpdateUserEmailReason {
  LostAccess = 'LOST_ACCESS',
  Other = 'OTHER'
}

export type UpdateUserPhoneNumberInput = {
  /** Optional accompanying notes about why the update was made */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The new phone to replace the old one with */
  phoneNumber: Scalars['String']['input'];
  /** Reason about why the update was made */
  reason: UpdateUserPhoneNumberReason;
  /** ID of the user whose phone to update */
  userId: Scalars['ID']['input'];
};

export enum UpdateUserPhoneNumberReason {
  LostAccess = 'LOST_ACCESS',
  Other = 'OTHER'
}

export type UpdateVehicleInput = {
  lienholderEmail?: InputMaybe<Scalars['String']['input']>;
  lienholderName?: InputMaybe<Scalars['String']['input']>;
  lienholderPhone?: InputMaybe<Scalars['String']['input']>;
  vehicleId: Scalars['ID']['input'];
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVehicleResult = BasicCompletedInsuranceApplicationResult | CompletedInsuranceApplicationResult | DraftInsuranceApplicationResult | InputFieldValidationError | UnexpectedError | UninsurableVinError;

export type UpgradeInfoConfig = {
  __typename?: 'UpgradeInfoConfig';
  link: Scalars['String']['output'];
  message: Scalars['String']['output'];
  minBuildNumber: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  update?: Maybe<Scalars['String']['output']>;
};

export type Usage = {
  __typename?: 'Usage';
  /** All charges of a user over the search period */
  charges?: Maybe<ChargesSummary>;
  /** All distance covered of a user over the search period */
  distances?: Maybe<DistancesSummary>;
};

export type UsageInput = {
  /** ISO8601 String for end date, defaults to current point in time */
  end?: InputMaybe<Scalars['String']['input']>;
  /** ISO8601 String for start date, defaults to start of Just Auto */
  start: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  /** @deprecated use underwriting.account.state.active as in ActiveState instead */
  actions: Array<Action>;
  acv?: Maybe<Acv>;
  auditHistory?: Maybe<AuditHistoryConnection>;
  betaFlagContent: BetaFlagContent;
  config: UserConfig;
  currentInsuranceApplication?: Maybe<InsuranceApplicationResult>;
  /** Information discovered about the user, including information like vehicles registered at their address */
  discovered?: Maybe<UserDiscovered>;
  drivingReportStatus: UserDrivingReportStatus;
  duplicateUserInfo?: Maybe<DuplicateUserInfo>;
  email: Scalars['String']['output'];
  emails: Array<AccountEmail>;
  id: Scalars['ID']['output'];
  identity?: Maybe<Identity>;
  /** User's identity check report */
  identityCheckReport: IdentityCheckReport;
  identityHistory: Array<Maybe<Identity>>;
  identityReport?: Maybe<Report>;
  identityReportHistory: Array<Maybe<Report>>;
  insuranceApplication?: Maybe<InsuranceApplicationResult>;
  insuranceApplications?: Maybe<InsuranceApplicationsResult>;
  insuranceSummary?: Maybe<InsuranceSummary>;
  /** User's marketing preferences */
  marketing: UserMarketingPreferences;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  policies: Array<Policy>;
  policyholderApplication: PolicyholderApplication;
  profile?: Maybe<Profile>;
  quoteOverviews: Array<QuoteOverview>;
  /** Returns a cursor-paginated page of quotes for the user */
  quotes: QuotesPage;
  /** Return a referral details to user */
  referral?: Maybe<Referral>;
  registration?: Maybe<Registration>;
  renewal?: Maybe<Renewal>;
  sms: Array<AccountSms>;
  stats: UserStats;
  transactions: Array<Transaction>;
  tripsLegacy: Array<Trip>;
  underwriting: UnderwritingUser;
  usage?: Maybe<Usage>;
  /** Current default vehicle. This is not necessary same as the vehicle associated with the current active policy */
  vehicle?: Maybe<InsurableVehicle>;
  vehicles: Array<InsurableVehicle>;
  wallet: Wallet;
  workflows?: Maybe<Array<Workflow>>;
};


export type UserActionsArgs = {
  actionFilter?: InputMaybe<ActionFilter>;
};


export type UserAuditHistoryArgs = {
  input: AuditHistoryInput;
};


export type UserInsuranceApplicationArgs = {
  id: Scalars['ID']['input'];
};


export type UserInsuranceSummaryArgs = {
  insuranceSummaryInput: InsuranceSummaryInput;
};


export type UserPoliciesArgs = {
  input?: InputMaybe<PoliciesInput>;
};


export type UserQuotesArgs = {
  input: GetQuotesInput;
};


export type UserSmsArgs = {
  since: Scalars['String']['input'];
  until?: InputMaybe<Scalars['String']['input']>;
};


export type UserTripsLegacyArgs = {
  input?: InputMaybe<TripsMockInput>;
};


export type UserUsageArgs = {
  usageInput: UsageInput;
};

export type UserConfig = {
  __typename?: 'UserConfig';
  vehicleDetection: VehicleDetectionUserConfig;
};

export type UserConfigInput = {
  vehicleDetection?: InputMaybe<VehicleDetectionUserConfigInput>;
};

export type UserDiscovered = {
  __typename?: 'UserDiscovered';
  /** ISO8601 Date string - when was the last time we looked up vehicles associated with the user in the external provider */
  lastUpdate?: Maybe<Scalars['String']['output']>;
  /** Vehicles we have discovered associated with the user. This can have vehicles we can't insure, parents cars or other transportation. Check `insured` property to see if we can insure this transportation */
  vehicles: Array<DiscoveredVehicle>;
};

export enum UserDrivingReportStatus {
  Available = 'AVAILABLE',
  NotEligible = 'NOT_ELIGIBLE',
  Pending = 'PENDING'
}

export type UserEmailMarketing = {
  __typename?: 'UserEmailMarketing';
  preferences: Array<UserMarketingPreference>;
};


export type UserEmailMarketingPreferencesArgs = {
  filter?: InputMaybe<EmailMarketingPreferencesFilter>;
};

export type UserMarketingPreference = {
  __typename?: 'UserMarketingPreference';
  /** Text to show in frontend for this marketing preference */
  description: Scalars['String']['output'];
  /** true if user has opted-in to this marketing preference, false otherwise */
  isEnabled: Scalars['Boolean']['output'];
  /** Internal marketing preference name */
  name: Scalars['String']['output'];
  type: MarketingPreferenceType;
};

export type UserMarketingPreferences = {
  __typename?: 'UserMarketingPreferences';
  email?: Maybe<UserEmailMarketing>;
  sms?: Maybe<UserSmsMarketing>;
};

export type UserSmsMarketing = {
  __typename?: 'UserSmsMarketing';
  /** The language in which the user should receive SMS marketing */
  language: Scalars['String']['output'];
  /** The user's phone number. This number will receive SMS marketing for enabled preferences. */
  phoneNumber: Scalars['String']['output'];
  /** List of user's SMS marketing preferences */
  preferences: Array<UserMarketingPreference>;
};


export type UserSmsMarketingPreferencesArgs = {
  filter?: InputMaybe<SmsMarketingPreferencesFilter>;
};

/** Statistical information about user */
export type UserStats = {
  __typename?: 'UserStats';
  /** Very first day of insurance with us ISO8601 */
  firstInsuredDate?: Maybe<Scalars['String']['output']>;
  /** Total number of km driven */
  totalDistance: Scalars['Float']['output'];
  /** Total number of days customer has been insured with us */
  totalInsuredDays: Scalars['Int']['output'];
  /** Total amount of refund issued */
  totalRefundAmount: Scalars['Int']['output'];
  /** Total amount of top up including manual and automatic */
  totalTopupAmount: Scalars['Int']['output'];
};

export type ValidateZipCodeInput = {
  zipCode: Scalars['String']['input'];
};

export type ValuesWithCount = {
  __typename?: 'ValuesWithCount';
  count: Scalars['Int']['output'];
  values: Array<Scalars['String']['output']>;
};

/** Information about vehicles insurability. Use `__typename` to check if vehicle can be insured */
export type Vehicle = InsurableVehicle | UninsurableVehicle | VehicleError;

export type VehicleDetails = {
  __typename?: 'VehicleDetails';
  /** Vehicle ID */
  id: Scalars['ID']['output'];
  /** Make of a vehicle. This is not a stable identifier and if brand name changes this can change */
  make: Scalars['String']['output'];
  /** Model of a vehicle. This is not a stable identifier and if model name changes this can change */
  model: Scalars['String']['output'];
  /** Vehicle VIN, can be 15 or 17 characters */
  vin: Scalars['ID']['output'];
  /** Vehicle manufacturing year (e.g. 2015) */
  year: Scalars['Int']['output'];
};

export type VehicleDetectionConfig = {
  __typename?: 'VehicleDetectionConfig';
  bluetoothDeviceSelected: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
};

export type VehicleDetectionUserConfig = {
  __typename?: 'VehicleDetectionUserConfig';
  isDeviceSelected: Scalars['Boolean']['output'];
};

export type VehicleDetectionUserConfigInput = {
  isDeviceSelected?: InputMaybe<Scalars['Boolean']['input']>;
};

/** We failed to decode partially/completely VIN. We will provide make/model/year if error was partial as well as error information about why we failed to decode. *Note: services being down or any other __exceptional cases__ will be returned as GQL error* */
export type VehicleError = {
  __typename?: 'VehicleError';
  error: VehicleErrorDetails;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  vin: Scalars['String']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};

/** Error info about why VIN decode failed */
export type VehicleErrorDetails = {
  __typename?: 'VehicleErrorDetails';
  /** Stable and unique error code */
  code?: Maybe<Scalars['String']['output']>;
  /** Details on why we failed to decode VIN */
  message: Scalars['String']['output'];
};

export type VehicleInput = {
  makeId?: InputMaybe<Scalars['Int']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  vin: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type VehicleLienholder = {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleSelectionConfig = {
  __typename?: 'VehicleSelectionConfig';
  enabled: Scalars['Boolean']['output'];
  /** Determines how long in milliseconds to wait for a response from Fenris */
  timeout: Scalars['Int']['output'];
};

export type Venmo = PaymentMethodBase & {
  __typename?: 'Venmo';
  error?: Maybe<PaymentMethodError>;
  imageUrl: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  lastUsed?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentType: PaymentType;
  token: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export enum VerificationState {
  Failing = 'FAILING',
  Pending = 'PENDING',
  Verified = 'VERIFIED',
  Verifying = 'VERIFYING'
}

export type Violation = {
  __typename?: 'Violation';
  violationType: Scalars['Int']['output'];
  yearOccurred: Scalars['Int']['output'];
};

export type ViolationInput = {
  violationType: Scalars['Int']['input'];
  yearOccurred: Scalars['Int']['input'];
};

export enum ViolationType {
  Major = 'MAJOR',
  Minor = 'MINOR'
}

export type ViolationV2 = {
  __typename?: 'ViolationV2';
  description: Scalars['String']['output'];
  type: ViolationType;
  yearOccurred: Scalars['Int']['output'];
};

export type VisualInspectionRequirement = BindRequirement & {
  __typename?: 'VisualInspectionRequirement';
  expiresIn?: Maybe<Scalars['String']['output']>;
  photos: Array<BindRequirementsVehicleEvidencePhoto>;
  startedAt?: Maybe<Scalars['String']['output']>;
  status: BindRequirementStatus;
  type: BindRequirementType;
};

export type VoidFuturePolicyInput = {
  policyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type VoidQuoteInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  quoteId: Scalars['ID']['input'];
  reason: QuoteVoidReason;
};

export type Wallet = {
  __typename?: 'Wallet';
  automaticPaymentConfig: AutomaticPaymentsConfig;
  automaticPayments?: Maybe<AutomaticPayments>;
  /** Total balance which excludes deposit */
  balance: Scalars['Int']['output'];
  /** Total money that user has with us. This is current balance + deposit */
  funds: Scalars['Int']['output'];
  payment: PaymentTransaction;
  paymentConfig: PaymentConfig;
  paymentMethods: Array<PaymentMethod>;
};


export type WalletPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type Workflow = {
  __typename?: 'Workflow';
  name: Scalars['ID']['output'];
  paused: Scalars['Boolean']['output'];
  title: Scalars['ID']['output'];
};

export type WorkflowActionInput = {
  emitAction: Scalars['Boolean']['input'];
  segmentName: WorkflowSegmentName;
  userIds: Array<Scalars['ID']['input']>;
  workflowName: WorkflowName;
};

export type WorkflowEventInput = {
  eventType: WorkflowEventType;
  userIds: Array<Scalars['ID']['input']>;
};

export enum WorkflowEventType {
  PolicyCreated = 'POLICY_CREATED'
}

export type WorkflowInput = {
  name: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum WorkflowName {
  ExpiringPolicy = 'EXPIRING_POLICY',
  NegativeBalance = 'NEGATIVE_BALANCE'
}

export enum WorkflowSegmentName {
  ExpiredPolicy = 'EXPIRED_POLICY',
  ExpiringPolicyDay22 = 'EXPIRING_POLICY_DAY22',
  ExpiringPolicyDay23 = 'EXPIRING_POLICY_DAY23',
  ExpiringPolicyDay24 = 'EXPIRING_POLICY_DAY24',
  ExpiringPolicyDay25 = 'EXPIRING_POLICY_DAY25',
  ExpiringPolicyDay26 = 'EXPIRING_POLICY_DAY26',
  ExpiringPolicyDay27 = 'EXPIRING_POLICY_DAY27',
  ExpiringPolicyDay28 = 'EXPIRING_POLICY_DAY28',
  ExpiringPolicyDay29 = 'EXPIRING_POLICY_DAY29',
  ExpiringPolicyDay30 = 'EXPIRING_POLICY_DAY30',
  NegativeBalanceDay1 = 'NEGATIVE_BALANCE_DAY1',
  NegativeBalanceDay1HourEvent = 'NEGATIVE_BALANCE_DAY1_HOUR_EVENT',
  NegativeBalanceDay2 = 'NEGATIVE_BALANCE_DAY2',
  NegativeBalanceDay3 = 'NEGATIVE_BALANCE_DAY3',
  NegativeBalanceDay4 = 'NEGATIVE_BALANCE_DAY4',
  NegativeBalanceDay5 = 'NEGATIVE_BALANCE_DAY5',
  NegativeBalanceDay6 = 'NEGATIVE_BALANCE_DAY6',
  NegativeBalanceDay7 = 'NEGATIVE_BALANCE_DAY7',
  NegativeBalanceDay8 = 'NEGATIVE_BALANCE_DAY8',
  NegativeBalanceDay9 = 'NEGATIVE_BALANCE_DAY9'
}

export type AddOrganisationMutationVariables = Exact<{
  organisation: OrganisationInput;
}>;


export type AddOrganisationMutation = { __typename?: 'Mutation', addOrganisation?: { __typename?: 'Organisation', id: number } | null };

export type AddSalesPersonMutationVariables = Exact<{
  salesPerson: SalesPersonInput;
}>;


export type AddSalesPersonMutation = { __typename?: 'Mutation', addSalesPerson?: { __typename?: 'SalesPerson', id: number } | null };

export type AmendActivePolicyLienholderMutationVariables = Exact<{
  input: UpdateActivePolicyLienholderInput;
}>;


export type AmendActivePolicyLienholderMutation = { __typename?: 'Mutation', amendActivePolicyLienholder?: string | null };

export type CancelUserPolicyMutationVariables = Exact<{
  input: CancelUserPolicyInput;
}>;


export type CancelUserPolicyMutation = { __typename?: 'Mutation', cancelUserPolicy?: string | null };

export type ChargeUserMutationVariables = Exact<{
  input: ChargeInput;
}>;


export type ChargeUserMutation = { __typename?: 'Mutation', charge: { __typename: 'ChargeFailure', code: string, message: string, providerMessage: string, reason: string, type: PaymentMethodErrorType } | { __typename: 'ChargeSuccess', amount: number, id: number, requester: string, token: string } };

export type CreateEndorsementQuoteMutationVariables = Exact<{
  input: CreateEndorsementQuoteInput;
}>;


export type CreateEndorsementQuoteMutation = { __typename?: 'Mutation', createEndorsementQuote?: string | null };

export type CreateReferralCodesMutationVariables = Exact<{
  referralCodesInput: ReferralCodesInput;
}>;


export type CreateReferralCodesMutation = { __typename?: 'Mutation', createReferralCodes?: Array<{ __typename?: 'ReferralCode', code?: string | null, referrerId: number } | null> | null };

export type DisableAutoPayMutationVariables = Exact<{
  input: DisableAutomaticPaymentsForUserInput;
}>;


export type DisableAutoPayMutation = { __typename?: 'Mutation', disableAutomaticPaymentsForUser: { __typename?: 'AutomaticPayments', isEnabled: boolean } };

export type GetDuplicateAccountStatusQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetDuplicateAccountStatusQuery = { __typename?: 'Query', user: { __typename?: 'User', duplicateUserInfo?: { __typename?: 'DuplicateUserInfo', status: DuplicateUserStatus, hasDuplicates: boolean, associations: Array<{ __typename?: 'DuplicateUserAssociation', user: { __typename?: 'DuplicateUser', id: string, firstName?: string | null, lastName?: string | null, account: { __typename?: 'UnderwritingAccount', state: { __typename: 'ActiveState' } | { __typename: 'LapsedState' } | { __typename: 'RegistrationState' } | { __typename: 'SuspendedState' } } } }> } | null } };

type AccountState_ActiveState_Fragment = { __typename: 'ActiveState' };

type AccountState_LapsedState_Fragment = { __typename: 'LapsedState' };

type AccountState_RegistrationState_Fragment = { __typename: 'RegistrationState' };

type AccountState_SuspendedState_Fragment = { __typename: 'SuspendedState' };

export type AccountStateFragment = AccountState_ActiveState_Fragment | AccountState_LapsedState_Fragment | AccountState_RegistrationState_Fragment | AccountState_SuspendedState_Fragment;

export type ValidateUserDuplicateStatusMutationVariables = Exact<{
  input: DuplicateUsersValidationInput;
}>;


export type ValidateUserDuplicateStatusMutation = { __typename?: 'Mutation', validateUserDuplicateStatus: { __typename?: 'DuplicateUserInfo', status: DuplicateUserStatus, hasDuplicates: boolean, associations: Array<{ __typename?: 'DuplicateUserAssociation', user: { __typename?: 'DuplicateUser', id: string, firstName?: string | null, lastName?: string | null, account: { __typename?: 'UnderwritingAccount', state: { __typename: 'ActiveState' } | { __typename: 'LapsedState' } | { __typename: 'RegistrationState' } | { __typename: 'SuspendedState' } } } }> } };

export type GetAccessCodeForUserMutationVariables = Exact<{
  input: GetAccessCodeForUserInput;
}>;


export type GetAccessCodeForUserMutation = { __typename?: 'Mutation', getAccessCodeForUser: string };

export type GetDrivingReportQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetDrivingReportQuery = { __typename?: 'Query', getDrivingReport: Array<{ __typename: 'DrivingReportFailure', description: string, reason: string } | { __typename: 'DrivingReportSuccess', reportedAt: any, violations: Array<{ __typename?: 'DrivingViolation', avd: string, date: string, description: string, severity: DrivingViolationSeverity }> }> };

export type GetOrganisationQueryVariables = Exact<{
  organisationId: Scalars['ID']['input'];
}>;


export type GetOrganisationQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', addressLine1: string, addressLine2?: string | null, city: string, fein?: string | null, id: number, name: string, parentName?: string | null, state: string, type: string, website: string, zipCode: string } };

export type GetOrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganisationsQuery = { __typename?: 'Query', organisations: Array<{ __typename?: 'Organisation', addressLine1: string, addressLine2?: string | null, city: string, fein?: string | null, id: number, name: string, parentName?: string | null, state: string, type: string, website: string, zipCode: string }> };

export type GetPolicyCancellationReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPolicyCancellationReasonsQuery = { __typename?: 'Query', underwriting: { __typename?: 'Underwriting', cancellationReasons: Array<{ __typename?: 'CancellationExplanation', code: string, message: string }> } };

export type GetSalesPeopleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSalesPeopleQuery = { __typename?: 'Query', salesPeople: Array<{ __typename?: 'SalesPerson', email: string, firstName: string, id: number, lastName: string, level: SalesPersonLevel, personalCode?: string | null, phoneNumber: string, organisation: { __typename?: 'Organisation', name: string } }> };

export type GetSalesPersonQueryVariables = Exact<{
  salesPersonId: Scalars['ID']['input'];
}>;


export type GetSalesPersonQuery = { __typename?: 'Query', salesPerson: { __typename?: 'SalesPerson', id: number, firstName: string, lastName: string, email: string, level: SalesPersonLevel, personalCode?: string | null, phoneNumber: string, taxId?: string | null, organisation: { __typename?: 'Organisation', id: number, name: string } } };

type ActiveStateActions_NegativeBalanceAction_Fragment = { __typename: 'NegativeBalanceAction', title: string };

type ActiveStateActions_NewQuoteAvailableAction_Fragment = { __typename: 'NewQuoteAvailableAction', title: string };

type ActiveStateActions_OdometerActionV2_Fragment = { __typename: 'OdometerActionV2', title: string };

type ActiveStateActions_OdometerDueAction_Fragment = { __typename: 'OdometerDueAction', title: string };

type ActiveStateActions_PaymentAction_Fragment = { __typename: 'PaymentAction', title: string, payload: { __typename?: 'PaymentActionPayload', amount: number } };

export type ActiveStateActionsFragment = ActiveStateActions_NegativeBalanceAction_Fragment | ActiveStateActions_NewQuoteAvailableAction_Fragment | ActiveStateActions_OdometerActionV2_Fragment | ActiveStateActions_OdometerDueAction_Fragment | ActiveStateActions_PaymentAction_Fragment;

type LapsedStateFinalisation_AccountFinalisationComplete_Fragment = { __typename: 'AccountFinalisationComplete' };

type LapsedStateFinalisation_AccountFinalisationSettlementPending_Fragment = { __typename: 'AccountFinalisationSettlementPending', state: AccountFinalisationSettlementPendingState };

type LapsedStateFinalisation_AccountFinalisationTallyUpDue_Fragment = { __typename: 'AccountFinalisationTallyUpDue' };

type LapsedStateFinalisation_AccountFinalisationTallyUpRejected_Fragment = { __typename: 'AccountFinalisationTallyUpRejected' };

type LapsedStateFinalisation_AccountFinalisationTallyUpSubmitted_Fragment = { __typename: 'AccountFinalisationTallyUpSubmitted' };

export type LapsedStateFinalisationFragment = LapsedStateFinalisation_AccountFinalisationComplete_Fragment | LapsedStateFinalisation_AccountFinalisationSettlementPending_Fragment | LapsedStateFinalisation_AccountFinalisationTallyUpDue_Fragment | LapsedStateFinalisation_AccountFinalisationTallyUpRejected_Fragment | LapsedStateFinalisation_AccountFinalisationTallyUpSubmitted_Fragment;

export type GetUserAccountStateQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserAccountStateQuery = { __typename?: 'Query', user: { __typename?: 'User', underwriting: { __typename?: 'UnderwritingUser', account?: { __typename?: 'UnderwritingAccount', state: { __typename: 'ActiveState', isRestricted: boolean, actions: Array<{ __typename: 'NegativeBalanceAction', title: string } | { __typename: 'NewQuoteAvailableAction', title: string } | { __typename: 'OdometerActionV2', title: string } | { __typename: 'OdometerDueAction', title: string } | { __typename: 'PaymentAction', title: string, payload: { __typename?: 'PaymentActionPayload', amount: number } }> } | { __typename: 'LapsedState', isRestricted: boolean, finalisation: { __typename: 'AccountFinalisationComplete' } | { __typename: 'AccountFinalisationSettlementPending', state: AccountFinalisationSettlementPendingState } | { __typename: 'AccountFinalisationTallyUpDue' } | { __typename: 'AccountFinalisationTallyUpRejected' } | { __typename: 'AccountFinalisationTallyUpSubmitted' } } | { __typename: 'RegistrationState', isRestricted: boolean, upcomingPolicy?: { __typename?: 'Policy', id: string } | null } | { __typename: 'SuspendedState', isRestricted: boolean } } | null } } };

export type GetUserActionsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserActionsQuery = { __typename?: 'Query', user: { __typename?: 'User', actions: Array<{ __typename: 'NegativeBalanceAction', title: string } | { __typename: 'NewQuoteAvailableAction', title: string } | { __typename: 'OdometerActionV2', title: string } | { __typename: 'OdometerDueAction', title: string } | { __typename: 'PaymentAction', title: string, paymentPayload: { __typename?: 'PaymentActionPayload', amount: number } }> } };

export type GetUserBetaFlagsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserBetaFlagsQuery = { __typename?: 'Query', user: { __typename?: 'User', betaFlagContent: { __typename?: 'BetaFlagContent', flags?: Array<{ __typename?: 'BetaFlagEntry', title: string, value: boolean }> | null } } };

export type GetUserDiscoveryQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserDiscoveryQuery = { __typename?: 'Query', user: { __typename?: 'User', discovered?: { __typename?: 'UserDiscovered', lastUpdate?: string | null, vehicles: Array<{ __typename?: 'DiscoveredVehicle', vin: string, plate?: string | null, make?: string | null, model?: string | null, year?: number | null, insurable?: { __typename: 'InsurableVehicle', makeId: number, modelId: number, insurableMake: string, insurableModel: string, insurableYear: number } | { __typename: 'UninsurableVehicle', uninsurableMake: string, uninsurableModel: string, uninsurableYear: number } | { __typename: 'VehicleError', make?: string | null, model?: string | null, year?: number | null, error: { __typename?: 'VehicleErrorDetails', code?: string | null, message: string } } | null }> } | null } };

export type GetUserDrivingHistoryStatusQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetUserDrivingHistoryStatusQuery = { __typename?: 'Query', user: { __typename?: 'User', drivingReportStatus: UserDrivingReportStatus } };

type PaymentMethods_CreditCard_Fragment = { __typename?: 'CreditCard', cardType: string, last4: string, expirationMonth: string, expirationYear: string, token: string, error?: { __typename?: 'PaymentMethodError', description: string, type: PaymentMethodErrorType } | null };

type PaymentMethods_PayPal_Fragment = { __typename?: 'PayPal', email: string, token: string, error?: { __typename?: 'PaymentMethodError', description: string, type: PaymentMethodErrorType } | null };

type PaymentMethods_Venmo_Fragment = { __typename?: 'Venmo', username: string, token: string, error?: { __typename?: 'PaymentMethodError', description: string, type: PaymentMethodErrorType } | null };

export type PaymentMethodsFragment = PaymentMethods_CreditCard_Fragment | PaymentMethods_PayPal_Fragment | PaymentMethods_Venmo_Fragment;

export type GetUserPaymentMethodsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserPaymentMethodsQuery = { __typename?: 'Query', user: { __typename?: 'User', wallet: { __typename?: 'Wallet', automaticPayments?: { __typename?: 'AutomaticPayments', isEnabled: boolean, payment?: { __typename?: 'AutomaticPayment', token: string } | null } | null, paymentMethods: Array<{ __typename: 'CreditCard', cardType: string, last4: string, expirationMonth: string, expirationYear: string, token: string, error?: { __typename?: 'PaymentMethodError', description: string, type: PaymentMethodErrorType } | null } | { __typename: 'PayPal', email: string, token: string, error?: { __typename?: 'PaymentMethodError', description: string, type: PaymentMethodErrorType } | null } | { __typename: 'Venmo', username: string, token: string, error?: { __typename?: 'PaymentMethodError', description: string, type: PaymentMethodErrorType } | null }> } } };

export type GetUserPoliciesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserPoliciesQuery = { __typename?: 'Query', user: { __typename?: 'User', policies: Array<{ __typename?: 'Policy', term: string, cancellationDate?: string | null, policyNumberDetails: { __typename?: 'PolicyNumberDetails', value: string }, totalPremium: { __typename?: 'TotalPremium', perMile: number } }> } };

export type GetUserPoliciesPricePerMileQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserPoliciesPricePerMileQuery = { __typename?: 'Query', user: { __typename?: 'User', policies: Array<{ __typename?: 'Policy', term: string, policyNumberDetails: { __typename?: 'PolicyNumberDetails', value: string }, totalPremium: { __typename?: 'TotalPremium', perMile: number } }> } };

export type GetUserStatsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserStatsQuery = { __typename?: 'Query', user: { __typename?: 'User', stats: { __typename?: 'UserStats', totalInsuredDays: number, firstInsuredDate?: string | null, totalDistance: number } } };

export type GetUserTimezoneQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserTimezoneQuery = { __typename?: 'Query', user: { __typename?: 'User', profile?: { __typename?: 'Profile', timezone: string } | null } };

export type GetUserTransactionsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserTransactionsQuery = { __typename?: 'Query', user: { __typename?: 'User', transactions: Array<{ __typename?: 'Transaction', activityType?: ActivityType | null, amount: number, createdAt: string, type: TransactionType }> } };

export type GetUserWalletQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserWalletQuery = { __typename?: 'Query', user: { __typename?: 'User', wallet: { __typename?: 'Wallet', funds: number, automaticPayments?: { __typename?: 'AutomaticPayments', isEnabled: boolean, isApprovalEnabled: boolean, payment?: { __typename?: 'AutomaticPayment', token: string } | null } | null } } };

export type IssuePolicyEndorsementMutationVariables = Exact<{
  input: IssuePolicyEndorsementInput;
}>;


export type IssuePolicyEndorsementMutation = { __typename?: 'Mutation', issuePolicyEndorsement?: string | null };

export type RescindPolicyMutationVariables = Exact<{
  input: RescindPolicyInput;
}>;


export type RescindPolicyMutation = { __typename?: 'Mutation', rescindPolicy?: string | null };

export type RunDrivingHistoryReportMutationVariables = Exact<{
  input: RunDrivingCheckInput;
}>;


export type RunDrivingHistoryReportMutation = { __typename?: 'Mutation', runDrivingCheck: { __typename: 'DrivingReportFailure', description: string, reason: string } | { __typename: 'DrivingReportSuccess', reportedAt: any, violations: Array<{ __typename?: 'DrivingViolation', avd: string, date: string, description: string, severity: DrivingViolationSeverity }> } };

export type SearchPoliciesQueryVariables = Exact<{
  input: PoliciesSearchInput;
}>;


export type SearchPoliciesQuery = { __typename?: 'Query', policies: Array<{ __typename?: 'Policy', policyholderId: number, status: PolicyStatus, term: string, policyNumberDetails: { __typename?: 'PolicyNumberDetails', value: string }, policyholder: { __typename?: 'Driver', name: { __typename?: 'Name', firstName: string, lastName: string } }, vehicles: Array<{ __typename?: 'InsuredVehicle', year: number, make: string, model: string }> }> };

export type UpdateOrganisationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  organisationInput: OrganisationInput;
}>;


export type UpdateOrganisationMutation = { __typename?: 'Mutation', updateOrganisation?: { __typename?: 'Organisation', addressLine1: string, addressLine2?: string | null, city: string, fein?: string | null, id: number, name: string, parentName?: string | null, state: string, type: string, website: string, zipCode: string } | null };

export type UpdateSalesPersonMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  salesPersonInput: SalesPersonInput;
}>;


export type UpdateSalesPersonMutation = { __typename?: 'Mutation', updateSalesPerson?: { __typename?: 'SalesPerson', id: number, firstName: string, lastName: string, email: string, level: SalesPersonLevel, personalCode?: string | null, phoneNumber: string, taxId?: string | null, organisation: { __typename?: 'Organisation', id: number, name: string } } | null };

export const AccountStateFragmentDoc = `
    fragment AccountState on UnderwritingAccountState {
  __typename
}
    `;
export const ActiveStateActionsFragmentDoc = `
    fragment ActiveStateActions on Action {
  ... on NegativeBalanceAction {
    __typename
    title
  }
  ... on NewQuoteAvailableAction {
    __typename
    title
  }
  ... on OdometerActionV2 {
    __typename
    title
  }
  ... on OdometerDueAction {
    __typename
    title
  }
  ... on PaymentAction {
    __typename
    title
    payload {
      amount
    }
  }
}
    `;
export const LapsedStateFinalisationFragmentDoc = `
    fragment LapsedStateFinalisation on AccountFinalisation {
  ... on AccountFinalisationTallyUpDue {
    __typename
  }
  ... on AccountFinalisationTallyUpRejected {
    __typename
  }
  ... on AccountFinalisationTallyUpSubmitted {
    __typename
  }
  ... on AccountFinalisationSettlementPending {
    __typename
    state
  }
  ... on AccountFinalisationComplete {
    __typename
  }
}
    `;
export const PaymentMethodsFragmentDoc = `
    fragment PaymentMethods on PaymentMethod {
  ... on CreditCard {
    cardType
    last4
    expirationMonth
    expirationYear
    token
    error {
      description
      type
    }
  }
  ... on PayPal {
    email
    token
    error {
      description
      type
    }
  }
  ... on Venmo {
    username
    token
    error {
      description
      type
    }
  }
}
    `;
export const AddOrganisationDocument = `
    mutation addOrganisation($organisation: OrganisationInput!) {
  addOrganisation(organisationInput: $organisation) {
    id
  }
}
    `;
export const useAddOrganisationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddOrganisationMutation, TError, AddOrganisationMutationVariables, TContext>) => 
    useMutation<AddOrganisationMutation, TError, AddOrganisationMutationVariables, TContext>(
      (variables?: AddOrganisationMutationVariables) => fetchData<AddOrganisationMutation, AddOrganisationMutationVariables>(AddOrganisationDocument, variables)(),
      options
    );
export const AddSalesPersonDocument = `
    mutation addSalesPerson($salesPerson: SalesPersonInput!) {
  addSalesPerson(salesPersonInput: $salesPerson) {
    id
  }
}
    `;
export const useAddSalesPersonMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddSalesPersonMutation, TError, AddSalesPersonMutationVariables, TContext>) => 
    useMutation<AddSalesPersonMutation, TError, AddSalesPersonMutationVariables, TContext>(
      (variables?: AddSalesPersonMutationVariables) => fetchData<AddSalesPersonMutation, AddSalesPersonMutationVariables>(AddSalesPersonDocument, variables)(),
      options
    );
export const AmendActivePolicyLienholderDocument = `
    mutation amendActivePolicyLienholder($input: UpdateActivePolicyLienholderInput!) {
  amendActivePolicyLienholder(input: $input)
}
    `;
export const useAmendActivePolicyLienholderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AmendActivePolicyLienholderMutation, TError, AmendActivePolicyLienholderMutationVariables, TContext>) => 
    useMutation<AmendActivePolicyLienholderMutation, TError, AmendActivePolicyLienholderMutationVariables, TContext>(
      (variables?: AmendActivePolicyLienholderMutationVariables) => fetchData<AmendActivePolicyLienholderMutation, AmendActivePolicyLienholderMutationVariables>(AmendActivePolicyLienholderDocument, variables)(),
      options
    );
export const CancelUserPolicyDocument = `
    mutation cancelUserPolicy($input: CancelUserPolicyInput!) {
  cancelUserPolicy(input: $input)
}
    `;
export const useCancelUserPolicyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CancelUserPolicyMutation, TError, CancelUserPolicyMutationVariables, TContext>) => 
    useMutation<CancelUserPolicyMutation, TError, CancelUserPolicyMutationVariables, TContext>(
      (variables?: CancelUserPolicyMutationVariables) => fetchData<CancelUserPolicyMutation, CancelUserPolicyMutationVariables>(CancelUserPolicyDocument, variables)(),
      options
    );
export const ChargeUserDocument = `
    mutation chargeUser($input: ChargeInput!) {
  charge(input: $input) {
    ... on ChargeFailure {
      __typename
      code
      message
      providerMessage
      reason
      type
    }
    ... on ChargeSuccess {
      __typename
      amount
      id
      requester
      token
    }
  }
}
    `;
export const useChargeUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChargeUserMutation, TError, ChargeUserMutationVariables, TContext>) => 
    useMutation<ChargeUserMutation, TError, ChargeUserMutationVariables, TContext>(
      (variables?: ChargeUserMutationVariables) => fetchData<ChargeUserMutation, ChargeUserMutationVariables>(ChargeUserDocument, variables)(),
      options
    );
export const CreateEndorsementQuoteDocument = `
    mutation CreateEndorsementQuote($input: CreateEndorsementQuoteInput!) {
  createEndorsementQuote(input: $input)
}
    `;
export const useCreateEndorsementQuoteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEndorsementQuoteMutation, TError, CreateEndorsementQuoteMutationVariables, TContext>) => 
    useMutation<CreateEndorsementQuoteMutation, TError, CreateEndorsementQuoteMutationVariables, TContext>(
      (variables?: CreateEndorsementQuoteMutationVariables) => fetchData<CreateEndorsementQuoteMutation, CreateEndorsementQuoteMutationVariables>(CreateEndorsementQuoteDocument, variables)(),
      options
    );
export const CreateReferralCodesDocument = `
    mutation createReferralCodes($referralCodesInput: ReferralCodesInput!) {
  createReferralCodes(referralCodesInput: $referralCodesInput) {
    code
    referrerId
  }
}
    `;
export const useCreateReferralCodesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateReferralCodesMutation, TError, CreateReferralCodesMutationVariables, TContext>) => 
    useMutation<CreateReferralCodesMutation, TError, CreateReferralCodesMutationVariables, TContext>(
      (variables?: CreateReferralCodesMutationVariables) => fetchData<CreateReferralCodesMutation, CreateReferralCodesMutationVariables>(CreateReferralCodesDocument, variables)(),
      options
    );
export const DisableAutoPayDocument = `
    mutation disableAutoPay($input: DisableAutomaticPaymentsForUserInput!) {
  disableAutomaticPaymentsForUser(input: $input) {
    isEnabled
  }
}
    `;
export const useDisableAutoPayMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DisableAutoPayMutation, TError, DisableAutoPayMutationVariables, TContext>) => 
    useMutation<DisableAutoPayMutation, TError, DisableAutoPayMutationVariables, TContext>(
      (variables?: DisableAutoPayMutationVariables) => fetchData<DisableAutoPayMutation, DisableAutoPayMutationVariables>(DisableAutoPayDocument, variables)(),
      options
    );
export const GetDuplicateAccountStatusDocument = `
    query getDuplicateAccountStatus($userId: ID!) {
  user(id: $userId) {
    duplicateUserInfo {
      status
      hasDuplicates
      associations {
        user {
          id
          firstName
          lastName
          account {
            state {
              ...AccountState
            }
          }
        }
      }
    }
  }
}
    ${AccountStateFragmentDoc}`;
export const useGetDuplicateAccountStatusQuery = <
      TData = GetDuplicateAccountStatusQuery,
      TError = unknown
    >(
      variables: GetDuplicateAccountStatusQueryVariables, 
      options?: UseQueryOptions<GetDuplicateAccountStatusQuery, TError, TData>
    ) => 
    useQuery<GetDuplicateAccountStatusQuery, TError, TData>(
      ['getDuplicateAccountStatus', variables],
      fetchData<GetDuplicateAccountStatusQuery, GetDuplicateAccountStatusQueryVariables>(GetDuplicateAccountStatusDocument, variables),
      options
    );
export const ValidateUserDuplicateStatusDocument = `
    mutation validateUserDuplicateStatus($input: DuplicateUsersValidationInput!) {
  validateUserDuplicateStatus(input: $input) {
    status
    hasDuplicates
    associations {
      user {
        id
        firstName
        lastName
        account {
          state {
            ...AccountState
          }
        }
      }
    }
  }
}
    ${AccountStateFragmentDoc}`;
export const useValidateUserDuplicateStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ValidateUserDuplicateStatusMutation, TError, ValidateUserDuplicateStatusMutationVariables, TContext>) => 
    useMutation<ValidateUserDuplicateStatusMutation, TError, ValidateUserDuplicateStatusMutationVariables, TContext>(
      (variables?: ValidateUserDuplicateStatusMutationVariables) => fetchData<ValidateUserDuplicateStatusMutation, ValidateUserDuplicateStatusMutationVariables>(ValidateUserDuplicateStatusDocument, variables)(),
      options
    );
export const GetAccessCodeForUserDocument = `
    mutation getAccessCodeForUser($input: GetAccessCodeForUserInput!) {
  getAccessCodeForUser(input: $input)
}
    `;
export const useGetAccessCodeForUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GetAccessCodeForUserMutation, TError, GetAccessCodeForUserMutationVariables, TContext>) => 
    useMutation<GetAccessCodeForUserMutation, TError, GetAccessCodeForUserMutationVariables, TContext>(
      (variables?: GetAccessCodeForUserMutationVariables) => fetchData<GetAccessCodeForUserMutation, GetAccessCodeForUserMutationVariables>(GetAccessCodeForUserDocument, variables)(),
      options
    );
export const GetDrivingReportDocument = `
    query getDrivingReport($userId: ID!) {
  getDrivingReport(input: {userId: $userId}) {
    __typename
    ... on DrivingReportSuccess {
      reportedAt
      violations {
        avd
        date
        description
        severity
      }
    }
    ... on DrivingReportFailure {
      description
      reason
    }
  }
}
    `;
export const useGetDrivingReportQuery = <
      TData = GetDrivingReportQuery,
      TError = unknown
    >(
      variables: GetDrivingReportQueryVariables, 
      options?: UseQueryOptions<GetDrivingReportQuery, TError, TData>
    ) => 
    useQuery<GetDrivingReportQuery, TError, TData>(
      ['getDrivingReport', variables],
      fetchData<GetDrivingReportQuery, GetDrivingReportQueryVariables>(GetDrivingReportDocument, variables),
      options
    );
export const GetOrganisationDocument = `
    query getOrganisation($organisationId: ID!) {
  organisation(id: $organisationId) {
    addressLine1
    addressLine2
    city
    fein
    id
    name
    parentName
    state
    type
    website
    zipCode
  }
}
    `;
export const useGetOrganisationQuery = <
      TData = GetOrganisationQuery,
      TError = unknown
    >(
      variables: GetOrganisationQueryVariables, 
      options?: UseQueryOptions<GetOrganisationQuery, TError, TData>
    ) => 
    useQuery<GetOrganisationQuery, TError, TData>(
      ['getOrganisation', variables],
      fetchData<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, variables),
      options
    );
export const GetOrganisationsDocument = `
    query getOrganisations {
  organisations {
    addressLine1
    addressLine2
    city
    fein
    id
    name
    parentName
    state
    type
    website
    zipCode
  }
}
    `;
export const useGetOrganisationsQuery = <
      TData = GetOrganisationsQuery,
      TError = unknown
    >(
      variables?: GetOrganisationsQueryVariables, 
      options?: UseQueryOptions<GetOrganisationsQuery, TError, TData>
    ) => 
    useQuery<GetOrganisationsQuery, TError, TData>(
      ['getOrganisations', variables],
      fetchData<GetOrganisationsQuery, GetOrganisationsQueryVariables>(GetOrganisationsDocument, variables),
      options
    );
export const GetPolicyCancellationReasonsDocument = `
    query getPolicyCancellationReasons {
  underwriting {
    cancellationReasons {
      code
      message
    }
  }
}
    `;
export const useGetPolicyCancellationReasonsQuery = <
      TData = GetPolicyCancellationReasonsQuery,
      TError = unknown
    >(
      variables?: GetPolicyCancellationReasonsQueryVariables, 
      options?: UseQueryOptions<GetPolicyCancellationReasonsQuery, TError, TData>
    ) => 
    useQuery<GetPolicyCancellationReasonsQuery, TError, TData>(
      ['getPolicyCancellationReasons', variables],
      fetchData<GetPolicyCancellationReasonsQuery, GetPolicyCancellationReasonsQueryVariables>(GetPolicyCancellationReasonsDocument, variables),
      options
    );
export const GetSalesPeopleDocument = `
    query getSalesPeople {
  salesPeople {
    email
    firstName
    id
    lastName
    level
    organisation {
      name
    }
    personalCode
    phoneNumber
  }
}
    `;
export const useGetSalesPeopleQuery = <
      TData = GetSalesPeopleQuery,
      TError = unknown
    >(
      variables?: GetSalesPeopleQueryVariables, 
      options?: UseQueryOptions<GetSalesPeopleQuery, TError, TData>
    ) => 
    useQuery<GetSalesPeopleQuery, TError, TData>(
      ['getSalesPeople', variables],
      fetchData<GetSalesPeopleQuery, GetSalesPeopleQueryVariables>(GetSalesPeopleDocument, variables),
      options
    );
export const GetSalesPersonDocument = `
    query getSalesPerson($salesPersonId: ID!) {
  salesPerson(id: $salesPersonId) {
    id
    firstName
    lastName
    email
    level
    personalCode
    phoneNumber
    taxId
    organisation {
      id
      name
    }
  }
}
    `;
export const useGetSalesPersonQuery = <
      TData = GetSalesPersonQuery,
      TError = unknown
    >(
      variables: GetSalesPersonQueryVariables, 
      options?: UseQueryOptions<GetSalesPersonQuery, TError, TData>
    ) => 
    useQuery<GetSalesPersonQuery, TError, TData>(
      ['getSalesPerson', variables],
      fetchData<GetSalesPersonQuery, GetSalesPersonQueryVariables>(GetSalesPersonDocument, variables),
      options
    );
export const GetUserAccountStateDocument = `
    query getUserAccountState($userId: ID!) {
  user(id: $userId) {
    underwriting {
      account {
        state {
          __typename
          ... on BaseUnderwritingAccountState {
            isRestricted
          }
          ... on ActiveState {
            actions {
              ...ActiveStateActions
            }
          }
          ... on LapsedState {
            finalisation {
              ...LapsedStateFinalisation
            }
          }
          ... on RegistrationState {
            upcomingPolicy {
              id
            }
          }
        }
      }
    }
  }
}
    ${ActiveStateActionsFragmentDoc}
${LapsedStateFinalisationFragmentDoc}`;
export const useGetUserAccountStateQuery = <
      TData = GetUserAccountStateQuery,
      TError = unknown
    >(
      variables: GetUserAccountStateQueryVariables, 
      options?: UseQueryOptions<GetUserAccountStateQuery, TError, TData>
    ) => 
    useQuery<GetUserAccountStateQuery, TError, TData>(
      ['getUserAccountState', variables],
      fetchData<GetUserAccountStateQuery, GetUserAccountStateQueryVariables>(GetUserAccountStateDocument, variables),
      options
    );
export const GetUserActionsDocument = `
    query getUserActions($userId: ID!) {
  user(id: $userId) {
    actions {
      ... on NewQuoteAvailableAction {
        __typename
        title
      }
      ... on OdometerDueAction {
        __typename
        title
      }
      ... on OdometerActionV2 {
        __typename
        title
      }
      ... on NegativeBalanceAction {
        __typename
        title
      }
      ... on PaymentAction {
        __typename
        title
        paymentPayload: payload {
          amount
        }
      }
    }
  }
}
    `;
export const useGetUserActionsQuery = <
      TData = GetUserActionsQuery,
      TError = unknown
    >(
      variables: GetUserActionsQueryVariables, 
      options?: UseQueryOptions<GetUserActionsQuery, TError, TData>
    ) => 
    useQuery<GetUserActionsQuery, TError, TData>(
      ['getUserActions', variables],
      fetchData<GetUserActionsQuery, GetUserActionsQueryVariables>(GetUserActionsDocument, variables),
      options
    );
export const GetUserBetaFlagsDocument = `
    query getUserBetaFlags($userId: ID!) {
  user(id: $userId) {
    betaFlagContent {
      flags {
        title
        value
      }
    }
  }
}
    `;
export const useGetUserBetaFlagsQuery = <
      TData = GetUserBetaFlagsQuery,
      TError = unknown
    >(
      variables: GetUserBetaFlagsQueryVariables, 
      options?: UseQueryOptions<GetUserBetaFlagsQuery, TError, TData>
    ) => 
    useQuery<GetUserBetaFlagsQuery, TError, TData>(
      ['getUserBetaFlags', variables],
      fetchData<GetUserBetaFlagsQuery, GetUserBetaFlagsQueryVariables>(GetUserBetaFlagsDocument, variables),
      options
    );
export const GetUserDiscoveryDocument = `
    query getUserDiscovery($userId: ID!) {
  user(id: $userId) {
    discovered {
      lastUpdate
      vehicles {
        vin
        plate
        make
        model
        year
        insurable {
          __typename
          ... on InsurableVehicle {
            insurableMake: make
            makeId
            insurableModel: model
            modelId
            insurableYear: year
          }
          ... on UninsurableVehicle {
            uninsurableMake: make
            uninsurableModel: model
            uninsurableYear: year
          }
          ... on VehicleError {
            make
            model
            year
            error {
              code
              message
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetUserDiscoveryQuery = <
      TData = GetUserDiscoveryQuery,
      TError = unknown
    >(
      variables: GetUserDiscoveryQueryVariables, 
      options?: UseQueryOptions<GetUserDiscoveryQuery, TError, TData>
    ) => 
    useQuery<GetUserDiscoveryQuery, TError, TData>(
      ['getUserDiscovery', variables],
      fetchData<GetUserDiscoveryQuery, GetUserDiscoveryQueryVariables>(GetUserDiscoveryDocument, variables),
      options
    );
export const GetUserDrivingHistoryStatusDocument = `
    query getUserDrivingHistoryStatus($userId: ID) {
  user(id: $userId) {
    drivingReportStatus
  }
}
    `;
export const useGetUserDrivingHistoryStatusQuery = <
      TData = GetUserDrivingHistoryStatusQuery,
      TError = unknown
    >(
      variables?: GetUserDrivingHistoryStatusQueryVariables, 
      options?: UseQueryOptions<GetUserDrivingHistoryStatusQuery, TError, TData>
    ) => 
    useQuery<GetUserDrivingHistoryStatusQuery, TError, TData>(
      ['getUserDrivingHistoryStatus', variables],
      fetchData<GetUserDrivingHistoryStatusQuery, GetUserDrivingHistoryStatusQueryVariables>(GetUserDrivingHistoryStatusDocument, variables),
      options
    );
export const GetUserPaymentMethodsDocument = `
    query getUserPaymentMethods($userId: ID!) {
  user(id: $userId) {
    wallet {
      automaticPayments {
        isEnabled
        payment {
          token
        }
      }
      paymentMethods {
        __typename
        ...PaymentMethods
      }
    }
  }
}
    ${PaymentMethodsFragmentDoc}`;
export const useGetUserPaymentMethodsQuery = <
      TData = GetUserPaymentMethodsQuery,
      TError = unknown
    >(
      variables: GetUserPaymentMethodsQueryVariables, 
      options?: UseQueryOptions<GetUserPaymentMethodsQuery, TError, TData>
    ) => 
    useQuery<GetUserPaymentMethodsQuery, TError, TData>(
      ['getUserPaymentMethods', variables],
      fetchData<GetUserPaymentMethodsQuery, GetUserPaymentMethodsQueryVariables>(GetUserPaymentMethodsDocument, variables),
      options
    );
export const GetUserPoliciesDocument = `
    query getUserPolicies($userId: ID!) {
  user(id: $userId) {
    policies {
      policyNumberDetails {
        value
      }
      term
      cancellationDate
      totalPremium {
        perMile
      }
    }
  }
}
    `;
export const useGetUserPoliciesQuery = <
      TData = GetUserPoliciesQuery,
      TError = unknown
    >(
      variables: GetUserPoliciesQueryVariables, 
      options?: UseQueryOptions<GetUserPoliciesQuery, TError, TData>
    ) => 
    useQuery<GetUserPoliciesQuery, TError, TData>(
      ['getUserPolicies', variables],
      fetchData<GetUserPoliciesQuery, GetUserPoliciesQueryVariables>(GetUserPoliciesDocument, variables),
      options
    );
export const GetUserPoliciesPricePerMileDocument = `
    query getUserPoliciesPricePerMile($userId: ID!) {
  user(id: $userId) {
    policies {
      policyNumberDetails {
        value
      }
      term
      totalPremium {
        perMile
      }
    }
  }
}
    `;
export const useGetUserPoliciesPricePerMileQuery = <
      TData = GetUserPoliciesPricePerMileQuery,
      TError = unknown
    >(
      variables: GetUserPoliciesPricePerMileQueryVariables, 
      options?: UseQueryOptions<GetUserPoliciesPricePerMileQuery, TError, TData>
    ) => 
    useQuery<GetUserPoliciesPricePerMileQuery, TError, TData>(
      ['getUserPoliciesPricePerMile', variables],
      fetchData<GetUserPoliciesPricePerMileQuery, GetUserPoliciesPricePerMileQueryVariables>(GetUserPoliciesPricePerMileDocument, variables),
      options
    );
export const GetUserStatsDocument = `
    query getUserStats($userId: ID!) {
  user(id: $userId) {
    stats {
      totalInsuredDays
      firstInsuredDate
      totalDistance
    }
  }
}
    `;
export const useGetUserStatsQuery = <
      TData = GetUserStatsQuery,
      TError = unknown
    >(
      variables: GetUserStatsQueryVariables, 
      options?: UseQueryOptions<GetUserStatsQuery, TError, TData>
    ) => 
    useQuery<GetUserStatsQuery, TError, TData>(
      ['getUserStats', variables],
      fetchData<GetUserStatsQuery, GetUserStatsQueryVariables>(GetUserStatsDocument, variables),
      options
    );
export const GetUserTimezoneDocument = `
    query getUserTimezone($userId: ID!) {
  user(id: $userId) {
    profile {
      timezone
    }
  }
}
    `;
export const useGetUserTimezoneQuery = <
      TData = GetUserTimezoneQuery,
      TError = unknown
    >(
      variables: GetUserTimezoneQueryVariables, 
      options?: UseQueryOptions<GetUserTimezoneQuery, TError, TData>
    ) => 
    useQuery<GetUserTimezoneQuery, TError, TData>(
      ['getUserTimezone', variables],
      fetchData<GetUserTimezoneQuery, GetUserTimezoneQueryVariables>(GetUserTimezoneDocument, variables),
      options
    );
export const GetUserTransactionsDocument = `
    query getUserTransactions($userId: ID!) {
  user(id: $userId) {
    transactions {
      activityType
      amount
      createdAt
      type
    }
  }
}
    `;
export const useGetUserTransactionsQuery = <
      TData = GetUserTransactionsQuery,
      TError = unknown
    >(
      variables: GetUserTransactionsQueryVariables, 
      options?: UseQueryOptions<GetUserTransactionsQuery, TError, TData>
    ) => 
    useQuery<GetUserTransactionsQuery, TError, TData>(
      ['getUserTransactions', variables],
      fetchData<GetUserTransactionsQuery, GetUserTransactionsQueryVariables>(GetUserTransactionsDocument, variables),
      options
    );
export const GetUserWalletDocument = `
    query getUserWallet($userId: ID!) {
  user(id: $userId) {
    wallet {
      funds
      automaticPayments {
        isEnabled
        isApprovalEnabled
        payment {
          token
        }
      }
    }
  }
}
    `;
export const useGetUserWalletQuery = <
      TData = GetUserWalletQuery,
      TError = unknown
    >(
      variables: GetUserWalletQueryVariables, 
      options?: UseQueryOptions<GetUserWalletQuery, TError, TData>
    ) => 
    useQuery<GetUserWalletQuery, TError, TData>(
      ['getUserWallet', variables],
      fetchData<GetUserWalletQuery, GetUserWalletQueryVariables>(GetUserWalletDocument, variables),
      options
    );
export const IssuePolicyEndorsementDocument = `
    mutation IssuePolicyEndorsement($input: IssuePolicyEndorsementInput!) {
  issuePolicyEndorsement(input: $input)
}
    `;
export const useIssuePolicyEndorsementMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<IssuePolicyEndorsementMutation, TError, IssuePolicyEndorsementMutationVariables, TContext>) => 
    useMutation<IssuePolicyEndorsementMutation, TError, IssuePolicyEndorsementMutationVariables, TContext>(
      (variables?: IssuePolicyEndorsementMutationVariables) => fetchData<IssuePolicyEndorsementMutation, IssuePolicyEndorsementMutationVariables>(IssuePolicyEndorsementDocument, variables)(),
      options
    );
export const RescindPolicyDocument = `
    mutation rescindPolicy($input: RescindPolicyInput!) {
  rescindPolicy(input: $input)
}
    `;
export const useRescindPolicyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RescindPolicyMutation, TError, RescindPolicyMutationVariables, TContext>) => 
    useMutation<RescindPolicyMutation, TError, RescindPolicyMutationVariables, TContext>(
      (variables?: RescindPolicyMutationVariables) => fetchData<RescindPolicyMutation, RescindPolicyMutationVariables>(RescindPolicyDocument, variables)(),
      options
    );
export const RunDrivingHistoryReportDocument = `
    mutation runDrivingHistoryReport($input: RunDrivingCheckInput!) {
  runDrivingCheck(input: $input) {
    __typename
    ... on DrivingReportSuccess {
      reportedAt
      violations {
        avd
        date
        description
        severity
      }
    }
    ... on DrivingReportFailure {
      description
      reason
    }
  }
}
    `;
export const useRunDrivingHistoryReportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RunDrivingHistoryReportMutation, TError, RunDrivingHistoryReportMutationVariables, TContext>) => 
    useMutation<RunDrivingHistoryReportMutation, TError, RunDrivingHistoryReportMutationVariables, TContext>(
      (variables?: RunDrivingHistoryReportMutationVariables) => fetchData<RunDrivingHistoryReportMutation, RunDrivingHistoryReportMutationVariables>(RunDrivingHistoryReportDocument, variables)(),
      options
    );
export const SearchPoliciesDocument = `
    query searchPolicies($input: PoliciesSearchInput!) {
  policies(input: $input) {
    policyNumberDetails {
      value
    }
    policyholder {
      name {
        firstName
        lastName
      }
    }
    policyholderId
    status
    term
    vehicles {
      year
      make
      model
    }
  }
}
    `;
export const useSearchPoliciesQuery = <
      TData = SearchPoliciesQuery,
      TError = unknown
    >(
      variables: SearchPoliciesQueryVariables, 
      options?: UseQueryOptions<SearchPoliciesQuery, TError, TData>
    ) => 
    useQuery<SearchPoliciesQuery, TError, TData>(
      ['searchPolicies', variables],
      fetchData<SearchPoliciesQuery, SearchPoliciesQueryVariables>(SearchPoliciesDocument, variables),
      options
    );
export const UpdateOrganisationDocument = `
    mutation updateOrganisation($id: ID!, $organisationInput: OrganisationInput!) {
  updateOrganisation(id: $id, organisationInput: $organisationInput) {
    addressLine1
    addressLine2
    city
    fein
    id
    name
    parentName
    state
    type
    website
    zipCode
  }
}
    `;
export const useUpdateOrganisationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrganisationMutation, TError, UpdateOrganisationMutationVariables, TContext>) => 
    useMutation<UpdateOrganisationMutation, TError, UpdateOrganisationMutationVariables, TContext>(
      (variables?: UpdateOrganisationMutationVariables) => fetchData<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, variables)(),
      options
    );
export const UpdateSalesPersonDocument = `
    mutation updateSalesPerson($id: ID!, $salesPersonInput: SalesPersonInput!) {
  updateSalesPerson(id: $id, salesPersonInput: $salesPersonInput) {
    id
    firstName
    lastName
    email
    level
    personalCode
    phoneNumber
    taxId
    organisation {
      id
      name
    }
  }
}
    `;
export const useUpdateSalesPersonMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSalesPersonMutation, TError, UpdateSalesPersonMutationVariables, TContext>) => 
    useMutation<UpdateSalesPersonMutation, TError, UpdateSalesPersonMutationVariables, TContext>(
      (variables?: UpdateSalesPersonMutationVariables) => fetchData<UpdateSalesPersonMutation, UpdateSalesPersonMutationVariables>(UpdateSalesPersonDocument, variables)(),
      options
    );