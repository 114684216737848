const style = () => ({
  formControl: {
    minWidth: 120,
  },
  formControlMargin: {
    marginTop: 16,
    marginBottom: 8,
  },
});

export default style;
