import PageContent from '../../../components/PageContent';

type Props = {
  error: string;
};

const UserErrorPage: React.FC<Props> = ({ error }) => (
  <PageContent>
    <div className="text-red">{error}</div>
  </PageContent>
);

export default UserErrorPage;
