import React from 'react';
import { Attachment } from '../../../../../services/repository/communicationService';
import * as S from './AttachmentList.styled';

interface Props {
  attachments: Attachment[];
  type?: S.ListType;
  onOpenAttachment: (url: string) => void;
}

export const AttachmentList: React.FC<Props> = ({
  attachments,
  type = 'large',
  onOpenAttachment,
}) => {
  return (
    <S.AttachmentList type={type}>
      {attachments.map(({ filename, url }) => (
        <S.Attachment
          key={filename!}
          onClick={() => onOpenAttachment(url)}
          target="_blank"
          type={type}
        >
          <img className={type} src="/images/pdf.png" alt="" />
          <div>{filename}</div>
        </S.Attachment>
      ))}
    </S.AttachmentList>
  );
};
