import { fetchGraphQLQuery } from '../../../../graphql/service';
import { GetDuplicateAccountStatusResponse } from './types';
import {
  ValidateUserDuplicateStatusDocument,
  GetDuplicateAccountStatusDocument,
} from '../../../../graphql/api';
import {
  ValidateUserDuplicateStatusMutationVariables,
  ValidateUserDuplicateStatusMutation,
  GetDuplicateAccountStatusQuery,
  GetDuplicateAccountStatusQueryVariables,
} from '../../../../graphql/types';
import { mapAccountState } from './util';

class DuplicateAccountApi {
  constructor(private userId: string) {}

  getDuplicateAccountStatus =
    async (): Promise<GetDuplicateAccountStatusResponse> => {
      try {
        const response = await fetchGraphQLQuery<
          GetDuplicateAccountStatusQuery,
          GetDuplicateAccountStatusQueryVariables
        >(GetDuplicateAccountStatusDocument, {
          userId: this.userId,
        });

        return {
          success: true,
          data: {
            associatedUsers:
              response.user.duplicateUserInfo?.associations.map(userInfo => ({
                id: userInfo.user.id,
                name: `${userInfo.user.firstName} ${userInfo.user.lastName}`,
                accountState: mapAccountState(userInfo.user.account.state),
              })) ?? [],
          },
        };
      } catch (e: any) {
        return {
          success: false,
          error: e.message,
        };
      }
    };

  validateUserStatus = async (): Promise<GetDuplicateAccountStatusResponse> => {
    try {
      const response = await fetchGraphQLQuery<
        ValidateUserDuplicateStatusMutation,
        ValidateUserDuplicateStatusMutationVariables
      >(ValidateUserDuplicateStatusDocument, {
        input: {
          userId: this.userId,
        },
      });

      return {
        success: true,
        data: {
          associatedUsers:
            response.validateUserDuplicateStatus.associations.map(userInfo => ({
              id: userInfo.user.id,
              name: `${userInfo.user.firstName} ${userInfo.user.lastName}`,
              accountState: mapAccountState(userInfo.user.account.state),
            })) ?? [],
        },
      };
    } catch (e: any) {
      return {
        success: false,
        error: e.message,
      };
    }
  };
}

export default DuplicateAccountApi;
