import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Column } from '../../../../components/Table/Table';
import { Accident } from '../../../../graphql/types';
import {
  MenuItem,
  PopUpMenu,
} from '../../../../components/PopUpMenu/PopUpMenu';
import { Feature, isFeatureEnabled } from '../../../../config';
import { PopUpMenuContainer } from '../common/RowTemplate.styled';
import { menuOptions } from '../common/helpers';

interface Props {
  columns: Column[];
  item: Accident;
  onMenuClick: (menuItem: MenuItem, accident: Accident) => void;
}

const RowTemplate: React.FC<Props> = ({ item, onMenuClick }) => {
  const amountOfLossLabel = (amountOfLoss: number): string | number => {
    switch (amountOfLoss) {
      case 0:
        return 'No damage';
      case 999:
        return 'Yes, $999 and below';
      case 1000:
        return 'Yes, above $999';
      case 1001:
        return "I don't know";
      default:
        return amountOfLoss;
    }
  };

  return (
    <TableRow>
      <TableCell>{item.yearOccurred}</TableCell>
      <TableCell>{amountOfLossLabel(item.amountOfLoss)}</TableCell>
      <TableCell>{item.anyoneInjured ? 'Yes' : 'No'}</TableCell>
      <TableCell>{item.atFault ? 'Yes' : 'No'}</TableCell>
      <TableCell>
        {isFeatureEnabled(Feature.EditACV) ? (
          <PopUpMenuContainer>
            <PopUpMenu
              menuItems={menuOptions}
              onClick={menuItem => onMenuClick(menuItem, item)}
            />
          </PopUpMenuContainer>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default RowTemplate;
