import { TextField } from '@material-ui/core';
import React, { FocusEvent } from 'react';

type Props = {
  value: string;
  onChange: (v: string) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  type?: string;
  label: string;
  disabled?: boolean;
  error?: string;
};

const Input: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  label,
  type = 'text',
  disabled,
  error,
}) => {
  return (
    <TextField
      onBlur={onBlur}
      error={!!error}
      helperText={error || ' '}
      disabled={disabled}
      value={value}
      onChange={({ target: { value: newText } }) => onChange(newText)}
      label={label}
      type={type}
      variant="outlined"
    />
  );
};

export default Input;
