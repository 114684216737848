import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: absolute;
  background: ${({ theme: t }) => t.palette.primary.white};
  border-radius: 10px;
  top: 5vh;
  left: calc(50% - 300px);
  outline: none;
  overflow: auto;
  width: 600px;
  max-height: 90vh;
  padding: 24px 40px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${({ theme: t }) => t.palette.primary.bluePurple};
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Progress = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const Error = styled.h2`
  text-align: center;
  color: ${({ theme: t }) => t.palette.primary.red};
`;
export const SubtitleContainer = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
`;

export const Subtitle = styled.p<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  text-align: left;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 8px;
`;
