import React from 'react';
import TileContent from './TileContent';
import { TileShell } from '../../../../../userTiles/shared/TileShell';

interface Props {
  isRestricted: boolean;
  isLoading: boolean;
  errorMessage: string;
  onRetry: () => Promise<void>;
  onActionClick: () => void;
}

const PolicyPurchaseStatusTile: React.FC<Props> = ({
  isLoading,
  isRestricted,
  errorMessage,
  onRetry,
  onActionClick,
}) => {
  return (
    <TileShell title="Policy Eligibility">
      <TileContent
        onActionClick={onActionClick}
        errorMessage={errorMessage}
        onRetry={onRetry}
        isLoading={isLoading}
        isRestricted={isRestricted}
      />
    </TileShell>
  );
};

export default PolicyPurchaseStatusTile;
