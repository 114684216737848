import React from 'react';
import { Loader } from '../../../../../../components/Loader';
import * as S from './TileContent.styled';
import { ErrorMessage } from '../../../../../userTiles/shared/ErrorMessage';
import { withErrorBoundary } from '../../../../../../components/withErrorBoundary';

interface Props {
  onRetry: () => Promise<void>;
  onActionClick: () => void;
  isLoading: boolean;
  isRestricted: boolean;
  errorMessage: string;
}

const BlockedUserTileContent: React.FC<Props> = ({
  onRetry,
  isLoading,
  errorMessage,
  isRestricted,
  onActionClick,
}) => {
  if (isLoading) {
    return <Loader />;
  }

  if (errorMessage) {
    return <ErrorMessage message={errorMessage} onRetry={onRetry} />;
  }

  return (
    <S.Container>
      <S.Content>
        <S.ValueText isRestricted={isRestricted}>
          {isRestricted ? 'Not insurable' : 'Insurable'}
        </S.ValueText>
      </S.Content>
      <S.LinkButton onClick={onActionClick}>EDIT ELIGIBILITY</S.LinkButton>
    </S.Container>
  );
};

export default withErrorBoundary(BlockedUserTileContent);
