export enum CreditScoreStatus {
  Available = 'Available',
  InsufficientData = 'InsufficientData',
  Unavailable = 'Unavailable',
}

export type CreditReport = {
  keyFactors: string[];
  score: number;
  reportedAt: string;
  status: CreditScoreStatus;
};

export type CreditCheckResult =
  | {
      isCreditCheckAvailable: true;
      lastCreditCheck: CreditReport;
      creditReports: CreditReport[];
    }
  | {
      isCreditCheckAvailable: false;
    };

export type ApiCreditReport = {
  keyFactors: string[];
  score: number;
  reportedAt: string;
  status: CreditScoreStatus;
};

export type GetCreditReportsQuery = {
  getCreditReport: ApiCreditReport[];
};

export type RunCreditCheckMutationResult = {
  runCreditCheck: ApiCreditReport;
};
export type RunCreditCheckMutationInput = {
  input: {
    userId: string;
    note: string;
  };
};

export type GetCreditReportResult =
  | {
      success: true;
      data: CreditReport[];
    }
  | {
      success: false;
      error: string;
    };

export type RunCreditCheckResult =
  | {
      success: true;
      data: CreditReport;
    }
  | {
      success: false;
      error: string;
    };
