import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import EditPageDetailsForm from '../../EditPageDetailsForm';
import { Row } from './OrganisationDetailsForm.styled';
import FormTextInput from '../../../../components/FormTextInput/FormTextInput';
import FormSelect from '../../../../components/FormSelect/FormSelect';
import { Organisation } from '../../../../graphql/api';
import Form from '../../../../components/Form/Form';
import schema from '../../schema/organisationSchema';
import { organisationInitialValues } from '../../helpers/initialValues';
import { organisationTypeOptions } from '../../helpers/selectOptions';

interface Props {
  organisation: Organisation;
  onSubmit: (organisation: Organisation) => Promise<void>;
}

const OrganisationDetailsForm: React.FC<Props> = ({
  organisation,
  onSubmit,
}) => {
  const [isEditing, setEditing] = useState(false);

  const onFormSubmit = async (updatedOrganisation: Organisation) => {
    setEditing(false);
    await onSubmit(updatedOrganisation);
  };

  return (
    <>
      <Form
        initialValues={organisationInitialValues(organisation)}
        submit={onFormSubmit}
        validationSchema={schema}
        validateOnMount={false}
        enableReinitialize
      >
        {({ resetForm }) => (
          <>
            <Row>
              <Grid>
                <EditPageDetailsForm
                  onToggleEdit={() => setEditing(!isEditing)}
                  isEditing={isEditing}
                  onCancelEdit={() => {
                    resetForm();
                    setEditing(false);
                  }}
                  label={'organisation'}
                />
              </Grid>
            </Row>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4} md={2}>
                <FormSelect
                  name="type"
                  options={organisationTypeOptions}
                  editable={isEditing}
                  label="Type"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormTextInput
                  name="name"
                  label="Organisation Name"
                  editable={isEditing}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormTextInput
                  name="website"
                  editable={isEditing}
                  label="Website"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormTextInput
                  name="fein"
                  editable={isEditing}
                  label="FEIN"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormTextInput
                  name="parentName"
                  editable={isEditing}
                  label="Parent Company Name"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormTextInput
                  name="addressLine1"
                  editable={isEditing}
                  label="Address 1"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormTextInput
                  name="addressLine2"
                  editable={isEditing}
                  label="Address 2"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormTextInput
                  name="state"
                  editable={isEditing}
                  label="State"
                  inputProps={{ maxLength: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormTextInput
                  name="zipCode"
                  editable={isEditing}
                  label="Zipcode"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Form>
    </>
  );
};

export default OrganisationDetailsForm;
