import React from 'react';
import FormModal from '../../../../../../components/FormModal/FormModal';
import { restrictSchema, unrestrictSchema } from './validationSchema';
import {
  RestrictUserReason,
  UnrestrictUserReason,
} from '../../../../../../graphql/api';
import FormSelect from '../../../../../../components/FormSelect/FormSelect';
import { RESTRICT_REASONS, UNRESTRICT_REASONS } from './constants';
import FormTextInput from '../../../../../../components/FormTextInput/FormTextInput';
import { Description } from './PolicyPurchaseStatusModal.styled';

interface Props {
  isOpen: boolean;
  hideModal: () => void;
  isRestricted: boolean;
  isLoading: boolean;
  restrictPolicyPurchase: (payload: {
    reason: RestrictUserReason;
    notes: string;
  }) => Promise<void>;
  enablePolicyPurchase: (payload: {
    reason: UnrestrictUserReason;
    notes: string;
  }) => Promise<void>;
}

const FORM_INITIAL_VALUES = {
  notes: '',
  reason: '',
};

const DuplicateModal: React.FC<Props> = ({
  isOpen,
  hideModal,
  isRestricted,
  isLoading,
  restrictPolicyPurchase,
  enablePolicyPurchase,
}) => {
  const handleSubmit = async (values: any) => {
    if (isRestricted) {
      await enablePolicyPurchase(values);
    } else {
      await restrictPolicyPurchase(values);
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      isLoading={isLoading}
      isError={false}
      onSubmit={handleSubmit}
      onClose={hideModal}
      confirmLabel="APPROVE"
      initialValues={FORM_INITIAL_VALUES}
      validationSchema={isRestricted ? unrestrictSchema : restrictSchema}
      title={
        isRestricted ? 'Allow policy creation' : 'Restrict new policy purchase'
      }
      centerTitle
    >
      <Description>
        {isRestricted
          ? 'Please confirm you would like to allow the user to become eligible to purchase a new policy.'
          : 'Please confirm you would like to block the user from being eligible to purchase a new policy.'}
      </Description>
      <FormSelect
        name="reason"
        options={isRestricted ? UNRESTRICT_REASONS : RESTRICT_REASONS}
        editable
        label="Reason"
      />
      <FormTextInput name="notes" editable label="Notes" />
    </FormModal>
  );
};

export default DuplicateModal;
