export const quoteListColumnDefs = [
  {
    title: 'Quote Id',
    id: 'quoteId',
  },
  {
    title: 'Issue Date',
    id: 'issueDate',
  },
  {
    title: 'Policy Type',
    id: 'policyType',
  },
  {
    title: 'Selected Coverages',
    id: 'selection',
  },
  {
    title: 'Selection Price',
    id: 'selection',
  },
  {
    title: 'Accepted',
    id: 'isAccepted',
  },
  {
    title: 'Accepted At',
    id: 'acceptedAt',
  },
  {
    title: 'Status',
    id: 'status',
  },
  {
    title: '',
    id: 'rowOptions',
  },
];
