import { DateTime } from 'luxon';
import numeral from 'numeral';

export const getAgeFromDob = (dob: string): number =>
  Math.floor(Math.abs(DateTime.fromISO(dob).diffNow('years').years));

export const formatBalance = (balance: number) =>
  numeral(balance / 100).format('$0,0.00');

export const formatKmToMiles = (km: number) =>
  numeral(convertKmToMiles(km)).format('0,0');

export const convertKmToMiles = (km: number): number => km * 0.62137;

export const convertMilesToKm = (ml: number) => ml * 1.609344;

export const isEmpty = (value?: string) => {
  return value === undefined || value === '';
};
