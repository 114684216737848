import { Icon, IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import {
  HeaderContainer,
  Headline,
  PreviewContainer,
  AllDoneImage,
  Comment,
} from './ConfirmationModal.styled';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ConfirmationModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={() => onClose()}>
      <PreviewContainer>
        <HeaderContainer>
          <IconButton color="primary" onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </HeaderContainer>
        <AllDoneImage src="/images/all-done.svg" alt="" />
        <Headline>All Done</Headline>
        <Comment>
          <strong>Please allow 1 minute for the email to be delivered.</strong>
          <br />
          Once it has reached the inbox of the recipient the status will be
          updated to “Sent”.
        </Comment>
      </PreviewContainer>
    </Modal>
  );
};
