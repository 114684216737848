import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
  BackButton,
  BackText,
  Group,
  TitleButtonContainer,
  TitleContainer,
} from './TallyUpHeader.styled';

interface Props {
  title: string;
  onUserClick: () => void;
  onGoBack: () => void;
  // TODO check if we need this. doesnt seem like it.
  hideReset?: boolean;
}

const TallyUpHeader: React.FC<Props> = ({ title, onUserClick, onGoBack }) => {
  return (
    <>
      <TitleButtonContainer>
        <Group>
          <BackButton
            variant="contained"
            data-testid="header-button"
            onClick={onGoBack}
          >
            <ChevronLeftIcon />
            <BackText>Odometer photos</BackText>
          </BackButton>
          <TitleContainer title={'View profile'} onClick={onUserClick}>
            <Typography variant="h4" data-testid="header-title">
              {title}
            </Typography>
          </TitleContainer>
        </Group>
      </TitleButtonContainer>
    </>
  );
};

export default TallyUpHeader;
