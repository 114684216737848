import React, { memo, useState } from 'react';
import { Table } from '../../../components/Table';
import { Feature, isFeatureEnabled } from '../../../config';
import { Transaction } from '../../../services/repository/userService';
import { transactionBalanceListColumnDefs } from './transactionsListColumnDefs';
import SpeedDialNav from '../../../components/SpeedDialNav/SpeedDialNav';
import { SpeedDialAction } from '../../../../types/interfaces';
import { AddTransactionType } from './types';
import ManualTransaction from './ManualTransaction/ManualTransaction';
import FiftyMileCharge from './FiftyMileCharge/FiftyMileCharge';
import FiftyMileSinceOdometer from './FiftyMileCharge/FiftyMileSinceOdometer';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import CalendarToday from '@material-ui/icons/CalendarToday';
import DateRange from '@material-ui/icons/DateRange';

interface Props {
  userTransactions: Transaction[];
  onRefresh: () => void;
  visible: boolean;
  userId: string;
}

const TransactionsList: React.FC<Props> = ({
  userTransactions,
  visible,
  userId,
  onRefresh,
}) => {
  const manualTransactionEnabled = isFeatureEnabled(Feature.ManualTransaction);
  const [openModalType, setOpenModalType] = useState<
    AddTransactionType | undefined
  >();

  if (!visible) {
    return null;
  }

  const speedDialActions: SpeedDialAction[] = [
    ...(manualTransactionEnabled
      ? [
          {
            icon: <SyncAltIcon />,
            name: 'Create a financial transaction',
            onClick: () => setOpenModalType(AddTransactionType.Manual),
          },
        ]
      : []),
    {
      icon: <CalendarToday />,
      name: 'Apply 50 mile charge for 1 day',
      onClick: () => setOpenModalType(AddTransactionType.FiftyMileCharge),
    },
    {
      icon: <DateRange />,
      name: 'Apply 50 mile charge for period since last odometer photo',
      onClick: () =>
        setOpenModalType(AddTransactionType.FiftyMileSinceOdometer),
    },
  ];

  const onClose = () => setOpenModalType(undefined);

  return (
    <>
      <Table
        columns={transactionBalanceListColumnDefs}
        defaultOrder="date"
        defaultDir="desc"
        pageSize={10}
        data={userTransactions}
      />

      {openModalType === AddTransactionType.Manual ? (
        <ManualTransaction
          userId={userId}
          isOpen
          onClose={onClose}
          onSave={onRefresh}
        />
      ) : null}

      {openModalType === AddTransactionType.FiftyMileCharge ? (
        <FiftyMileCharge
          userId={userId}
          onClose={onClose}
          onSave={() => {
            onClose();
            onRefresh();
          }}
        />
      ) : null}

      {openModalType === AddTransactionType.FiftyMileSinceOdometer ? (
        <FiftyMileSinceOdometer
          userId={userId}
          onClose={onClose}
          onSave={() => {
            onClose();
            onRefresh();
          }}
        />
      ) : null}

      <SpeedDialNav actions={speedDialActions} />
    </>
  );
};

export default memo(TransactionsList);
