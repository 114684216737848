import * as yup from 'yup';
import { UpdateUserEmailReason } from '../../../../../graphql/api';

export default yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Email Address is not valid')
    .required('Email Address is required'),
  reason: yup.string().required('Please select a reason'),
  description: yup
    .string()
    .trim()
    .when('reason', {
      is: UpdateUserEmailReason.Other,
      then: yup
        .string()
        .trim()
        .required('Description is required for "Other" reason'),
      otherwise: yup.string().trim().optional(),
    }),
});
