import { Profile } from '@just-insure/api';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import React, { memo, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from '../../../../types/interfaces';
import Loading from '../../../components/Loading/Loading';
import { Feature, isFeatureEnabled } from '../../../config';
import { ErrorCode } from '../../../services/logging';
import * as userService from '../../../services/repository/userService';
import { UserGraph } from '../../../services/repository/userService';
import AcvList from '../acv/AcvList';
import { CommunicationsHistory } from '../CommunicationsHistory/CommunicationsHistory';
import OdometerList from '../OdometerList/OdometerList';
import PolicyList from '../PolicyList/PolicyList';
import QuoteList from '../QuoteList/QuoteList';
import TallyUps from '../TallyUpList/TallyUpList';
import TransactionsList from '../TransactionsList/TransactionsList';
import TripDetailsTable from '../TripDetailsList/TripDetailsList';
import TripDetailsV1List from '../TripDetailsV1List/TripDetailsV1List';
import UserHeaderForm from '../UserHeader/UserHeaderForm';
import VehicleList from '../VehicleList';
import { getTabs } from './tabs';
import { Container } from './UserDetailsV1.styled';
import { ClientError as GraphQLRequestError } from 'graphql-request';
import PaymentMethods from '../PaymentMethods';

interface Props extends RouteComponentProps {
  userId: string;
  onError: (err: Error, errorCode: ErrorCode) => void;
}

const UserDetailsV1: React.FC<Props> = ({ history, userId, onError }) => {
  const { defaultIndex, tabs } = getTabs();

  const [selectedTab, setSelectedTab] = useState(defaultIndex);
  const [user, setUser] = useState<User>();
  const [userGraph, setUserGraph] = useState<UserGraph>();
  const [editing, setEditing] = useState(false);

  const tripsV2Enabled = isFeatureEnabled(Feature.TripsV2);

  const onLoadUserDetailsFromGraph = async () => {
    try {
      const details = await userService.getUserGraphDetails(userId);
      setUserGraph(details);
    } catch (ex) {
      onError(ex as GraphQLRequestError, ErrorCode.UserDetails);
    }
  };

  const updateUserDetails = async () => {
    try {
      const details = await userService.getUserDetails(userId);
      setUser(details);
    } catch (ex: any) {
      onError(ex, ErrorCode.UserDetails);
    }
  };

  const onUpdateUserDetailsFromGraph = async () => {
    try {
      const details = await userService.getUserGraphDetails(userId);
      setUserGraph(details);
    } catch (ex) {
      onError(ex as GraphQLRequestError, ErrorCode.UserDetails);
    }
  };

  useEffect(() => {
    updateUserDetails();
    onLoadUserDetailsFromGraph();
  }, [userId]);

  const updateUserProfile = async (updatedUser: Profile) => {
    if (user) {
      // NOTE: We extend user due to it contains other non-profile fields like trips.
      setUser({
        ...user,
        ...updatedUser,
      });
    }
  };

  const isTabVisible = (label: string): boolean =>
    selectedTab === tabs.findIndex(t => t.id === label);

  const activePolicy = userGraph?.policies.find(
    policy => policy.status === 'active',
  );

  return (
    <Container>
      {!user || !userGraph ? (
        <Loading text="Loading user details, please wait..." />
      ) : null}
      {user && userGraph ? (
        <>
          <UserHeaderForm
            funds={userGraph.funds}
            balance={userGraph.balance}
            autoPay={userGraph.autoPay}
            editing={editing}
            onEditChange={canEdit => setEditing(canEdit)}
            userId={userId}
            user={user}
            onUpdateUser={setUser}
            onUpdateUserProfile={updateUserProfile}
            onRefreshUserDetails={onLoadUserDetailsFromGraph}
          />

          <AppBar position="static">
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map(t => t.content)}
            </Tabs>
          </AppBar>

          <TripDetailsV1List
            trips={user.trips || []}
            onNavigation={url => history.push(url)}
            visible={isTabVisible('trips')}
          />

          {tripsV2Enabled ? (
            <TripDetailsTable
              onNavigation={url => history.push(url)}
              visible={isTabVisible('tripsV2')}
              userId={userId}
            />
          ) : null}

          <OdometerList
            visible={isTabVisible('odometer')}
            userId={userId}
            primaryVehicle={userGraph?.policies[0]?.vehicles[0] ?? null}
          />
          <TransactionsList
            userTransactions={userGraph.transactions}
            visible={isTabVisible('transactions')}
            userId={userId}
            onRefresh={onUpdateUserDetailsFromGraph}
          />
          <PolicyList
            quoteOverviews={userGraph.quoteOverviews}
            policies={userGraph.policies}
            visible={isTabVisible('policies')}
            userId={parseInt(userId, 10)}
            onRefresh={onUpdateUserDetailsFromGraph}
          />
          <TallyUps
            onEditTallyUp={tallyup => {
              history.push(`/users/${userId}/tallyups/${tallyup.id}`);
            }}
            visible={isTabVisible('tallyUps')}
            userId={userId}
          />
          <VehicleList
            visible={isTabVisible('vehicle')}
            userId={userId}
            policies={userGraph.policies}
          />
          <QuoteList
            quoteOverviews={userGraph.quoteOverviews}
            visible={isTabVisible('quotes')}
            userId={userId}
            onRefresh={onUpdateUserDetailsFromGraph}
            activePolicy={activePolicy}
          />
          <AcvList
            acv={
              userGraph.acv || {
                accidents: [],
                claims: [],
                violations: [],
              }
            }
            visible={isTabVisible('acv')}
            userId={userId}
            onRefresh={onUpdateUserDetailsFromGraph}
          />
          {isTabVisible('paymentMethods') ? (
            <PaymentMethods userId={userId} />
          ) : null}
          {isTabVisible('communications') ? (
            <CommunicationsHistory userId={userId} />
          ) : null}
        </>
      ) : null}
    </Container>
  );
};

export default memo(withRouter(UserDetailsV1));
