import * as yup from 'yup';

export default yup.object().shape({
  image: yup.mixed().required('Image is required'),
  userReading: yup
    .number()
    .typeError('Odometer reading must be a number')
    .required('Odometer reading is required'),
  notes: yup.string().required('Note is required'),
});
