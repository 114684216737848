export const tripListColumnDefs = [
  {
    title: 'ID',
    id: 'id',
  },
  {
    title: 'Start Date',
    id: 'start',
  },
  {
    title: 'End Date',
    id: 'end',
  },
  {
    title: 'Miles',
    id: 'distance',
  },
  {
    title: 'Cost',
    id: 'cost',
  },
  {
    title: 'Duration',
    id: 'duration',
  },
];
