import React, { useEffect } from 'react';
import { useDebounce } from '../../../../hooks/useDebounce';
import FormTextInput from '../../../../components/FormTextInput/FormTextInput';
import { InputContainer, Loading } from './rowTemplate.style';

interface Props {
  name?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdate: (vin: string) => void;
  isLoading: boolean;
}

const VinField: React.FC<Props> = ({
  name = 'vin',
  value,
  onChange,
  onUpdate,
  isLoading,
}) => {
  const debouncedValue = useDebounce(value || '', 500);

  useEffect(() => {
    onUpdate(debouncedValue);
  }, [debouncedValue]);

  return (
    <InputContainer>
      <FormTextInput name={name} editable={true} onChange={onChange} />
      {isLoading && <Loading />}
    </InputContainer>
  );
};

export default VinField;
