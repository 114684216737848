import React from 'react';
import {
  Organisation,
  GetOrganisationQuery,
  useGetOrganisationQuery,
  useUpdateOrganisationMutation,
} from '../../../graphql/api';
import { withErrorBoundary } from '../../../components/withErrorBoundary';
import { RouteComponentProps } from 'react-router-dom';
import { GenericComponentError } from '../../../components/GenericComponentError';
import { Typography } from '@material-ui/core';
import { ErrorCode, logError } from '../../../services/logging';
import { Container, Loading } from './OrganisationDetails.styled';
import OrganisationDetailsForm from './OrganisationDetailsForm';
import { mapOrganisationToOrganisationInput } from '../helpers/mappers';

interface MatchParams {
  organisationId: string;
}

const OrganisationDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { organisationId } = match.params;

  const {
    isLoading,
    isError: isFetchError,
    error: fetchError,
    data,
    refetch,
  } = useGetOrganisationQuery<GetOrganisationQuery, Error>({ organisationId });

  const {
    mutate,
    isError: isUpdateError,
    error: updateError,
  } = useUpdateOrganisationMutation({
    onSuccess: () => refetch(),
    onError: (e: Error) => logError(ErrorCode.ReferralUpdateOrganisation, e),
  });

  const updateOrganisation = async (organisation: Organisation) => {
    mutate({
      id: organisationId,
      organisationInput: mapOrganisationToOrganisationInput(organisation),
    });
  };

  return (
    <>
      <Container>
        <Typography variant="h4">{`Organisation - ID ${organisationId}`}</Typography>

        {isLoading ? (
          <Loading text="Loading organisation details, please wait..." />
        ) : null}
        {isFetchError ? <GenericComponentError error={fetchError!} /> : null}
        {isUpdateError ? <GenericComponentError error={updateError!} /> : null}

        {data ? (
          <OrganisationDetailsForm
            organisation={data.organisation}
            onSubmit={updateOrganisation}
          />
        ) : null}
      </Container>
    </>
  );
};

export default withErrorBoundary(OrganisationDetails);
