import { processGeojson, processRowObject } from 'kepler.gl/processors';
import {
  AccelerationDataPoints,
  LocationDataPoints,
  TripData,
  TripLocationEvent,
} from '../../../../types/interfaces';

const mapCoords: any = (coords: LocationDataPoints[]) => ({
  features: [
    {
      geometry: {
        coordinates: [coords.map(c => [c.coords.longitude, c.coords.latitude])],
        type: 'MultiLineString',
      },
      properties: {
        'hi-precision': true,
        id: 'customer_trip_feature',
        lineColor: [130, 154, 227],
        lineWidth: 5,
      },
      type: 'Feature',
    },
  ],
  type: 'FeatureCollection',
});

export const buildTripInfoGeoLocationDataSet = (
  events: TripLocationEvent[],
) => ({
  data: processGeojson(mapCoords(events)),
  info: {
    id: 'customer_trip',
    label: 'Trip',
  },
});

export const buildTripGeoLocationDataSet = (trip: TripData) => ({
  data: processGeojson(mapCoords(trip.locationDataPoints)),
  info: {
    id: 'customer_trip',
    label: 'Trip',
  },
});

export const buildAccelerationDataSet = (points: AccelerationDataPoints[]) => ({
  data: processRowObject(
    points.map(c => ({
      gForce: c.gForce,
      x: c.x,
      y: c.y,
      z: c.z,
      latitude: c.coords.latitude,
      longitude: c.coords.longitude,
    })),
  ),
  info: {
    id: 'acceleration_metadata',
    label: 'Acceleration Metadata',
  },
});

export const buildLocationDataSet = (points: LocationDataPoints[]) => ({
  data: processRowObject(
    points.map(c => ({
      accuracy: c.coords.accuracy,
      altitude: c.coords.altitude,
      batteryCharging: c.battery.is_charging,
      batteryLevel: c.battery.level,
      heading: c.coords.heading,
      latitude: c.coords.latitude,
      longitude: c.coords.longitude,
      speed: c.coords.speed,
    })),
  ),
  info: {
    id: 'location_metadata',
    label: 'Location Metadata',
  },
});
