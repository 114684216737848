import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  table {
    tr {
      td:nth-child(5) {
        width: 40px;
      }
    }
  }
`;

export const UserContent = styled.div`
  height: 700px;
  width: 100%;
`;

export const TallyTileContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 40px 0 50px 0;
`;

export const PendingHeader = styled.div`
  color: ${({ theme }) => theme.palette.primary.white};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.86px;
  padding: 17px 20px 16px 20px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const UserLink = styled.div`
  cursor: pointer;

  &&:hover {
    text-decoration-line: underline;
  }
`;
