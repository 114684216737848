import { AssociatedUser, AccountState } from '../../Controller/types';
import * as S from './DuplciateTile.styled';
import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';

type ContentProps = {
  duplicateAccounts: AssociatedUser[];
  errorMessage: string | null;
  onRetry: () => void;
  isLoading: boolean;
  onViewMore: () => void;
};

const Content: React.FC<ContentProps> = ({
  errorMessage,
  duplicateAccounts,
  onRetry,
  isLoading,
  onViewMore,
}) => {
  if (errorMessage) {
    return (
      <S.ErrorContainer>
        <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          onClick={onRetry}
        >
          {isLoading ? <CircularProgress size={20} /> : 'Retry'}
        </Button>
      </S.ErrorContainer>
    );
  }

  const displayedDuplicateAccounts = [...duplicateAccounts].slice(0, 4);

  return (
    <>
      {displayedDuplicateAccounts.map((account, index) => (
        <RowItem
          key={index}
          value={account.id}
          accountState={account.accountState}
        />
      ))}
      <S.ViewMoreLink onClick={onViewMore}>View More</S.ViewMoreLink>
    </>
  );
};

const RowItem: React.FC<{ value: string; accountState: AccountState }> = ({
  value,
  accountState,
}) => {
  return (
    <S.RowWrapper>
      <S.RowLabel>{value}</S.RowLabel>
      <S.RowValue>{accountState}</S.RowValue>
    </S.RowWrapper>
  );
};

export default Content;
