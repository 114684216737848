import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';
import { menu } from './menu';
import { Destination, Destinations } from '../../Destinations';
/**
 * Imports for main nav - currently disabled
 * import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
 */

type Props = {
  activeDestination: Destination | undefined;
  navigateToPath: (pathName: string) => void;
  radrV1Path?: string;
};

const NavBar: React.FC<Props> = ({
  activeDestination,
  navigateToPath,
  radrV1Path,
}) => (
  <Disclosure as="nav" className="bg-blue relative z-50">
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex h-20 justify-between">
        <div className="flex">
          {/*
            * Main nav - currently disabled
            *

          <div className="-ml-2 mr-2 flex items-center md:hidden">
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block h-6 w-6 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden h-6 w-6 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
          */}
          <div className="flex flex-shrink-0 items-center">
            <img alt="Just" src="/images/logo.svg" className="h-10 w-auto" />
          </div>
          <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
            {menu.map(item => (
              <button
                type="button"
                onClick={() => navigateToPath(Destinations[item.destination])}
                key={item.destination}
                aria-current={
                  item.destination === activeDestination ? 'page' : undefined
                }
                className={twMerge(
                  'text-white px-3 py-2 text-sm font-circular font-bold',
                  item.destination === activeDestination ? 'underline' : '',
                )}
              >
                {item.title}
              </button>
            ))}
          </div>
        </div>
        {radrV1Path ? (
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <button
                onClick={() => navigateToPath(radrV1Path)}
                className="relative inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-xs text-white hover:text-grey-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-light"
              >
                <ArrowUturnLeftIcon
                  aria-hidden="true"
                  className="h-4 w-4 text-cyan"
                />
                Back to old layout
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>

    <DisclosurePanel className="md:hidden">
      <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
        {menu.map(item => (
          <DisclosureButton
            key={item.destination}
            onClick={() => navigateToPath(Destinations[item.destination])}
            aria-current={
              item.destination === activeDestination ? 'page' : undefined
            }
            className={twMerge(
              item.destination === activeDestination ? 'underline' : '',
              'block px-3 py-2 text-base font-bold text-white',
            )}
          >
            {item.title}
          </DisclosureButton>
        ))}
      </div>
    </DisclosurePanel>
  </Disclosure>
);

export default NavBar;
