import React, { useState } from 'react';
import { Table } from '../../../components/Table';
import { organisationsListColumnDefs } from './organisationsListColumnDefs';
import {
  Organisation,
  useGetOrganisationsQuery,
  GetOrganisationsQuery,
} from '../../../graphql/api';
import { withErrorBoundary } from '../../../components/withErrorBoundary';
import { Loading } from './OrganisationsList.styled';
import { RouteComponentProps } from 'react-router-dom';
import { GenericComponentError } from '../../../components/GenericComponentError';
import { filterList } from '../../../helpers/filterHelpers';

const OrganisationsList: React.FC<RouteComponentProps> = ({ history }) => {
  const [filter, setFilter] = useState<string>('');

  const { isLoading, isError, error, data } = useGetOrganisationsQuery<
    GetOrganisationsQuery,
    Error
  >();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <GenericComponentError error={error!} />;
  }

  const filteredData = data
    ? filterList(data.organisations, ['id', 'name'], filter)
    : [];

  const navigateToOrganisation = (organisation: Organisation) => {
    history.push(`/referrals/organisation/${organisation.id}`);
  };

  return (
    <Table
      data={filteredData}
      total={filteredData.length}
      columns={organisationsListColumnDefs}
      disablePagination={true}
      useAutoFocus
      searchLabel="Search by ID or name"
      searchOnClient
      onFilterData={setFilter}
      onRowClick={navigateToOrganisation}
    />
  );
};

export default withErrorBoundary(OrganisationsList);
