import { TransactionRequest, TransactionType } from '@just-insure/api';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import Form from '../../../../../components/Form/Form';
import FormSelect from '../../../../../components/FormSelect/FormSelect';
import FormTextInput from '../../../../../components/FormTextInput/FormTextInput';
import { mapActivityTypes } from '../../../../../services/repository/odometerService';
import schema from './schema';
import * as S from './TransacationModal.styled';

interface Props {
  isOpen: boolean;
  onSaveTransaction: (transaction: TransactionRequest) => Promise<void>;
  onClose: () => void;
}

const TransacationModal: React.FC<Props> = ({
  isOpen,
  onSaveTransaction,
  onClose,
}) => {
  const newTransaction = {
    reason: '',
    type: undefined,
    activityType: undefined,
    amount: '',
  };

  return (
    <Modal open={isOpen} onClose={() => onClose()}>
      <S.SaveModal>
        <Form
          initialValues={newTransaction}
          validateOnMount={false}
          validationSchema={schema}
          submit={onSaveTransaction}
        >
          {({ resetForm, handleSubmit }) => (
            <>
              <S.Headline>Create a Transaction</S.Headline>
              <S.Comment>
                You can create a new transaction for a user based on an activity
                type selectable below.
              </S.Comment>
              <div>
                <FormSelect
                  name="type"
                  options={Object.keys(TransactionType).map(v => ({
                    label: v,
                    value: v,
                  }))}
                  editable={true}
                  label="Transaction Type"
                  placeholder="Select a Transaction Type"
                />
              </div>
              <div>
                <FormSelect
                  name="activityType"
                  options={mapActivityTypes()}
                  label="Activity Type"
                  editable={true}
                  placeholder="Select an Activity Type"
                />
              </div>
              <div>
                <FormTextInput
                  name="amount"
                  editable={true}
                  label="Amount (in $)"
                  placeholder="Enter an amount"
                />
              </div>
              <div>
                <FormTextInput
                  name="reason"
                  editable={true}
                  label="Reason"
                  placeholder="Provide a reason"
                />
              </div>
              <S.Row>
                <S.CancelButton
                  variant="contained"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  ABORT
                </S.CancelButton>
                <S.StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  CREATE
                </S.StyledButton>
              </S.Row>
            </>
          )}
        </Form>
      </S.SaveModal>
    </Modal>
  );
};

export default TransacationModal;
