import React from 'react';
import styled from 'styled-components';
import { H3 } from '../../../components/Typography';
import { Tile } from './Tile';

const TileTitle = styled(H3)`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.m};
  color: ${({ theme }) => theme.palette.primary.veryDarkGrey};
`;

export interface Props {
  className?: string;
  title: string;
}

export const TileShell: React.FC<Props> = ({
  className,
  children,
  title: tileTitle,
}) => (
  <Tile className={className}>
    <TileTitle>{tileTitle}</TileTitle>
    {children}
  </Tile>
);
