import React from 'react';
import FormModal from '../../../../components/FormModal/FormModal';
import schema from './schema';
import FormSelect from '../../../../components/FormSelect/FormSelect';
import { DateTime } from 'luxon';
import { createRange } from '../../../../helpers/arrayHelpers';
import { Accident } from '../../../../graphql/api';
import { AccidentForm, BooleanAsString } from '../common/types';

interface Props {
  activeAccident?: Accident;
  isOpen: boolean;
  isLoading: boolean;
  onSave: (accident: Accident) => void;
  onClose: () => void;
  error?: Error;
}

const AccidentModal: React.FC<Props> = ({
  activeAccident,
  isOpen,
  isLoading,
  onSave,
  onClose,
  error,
}) => {
  const onSubmit = async (accidentForm: AccidentForm) => {
    const accident: Accident = {
      amountOfLoss: accidentForm.amountOfLoss,
      yearOccurred: accidentForm.yearOccurred,
      anyoneInjured: accidentForm.anyoneInjured === BooleanAsString.True,
      atFault: accidentForm.atFault === BooleanAsString.True,
    };

    onSave(accident);
  };

  const initialValues = activeAccident
    ? {
        amountOfLoss: activeAccident.amountOfLoss,
        yearOccurred: activeAccident.yearOccurred,
        anyoneInjured: activeAccident.anyoneInjured
          ? BooleanAsString.True
          : BooleanAsString.False,
        atFault: activeAccident.atFault
          ? BooleanAsString.True
          : BooleanAsString.False,
      }
    : {
        amountOfLoss: '',
        yearOccurred: '',
        anyoneInjured: '',
        atFault: '',
      };

  const thisYear = DateTime.local().year;
  const yearRange = createRange(thisYear - 4, thisYear).reverse();

  return (
    <FormModal
      isOpen={isOpen}
      isLoading={isLoading}
      isError={!!error}
      error={error}
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={schema}
      title="Add an accident"
    >
      <FormSelect
        name="yearOccurred"
        options={yearRange.map(year => ({
          label: year.toString(),
          value: year,
        }))}
        editable
        label="Year occurred"
      />
      <FormSelect
        name="amountOfLoss"
        options={[
          {
            label: 'No damage',
            value: 0,
          },
          {
            label: 'Yes, $999 and below',
            value: 999,
          },
          {
            label: 'Yes, above $999',
            value: 1000,
          },
          {
            label: "I don't know",
            value: 1001,
          },
        ]}
        editable
        label="Amount of loss"
      />
      <FormSelect
        name="anyoneInjured"
        options={[
          { label: 'No', value: BooleanAsString.False },
          { label: 'Yes', value: BooleanAsString.True },
        ]}
        editable
        label="Anyone injured"
      />
      <FormSelect
        name="atFault"
        options={[
          { label: 'No', value: BooleanAsString.False },
          { label: 'Yes', value: BooleanAsString.True },
        ]}
        editable
        label="At Fault"
      />
    </FormModal>
  );
};

export default AccidentModal;
