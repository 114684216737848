import styled from 'styled-components';

export const ErrorModal = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 30%;
  left: calc(50% - 200px);
  padding: 30px;
  width: 500px;

  h2 {
    color: ${({ theme }) => theme.palette.primary.bluePurple};
  }

  p {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .row {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    display: flex;
    padding-top: 10px;
  }

  .MuiButton-contained {
    min-width: 80px;
  }
`;
