import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  TallyUpOdometer,
  getUserOdometer,
  uploadOdometer,
  sortOdometerToByNewestToOldest,
  OdometerUploadPayload,
} from '../../../../services/repository/odometerService';
import { logError, ErrorCode } from '../../../../services/logging';
import { AxiosError } from 'axios';
import useIdempotencyKey from '../../../../hooks/useIdempotencyKey';

const getUserOdometersKey = (userId: string) => ['user-odometers', { userId }];

export const useGetOdometers = (userId: string) => {
  return useQuery<TallyUpOdometer[], Error | AxiosError>(
    getUserOdometersKey(userId),
    () => getUserOdometer(userId),
    {
      select: odometers => [...odometers].sort(sortOdometerToByNewestToOldest),
      onError: e => logError(ErrorCode.UserOdometer, e),
      useErrorBoundary: false,
    },
  );
};

export const useUploadOdometer = (userId: string, onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const { idempotencyKey, resetIdempotencyKey } = useIdempotencyKey();
  return useMutation<void, Error | AxiosError, OdometerUploadPayload>(
    (payload: OdometerUploadPayload) =>
      uploadOdometer(userId, payload, idempotencyKey),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getUserOdometersKey(userId));
        onSuccess();
      },
      onError: e => logError(ErrorCode.UserOdometer, e),
      onSettled: () => resetIdempotencyKey(),
      useErrorBoundary: false,
    },
  );
};
