import React from 'react';
import { Dialog, CircularProgress } from '@material-ui/core';
import { LoadingContainer } from './TakePaymentModal.styled';
import { useGetUserWalletQuery } from '../../../../../graphql/api';
import ModalContent from './components/ModalContent';
import ModalError from '../../../../../components/ModalError/ModalError';
import { useGetUserWalletSelector } from '../common/useGetUserWalletSelector';
import { UserWallet } from '../common/types';

interface Props {
  userId: string;
  onClose: () => void;
}

const TakePaymentModal: React.FC<Props> = ({ userId, onClose }) => {
  const { isLoading, data, error } = useGetUserWalletQuery<UserWallet, Error>(
    { userId },
    {
      select: useGetUserWalletSelector,
      useErrorBoundary: false,
    },
  );

  return (
    <Dialog open onClose={onClose} scroll="body" fullWidth maxWidth="xs">
      {(() => {
        if (isLoading) {
          return (
            <LoadingContainer>
              <CircularProgress size={20} />
            </LoadingContainer>
          );
        }

        if (error) {
          return <ModalError error={error.message} />;
        }

        if (data) {
          return <ModalContent userId={userId} data={data} onClose={onClose} />;
        }
      })()}
    </Dialog>
  );
};

export default TakePaymentModal;
