import React from 'react';
import { TileShell } from '../shared/TileShell';
import UserStatsContainer from './UserStatsContainer';
import { Props } from '../types';

export const Stats: React.FC<Props> = ({ userId, className }) => (
  <TileShell title="Stats" className={className}>
    <UserStatsContainer userId={userId} />
  </TileShell>
);
