export const tallyUpListColumnDefs = [
  {
    title: 'Date',
    id: 'endOdometer.createdAt',
  },
  {
    title: 'Odometer Readings',
    id: 'id',
  },
  {
    title: 'Miles tracked based on odometer',
    id: 'odometerDistance',
  },
  {
    title: 'Miles tracked based on app',
    id: 'distanceTracked',
  },
  {
    title: 'Missing miles',
    id: 'id',
  },
  {
    title: 'Price per mile',
    id: 'pricePerMile',
  },
  {
    title: 'Charged',
    id: 'charge',
  },
  {
    title: 'Auto accepted',
    id: 'statusAuthority',
    sortable: false,
  },
  {
    title: 'Note',
    id: 'note',
  },
  {
    title: '',
    id: 'spacer',
  },
];
