import React, { useState } from 'react';
import { Container } from './ReferralList.styled';
import TabsList from '../../../components/TabsList/TabsList';
import { TabItem, SpeedDialAction } from '../../../../types/interfaces';
import SalesPeopleList from '../SalesPeopleList/SalesPeopleList';
import OrganisationsList from '../OrganisationsList/OrganisationsList';
import { RouteComponentProps } from 'react-router-dom';
import SpeedDialNav from '../../../components/SpeedDialNav/SpeedDialNav';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import AddSalesPersonModal from '../AddSalesPersonModal/AddSalesPersonModal';
import AddOrganisationModal from '../AddOrganisationModal/AddOrganisationModal';
import { useQueryClient } from 'react-query';

const ReferralList = (props: RouteComponentProps) => {
  const [isSalesPersonModalOpen, setSalesPersonModalOpen] = useState(false);
  const [isOrganisationModalOpen, setOrganisationModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const speedDialActions: SpeedDialAction[] = [
    {
      icon: <BusinessIcon />,
      name: 'Add a new organisation',
      onClick: () => setOrganisationModalOpen(true),
    },
    {
      icon: <PersonIcon />,
      name: 'Add a new salesperson',
      onClick: () => setSalesPersonModalOpen(true),
    },
  ];

  const tabs: TabItem[] = [
    {
      label: 'Salespeople',
      component: <SalesPeopleList {...props} />,
    },
    {
      label: 'Organisations',
      component: <OrganisationsList {...props} />,
    },
  ];

  return (
    <Container>
      <TabsList tabs={tabs} />
      <SpeedDialNav actions={speedDialActions} />
      <AddSalesPersonModal
        isOpen={isSalesPersonModalOpen}
        onClose={() => setSalesPersonModalOpen(false)}
        onSave={() => {
          queryClient.invalidateQueries('getSalesPeople');
          setSalesPersonModalOpen(false);
        }}
        onClick={() => setOrganisationModalOpen(true)}
      />
      <AddOrganisationModal
        isOpen={isOrganisationModalOpen}
        onClose={() => setOrganisationModalOpen(false)}
        onSave={() => {
          queryClient.invalidateQueries('getOrganisations');
          setOrganisationModalOpen(false);
        }}
      />
    </Container>
  );
};

export default ReferralList;
