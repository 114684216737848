import React from 'react';
import { ActionButton } from '../ActionButton/ActionButton.styled';

interface Props {
  onClick: () => void;
}

const RunCreditCheckButton: React.FC<Props> = ({ onClick }) => {
  return (
    <ActionButton variant="contained" color="primary" onClick={onClick}>
      RUN CREDIT CHECK
    </ActionButton>
  );
};
export default RunCreditCheckButton;
