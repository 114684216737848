import {
  RestrictUserReason,
  UnrestrictUserReason,
} from '../../../../../../graphql/api';

export const RESTRICT_REASONS = [
  {
    label: 'Other',
    value: RestrictUserReason.Other,
  },
  {
    label: 'Multiple Disputed Payments',
    value: RestrictUserReason.MultipleDisputedPayments,
  },
  {
    label: 'Negative Balances',
    value: RestrictUserReason.NegativeBalances,
  },
  {
    label: 'Duplicate User',
    value: RestrictUserReason.DuplicateUser,
  },
];

export const UNRESTRICT_REASONS = [
  {
    label: 'Other',
    value: UnrestrictUserReason.Other,
  },
  {
    label: 'Duplicate User Override',
    value: UnrestrictUserReason.DuplicateUserOverride,
  },
];
