import React, { useState } from 'react';
import { TransactionRequest } from '@just-insure/api';
import { ErrorCode, logError } from '../../../../services/logging';
import * as transactionService from '../../../../services/repository/transactionService';
import TransacationModal from './TransacationModal/TransacationModal';
import ErrorModal from '../../../../components/ErrorModal/ErrorModal';
import { AxiosError } from 'axios';

interface Props {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const ManualTransaction: React.FC<Props> = ({
  userId,
  isOpen,
  onClose,
  onSave,
}) => {
  const [error, setError] = useState<AxiosError | undefined>();

  const onSaveTransaction = async (
    transactionInDollars: TransactionRequest,
  ) => {
    try {
      onClose();

      const transactionInCents: TransactionRequest = {
        ...transactionInDollars,
        amount: transactionInDollars.amount * 100,
      };

      await transactionService.createTransaction(userId, transactionInCents);
      onSave();
    } catch (ex: any) {
      logError(ErrorCode.UserTransactions, ex);
      setError(ex);
    }
  };

  return (
    <>
      <TransacationModal
        isOpen={isOpen}
        onSaveTransaction={onSaveTransaction}
        onClose={onClose}
      />
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </>
  );
};

export default ManualTransaction;
