import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import FormModal from '../../../components/FormModal/FormModal';
import { StyledContextText } from './AddSalesPersonModal.styled';
import { ReferralModal } from '../../../../types/interfaces';
import schema from '../schema/salespersonSchema';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import FormSelect from '../../../components/FormSelect/FormSelect';
import OrganisationsSelect from './OrganisationsSelect';
import {
  useAddSalesPersonMutation,
  SalesPersonInput,
  useCreateReferralCodesMutation,
  ReferralCodesInput,
  ReferralType,
} from '../../../graphql/api';
import { salesPersonLevelOptions } from '../helpers/selectOptions';
import { ErrorCode, logError } from '../../../services/logging';

interface Props extends ReferralModal {
  onClick: () => void;
}

const AddSalesPersonModal: React.FC<Props> = ({
  isOpen,
  onSave,
  onClose,
  onClick,
}) => {
  const [error, setError] = useState<Error | null>(null);
  const addSalesPersonMutation = useAddSalesPersonMutation();
  const createReferralCodesMutation = useCreateReferralCodesMutation();

  const onSubmit = async (salesPerson: SalesPersonInput) => {
    try {
      const data = await addSalesPersonMutation.mutateAsync({ salesPerson });
      if (!data.addSalesPerson) {
        throw new Error('There was a problem creating the referral code');
      }
      const referralCodesInput: ReferralCodesInput = {
        referrerIds: [data.addSalesPerson.id],
        type: ReferralType.SalesPerson,
      };
      await createReferralCodesMutation.mutateAsync({ referralCodesInput });
      onSave();
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
        logError(ErrorCode.ReferralCreateSalesperson, e);
      }
    }
  };

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    level: '',
    phoneNumber: '',
    taxId: '',
    organisationId: '',
  };

  return (
    <FormModal
      isOpen={isOpen}
      isLoading={
        addSalesPersonMutation.isLoading ||
        createReferralCodesMutation.isLoading
      }
      isError={!!error}
      error={error}
      onSubmit={onSubmit}
      onClose={() => {
        addSalesPersonMutation.reset();
        createReferralCodesMutation.reset();
        onClose();
      }}
      initialValues={initialValues}
      validationSchema={schema}
      title="Add a new salesperson"
    >
      <FormTextInput name="firstName" editable label="First Name" />
      <FormTextInput name="lastName" editable label="Last Name" />
      <FormTextInput name="phoneNumber" editable label="Phone Number" />
      <FormTextInput name="email" editable label="Email Address" />
      <FormSelect
        name="level"
        options={salesPersonLevelOptions}
        editable
        label="Level"
      />
      <FormTextInput
        name="taxId"
        editable
        label="Salesperson Tax ID (optional)"
      />
      <OrganisationsSelect name="organisationId" label="Organisation name" />

      <StyledContextText>
        Can’t find the organisation?{' '}
        <Link
          color="primary"
          component="button"
          variant="body1"
          onClick={onClick}
        >
          Add a new one here.
        </Link>
      </StyledContextText>
    </FormModal>
  );
};

export default AddSalesPersonModal;
