import { useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react';
import {
  XMarkIcon,
  ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/24/outline';

const Sidebar: React.FC = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      {/* Sidebar for mobile */}
      <Dialog
        open={sidebarOpen}
        onClose={setSidebarOpen}
        className="relative z-40 lg:hidden"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-grey/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute left-full top-20 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button
                  type="button"
                  onClick={() => setSidebarOpen(false)}
                  className="-m-2.5 p-2.5"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-white"
                  />
                </button>
              </div>
            </TransitionChild>
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-grey-light px-6 pb-4 pt-20">
              <div className="py-6">{children}</div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-45 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-grey-light px-6 pb-4 border-r border-grey pt-20">
          <div className="py-6">{children}</div>
        </div>
      </div>
      <button
        type="button"
        onClick={() => setSidebarOpen(true)}
        className="text-grey lg:hidden p-2.5 absolute top-25 left-2 sm:left-4"
      >
        <span className="sr-only">Open sidebar</span>
        <ArrowRightStartOnRectangleIcon
          aria-hidden="true"
          className="h-6 w-6"
        />
      </button>
    </>
  );
};

export const SidebarContent: React.FC = ({ children }) => (
  <div className="lg:pl-45">{children}</div>
);

export default Sidebar;
