import { UserTransaction } from '../../../../types/interfaces';
import { formatBalance } from '../../../helpers/formatHelpers';

export const transactionBalanceListColumnDefs = [
  {
    title: 'Date',
    id: 'createdAt',
    rowFormatter: (transaction: UserTransaction) =>
      transaction.createdAt.toFormat('FF'),
  },
  {
    title: 'Activity Type',
    id: 'activityType',
  },
  {
    title: 'Amount',
    id: 'amount',
    rowFormatter: (transaction: UserTransaction) =>
      `${formatBalance(transaction.amount)}`,
  },
  {
    title: 'Type',
    id: 'type',
  },
  {
    title: 'Balance',
    id: 'balance',
    rowFormatter: (transaction: UserTransaction) =>
      `${formatBalance(transaction.balance)}`,
  },
  {
    title: 'Reason',
    id: 'reason',
    rowFormatter: (transaction: UserTransaction) =>
      `${transaction.reason ? transaction.reason : '-'}`,
  },
];
