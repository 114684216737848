import { DateTime } from 'luxon';
import React from 'react';
import { LastLookup, Title, StyledButton } from './DiscoveredVehicles.style';
import VehiclesTable, { Vehicle } from './VehiclesTable';

interface Props {
  discoveredVehicles: DiscoveredVehiclesResult;
  closeModal: () => void;
}

export interface DiscoveredVehiclesResult {
  lastUpdate?: DateTime;
  insurableVehicles: Vehicle[];
  uninsurableVehicles: Vehicle[];
}

const DiscoverySuccess: React.FC<Props> = ({
  discoveredVehicles,
  closeModal,
}) => {
  return (
    <>
      <Title>Discovered Vehicles</Title>
      <LastLookup>
        {discoveredVehicles.lastUpdate
          ? `Last lookup run date: ${discoveredVehicles.lastUpdate.toLocal()}`
          : 'Lookup was never made by this user.'}
      </LastLookup>
      <VehiclesTable
        header="Insurable Vehicles"
        vehicles={discoveredVehicles.insurableVehicles}
      />
      <VehiclesTable
        header="Non-insurable Vehicles"
        vehicles={discoveredVehicles.uninsurableVehicles}
      />
      <StyledButton onClick={closeModal}>Close</StyledButton>
    </>
  );
};

export default DiscoverySuccess;
