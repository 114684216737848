import Typography from '@material-ui/core/Typography';
import React, { memo } from 'react';
import { Paginated, User } from '../../../../../types/interfaces';
import { UserSearchInput } from './types';
import { useQuery } from 'react-query';
import { QueryOptions } from '../../../../../types/interfaces';
import * as userService from '../../../../services/repository/userService';
import { logError, ErrorCode } from '../../../../services/logging';
import { Table } from '../../../../components/Table';
import { userListColumnDefs } from './userListColumnDefs';

type Props = {
  searchTerm: string;
  navigateToUserPage: (userId: string) => void;
  setSearchLoading: (isLoading: boolean) => void;
};

const UserList: React.FC<Props> = ({
  searchTerm,
  navigateToUserPage,
  setSearchLoading,
}) => {
  const [input, setInput] = React.useState<UserSearchInput | undefined>();

  const {
    data: users,
    error,
    isLoading,
  } = useQuery<Paginated<User>, Error>(
    ['search-users', input],
    () => userService.getUserList(input!.options, input!.search),
    {
      useErrorBoundary: false,
      onError: (e: Error) => logError(ErrorCode.SearchUsers, e),
      enabled: !!input && input.search.length > 0,
    },
  );

  const onFetchData = (options: QueryOptions, search: string) =>
    setInput({ options, search });

  const onRowClick = (user: User) => {
    navigateToUserPage(user.id);
  };

  React.useEffect(() => {
    setSearchLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <Typography variant="h4">User List</Typography>
      <Table
        data={users?.items.result || []}
        total={users?.items.total || 0}
        columns={userListColumnDefs}
        onFetchData={onFetchData}
        onRowClick={onRowClick}
        searchTerm={searchTerm}
        error={error?.message}
        isLoading={isLoading}
      />
    </>
  );
};

export default memo(UserList);
