import { CircularProgress, TextField } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTable = styled.div`
  && {
    .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
      padding: 0 40px;
    }
  }
`;

export const SearchTextField = styled(TextField)`
  && {
    min-width: 300px;
  }
`;

export const SearchRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Loading = styled(CircularProgress)`
  margin-left: 12px;
`;
