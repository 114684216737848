import {
  CardContainer,
  ErrorContainer,
  HeaderTitle,
  LoadingWrapper,
} from './ObdTile.styled';
import React from 'react';
import { useObdContext } from '../Controller';
import { Button, CircularProgress } from '@material-ui/core';
import { ObdDevice, ObdError, ObdErrorType } from '../Controller/types';
import DeviceStatus from './DeviceStatus';
import { useFeatureFlags } from '../../featureFlags';

const ObdTile = () => {
  const { featureFlags } = useFeatureFlags();
  const { getDeviceDetails, isProcessing, device, error } = useObdContext();
  const isObdEnabled = !!featureFlags?.isObd;

  React.useEffect(() => {
    if (isObdEnabled) {
      getDeviceDetails();
    }
  }, [isObdEnabled]);

  if (!featureFlags) {
    return null;
  }

  if (isProcessing) {
    return (
      <LoadingWrapper>
        <CircularProgress size={20} />
      </LoadingWrapper>
    );
  }

  if (error?.type === ObdErrorType.NO_FIRST_POlICY) {
    return null;
  }

  return (
    <CardContainer>
      <HeaderTitle>Miles tracker</HeaderTitle>
      <Content
        isEnabled={isObdEnabled}
        onRetry={getDeviceDetails}
        error={error}
        device={device}
      />
    </CardContainer>
  );
};

interface Props {
  isEnabled: boolean;
  device: ObdDevice | null;
  error: ObdError | null;
  onRetry: () => void;
}

const Content: React.FC<Props> = ({ onRetry, device, error, isEnabled }) => {
  if (error) {
    return (
      <ErrorContainer>
        <p>{error.message}</p>
        {!error.isPermanent ? (
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={onRetry}
          >
            Retry
          </Button>
        ) : null}
      </ErrorContainer>
    );
  }

  return <DeviceStatus isEnabled={isEnabled} device={device} />;
};

export default ObdTile;
