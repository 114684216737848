import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  color?: string;
  className?: string;
};

const Loading: React.FC<Props> = ({ color = '#ffffff', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className={twMerge('block h-6 w-6 animate-spin', className)}
    >
      <circle
        cx="50"
        cy="50"
        r="32"
        strokeWidth="8"
        stroke={color}
        strokeDasharray="50.26548245743669 50.26548245743669"
        fill="none"
        strokeLinecap="round"
        transform="matrix(1,0,0,1,0,0)"
        style={{
          transform: 'matrix(1, 0, 0, 1, 0, 0)',
        }}
      />
    </svg>
  );
};

export default Loading;
