import { Button, TableCell, TableRow } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import React from 'react';
import { Column } from '../../../../components/Table/Table';
import { Feature, isFeatureEnabled } from '../../../../config';
import { TallyUpStatus } from '@just-insure/api';
import {
  formatBalance,
  formatKmToMiles,
} from '../../../../helpers/formatHelpers';
import {
  TallyUpOdometer,
  TallyUp,
} from '../../../../services/repository/odometerService';
import TallyUpOdometerComponent from '../../TallyUpOdometer/TallyUpOdometer';
import { Label, Row } from './TallyUpReading.styled';
import TallyUpCharge from './TallyUpCharge';

interface Props {
  columns: Column[];
  item: TallyUp;
  rowIndex: number;
  onImageClick: (reading: TallyUpOdometer) => void;
  onEdit?: () => void;
}

const TallyUpRowTemplate: React.FC<Props> = ({
  item,
  onImageClick,
  onEdit,
}) => {
  const editFeatureEnabled = isFeatureEnabled(Feature.EditTallyUps);
  const isCreditCharge = (item.distanceUntracked ?? 0) < 0;

  return (
    <TableRow>
      <TableCell>
        <Label>{`${item.startOdometer.createdAt.toFormat(
          'dd MMM yy',
        )} - ${item.endOdometer.createdAt.toFormat('dd MMM yy')}`}</Label>
      </TableCell>
      <TableCell>
        <Row>
          <TallyUpOdometerComponent
            odometer={item.startOdometer}
            onImageClick={onImageClick}
          />
          {!item.isInitial ? (
            <TallyUpOdometerComponent
              isTallyUpVoid={item.isChargeVoided}
              odometer={item.endOdometer}
              onImageClick={onImageClick}
            />
          ) : null}
        </Row>
      </TableCell>
      <TableCell>
        {!item.isInitial && item.status === TallyUpStatus.Verified
          ? formatKmToMiles(item.odometerDistance)
          : ''}
      </TableCell>
      <TableCell>
        {!item.isInitial && item.status === TallyUpStatus.Verified
          ? formatKmToMiles(item.distanceTracked)
          : ''}
      </TableCell>
      <TableCell>
        {!item.isInitial && item.status === TallyUpStatus.Verified
          ? formatKmToMiles(item.distanceTracked - item.odometerDistance)
          : ''}
      </TableCell>
      <TableCell>{formatBalance(item.pricePerMile)}</TableCell>
      <TableCell>
        {!item.isInitial && item.status === TallyUpStatus.Verified ? (
          <TallyUpCharge
            isVoided={item.isChargeVoided}
            charge={formatBalance(item.charge)}
            isCredit={isCreditCharge}
          />
        ) : (
          ''
        )}
      </TableCell>
      <TableCell>
        {item.statusAuthority === 'ocr' ? <DoneIcon htmlColor="green" /> : null}
      </TableCell>
      <TableCell>
        <Label>{item.serviceVersion}</Label>
      </TableCell>
      <TableCell>
        {editFeatureEnabled && onEdit ? (
          <Button variant="outlined" color="primary" onClick={onEdit}>
            {item.status === TallyUpStatus.Pending ? 'Edit' : 'View'}
          </Button>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default TallyUpRowTemplate;
