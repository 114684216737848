import React from 'react';
import { Modal } from '@material-ui/core';
import * as S from './EditEmailModal.styled';
import Form from '../../../../../components/Form/Form';
import validationSchema from './schema';
import FormTextInput from '../../../../../components/FormTextInput/FormTextInput';
import FormSelect from '../../../../../components/FormSelect/FormSelect';
import { emailChangeReasons, initialFormValues } from './constants';
import { UpdateUserEmailReason } from '../../../../../graphql/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (payload: {
    email: string;
    reason: UpdateUserEmailReason;
    notes: string;
  }) => Promise<void>;
}

const EditEmailModal: React.FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <S.ModalContent>
        <S.Title>Update user email address</S.Title>
        <S.Body>
          Once the email address is changed, we will make it the main account
          and use it for authentication.
        </S.Body>
        <Form
          submit={values =>
            onSubmit({
              email: values.email,
              reason: values.reason,
              notes: values.description,
            })
          }
          initialValues={initialFormValues}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <>
              <FormTextInput editable name="email" label="Email Address" />
              <FormSelect
                editable
                name="reason"
                options={emailChangeReasons}
                label="Reason"
              />
              <FormTextInput editable name="description" label="Description" />

              <S.StyledButton
                variant={'contained'}
                color={'primary'}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Update
              </S.StyledButton>
            </>
          )}
        </Form>
      </S.ModalContent>
    </Modal>
  );
};

export default EditEmailModal;
