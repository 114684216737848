import React from 'react';
import styled from 'styled-components';
import { Body } from '../../../components/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InfoItemTitle = styled(Body)`
  color: ${({ theme }) => theme.palette.primary.grey};
  font-size: ${({ theme }) => theme.font.size.s};
`;
const InfoItemValue = styled(Body)`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: right;
  font-size: ${({ theme }) => theme.font.size.s};
`;
const InfoItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Subtitle = styled.h3<{ isLargeTitle?: boolean }>`
  font-family: ${props => props.theme.typography.h5.fontFamily};
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => (props.isLargeTitle ? '36px' : '18px')};
  font-weight: 500;
  margin: 0;
  text-align: center;
  padding-bottom: 8px;
`;

export const InfoItemComponent: React.FC<InfoItem> = ({ title, value }) => (
  <InfoItemContainer>
    <InfoItemTitle>{title}</InfoItemTitle>
    {value ? <InfoItemValue>{value}</InfoItemValue> : null}
  </InfoItemContainer>
);

export interface InfoItem {
  title: string;
  value?: string;
}

export interface DetailedInfoTileProps {
  title?: string;
  isLargeTitle?: boolean;
  infoItems?: InfoItem[];
  isLoading?: boolean;
}

export const DetailedInfoTile: React.FC<DetailedInfoTileProps> = ({
  title,
  isLargeTitle,
  infoItems,
  isLoading,
}) => (
  <>
    {title ? <Subtitle isLargeTitle={isLargeTitle}>{title}</Subtitle> : null}
    {isLoading ? <CircularProgress size={24} /> : null}
    {!isLoading && infoItems ? (
      <Container>
        <div>
          {infoItems.map(i => (
            <InfoItemComponent {...i} key={i.title} />
          ))}
        </div>
      </Container>
    ) : null}
  </>
);
