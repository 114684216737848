import { gql } from 'graphql-request';

export const getActivePolicy = gql`
  query getActivePolicy($userId: ID!) {
    user(id: $userId) {
      policies {
        policyNumberDetails {
          value
        }
        vehicles {
          # this is array, but should be only one active, so can get first element
          make
          model
          vin
          year
          vehicleDetails {
            id
          }
        }
      }
    }
  }
`;
