import { gql } from 'graphql-request';
import { fetchGraphQLQuery, getGraphClient } from '../../graphql/service';
import { DateTime, Interval } from 'luxon';
import {
  CancellationReason,
  getSdk,
  Policy,
  UpdateActivePolicyLienholderInput,
} from '../../graphql/types';
import { formatBalance } from '../../helpers/formatHelpers';
import { api } from '../httpService';
import { sortPoliciesByDate } from '../../helpers/policyHelpers';

export const PolicyDateFormat = 'd LLL yyyy HH:mm:ss.SSS';
export const optionsMap = {
  'no-glass-excess': '$0 glass deductible',
  '500excess': '$500 glass deductible',
  '1000excess': '$1000 glass deductible',
};

export interface Vehicle {
  id: string;
  vehicle: string;
  make: string;
  model: string;
  vin: string;
  year: number;
}

export interface UserPolicy {
  policyNr: string;
  quoteId: string;
  start: string;
  end: string;
  duration: number;
  timezone: string;
  coverages: string[];
  vehicles: Vehicle[];
  pricePerMile: string;
  pricePerDay?: string;
  status: string;
  odometersDue: number[];
  cancellationDate?: string;
  cancellationReason?: string;
}

export const mapCancellationTypes = () => {
  return Object.values(CancellationReason).map(value => {
    let label = value as string;
    if (value === CancellationReason.User) {
      label = 'Cancelled by user';
    } else if (value === CancellationReason.NonPayment) {
      label = 'Cancelled for non payment';
    }
    return {
      label,
      value,
    };
  });
};

export const mapGraphPolicies = (policies: Policy[]): UserPolicy[] => {
  const sortedPolicies = sortPoliciesByDate(policies);

  return sortedPolicies.map(
    ({
      quoteId,
      cancellationDate,
      timezone,
      cancellationReason,
      policyNumberDetails,
      status,
      term,
      totalPremium,
      vehicles,
      product,
      isRenewal,
    }) => {
      const { start, end } = Interval.fromISO(term, { setZone: true });
      return {
        policyNr: policyNumberDetails.value,
        quoteId,
        timezone,
        start: start.toFormat(PolicyDateFormat),
        end: end.toFormat(PolicyDateFormat),
        duration: product.policyOptions.duration,
        coverages: vehicles.map(({ coverages }) =>
          coverages.map(c => c.name).join(', '),
        ),
        vehicles: vehicles.map(
          ({ vehicleDetails: { id, make, model, year, vin } }) => ({
            id,
            make,
            model,
            year,
            vin,
            vehicle: `${year} ${make} ${model}`,
          }),
        ),
        pricePerMile: formatBalance(totalPremium.perMile),
        pricePerDay: formatBalance(totalPremium.perDay || 0),
        status: status.toLowerCase(),
        odometersDue: isRenewal
          ? product.odometerOptions.subsequentPolicy
          : product.odometerOptions.firstPolicy,
        cancellationDate: cancellationDate
          ? DateTime.fromISO(cancellationDate, { setZone: true }).toFormat('FF')
          : undefined,
        cancellationReason: cancellationReason ? cancellationReason : undefined,
      };
    },
  );
};

export const cancelPolicy = async (
  userId: number,
  reason: CancellationReason,
) => {
  const queryString = gql`
    mutation cancelCurrentActivePolicy(
      $cancellationReasonInput: CancelCurrentActivePolicyInput!
    ) {
      cancelCurrentActivePolicy(
        cancellationReasonInput: $cancellationReasonInput
      )
    }
  `;
  const cancellationReasonInput = {
    reason,
    userId,
  };

  await fetchGraphQLQuery(queryString, {
    cancellationReasonInput,
  });
};

export type AmendLienholderRequest = UpdateActivePolicyLienholderInput;

export const amendLienholder = async (input: AmendLienholderRequest) => {
  const client = await getGraphClient();
  await getSdk(client).amendActivePolicyLienholder({ input });
};

export const createPolicy = async (userId: string, covers: number[]) =>
  await api<void>('post', `/users/${userId}/policies`, {
    covers,
  });
