import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import { useField } from 'formik';
import React from 'react';

interface Props
  extends Omit<
    StandardTextFieldProps,
    'value' | 'error' | 'variant' | 'helperText'
  > {
  readonly editable?: boolean;
  readonly name: string;
}

const FormTextInput: React.FC<Props> = props => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const { editable, onBlur, onChange, ...rest } = props;
  return (
    <TextField
      {...rest}
      fullWidth
      margin="normal"
      variant={(editable ? 'filled' : 'standard') as any}
      InputProps={{
        disabled: !editable,
        readOnly: !editable,
      }}
      onBlur={e => {
        field.onBlur(e);
        if (onBlur) {
          onBlur(e);
        }
      }}
      error={meta.touched ? !!meta.error : undefined}
      helperText={meta.touched ? meta.error : undefined}
      onChange={e => {
        field.onChange(e);
        if (onChange) {
          onChange(e);
        }
      }}
      value={field.value || ''}
    />
  );
};

export default FormTextInput;
