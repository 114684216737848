import { combineReducers } from 'redux';
import * as accountManagement from '../../features/accountManagement';
import keplerGl from './keplerGlReducer';

export const rootReducer = combineReducers({
  keplerGl,
  [accountManagement.FEATURE_NAME]: accountManagement.reducer,
});

export default rootReducer;
