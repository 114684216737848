import { fetchGraphQLQuery } from '../../../../../graphql/service';
import { getCreditReportsQuery, runCreditReportMutation } from './gql';
import {
  GetCreditReportResult,
  GetCreditReportsQuery,
  RunCreditCheckMutationInput,
  RunCreditCheckMutationResult,
  RunCreditCheckResult,
} from './types';

class CreditHistoryApi {
  constructor(private readonly userId: string) {}

  getCreditReports = async (): Promise<GetCreditReportResult> => {
    try {
      const result = await fetchGraphQLQuery<
        GetCreditReportsQuery,
        { userId: string }
      >(getCreditReportsQuery, {
        userId: this.userId,
      });

      return {
        success: true,
        data: result.getCreditReport,
      };
    } catch (e: any) {
      return {
        success: false,
        error: e?.message ?? 'Network request failed: ' + JSON.stringify(e),
      };
    }
  };

  runCreditCheck = async (note: string): Promise<RunCreditCheckResult> => {
    try {
      const result = await fetchGraphQLQuery<
        RunCreditCheckMutationResult,
        RunCreditCheckMutationInput
      >(runCreditReportMutation, { input: { userId: this.userId, note } });

      return {
        success: true,
        data: {
          status: result.runCreditCheck.status,
          reportedAt: result.runCreditCheck.reportedAt,
          keyFactors: result.runCreditCheck.keyFactors,
          score: result.runCreditCheck.score,
        },
      };
    } catch (e: any) {
      return {
        success: false,
        error: e?.message ?? 'Network request failed: ' + JSON.stringify(e),
      };
    }
  };
}

export default CreditHistoryApi;
