import styled from 'styled-components';

export type ListType = 'small' | 'large';

interface ListProps {
  type: ListType;
}

export const Attachment = styled.a<ListProps>`
  align-items: center;
  color: ${({ theme }) => theme.typography.body1.color};
  cursor: pointer;
  display: flex;
  flex-direction: row;

  ${({ theme, type }) => {
    if (type === 'large') {
      return `
        border-bottom: 1px solid ${theme.palette.primary.darkGrey};
        font-size: ${theme.typography.body1.fontSize};
        padding: 10px;
      `;
    } else {
      return `
        font-size: 8px;
        padding: 10px 10px 10px 0;
      `;
    }
  }}

  img.small {
    height: 14px;
    margin-right: 7px;
    width: 12px;
  }

  img.large {
    height: 24px;
    margin-right: 15px;
    width: 20px;
  }
`;

export const AttachmentList = styled.div<ListProps>`
  display: flex;
  flex-direction: ${({ type }) => (type === 'large' ? 'column' : 'row')};
`;
