import React from 'react';
import styled from 'styled-components';

type Props = {
  charge: string;
  isVoided: boolean;
  isCredit: boolean;
};

const NoCharge = styled.span`
  color: ${({ theme }) => theme.palette.primary.warning};
`;

const formatCreditCharge = (charge: string, isCreditCharge: boolean) => {
  return isCreditCharge ? `(-${charge})` : charge;
};

const TallyUpCharge: React.FC<Props> = ({ charge, isVoided, isCredit }) => {
  return isVoided ? (
    <NoCharge>No charge</NoCharge>
  ) : (
    <span>{formatCreditCharge(charge, isCredit)}</span>
  );
};

export default TallyUpCharge;
