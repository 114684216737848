import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0;
`;

export const Headline = styled.h2``;

export const InfoBubble = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.paleLilac};
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
`;

export const ModalContent = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 20%;
  left: calc(50% - 200px);
  outline: none;
  padding: 10px 30px;
  width: 500px;
`;

export const Heading = styled.h2``;

export const Content = styled.div`
  align-items: center;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProgressList = styled.div`
  width: 100%;
`;

export const ProgressItem = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.darkGrey};
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.palette.primary.bluePurple};
  font-size: 18px;
`;

export const Value = styled.div`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 18px;
`;

export const StyledButton = styled(Button)`
  && {
    width: 220px;
  }
`;
