import { IssuePolicyEndorsementReason } from '../../../../graphql/api';

export const mapReason = (reason: IssuePolicyEndorsementReason): string => {
  switch (reason) {
    case IssuePolicyEndorsementReason.CreditScoreChange:
      return 'Credit score changed';
    case IssuePolicyEndorsementReason.DrivingReportChange:
      return 'Driving report changed';
    case IssuePolicyEndorsementReason.PolicyDetailsUpdate:
      return 'Policy details updated';
    default:
      return reason;
  }
};
