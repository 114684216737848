import React from 'react';
import { ActionButton } from '../ActionButton/ActionButton.styled';

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const DrivingHistoryButton: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <ActionButton
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      RUN DRIVING CHECK
    </ActionButton>
  );
};

export default DrivingHistoryButton;
