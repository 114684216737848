import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Column, RowAction } from '../../../components/Table/Table';
import { UserPolicy } from '../../../services/repository/policyService';
import { MenuItem, PopUpMenu } from '../../../components/PopUpMenu/PopUpMenu';
import { PopUpMenuContainer } from './PolicyRowTemplate.style';
import { MenuOption } from './types';

interface Props {
  columns: Column[];
  rowActions?: RowAction[];
  item: UserPolicy;
  rowIndex: number;
  onSelectQuote: (id: number) => void;
  onPressPolicyId: (policy: UserPolicy) => void;
  onMenuClick: (menuItem: MenuItem) => void;
}

const menuOptions: MenuItem[] = [
  {
    label: 'Cancel by Carrier',
    type: MenuOption.CancelByCarrier,
  },
  {
    label: 'Rescind',
    type: MenuOption.Rescind,
  },
];

const PolicyRowTemplate: React.FC<Props> = ({
  item,
  onSelectQuote,
  onPressPolicyId,
  onMenuClick,
}) => {
  return (
    <TableRow className="policy">
      <TableCell>
        <div
          className="clickable"
          title="Click here to view the quote"
          onClick={() => {
            onPressPolicyId(item);
          }}
        >
          {item.policyNr}
        </div>
      </TableCell>
      <TableCell>
        <div
          className="clickable"
          title="Click here to view the quote"
          onClick={() => onSelectQuote(parseInt(item.quoteId, 10))}
        >
          {item.quoteId}
        </div>
      </TableCell>
      <TableCell>{item.start}</TableCell>
      <TableCell>{item.end}</TableCell>
      <TableCell>{item.duration} days</TableCell>
      <TableCell>{item.coverages.join(', ')}</TableCell>
      <TableCell>
        {item.vehicles.map(({ vehicle }) => vehicle).join(', ')}
      </TableCell>
      <TableCell>
        <div>
          {`${item.pricePerMile} per mile`}
          <br />
          {`${item.pricePerDay} per day`}
        </div>
      </TableCell>
      <TableCell>{item.status}</TableCell>
      <TableCell>
        {item.odometersDue.length > 0
          ? `Day ${item.odometersDue.join(', ')}`
          : '-'}
      </TableCell>
      <TableCell>{item.cancellationDate}</TableCell>
      <TableCell>{item.cancellationReason}</TableCell>
      <TableCell>
        {item.status === 'active' ? (
          <PopUpMenuContainer>
            <PopUpMenu
              menuItems={menuOptions}
              onClick={menuItem => onMenuClick(menuItem)}
            />
          </PopUpMenuContainer>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default PolicyRowTemplate;
