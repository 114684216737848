import React, { useState } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormModal from '../../../../components/FormModal/FormModal';
import schema from './schema';
import {
  CancelUserPolicyInput,
  useCancelUserPolicyMutation,
} from '../../../../graphql/api';
import { ErrorCode, logError } from '../../../../services/logging';
import CancellationReasonsSelect from './CancellationReasonsSelect';
import { withErrorBoundary } from '../../../../components/withErrorBoundary';

interface Props {
  isOpen: boolean;
  userId: number;
  onSuccess: () => void;
  onClose: () => void;
}

interface FormValues {
  cancellationExplanationCode: string;
}

const CancelPolicyModal: React.FC<Props> = ({
  isOpen,
  userId,
  onSuccess,
  onClose,
}) => {
  const [error, setError] = useState<Error>();

  const { isLoading, mutate } = useCancelUserPolicyMutation({
    onSuccess,
    onError: (e: Error) => {
      setError(e);
      logError(ErrorCode.UserPolicyCancel, e, { userId });
    },
  });

  const onSubmit = async (values: FormValues) => {
    const input: CancelUserPolicyInput = {
      userId: userId.toString(),
      cancellationExplanationCode: values.cancellationExplanationCode,
    };

    mutate({ input });
  };

  const onError = (e: Error) => {
    setError(e);
  };

  const initialValues: FormValues = {
    cancellationExplanationCode: '',
  };

  return (
    <FormModal
      isOpen={isOpen}
      isLoading={isLoading}
      isError={!!error}
      error={error}
      validationSchema={schema}
      onSubmit={onSubmit}
      onClose={() => {
        setError(undefined);
        onClose();
      }}
      title="Cancel by Carrier"
      initialValues={initialValues}
      confirmLabel="Cancel Policy"
      confirmButtonType="delete"
    >
      <DialogContentText>
        {`Please select a reason to cancel the policy for user ${userId}`}
      </DialogContentText>
      <CancellationReasonsSelect onError={onError} />
    </FormModal>
  );
};

export default withErrorBoundary(CancelPolicyModal);
