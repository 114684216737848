import React from 'react';
import { LoadingContainer } from './DiscoveredVehicles.style';
import { CircularProgress } from '@material-ui/core';

const Loading = () => {
  return (
    <LoadingContainer>
      <CircularProgress size={32} color="primary" />
    </LoadingContainer>
  );
};

export default Loading;
