import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0;
`;

export const ModalContent = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 12px;
  top: 10%;
  bottom: 10%;
  left: calc(50% - 200px);
  outline: none;
  padding: 16px 0px;
  width: 600px;
  flex-direction: column;
  display: flex;
`;

export const ActionButton = styled(Button)`
  && {
    align-self: center;
    width: 220px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;
