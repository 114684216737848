import { Trip } from '../../../../types/interfaces';
import { formatBalance, formatKmToMiles } from '../../../helpers/formatHelpers';

export const tripV1ListColumnDefs = [
  {
    title: 'ID',
    id: 'id',
  },
  {
    title: 'Start Date',
    id: 'start',
  },
  {
    title: 'End Date',
    id: 'end',
  },
  {
    title: 'Miles',
    id: 'distance',
    rowFormatter: (trip: Trip) => `${formatKmToMiles(trip.distance)}`,
  },
  {
    title: 'Cost',
    id: 'cost',
    rowFormatter: (trip: Trip) => `${formatBalance(trip.cost)}`,
  },
  {
    title: 'Dispute reason',
    id: 'disputeReason',
    rowFormatter: (trip: Trip) => `${trip.disputeReason || ''}`,
  },
];
