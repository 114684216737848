import { gql } from 'graphql-request';

export const disablePolicyPurchaseMutation = gql`
  mutation restrictPolicyPurchase($input: RestrictUserInput!) {
    restrictUser(input: $input)
  }
`;

export const enablePolicyPurchaseMutation = gql`
  mutation unrestrictUser($input: UnrestrictUserInput!) {
    unrestrictUser(input: $input)
  }
`;

export const unblockUserMutation = gql`
  mutation reinstateUser($input: ReinstateUserInput!) {
    reinstateUser(input: $input)
  }
`;
