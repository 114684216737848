import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LogoutIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { matchPath } from 'react-router-dom';
import { Drawer } from '../Drawer';
import styles from './SiteAppBar.styled';
import { useAuth0 } from '@auth0/auth0-react';
import { PageTitle } from '../../../types/interfaces';

interface ISiteAppBarProps {
  classes: any;
  pathname: string;
  isSideBarOpen: boolean;
  onSideBarOpen: (open: boolean) => void;
  onNavigateToUrl: (url: string) => void;
  isStaging?: boolean;
}

const getTitle = (pathname: string) => {
  const pageTitles: PageTitle[] = [
    { path: '/', title: 'Dashboard' },
    { path: '/mapping/:tripId', title: 'Mapping' },
    { path: '/trips', title: 'Trip Management' },
    { path: '/tallyups', title: 'Tally Ups' },
    { path: '/users/:userId/tallyups/:tallyUpId', title: 'Tally Ups' },
    { path: '/users', title: 'Search' },
    { path: '/users/:userId', title: 'User Management' },
    { path: '/accounts', title: 'Account Management' },
    { path: '/reference-data', title: 'Reference Data' },
    {
      path: '/reference-data/:makeId/models/:makeName',
      title: 'Reference Data',
    },
    {
      path: '/referrals',
      title: 'Referrals Management',
    },
    {
      path: '/referrals/salesperson/:salesPersonId',
      title: 'Referrals Management',
    },
    {
      path: '/referrals/organisation/:organisationId',
      title: 'Referrals Management',
    },
    {
      path: '/productboard',
      title: 'Productboard',
    },
  ];

  const currentPage = pageTitles.find(page =>
    matchPath(pathname, { path: page.path, exact: true }),
  );

  return currentPage ? currentPage.title : '';
};

const SiteAppBar: React.FC<ISiteAppBarProps> = ({
  classes,
  isSideBarOpen,
  pathname,
  onSideBarOpen,
  onNavigateToUrl,
  isStaging = false,
}) => {
  const { logout } = useAuth0();

  return (
    <Fragment>
      <AppBar
        position="absolute"
        color="primary"
        className={classNames(
          classes.appBar,
          isSideBarOpen && classes.appBarShift,
        )}
      >
        <Toolbar disableGutters={!isSideBarOpen}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => onSideBarOpen(true)}
            className={classNames(
              classes.menuButton,
              isSideBarOpen && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" color="inherit" noWrap>
            {getTitle(pathname)}
          </Typography>
          {isStaging && (
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              className={classes.stagingLabel}
            >
              Just Staging
            </Typography>
          )}
          <div className={classes.toolbarRight}>
            <IconButton
              color="inherit"
              aria-label="Logout"
              onClick={() => logout({ returnTo: window.location.origin })}
              className={classes.logoutButton}
              classes={{
                label: classes.logoutLabel,
              }}
            >
              <LogoutIcon />
              <div>Sign Out</div>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        open={isSideBarOpen}
        onNavigateToUrl={onNavigateToUrl}
        onClose={() => onSideBarOpen(false)}
      />
    </Fragment>
  );
};

export default withStyles(styles)(SiteAppBar);
