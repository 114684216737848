import React from 'react';
import { ErrorMessage } from './ModalError.styled';

interface Props {
  error: string;
}

const ModalError: React.FC<Props> = ({ error }) => {
  return (
    <ErrorMessage align="center" variant="body2">
      {error}
    </ErrorMessage>
  );
};

export default ModalError;
