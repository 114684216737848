import React from 'react';
import { ControllerStatus, DuplicateAccountState } from './types';
import DuplicateAccountApi from './api';
import { useFeatureFlags } from '../../featureFlags';

interface ContextType {
  state: DuplicateAccountState;
  isFeatureEnabled: boolean;
  getDuplicateStatus: () => Promise<void>;
  validateUserStatus: () => Promise<void>;
}

const Context = React.createContext<ContextType | null>(null);

type Props = {
  userId: string;
};

const initialState: DuplicateAccountState = {
  status: ControllerStatus.Initial,
  data: null,
  error: null,
};

export const DuplicateAccountController: React.FC<Props> = ({
  userId,
  children,
}) => {
  const [duplicateAccountApi] = React.useState(
    () => new DuplicateAccountApi(userId),
  );
  const isFeatureEnabled =
    (useFeatureFlags().featureFlags?.isDuplicateVin as boolean) || false;

  const [duplicateAccountState, updateDuplicateAccountState] = React.useReducer(
    (
      prevState: DuplicateAccountState,
      nextState: Partial<DuplicateAccountState>,
    ) => {
      return { ...prevState, ...nextState };
    },
    initialState,
  );

  const getDuplicateStatus = async () => {
    updateDuplicateAccountState({ status: ControllerStatus.Loading });
    const response = await duplicateAccountApi.getDuplicateAccountStatus();
    if (response.success) {
      updateDuplicateAccountState({
        status: ControllerStatus.Success,
        data: response.data,
      });
    } else {
      updateDuplicateAccountState({
        status: ControllerStatus.Error,
        error: response.error,
      });
    }
  };

  const validateUserStatus = async () => {
    updateDuplicateAccountState({ status: ControllerStatus.Processing });
    const response = await duplicateAccountApi.validateUserStatus();
    if (response.success) {
      updateDuplicateAccountState({
        status: ControllerStatus.Success,
        data: response.data,
      });
    }
  };

  React.useEffect(() => {
    getDuplicateStatus();
  }, []);

  return (
    <Context.Provider
      value={{
        state: duplicateAccountState,
        isFeatureEnabled,
        getDuplicateStatus,
        validateUserStatus,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useDuplicateAccount = (): ContextType => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error(
      '[DuplicateAccountController]: Context must be used within a DuplicateAccountProvider',
    );
  }

  return context;
};
