import { Reducer } from 'redux';
import {
  SUBMIT_USER_COMPLETE,
  SUBMIT_USER_ERROR,
  SUBMIT_USER_START,
} from '../constants';
import { IAccountManagementReducerState } from '../interfaces';

export const defaultState: IAccountManagementReducerState = {
  error: null,
  isSubmitting: false,
  user: null,
  isFinished: false,
};

const mappingReducer: Reducer<IAccountManagementReducerState> = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case SUBMIT_USER_START:
      return {
        error: null,
        isSubmitting: true,
        isFinished: false,
      };
    case SUBMIT_USER_COMPLETE:
      return {
        error: null,
        isSubmitting: false,
        user: action.payload.user,
        isFinished: true,
      };
    case SUBMIT_USER_ERROR:
      return {
        error: action.payload.error,
        isSubmitting: false,
        isFinished: false,
      };
    default:
      return state;
  }
};

export default mappingReducer;
