import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 8px;
`;

export const ActionButton = styled(Button)`
  && {
    width: 220px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;
