import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  table {
    tr {
      td:nth-child(5) {
        width: 40px;
      }
    }
  }
`;

export const UserContent = styled.div`
  height: 700px;
  width: 100%;
`;

export const TallyTileContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 40px 0 50px 0;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const Row = styled.div`
  align-items: center;
  display: flex;

  div:first-child {
    margin-right: 10px;
  }
`;

export const EmptyContainer = styled.div`
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.palette.primary.grey};
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.palette.shades.boxBackground};
  color: ${({ theme }) => theme.palette.primary.grey};
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px;
  margin-right: 50px;
  height: 290px;
  width: 320px;
`;
