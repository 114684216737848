import { useFormikContext } from 'formik';
import React from 'react';
import { ActionButton } from './FormSubmitButton.styled';

interface Props {
  children: React.ReactNode;
  onClick?: (isSubmitting: boolean) => void;
  hasClickedEdit?: boolean;
  hasChanges?: boolean;
  startIcon: React.ReactElement;
}

const FormSubmitButton: React.FC<Props> = ({
  children,
  onClick,
  hasClickedEdit = false,
  hasChanges,
  ...rest
}) => {
  const { handleSubmit, isValid } = useFormikContext();

  const isActive = (hasClickedEdit && !hasChanges) || isValid;
  return (
    <ActionButton
      variant="contained"
      color="primary"
      {...rest}
      disabled={!isActive}
      onClick={() => {
        if (hasClickedEdit && !hasChanges && onClick) {
          return onClick(false);
        }

        handleSubmit();
        if (onClick) {
          onClick(true);
        }
      }}
    >
      {children}
    </ActionButton>
  );
};

export default FormSubmitButton;
