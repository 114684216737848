import PageContent from '../../../components/PageContent';

type Props = {
  userId: string;
};

const RegistrationUserPage: React.FC<Props> = ({ userId }) => (
  <PageContent>User {userId} is in registration</PageContent>
);

export default RegistrationUserPage;
