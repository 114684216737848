import { getFeatureFlags } from './api';
import { FeatureFlags, GetFeatureFlagsResponse } from './types';

const useFeatureFlagsApi = () => {
  const getFeatureFlagsData = async (
    userId: string,
  ): Promise<GetFeatureFlagsResponse> => {
    try {
      const response = await getFeatureFlags(userId);

      const result = response.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {} as FeatureFlags);

      return { success: true, result };
    } catch (e) {
      return { success: false };
    }
  };

  return {
    getFeatureFlagsData,
  };
};

export default useFeatureFlagsApi;
