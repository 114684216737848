export const columnDefs = [
  {
    id: 'yearOccurred',
    title: 'Year Occurred',
    sortable: false,
  },
  {
    id: 'amountOfLoss',
    title: 'Amount Of Loss',
    sortable: false,
  },
  {
    id: 'anyoneInjured',
    title: 'Anyone Injured',
    sortable: false,
  },
  {
    id: 'atFault',
    title: 'At Fault',
    sortable: false,
  },
  {
    id: 'rowOptions',
    title: '',
    sortable: false,
  },
];
