import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TakePaymentModal from './TakePaymentModal';

interface Props {
  userId: string;
}

const TakeFinalPayment: React.FC<Props> = ({ userId }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}
      >
        Take final payment
      </Button>
      {isModalOpen ? (
        <TakePaymentModal userId={userId} onClose={() => setModalOpen(false)} />
      ) : null}
    </>
  );
};

export default TakeFinalPayment;
