import * as yup from 'yup';

export default yup.object().shape({
  vin: yup
    .string()
    .required('VIN is required')
    .length(17, 'VIN must be 17 characters'),
  make: yup.string(),
  model: yup.string(),
  makeId: yup.number(),
  modelId: yup.number(),
  year: yup.number().required('Year is required'),
});
