import { datadogLogs } from '@datadog/browser-logs';
import React from 'react';
import { withErrorBoundary as withReactErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';
import { getConfig } from '../config';
import { GenericComponentError } from './GenericComponentError';

const config = getConfig();

const errorHandler = (error: Error) => {
  if (config.REACT_APP_ENVIRONMENT === 'local') {
    // eslint-disable-next-line no-console
    console.error(error);
    return;
  }

  datadogLogs.logger.error(error.message);
  Sentry.captureException(error, {
    level: 'error',
  });
};

export const withErrorBoundary = <P>(Component: React.ComponentType<P>) =>
  withReactErrorBoundary(Component, {
    FallbackComponent: GenericComponentError,
    onError: errorHandler,
  });
