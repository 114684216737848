import { DateTime } from 'luxon';
import getFiftyMileChargedAmount from './getFiftyMileChargedAmount';
import getTrackedTrips from './getTrackedTrips';
import { CalculationData, UserTrips, Transaction } from '../types';
import { formatBalance } from '../../../../../helpers/formatHelpers';

const calculateFinalBalance = (
  policyNumber: string,
  policyStartDate: DateTime,
  policyTerminationDate: DateTime,
  latestOdometerDate: DateTime | undefined,
  transactions: Transaction[],
  trips: UserTrips,
  pricePerMile: number,
): CalculationData => {
  // latest odometer date, or if no odometer then policy start date + 3 days
  const fiftyMilePerDayStartDate =
    latestOdometerDate ?? policyStartDate.plus({ days: 3 });

  // duration of 50 mile/day charge
  const fiftyMilePerDayPeriod = policyTerminationDate.diff(
    fiftyMilePerDayStartDate,
  );

  // number of miles for 50 mile/day charge
  const fiftyMilePerDayPeriodMiles = fiftyMilePerDayPeriod.as('days') * 50;

  // total amount of 50 mile/day charges already applied
  const fiftyMileAlreadyChargedAmount = getFiftyMileChargedAmount(
    transactions,
    policyStartDate,
  );

  // number of miles already charged
  const fiftyMileAlreadyChargedMiles =
    fiftyMileAlreadyChargedAmount / pricePerMile;

  const totalFiftyMileCharge =
    fiftyMilePerDayPeriodMiles - fiftyMileAlreadyChargedMiles;

  // number of tracked miles and disputed miles in the period
  const { trackedTripMiles, disputedTripMiles } = getTrackedTrips(
    trips,
    fiftyMilePerDayStartDate,
    policyTerminationDate,
  );

  const totalTrackedTripMiles = trackedTripMiles - disputedTripMiles;

  const totalChargeableMiles = Math.max(
    0,
    totalFiftyMileCharge - totalTrackedTripMiles,
  );

  // whole cents, rounded down. We cannot round up and charge the user more than they have used.
  const balanceFinalisationCharge = Math.floor(
    totalChargeableMiles * pricePerMile,
  );

  return {
    policyNumber,
    policyTerminationDate: policyTerminationDate.toFormat('FF'),
    latestOdometerDate: latestOdometerDate?.toFormat('FF'),
    fiftyMilePerDayPeriod: `${fiftyMilePerDayPeriod
      .as('days')
      .toFixed(8)} days`,
    fiftyMilePerDayPeriodMiles: `${fiftyMilePerDayPeriodMiles.toFixed(
      8,
    )} miles`,
    fiftyMileAlreadyChargedMiles: `${fiftyMileAlreadyChargedMiles.toFixed(
      8,
    )} miles`,
    totalFiftyMileChargeInMiles: `${totalFiftyMileCharge.toFixed(8)} miles`,
    trackedTripMiles: `${trackedTripMiles.toFixed(8)} miles`,
    disputedTripMiles: `${disputedTripMiles.toFixed(8)} miles`,
    totalTrackedTripMiles: `${totalTrackedTripMiles.toFixed(8)} miles`,
    totalChargeableMiles: `${totalChargeableMiles.toFixed(8)} miles`,
    pricePerMile: formatBalance(pricePerMile),
    balanceFinalisationCharge: formatBalance(balanceFinalisationCharge),
    balanceFinalisationChargeInCents: balanceFinalisationCharge,
  };
};

export default calculateFinalBalance;
