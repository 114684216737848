import styled from 'styled-components';

export const Row = styled.div`
  align-items: center;
  display: flex;

  div:last-child {
    margin-left: 10px;
  }
`;

export const Indicator = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.red};
  padding: 4px 12px;
  border-radius: 16px;
  color: ${({ theme }) => theme.palette.primary.white};
`;

export const FlagIcon = styled.img`
  margin-right: 5px;
  height: 10px;
  width: 10px;
`;
