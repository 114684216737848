import styled from 'styled-components';
import { SparklesIcon } from '@heroicons/react/20/solid';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 65px);
  overflow-y: none;

  form {
    .MuiInputBase-input {
      color: ${({ theme }) => theme.typography.body1.color};
    }
    .MuiFormLabel-root.Mui-disabled {
      color: ${({ theme }) => theme.typography.body1.color};
    }
  }

  .row {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .spltrow {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .MuiButton-contained {
    min-width: 110px;
  }

  .radr-table {
    height: 500px;
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -22px -22px 0;
  padding: 22px 22px 10px;
  background: ${({ theme }) => theme.palette.primary.white};
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
`;

export const HeaderLink = styled.button`
  cursor: pointer;
  align-items: center;
  border: none;
  background: none;
  display: flex;
  color: ${({ theme }) => theme.typography.body1.color};
  text-decoration-line: none;
  font-size: 14px;
  line-height: 20px;
  margin-right: 40px;

  &:focus {
    outline: none;
  }
`;

export const HeaderLinkImage = styled.img`
  margin-right: 5px;
  width: 20px;
  height: auto;
`;

export const UserV2Icon = styled(SparklesIcon)`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  color: #31a6c3;
`;

export const UserV2Button = styled.button`
  background: #162d8b;
  border-radius: 16px;
  padding: 4px 10px 4px 8px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: 15px;
`;
