import {
  useGetUserPoliciesQuery,
  useGetUserTransactionsQuery,
} from '../../../../graphql/api';
import { UserPolicy, Result, Transaction } from './types';
import { useGetUserPoliciesSelector } from './useGetUserPoliciesSelector';
import { useGetUserTransactionsSelector } from './useGetUserTransactionsSelector';
import useGetUserOdometers from './useGetUserOdometers';
import useGetUserTrips from './useGetUserTrips';
import getLatestActiveOdometer from './util/getLatestActiveOdometer';
import calculateFinalBalance from './util/calculateFinalBalance';

const useCalculateFinalBalance = (userId: string): Result => {
  const userPolicyQuery = useGetUserPoliciesQuery<
    UserPolicy | undefined,
    Error
  >(
    { userId },
    {
      select: useGetUserPoliciesSelector,
    },
  );

  const userTransactionsQuery = useGetUserTransactionsQuery<
    Transaction[],
    Error
  >(
    { userId },
    {
      select: useGetUserTransactionsSelector,
    },
  );

  const odometerQuery = useGetUserOdometers(userId);

  const userTripsQuery = useGetUserTrips(userId);

  if (
    userPolicyQuery.isLoading ||
    userTransactionsQuery.isLoading ||
    odometerQuery.isLoading ||
    userTripsQuery.isLoading
  ) {
    return {
      status: 'loading',
    };
  }

  if (
    userPolicyQuery.isError ||
    userTransactionsQuery.isError ||
    odometerQuery.isError ||
    userTripsQuery.isError
  ) {
    return {
      status: 'fail',
      error:
        userPolicyQuery.error?.message ||
        userTransactionsQuery.error?.message ||
        odometerQuery.error?.message ||
        userTripsQuery.error?.message ||
        'There was an error',
    };
  }

  if (
    !userPolicyQuery.data ||
    !userTransactionsQuery.data ||
    !odometerQuery.data ||
    !userTripsQuery.data
  ) {
    return {
      status: 'fail',
      error: 'Error fetching data',
    };
  }

  const policyTerminationDate =
    userPolicyQuery.data.cancellationDate ?? userPolicyQuery.data.end;

  const latestOdometer = getLatestActiveOdometer(odometerQuery.data);

  if (latestOdometer && latestOdometer.createdAt > policyTerminationDate) {
    return {
      status: 'fail',
      error: 'Odometer photo taken after policy termination',
    };
  }

  const finalBalance = calculateFinalBalance(
    userPolicyQuery.data.policyNumber,
    userPolicyQuery.data.start,
    policyTerminationDate,
    latestOdometer?.createdAt,
    userTransactionsQuery.data,
    userTripsQuery.data,
    userPolicyQuery.data.pricePerMile,
  );

  return {
    status: 'success',
    data: finalBalance,
  };
};

export default useCalculateFinalBalance;
