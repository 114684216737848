import React from 'react';
import { Container, TileText, TileValue } from './TallyTile.style';

interface Props {
  text: string;
  value: number | string;
}

const TallyTile: React.FC<Props> = ({ text, value }) => {
  return (
    <Container>
      <TileValue data-testid="tile-value">{value}</TileValue>
      <TileText data-testid="tile-title">{text}</TileText>
    </Container>
  );
};

export default TallyTile;
