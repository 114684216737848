import React from 'react';
import { ActionButton, Container, Subcontainer } from './UserActions.styled';
import UserProgress from './UserProgress/UserProgress';
import FormSubmitButton from './FormSubmitButton';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import DiscoveredVehicles from './DiscoveredVehicles';
import { Feature, isFeatureEnabled } from '../../../config';
import { useFeatureFlags } from '../featureFlags';
import UserFeatureFlags from '../featureFlags/components/UserFeatureFlags';
import { UnblockAccountButton } from '../DuplicateAccount';
import VerifyAccountButton from '../DuplicateAccount/components/VerifyAccountButton';

interface Props {
  userId: string;
  onEditUser: (isEditing: boolean) => void;
  onCancelEditUser: () => void;
  isEditingUser: boolean;
}

const UserActions: React.FC<Props> = ({
  userId,
  onEditUser,
  onCancelEditUser,
  isEditingUser,
}) => {
  const { isEnabled } = useFeatureFlags();
  return (
    <Container>
      <Subcontainer>
        <UserProgress userId={userId} />
        {isFeatureEnabled(Feature.VehicleSelection) ? (
          <DiscoveredVehicles userId={userId} />
        ) : null}
        {isEnabled ? <UserFeatureFlags /> : null}
        <UnblockAccountButton />
        <VerifyAccountButton />
        <FormSubmitButton
          onClick={isSubmitting => {
            if (!isSubmitting) {
              onEditUser(!isEditingUser);
            }
          }}
          toggleEdit
          toggleState={isEditingUser}
          startIcon={isEditingUser ? <SaveIcon /> : <EditIcon />}
        >
          {`${isEditingUser ? 'Save' : 'Edit'} user details`}
        </FormSubmitButton>
        {isEditingUser ? (
          <ActionButton
            variant="contained"
            onClick={onCancelEditUser}
            startIcon={<CancelIcon />}
          >
            Cancel
          </ActionButton>
        ) : null}
      </Subcontainer>
    </Container>
  );
};

export default UserActions;
