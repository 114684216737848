import React from 'react';
import { useObdContext } from '../Controller';
import { Button, Dialog } from '@material-ui/core';
import { DeviceStatus } from '../Controller/types';
import {
  StyledDialogTitle,
  ButtonWrapper,
  StyledDialogDescription,
  ErrorMessage,
} from './ObdDeactivateButton.styled';
import { CircularProgress } from '@material-ui/core';

const ObdDeactivateButton = () => {
  const { device, isProcessing, deactivateDevice } = useObdContext();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const isDeviceActive = device?.status === DeviceStatus.ACTIVATED;
  const [isModalVisible, setModalVisible] = React.useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const handleDeviceDeactivation = async () => {
    const response = await deactivateDevice();
    if (response.success) {
      setErrorMessage(null);
      hideModal();
    } else {
      setErrorMessage(response.error.message);
    }
  };

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  return (
    <>
      <Button
        disabled={!isDeviceActive || !device}
        variant="contained"
        color="primary"
        onClick={openModal}
      >
        Disable miles tracker
      </Button>
      <Dialog
        open={isModalVisible}
        scroll="body"
        fullWidth
        maxWidth="xs"
        onClose={hideModal}
      >
        <StyledDialogTitle>Are you sure</StyledDialogTitle>
        <StyledDialogDescription>
          Please confirm you would like to deactivate the miles tracker device
          from this user account.
        </StyledDialogDescription>
        <ButtonWrapper>
          <Button variant="outlined" color="primary" onClick={hideModal}>
            Abort
          </Button>
          <Button
            onClick={handleDeviceDeactivation}
            disabled={isProcessing}
            style={{ marginLeft: 16 }}
            variant={isProcessing ? 'outlined' : 'contained'}
            color={isProcessing ? undefined : 'primary'}
          >
            {isProcessing ? (
              <CircularProgress size={20} color={'secondary'} />
            ) : (
              'Confirm'
            )}
          </Button>
        </ButtonWrapper>
      </Dialog>
    </>
  );
};

export default ObdDeactivateButton;
