import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  margin: 9px 0;
`;

export const MenuIcon = styled.img`
  cursor: pointer;
  height: 33px;
  width: 33px;
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  height: 33px;
  width: 33px;

  &:focus,
  :active {
    outline: none;
  }
`;
