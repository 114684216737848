import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { EmailCard } from './EmailCard/EmailCard';
import { HistoryHeader } from './HistoryHeader/HistoryHeader';
import { SmsCard } from './SmsCard/SmsCard';
import {
  Communication,
  CommunicationFilter,
  getCommunicationHistory,
} from '../../../services/repository/communicationService';
import {
  getDefaultCommsOption,
  getDefaultDateOption,
} from './FilterSelector/filterOptions';
import { ContentLoader } from '../../../components/ContentLoader/ContentLoader';
import ErrorModal from '../../../components/ErrorModal/ErrorModal';
import * as S from './CommunicationsHistory.styled';

interface Props {
  userId: string;
}

export const CommunicationsHistory: React.FC<Props> = ({ userId }) => {
  const [communications, setCommunications] = useState<Communication[]>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [filter, setFilter] = useState<CommunicationFilter>({
    dateOption: getDefaultDateOption(),
    commsOption: getDefaultCommsOption(),
  });

  const getCommsHistory = useCallback(async (id, filterOptions) => {
    try {
      setLoading(true);
      const comms = await getCommunicationHistory(id, filterOptions);

      setLoading(false);
      setCommunications(comms);
    } catch (ex: any) {
      setError(ex);
    }
  }, []);

  useEffect(() => {
    getCommsHistory(userId, filter);
  }, [userId, filter, getCommsHistory]);

  return (
    <>
      <S.Container>
        <HistoryHeader filter={filter} onFilterChange={setFilter} />

        <S.Scroller>
          {loading ? (
            <ContentLoader text="Loading history, please wait..." />
          ) : null}
          {!loading &&
            communications.map(c => {
              switch (c.type) {
                case 'email':
                  return (
                    <EmailCard
                      key={c.id}
                      email={c}
                      userId={userId}
                      onRefresh={() => getCommsHistory(userId, filter)}
                    />
                  );
                case 'sms':
                  return <SmsCard key={c.id} sms={c} />;
                default:
                  return null;
              }
            })}
        </S.Scroller>
      </S.Container>
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </>
  );
};
