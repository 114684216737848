import { Button, Dialog, DialogContent } from '@material-ui/core';
import { useGetAccessCodeForUserMutation } from '../../../graphql/api';
import {
  StyledDialogTitle,
  StyledDialogActions,
} from '../../../components/ConfirmationModalV2/ConfirmationModalV2.styled';
import LoadingButton from '../../../components/LoadingButton/LoadingButton';
import { Formik } from 'formik';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import schema from './schema';
import { getConfig } from '../../../config';
import { ErrorMessage } from './LogInToJustWebsiteModal.styled';
import { ErrorCode, logError } from '../../../services/logging';

type Props = {
  onClose: () => void;
};

type FormValues = {
  email: string;
};

const logInToJustWebsite = (accessCode: string) => {
  const justWebsiteUrl = new URL(
    '/api/auth/verify',
    getConfig().REACT_APP_JUST_WEBSITE_URL,
  );
  justWebsiteUrl.searchParams.set('code', accessCode);
  window.open(justWebsiteUrl, '_blank');
};

const LogInToJustWebsiteModal: React.FC<Props> = ({ onClose }) => {
  const {
    mutate: getAccessCodeForUser,
    isLoading,
    error,
  } = useGetAccessCodeForUserMutation({
    onSuccess: ({ getAccessCodeForUser: accessCode }) => {
      logInToJustWebsite(accessCode);
      onClose();
    },
    onError: (e: Error) => logError(ErrorCode.LoginOnJustWebsite, e),
  });

  return (
    <Dialog open onClose={onClose} scroll="body" fullWidth maxWidth="xs">
      <Formik<FormValues>
        validateOnMount={false}
        validationSchema={schema}
        onSubmit={values =>
          getAccessCodeForUser({ input: { email: values.email } })
        }
        initialValues={{ email: '' }}
      >
        {({ handleSubmit }) => (
          <>
            <StyledDialogTitle>Log in to website as customer</StyledDialogTitle>
            <DialogContent>
              <FormTextInput
                name="email"
                editable
                label="Customer email"
                placeholder="Customer email"
              />
              {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
            </DialogContent>
            <StyledDialogActions disableSpacing>
              <LoadingButton onClick={handleSubmit} isLoading={isLoading}>
                Log in to website
              </LoadingButton>
              <Button onClick={onClose} variant="outlined" color="primary">
                Close
              </Button>
            </StyledDialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default LogInToJustWebsiteModal;
