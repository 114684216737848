import {
  Button,
  Icon,
  IconButton,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import AsyncImage from '../AsyncImage/AsyncImage';
import { CellTypes, Column, RowAction } from './Table';

interface Props {
  columns: Column[];
  rowActions?: RowAction[];
  item: any;
  rowIndex: number;
  onRowClick: () => void;
}

const DefaultRowTemplate: React.FC<Props> = ({
  columns,
  rowActions,
  item,
  rowIndex,
  onRowClick,
}) => {
  const textCell = React.useCallback(
    (column, cellItem, cellOnRowClick, cellRowIndex, index) => (
      <TableCell
        style={column.cellStyle}
        key={`${cellRowIndex}-${index}-${column.id}`}
        onClick={cellOnRowClick}
      >
        {column.rowFormatter
          ? column.rowFormatter(cellItem)
          : cellItem[column.id]}
      </TableCell>
    ),
    [],
  );

  const imageCell = React.useCallback(
    (column, cellItem, cellOnRowClick, cellRowIndex, index) => (
      <TableCell
        key={`${cellRowIndex}-${index}-${column.id}`}
        onClick={cellOnRowClick}
      >
        <AsyncImage column={column} row={cellItem} />
      </TableCell>
    ),
    [],
  );

  return (
    <TableRow hover={!!onRowClick}>
      {columns.map((column, index) =>
        column.type === CellTypes.Image
          ? imageCell(column, item, onRowClick, rowIndex, index)
          : textCell(column, item, onRowClick, rowIndex, index),
      )}
      {rowActions &&
        rowActions.map((action, idx: number) => (
          <TableCell key={`${idx}-${action.name}`}>
            {action.icon ? (
              <IconButton
                color="primary"
                onClick={() =>
                  action.onClick ? action.onClick(item, rowIndex) : null
                }
                disabled={action.isEnabled && !action.isEnabled(item)}
              >
                <Icon>{action.icon}</Icon>
              </IconButton>
            ) : null}
            {!action.icon && action.text ? (
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                size="large"
                disabled={action.isEnabled && !action.isEnabled(item)}
                onClick={() =>
                  action.onClick ? action.onClick(item, rowIndex) : null
                }
              >
                {action.text}
              </Button>
            ) : null}
          </TableCell>
        ))}
    </TableRow>
  );
};

export default DefaultRowTemplate;
