export function createRange(
  start: number,
  stop: number,
  includeStop: boolean = true,
): number[] {
  let result: number[] = [];

  const stopVal = stop + (includeStop ? 1 : 0);

  if (start >= stopVal) {
    return result;
  }

  let n = start;
  while (n < stopVal) {
    result.push(n);
    n += 1;
  }
  return result;
}
