import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Column } from '../../../../components/Table/Table';
import { Violation } from '../../../../graphql/types';
import {
  MenuItem,
  PopUpMenu,
} from '../../../../components/PopUpMenu/PopUpMenu';
import { Feature, isFeatureEnabled } from '../../../../config';
import { PopUpMenuContainer } from '../common/RowTemplate.styled';
import { menuOptions } from '../common/helpers';

interface Props {
  columns: Column[];
  item: Violation;
  onMenuClick: (menuItem: MenuItem, violation: Violation) => void;
}

const RowTemplate: React.FC<Props> = ({ item, onMenuClick }) => {
  const violationNameFromId = (id: number): string => {
    switch (id) {
      case 1:
        return 'Major';
      case 2:
        return 'Minor';
      default:
        return 'Unknown';
    }
  };

  return (
    <TableRow>
      <TableCell>{item.yearOccurred}</TableCell>
      <TableCell>{violationNameFromId(item.violationType)}</TableCell>
      <TableCell>
        {isFeatureEnabled(Feature.EditACV) ? (
          <PopUpMenuContainer>
            <PopUpMenu
              menuItems={menuOptions}
              onClick={menuItem => onMenuClick(menuItem, item)}
            />
          </PopUpMenuContainer>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default RowTemplate;
