import styled from 'styled-components';

export type ConfirmationType = 'save' | 'delete';

export const ModalContainer = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 20%;
  left: calc(50% - 200px);
  outline: none;
  padding: 30px;
  width: 500px;

  .MuiButton-contained {
    min-width: 80px;
  }
`;

export const Row = styled.div`
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  padding-top: 10px;
`;

export const Headline = styled.h2``;

export const ErrorText = styled.p`
  font-size: 14px;
  line-height: 22px;
`;

export const ErrorInfoText = styled.p`
  font-size: 12px;
  line-height: 18px;
`;

export const ErrorContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.primary.lightgrey};
  border-radius: 5px;
  padding: 0 10px;
  max-height: 300px;
  overflow: hidden;
`;
