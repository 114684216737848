import * as yup from 'yup';
import {
  RestrictUserReason,
  UnrestrictUserReason,
} from '../../../../../../graphql/api';

export const unrestrictSchema = yup.object().shape({
  reason: yup.string().required('Please select a reason'),
  notes: yup
    .string()
    .trim()
    .when('reason', {
      is: UnrestrictUserReason.Other,
      then: yup.string().trim().required('Note is required for "Other" reason'),
      otherwise: yup.string().trim().optional(),
    }),
});

export const restrictSchema = yup.object().shape({
  reason: yup.string().required('Please select a reason'),
  notes: yup
    .string()
    .trim()
    .when('reason', {
      is: RestrictUserReason.Other,
      then: yup.string().trim().required('Note is required for "Other" reason'),
      otherwise: yup.string().trim().optional(),
    }),
});
