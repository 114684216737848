import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { Container } from '@material-ui/core';
import {
  ActionButton,
  Subcontainer,
} from './FormSubmitButton/FormSubmitButton.styled';
import FormSubmitButton from './FormSubmitButton/FormSubmitButton';

interface Props {
  onToggleEdit: () => void;
  onCancelEdit: () => void;
  isEditing: boolean;
  label: string;
}

const EditPageDetailsForm: React.FC<Props> = ({
  onToggleEdit,
  onCancelEdit,
  isEditing,
  label,
}) => {
  return (
    <Container>
      <Subcontainer>
        <FormSubmitButton
          onClick={isSubmitting => {
            if (!isSubmitting) {
              onToggleEdit();
            }
          }}
          hasClickedEdit
          hasChanges={isEditing}
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
        >
          {`${isEditing ? 'Save' : 'Edit'} ${label}`}
        </FormSubmitButton>
        {isEditing ? (
          <ActionButton
            variant="contained"
            onClick={onCancelEdit}
            startIcon={<CancelIcon />}
          >
            Cancel
          </ActionButton>
        ) : null}
      </Subcontainer>
    </Container>
  );
};

export default EditPageDetailsForm;
