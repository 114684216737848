import {
  Organisation,
  OrganisationInput,
  OrganisationType,
  SalesPersonLevel,
} from '../../../graphql/api';

export const mapOrganisationTypeLabel = (organisationType: string) => {
  switch (organisationType) {
    case OrganisationType.CommunityCollege:
      return 'Community College';
    case OrganisationType.CommunityOrganisation:
      return 'Community Organisation';
    case OrganisationType.Dealership:
      return 'Dealership';
    case OrganisationType.University:
      return 'University';
    default:
      return organisationType;
  }
};

export const mapSalesPersonLevelLabel = (salesPersonLevel: string) => {
  switch (salesPersonLevel) {
    case SalesPersonLevel.Bronze:
      return 'Bronze';
    case SalesPersonLevel.Platinum:
      return 'Platinum';
    default:
      return salesPersonLevel;
  }
};

export const mapOrganisationToOrganisationInput = (
  organisation: Organisation,
): OrganisationInput => {
  return {
    name: organisation.name,
    type: organisation.type as OrganisationType,
    addressLine1: organisation.addressLine1,
    addressLine2: organisation.addressLine2,
    city: organisation.city,
    state: organisation.state,
    zipCode: organisation.zipCode,
    website: organisation.website,
    fein: organisation.fein,
    parentName: organisation.parentName,
  };
};
