import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import BalanceFinalisationModal from './BalanceFinalisationModal/BalanceFinalisationModal';
import {
  TransactionRequest,
  TransactionType,
  ActivityType,
} from '@just-insure/api';
import * as transactionService from '../../../../services/repository/transactionService';
import { ErrorCode, logError } from '../../../../services/logging';
import createIntercomNote from './util/createIntercomNote';

interface Props {
  userId: string;
}

const BalanceFinalisation: React.FC<Props> = ({ userId }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [isIntercomError, setIntercomError] = useState(false);

  const onConfirm = async (amount: number, note: string) => {
    const transaction: TransactionRequest = {
      reason: 'Balance finalization charge',
      type: TransactionType.debit,
      activityType: ActivityType.owed,
      amount,
    };

    try {
      setError(undefined);
      setLoading(true);
      await transactionService.createTransaction(userId, transaction);
      try {
        await createIntercomNote(userId, note);
      } catch (err) {
        setIntercomError(true);
        throw err;
      }
      setModalOpen(false);
    } catch (err: any) {
      logError(ErrorCode.UserTransactions, err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setIntercomError(false);
          setError(undefined);
          setModalOpen(true);
        }}
      >
        Apply final odometer charge
      </Button>
      {isModalOpen ? (
        <BalanceFinalisationModal
          userId={userId}
          isOpen
          isLoading={isLoading}
          onCancel={() => setModalOpen(false)}
          onConfirm={(amount, note) => onConfirm(amount, note)}
          error={error}
          isIntercomError={isIntercomError}
        />
      ) : null}
    </>
  );
};

export default BalanceFinalisation;
