import React, { useEffect, useState } from 'react';
import {
  useGetSalesPersonQuery,
  useUpdateSalesPersonMutation,
} from '../../../graphql/api';
import { withErrorBoundary } from '../../../components/withErrorBoundary';
import { RouteComponentProps } from 'react-router-dom';
import { GenericComponentError } from '../../../components/GenericComponentError';
import { Typography } from '@material-ui/core';
import { ErrorCode, logError } from '../../../services/logging';
import { Salesperson } from '../../../../types/interfaces';
import SalespersonDetailsForm from './SalespersonDetailsForm';
import {
  mapSalespersonToSalespersonInput,
  useGetSalespersonSelector,
} from './useGetSalespersonSelector';
import { Container, Loading } from './SalespersonDetails.styled';

interface MatchParams {
  salespersonId: string;
}

type Props = RouteComponentProps<MatchParams> & {};

const SalespersonDetails: React.FC<Props> = ({ match }) => {
  const [salesPerson, setSalesperson] = useState<Salesperson>();
  const { salespersonId } = match.params;

  const { isLoading, isError, error, data, refetch } = useGetSalesPersonQuery<
    Salesperson,
    Error
  >(
    { salesPersonId: salespersonId },
    {
      queryKey: ['salesPerson', { salesPersonId: salespersonId }],
      select: useGetSalespersonSelector,
    },
  );

  const updateSalespersonMutation = useUpdateSalesPersonMutation({
    onSuccess: () => refetch(),
    onError: (e: Error) => logError(ErrorCode.ReferralUpdateSalesperson, e),
  });

  const updateSalesperson = async (updatedSalesperson: Salesperson) => {
    updateSalespersonMutation.mutate({
      id: salespersonId.toString(),
      salesPersonInput: mapSalespersonToSalespersonInput(updatedSalesperson),
    });
  };

  useEffect(() => {
    salespersonId && setSalesperson(data);
  }, [salespersonId, data]);

  return (
    <>
      <Container>
        <Typography variant="h4">{`Salesperson - ID ${salespersonId}`}</Typography>

        {isLoading ? (
          <Loading text="Loading salesperson details, please wait..." />
        ) : null}
        {isError ? <GenericComponentError error={error!} /> : null}
        {updateSalespersonMutation.isError ? (
          <GenericComponentError error={updateSalespersonMutation.error!} />
        ) : null}

        {salesPerson && (
          <SalespersonDetailsForm
            salesPerson={salesPerson}
            onSubmit={updateSalesperson}
            onCreateCode={refetch}
          />
        )}
      </Container>
    </>
  );
};

export default withErrorBoundary(SalespersonDetails);
