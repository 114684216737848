import React from 'react';
import { UserDrivingReportStatus } from '../../../../graphql/api';
import {
  ActionButtonsColumn,
  ActionsContainer,
  ContentContainer,
  PageContainer,
} from './UserVerificationScreen.styled';
import ActionModal from './components/ActionModal/IdentityActionModal';
import UserVerificationTable from './components/UserVerificationTable';
import { CreditReport } from '../Controller/creditCheck/types';
import CreditScore from './components/CreditScore';
import DrivingHistory from './components/DrivingHistory';
import { DrivingReport } from '../Controller/drivingHistory/types';

type Props = {
  // User timezone props
  userTimezone: string;
  // Credit check props
  isCreditCheckAvailable: boolean;
  creditHistoryItems: CreditReport[];
  onRunCreditCheck: (note: string) => void;
  lastCreditCheck?: CreditReport;
  // Driving history props
  drivingHistoryStatus: UserDrivingReportStatus;
  drivingHistoryItems: DrivingReport[];
  onRunDrivingHistoryCheck: (note: string) => void;
};

type ModalData = {
  visible: boolean;
  action?: {
    inputLabel?: string;
    title: string;
    subtitle: string;
    onApprove: (reason: string) => void;
  };
};

const UserVerificationScreen: React.FC<Props> = ({
  userTimezone,
  isCreditCheckAvailable,
  onRunCreditCheck,
  creditHistoryItems,
  lastCreditCheck,
  drivingHistoryStatus,
  onRunDrivingHistoryCheck,
  drivingHistoryItems,
}) => {
  const [modalData, setModalData] = React.useState<ModalData>({
    visible: false,
  });

  function setModalVisible(action: ModalData['action']) {
    setModalData({
      visible: true,
      action,
    });
  }

  function onRunCreditCheckRequest() {
    setModalVisible({
      inputLabel: 'Reason',
      title: 'Re-run credit check',
      subtitle:
        'Please confirm you would like to run a new credit check report.',
      onApprove: async (reason: string) => {
        await onRunCreditCheck(reason);
        setModalData({ visible: false });
      },
    });
  }

  function onRunDrivingHistoryCheckRequest() {
    setModalVisible({
      inputLabel: 'Note',
      title: 'Re-run driving history check',
      subtitle:
        'Please confirm you would like to run a new driving check report.',
      onApprove: async (note: string) => {
        await onRunDrivingHistoryCheck(note);
        setModalData({ visible: false });
      },
    });
  }

  return (
    <PageContainer>
      <ContentContainer>
        <ContentContainer>
          <CreditScore.Tile
            isAvailable={isCreditCheckAvailable}
            status={lastCreditCheck?.status}
          />
          <DrivingHistory.Tile drivingHistoryStatus={drivingHistoryStatus} />
        </ContentContainer>
        <ActionsContainer>
          <ActionButtonsColumn>
            <CreditScore.RunCheckButton onClick={onRunCreditCheckRequest} />
            <DrivingHistory.ActionButton
              onClick={onRunDrivingHistoryCheckRequest}
              disabled={
                drivingHistoryStatus === UserDrivingReportStatus.NotEligible
              }
            />
          </ActionButtonsColumn>
        </ActionsContainer>
      </ContentContainer>
      <UserVerificationTable
        drivingHistoryItems={drivingHistoryItems}
        creditHistoryItems={creditHistoryItems}
        userTimezone={userTimezone}
      />

      <ActionModal
        isOpen={modalData.visible}
        approveAction={modalData.action}
        onClose={() => setModalData({ visible: false })}
      />
    </PageContainer>
  );
};

export default UserVerificationScreen;
