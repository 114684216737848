import { useQuery } from 'react-query';
import {
  TallyUpOdometer,
  getUserOdometer,
  sortOdometerToByNewestToOldest,
} from '../../../../../services/repository/odometerService';
import { logError, ErrorCode } from '../../../../../services/logging';
import { AxiosError } from 'axios';
import { OdometerStatus } from '@just-insure/api';

export const useGetDaysSinceOdometer = (userId: string) => {
  return useQuery<TallyUpOdometer[], Error | AxiosError, number | undefined>(
    ['user-odometers', { userId }],
    () => getUserOdometer(userId),
    {
      select: odometers => {
        const latestOdometerDate = [...odometers]
          .sort(sortOdometerToByNewestToOldest)
          .find(
            odometer => odometer.status === OdometerStatus.Accepted,
          )?.createdAt;
        return latestOdometerDate
          ? Math.ceil(
              Math.abs(latestOdometerDate.startOf('day').diffNow('days').days),
            )
          : undefined;
      },
      onError: e => logError(ErrorCode.FiftyMileCharge, e),
      useErrorBoundary: false,
    },
  );
};
