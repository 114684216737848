import styled from 'styled-components';

export const TableContainer = styled.div`
  && {
    .clickable {
      cursor: pointer;
      font-weight: 700;
      text-decoration-line: underline;
    }
  }
`;

export const PopUpMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
