import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import * as S from './ContentLoader.styled';

interface Props {
  text: string;
}

export const ContentLoader: React.FC<Props> = ({ text }) => {
  return (
    <S.LoadingContainer>
      <S.Content>
        <S.ProgressContainer>
          <CircularProgress color="primary" />
        </S.ProgressContainer>
        <S.Text>{text}</S.Text>
      </S.Content>
    </S.LoadingContainer>
  );
};
