import DrivingHistoryButton from './DrivingHistoryButton';
import DrivingHistoryList from './DrivingHistoryList';
import DrivingHistoryTile from './DrivingHistoryTile';

const DrivingHistory = {
  ActionButton: DrivingHistoryButton,
  List: DrivingHistoryList,
  Tile: DrivingHistoryTile,
};

export default DrivingHistory;
