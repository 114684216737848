import styled from 'styled-components';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

export const StyledDialogTitle = styled(DialogTitle)<{ centerTitle: boolean }>`
  && {
    padding: 20px 24px 0;

    h2 {
      font-size: ${({ theme }) => theme.font.size.l};
      text-align: ${props => (props.centerTitle ? 'center' : 'left')};
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 8px 24px 20px;
  }
`;
