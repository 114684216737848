import React from 'react';
import Modal from '@material-ui/core/Modal';
import usePolicyholderLocation from './usePolicyholderLocation';
import * as S from './LocationInfoModal.style';
import { UserPolicy } from '../../../../services/repository/policyService';
import LocationEvents from './components/LocationEvents';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  policy: UserPolicy;
  userId: number;
  onClose: () => void;
};

const PolicyholderLocationModal: React.FC<Props> = ({
  onClose,
  policy,
  userId,
}) => {
  const locationInfo = usePolicyholderLocation(`${userId}`, policy);
  return (
    <Modal open onClose={onClose}>
      <S.ModalContainer>
        <S.Title>Policy bind location</S.Title>
        <S.SubtitleContainer>
          <div>
            <S.Subtitle bold>userId</S.Subtitle>
            <S.Subtitle>{userId}</S.Subtitle>
          </div>
          <div>
            <S.Subtitle bold>Policy number</S.Subtitle>
            <S.Subtitle>{policy.policyNr}</S.Subtitle>
          </div>
          <div>
            <S.Subtitle bold>Policy start</S.Subtitle>
            <S.Subtitle>{policy.start}</S.Subtitle>
          </div>
        </S.SubtitleContainer>
        {(() => {
          switch (locationInfo.status) {
            case 'loading':
              return (
                <S.Progress>
                  <CircularProgress size={32} color="primary" />
                </S.Progress>
              );
            case 'fail':
              return <S.Error>{locationInfo.error}</S.Error>;
            case 'updating':
            case 'success':
              return (
                <LocationEvents
                  isUpdating={locationInfo.status === 'updating'}
                  timezone={policy.timezone}
                  events={locationInfo.data}
                />
              );
          }
        })()}
      </S.ModalContainer>
    </Modal>
  );
};

export default PolicyholderLocationModal;
