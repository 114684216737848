import { User } from '../../../../../types/interfaces';

export const userListColumnDefs = [
  {
    title: 'User Id',
    id: 'id',
    sortable: false,
  },
  {
    title: 'Name',
    id: 'name',
    rowFormatter: (user: User) => `${user.firstName} ${user.lastName}`,
  },
  {
    title: 'Email Address',
    id: 'email',
  },
  {
    title: 'Phone Number',
    id: 'phoneNumber',
  },
  {
    title: 'Address',
    id: 'address',
    rowFormatter: (user: User) =>
      `${user.address1}, ${user.address2 ? `${user.address2}, ` : ''}${
        user.city
      }, ${user.state}, ${user.zipCode}`,
  },
];
