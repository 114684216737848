import Tab from '@material-ui/core/Tab';
import React from 'react';

export enum UserVerificationTabId {
  CREDIT_SCORE = 'creditScore',
  DRIVING_HISTORY = 'drivingHistory',
}

interface TabItem {
  id: UserVerificationTabId;
  content: React.ReactElement;
}

export const USER_VERIFICATION_TABS: TabItem[] = [
  {
    id: UserVerificationTabId.CREDIT_SCORE,
    content: <Tab key="creditScore" label="Credit Score" />,
  },
  {
    id: UserVerificationTabId.DRIVING_HISTORY,
    content: <Tab key="drivingHistory" label="Driving History" />,
  },
];

export const DEFAULT_TAB = USER_VERIFICATION_TABS.findIndex(
  item => item.id === UserVerificationTabId.CREDIT_SCORE,
);
