import styled from 'styled-components';
import { Loader } from '../../../../components/Loader';
import { Container } from '@material-ui/core';

export const Loading = styled(Loader)`
  padding: 20px;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ReferralCodeContainer = styled.div`
  position: relative;
`;

export const CopyToClipboardContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 0;
`;

export const CreateReferralCodeContainer = styled(Container)`
  button {
    width: 220px;
  }
`;
