import React from 'react';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import {
  useCreateEndorsementQuoteMutation,
  CreateEndorsementQuoteInput,
} from '../../../graphql/api';
import { ErrorCode, logError } from '../../../services/logging';

interface Props {
  userId: string;
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
}

const CreateEndorsementQuoteModal: React.FC<Props> = ({
  userId,
  isOpen,
  onSave,
  onClose,
}) => {
  const { isLoading, error, mutate, reset } = useCreateEndorsementQuoteMutation(
    {
      onSuccess: onSave,
      onError: (e: Error) => logError(ErrorCode.CreateEndorsementQuote, e),
    },
  );

  const onSubmit = () => {
    const input: CreateEndorsementQuoteInput = { userId };
    mutate({ input });
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      isLoading={isLoading}
      confirmButtonText="Confirm"
      title="Generate a new quote"
      text="We will generate a new quote based on the latest account details, vehicle and selected coverage. This update will not affect the premium of an active policy."
      type="save"
      errorMessage={error?.message}
      onConfirm={onSubmit}
      onCancel={() => {
        reset();
        onClose();
      }}
    />
  );
};

export default CreateEndorsementQuoteModal;
