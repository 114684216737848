import React from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import LoadingButton from '../LoadingButton/LoadingButton';
import {
  StyledDialogTitle,
  StyledDialogActions,
} from './ConfirmationModalV2.styled';
import ModalError from '../ModalError/ModalError';

interface Props {
  title: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  hideContent?: boolean;
  hideSubmit?: boolean;
  isLoading?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonDisabled?: boolean;
  error?: string;
}

const ConfirmationModalV2: React.FC<Props> = ({
  title,
  isOpen,
  onConfirm,
  onCancel,
  hideContent = false,
  hideSubmit = false,
  isLoading = false,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Abort',
  confirmButtonDisabled = false,
  error,
  children,
}) => {
  return (
    <Dialog open={isOpen} scroll="body" fullWidth maxWidth="xs">
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <DialogContent>
        {!hideContent ? children : null}
        {error ? <ModalError error={error} /> : null}
      </DialogContent>
      <StyledDialogActions disableSpacing>
        {!hideSubmit ? (
          <LoadingButton
            onClick={onConfirm}
            isLoading={isLoading}
            buttonType="confirm"
            disabled={confirmButtonDisabled}
          >
            {confirmButtonText}
          </LoadingButton>
        ) : null}
        <Button onClick={onCancel} variant="outlined" color="primary">
          {cancelButtonText}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default ConfirmationModalV2;
