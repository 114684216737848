import { getConfig } from '../../../../../config';
import { getIdTokenSilently } from '../../../../../services/auth';
import { CalculationData } from '../types';

const createIntercomNote = async (userId: string, note: string) => {
  const endpoint = getConfig().REACT_APP_CREATE_INTERCOM_NOTE_URL;
  const jwksUri = getConfig().REACT_APP_JWKS_URL;
  const token = await getIdTokenSilently();

  try {
    const res = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        note,
        jwksUri,
      }),
    });

    const json = await res.json();

    if (json.error) {
      const message = `Error creating Intercom note: ${json.error}`;
      throw new Error(message);
    }
  } catch (err) {
    throw err;
  }
};

export const formatCalculation = (data: CalculationData): string => {
  // Intercom notes use markdown
  return `
  ### Balance finalization charge: Policy ${data.policyNumber}
  Date of Cancellation/Expiration: ${data.policyTerminationDate}
  Date of latest odometer photo: ${data.latestOdometerDate}
  Time duration: ${data.fiftyMilePerDayPeriod}
  ***
  50 mile per-day period: ${data.fiftyMilePerDayPeriodMiles}
  50 mile per-day already charged: ${data.fiftyMileAlreadyChargedMiles}
  Total 50 mile per-day charge: ${data.totalFiftyMileChargeInMiles}
  ***
  Tracked trips: ${data.trackedTripMiles}
  Disputed trips: ${data.disputedTripMiles}
  Total tracked trips: ${data.totalTrackedTripMiles}
  ***
  Total mileage charge: ${data.totalChargeableMiles}
  Price per-mile: ${data.pricePerMile}
  Balance finalization charge: ${data.balanceFinalisationCharge}
  `;
};

export default createIntercomNote;
