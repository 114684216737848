import styled from 'styled-components';

export const LoadingContainer = styled.div`
  align-items: center;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  top: 30%;
  left: calc(50% - 150px);
  min-height: 200px;
  padding: 30px 30px 15px 30px;
  width: 350px;
`;

export const ProgressContainer = styled.div`
  padding-bottom: 15px;
`;

export const Text = styled.div`
  font-size: 14px;
  text-align: center;
`;
