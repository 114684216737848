import React from 'react';
import { PolicyholderLocationEvent } from '../types';
import * as S from './Common.style';
import CircularProgress from '@material-ui/core/CircularProgress';
import utils from '../utils';
import EventDetail from './EventDetail';
import RestOfEventDetailList from './EventDetailList';

type Props = {
  events: PolicyholderLocationEvent[];
  timezone: string;
  isUpdating: boolean;
};

const LocationEventsContainer: React.FC<Props> = ({
  events,
  timezone,
  isUpdating,
}) => {
  const { main: mainEvent, rest } = utils.sortEventsIntoMainAndRest(events);
  return (
    <div>
      {isUpdating ? (
        <S.Progress>
          <CircularProgress size={16} color="primary" />
        </S.Progress>
      ) : null}
      {mainEvent ? (
        <>
          <S.Divider spaceBottom={24} />
          <S.SubTitle>Location Details</S.SubTitle>
          <EventDetail event={mainEvent} timezone={timezone} />
        </>
      ) : null}
      <RestOfEventDetailList events={rest} timezone={timezone} />
    </div>
  );
};

export default LocationEventsContainer;
