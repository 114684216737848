import React from 'react';
import { IframeContainer, Iframe, Icon } from './Productboard.styled';
import ProductboardImage from './productboard.svg';

export const ProductboardIcon = () => (
  <Icon src={ProductboardImage} alt="Productboard" />
);

const Productboard: React.FC = () => {
  return (
    <IframeContainer>
      <Iframe
        src="https://portal.productboard.com/prnzt97w8bvds2hx773i9xfr?hide_header=1"
        frameBorder="0"
      />
    </IframeContainer>
  );
};

export default Productboard;
