import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const ModalContent = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 20%;
  left: calc(50% - 200px);
  outline: none;
  padding: 10px 30px;
  width: 500px;
`;

export const Title = styled.h1`
  text-align: center;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.palette.primary.bluePurple};
`;

export const Body = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin: 8px !important;
`;
