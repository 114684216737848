import { Accident, Claim, Violation } from '../../../../graphql/api';
import { MenuItem } from '../../../../components/PopUpMenu/PopUpMenu';
import { MenuOption } from './types';

export const sortByYear = (array: Accident[] | Claim[] | Violation[]) =>
  [...array].sort((a, b) => (a.yearOccurred < b.yearOccurred ? 1 : -1));

export const menuOptions: MenuItem[] = [
  {
    label: 'Edit',
    type: MenuOption.Edit,
  },
  {
    label: 'Remove',
    type: MenuOption.Delete,
  },
];

export function addItemToArray<T>(arrayOfItems: T[], itemToAdd: T): T[] {
  return [...arrayOfItems, itemToAdd];
}

export function editItemInArray<T>(
  arrayOfItems: T[],
  itemToAdd: T,
  index: number,
): T[] {
  if (index < 0) {
    return arrayOfItems;
  }

  const updatedItems = [...arrayOfItems];
  updatedItems[index] = itemToAdd;

  return updatedItems;
}

export function removeItemFromArray<T>(arrayOfItems: T[], index: number): T[] {
  if (index < 0) {
    return arrayOfItems;
  }

  const updatedItems = [...arrayOfItems];
  updatedItems.splice(index, 1);

  return updatedItems;
}
