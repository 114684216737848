import { PolicyholderLocationEvent } from '../types';
import React from 'react';
import EventDetail from './EventDetail';
import * as S from './Common.style';

type MoreEventsProps = {
  events: PolicyholderLocationEvent[];
  timezone: string;
};
const MoreEvents: React.FC<MoreEventsProps> = ({ events, timezone }) => {
  const [showMore, setShowMore] = React.useState(false);

  if (!events.length) {
    return null;
  }

  return (
    <>
      <S.Divider spaceTop={12} spaceBottom={12} />
      <S.SubContainer>
        <S.SubTitle>Other Location Results</S.SubTitle>
        <S.MoreDetailsButton onClick={() => setShowMore(s => !s)}>
          {showMore ? 'Show less' : 'Show more'}
        </S.MoreDetailsButton>
      </S.SubContainer>
      {showMore
        ? events.map((event, index) => (
            <React.Fragment key={event.eventTimestamp}>
              <EventDetail event={event} timezone={timezone} />
              {index === events.length - 1 ? null : (
                <S.Divider spaceTop={12} spaceBottom={12} />
              )}
            </React.Fragment>
          ))
        : null}
    </>
  );
};

export default MoreEvents;
