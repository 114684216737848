import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: -80px;
  position: relative;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin-bottom: 20px;
    }
  }
`;

export const Logo = styled.img`
  position: fixed;
  bottom: 25px;
  width: 84px;
  height: 113px;
`;

const style: any = (theme: any) => ({
  buttonRoot: {
    alignSelf: 'center',
    backgroundColor: theme.palette.primary.bluePurple,
    marginTop: 30,
    padding: '15px 0',
    width: '80%',
  },
});

export default style;
