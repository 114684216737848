import React from 'react';
import FormModal from '../../../../components/FormModal/FormModal';
import schema from './schema';
import FormSelect from '../../../../components/FormSelect/FormSelect';
import { DateTime } from 'luxon';
import { createRange } from '../../../../helpers/arrayHelpers';
import { Claim } from '../../../../graphql/api';

interface Props {
  activeClaim?: Claim;
  isOpen: boolean;
  isLoading: boolean;
  onSave: (claim: Claim) => void;
  onClose: () => void;
  error?: Error;
}

const ClaimModal: React.FC<Props> = ({
  activeClaim,
  isOpen,
  isLoading,
  onSave,
  onClose,
  error,
}) => {
  const onSubmit = async (claim: Claim) => {
    onSave(claim);
  };

  const initialValues = activeClaim || {
    amountOfLoss: '',
    yearOccurred: '',
  };

  const thisYear = DateTime.local().year;
  const yearRange = createRange(thisYear - 4, thisYear).reverse();

  return (
    <FormModal
      isOpen={isOpen}
      isLoading={isLoading}
      isError={!!error}
      error={error}
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={schema}
      title="Add a claim"
    >
      <FormSelect
        name="yearOccurred"
        options={yearRange.map(year => ({
          label: year.toString(),
          value: year,
        }))}
        editable
        label="Year occurred"
      />
      <FormSelect
        name="amountOfLoss"
        options={[
          {
            label: '$0',
            value: 0,
          },
          {
            label: '$500 and below',
            value: 500,
          },
          {
            label: 'Above $500',
            value: 501,
          },
          {
            label: "I don't know",
            value: 1001,
          },
        ]}
        editable
        label="Amount of loss"
      />
    </FormModal>
  );
};

export default ClaimModal;
