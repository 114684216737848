export const columnDefs = [
  {
    id: 'yearOccurred',
    title: 'Year Occurred',
    sortable: false,
  },
  {
    id: 'violationType',
    title: 'Violation Type',
    sortable: false,
  },
  {
    id: 'rowOptions',
    title: '',
    sortable: false,
  },
];
