import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const ActionButton = styled(Button)`
  && {
    width: 200px;
    margin-bottom: 1rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
