import numeral from 'numeral';
import React, { useEffect } from 'react';
import { Redirect, Route, HashRouter, Switch } from 'react-router-dom';
import Portal from './features/portal/Portal/Portal';
import { QueryClient } from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { Duration } from 'luxon';
import { useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { setGetIdTokenSilently } from './services/auth';
import { createBrowserHistory } from 'history';
import Authentication from './features/authentication/components/Authentication/Authentication';
import { withErrorBoundary } from './components/withErrorBoundary';
import { GenericComponentError } from './components/GenericComponentError';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import styled from 'styled-components';
import { PageLoader } from './components/PageLoader';
import { Button } from '@material-ui/core';
import { getCurrentEnv, Environment } from './config';
import { setSentryUser } from './services/logging';
import { default as PortalV2 } from './v2/Portal';

const Container = styled.div`
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const history = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Duration.fromObject({ day: 1 }).as('seconds'),
      useErrorBoundary: true,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

// register the locale
numeral.register('locale', 'us', {
  abbreviations: {
    billion: 'b',
    million: 'm',
    thousand: 'k',
    trillion: 't',
  },
  currency: {
    symbol: '$',
  },
  delimiters: {
    decimal: '.',
    thousands: ',',
  },
} as any);

numeral.locale('us');

const App: React.FC = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    getIdTokenClaims,
    getAccessTokenSilently,
    loginWithPopup,
  } = useAuth0();
  setGetIdTokenSilently(getIdTokenClaims, getAccessTokenSilently);
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    persistQueryClient({
      queryClient,
      persistor: localStoragePersistor,
    })
      .then(() => setIsReady(true))
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  });

  useEffect(() => {
    const isStaging = getCurrentEnv() === Environment.Staging;

    if (isStaging) {
      const link = document.getElementById('favicon') as HTMLLinkElement;
      if (link) {
        link.href = link.href.replace(
          `/${Environment.Production}/`,
          `/${Environment.Staging}/`,
        );
      }
    }
  }, []);

  useEffect(() => {
    setSentryUser(user?.email);
  }, [user]);

  if (isLoading || !isReady) {
    return <PageLoader text="Loading Radr" />;
  }

  if (error) {
    return (
      <Container>
        <GenericComponentError error={error} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => loginWithPopup()}
        >
          Retry Login
        </Button>
      </Container>
    );
  }

  if (!isAuthenticated) {
    return <Authentication />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <Switch>
          <Route path="/v2/" component={withAuthenticationRequired(PortalV2)} />
          <Route path="/" component={withAuthenticationRequired(Portal)} />
          <Redirect to="/" />
        </Switch>
      </HashRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default withErrorBoundary(App);
