import styled from 'styled-components';

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.palette.primary.red};
`;

export const ErrorLink = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`;
