import { taskMiddleware } from 'react-palm/tasks';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from '../redux/epics';
import createReducer from '../redux/reducers/rootReducers';
import { RootAction, RootState } from '../redux/types';

export const configureStore = () => {
  const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    any
  >();
  const middleware: any[] = [epicMiddleware, taskMiddleware];

  middleware.push(createLogger({}));

  const store = createStore(
    createReducer,
    undefined,
    composeWithDevTools(applyMiddleware(...middleware)),
  );

  epicMiddleware.run(rootEpic);
  return store;
};
