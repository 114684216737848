import { SavedVehicle, Vehicle } from '@just-insure/api';
import { Button, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Column } from '../../../../components/Table/Table';
import {
  Row,
  PrimaryVehicleButton,
  ButtonContainer,
  IconContainer,
} from './rowTemplate.style';
import FileDownload from '@material-ui/icons/SystemUpdateAlt';

interface Props {
  columns: Column[];
  item: SavedVehicle;
  onMakePrimary: (vehicleId: number) => void;
  onDeleteVehicle: () => void;
  onLienHolderPress: (lienHolder: Vehicle) => void;
  onImageDownloadPress: (vin: string) => void;
  onShowPhotos: (vin: string) => void;
}

const ViewRowTemplate: React.FC<Props> = ({
  item,
  onMakePrimary,
  onDeleteVehicle,
  onLienHolderPress,
  onImageDownloadPress,
  onShowPhotos,
}) => (
  <TableRow className="vehicle-inactive">
    <TableCell>{item.vin}</TableCell>
    <TableCell>{item.make ?? 'N/A'}</TableCell>
    <TableCell>{item.model ?? 'N/A'}</TableCell>
    <TableCell>{item.year}</TableCell>
    <TableCell>{item.lienholder?.name}</TableCell>
    <TableCell>
      {!item.isDefault ? (
        <PrimaryVehicleButton
          variant="outlined"
          color="primary"
          onClick={() => onMakePrimary(item.id)}
        >
          Make Primary
        </PrimaryVehicleButton>
      ) : (
        'Yes'
      )}
    </TableCell>
    <TableCell>
      <ButtonContainer>
        {!item.isDefault ? (
          <Button variant="outlined" color="primary" onClick={onDeleteVehicle}>
            Delete Vehicle
          </Button>
        ) : null}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onLienHolderPress(item)}
        >
          Edit Vehicle
        </Button>
      </ButtonContainer>
    </TableCell>
    <TableCell>
      <Row>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            onShowPhotos(item.vin);
          }}
        >
          Show photos
        </Button>
        <IconContainer onClick={() => onImageDownloadPress(item.vin)}>
          <FileDownload color={'primary'} fontSize="large" />
        </IconContainer>
      </Row>
    </TableCell>
  </TableRow>
);

export default ViewRowTemplate;
