import { TallyUp, TallyUpOdometer, FormDataValues } from './types';

export const sortTallyUpDateToByNewestToOldest = (a: TallyUp, b: TallyUp) => {
  return a.endOdometer.createdAt.toSeconds() >
    b.endOdometer.createdAt.toSeconds()
    ? -1
    : 1;
};

export const sortTallyUpDateToByOldestToNewest = (a: TallyUp, b: TallyUp) => {
  return a.endOdometer.createdAt.toSeconds() >
    b.endOdometer.createdAt.toSeconds()
    ? 1
    : -1;
};

export const sortOdometerToByNewestToOldest = (
  a: TallyUpOdometer,
  b: TallyUpOdometer,
) => {
  return a.createdAt.toSeconds() > b.createdAt.toSeconds() ? -1 : 1;
};

export function getFormData<T extends FormDataValues>(formValues: T): FormData {
  const formData = new FormData();
  Object.keys(formValues).forEach(key => formData.append(key, formValues[key]));
  return formData;
}
