import React from 'react';
import { TileShell } from '../shared/TileShell';
import BetaFlagsContainer from './BetaFlagsContainer';
import { Props } from '../types';

export const BetaFlags: React.FC<Props> = ({ userId, className }) => (
  <TileShell title="Beta Flags" className={className}>
    <BetaFlagsContainer userId={userId} />
  </TileShell>
);
