import React from 'react';
import { Modal } from '@material-ui/core';
import * as S from './DuplicateModal.styled';
import { AssociatedUser } from '../../Controller/types';
import { SLink, Value } from './DuplicateModal.styled';

interface Props {
  isOpen: boolean;
  hideModal: () => void;
  duplicateAccounts: AssociatedUser[];
}

const DuplicateModal: React.FC<Props> = ({
  isOpen,
  hideModal,
  duplicateAccounts,
}) => {
  return (
    <Modal onClose={hideModal} open={isOpen}>
      <S.ModalContent>
        <S.Title>Same vehicle</S.Title>
        <S.Body>
          These are the identified user accounts associated with the same
          vehicle.
        </S.Body>
        <S.ListContainer>
          <S.ListHeader>
            <S.Column>
              <S.ColumnLabel>User ID</S.ColumnLabel>
            </S.Column>
            <S.Column width={4}>
              <S.ColumnLabel>Name</S.ColumnLabel>
            </S.Column>
            <S.Column width={2}>
              <S.ColumnLabel>Account State</S.ColumnLabel>
            </S.Column>
          </S.ListHeader>
          {duplicateAccounts.map(user => (
            <SLink key={user.id} to={`/users/${user.id}`} target="_blank">
              <S.Column>
                <Value>{user.id}</Value>
              </S.Column>
              <S.Column width={4}>
                <Value>{user.name}</Value>
              </S.Column>
              <S.Column width={2}>
                <Value>{user.accountState}</Value>
              </S.Column>
            </SLink>
          ))}
        </S.ListContainer>

        <S.ActionButton onClick={hideModal}>Close</S.ActionButton>
      </S.ModalContent>
    </Modal>
  );
};

export default DuplicateModal;
