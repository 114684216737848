import { useState, useEffect } from 'react';
import { getUserTrips } from './util/getUserTrips';
import { UserTrips } from './types';

const useGetUserTrips = (userId: string) => {
  const [data, setData] = useState<UserTrips>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);
  const isError = !!error;

  const updateUserTrips = async () => {
    setData(undefined);
    setError(undefined);
    setIsLoading(true);

    try {
      const userTrips = await getUserTrips(userId);
      setData(userTrips);
    } catch (ex: any) {
      setError(ex);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateUserTrips();
  }, [userId]);

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

export default useGetUserTrips;
