import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export type ConfirmationType = 'save' | 'deleting' | 'canceling';

export const SaveModal = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 30%;
  left: calc(50% - 200px);
  outline: none;
  padding: 30px;
  width: 500px;

  p {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .row {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    display: flex;
    padding-top: 10px;
  }

  .MuiButton-contained {
    min-width: 80px;
  }
  .MuiButton-containedPrimary {
    margin-left: 20px;
  }
`;

interface ButtonProps {
  buttonType: ConfirmationType;
}

export const StyledButton = styled(Button)<ButtonProps>`
  ${({ buttonType, theme }) => {
    if (buttonType !== 'save') {
      return `
        background-color: ${theme.palette.primary.red} !important;
      `;
    }
  }}
`;
