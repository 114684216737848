import { RouteComponentProps, withRouter } from 'react-router-dom';
import Layout from '../../components/Layout';
import { useGetUserAccountStateQuery } from '../../../graphql/api';
import getAccountStateSelector from './selectors/getAccountStateSelector';
import { ErrorCode, logError } from '../../../services/logging';
import PageLoading from '../../components/PageLoading';
import RegistrationUserPage from './pages/RegistrationUserPage';
import ActiveUserPage from './pages/ActiveUserPage';
import UserErrorPage from './pages/UserErrorPage';

type Props = RouteComponentProps<{ userId: string }>;

const UserPage: React.FC<Props> = ({ match }) => {
  const { userId } = match.params;
  const userV1Path = `/users/${userId}`;

  const {
    isLoading,
    error,
    data: userAccountState,
  } = useGetUserAccountStateQuery(
    { userId },
    {
      select: getAccountStateSelector,
      queryKey: ['userAccountState', { userId }],
      onError: (e: Error) => logError(ErrorCode.UserV2, e),
    },
  );

  return (
    <Layout radrV1Path={userV1Path}>
      {(() => {
        switch (true) {
          case isLoading:
            return <PageLoading />;
          case !!error:
            return <UserErrorPage error={error!.message} />;
          case !userAccountState:
            return <UserErrorPage error="No user account state" />;
          case userAccountState === 'Registration':
            return <RegistrationUserPage userId={userId} />;
          default:
            return <ActiveUserPage userId={userId} />;
        }
      })()}
    </Layout>
  );
};

export default withRouter(UserPage);
