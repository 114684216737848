/* eslint-disable no-console */
import {
  OdometerRestDto,
  OdometerStatus,
  TallyUpDto,
  TallyUpsPaginatedDto,
  TallyUpStatus,
} from '@just-insure/api';
import { api, getImage } from '../../httpService';
import { TallyUp, OdometerUploadPayload } from './types';
import { mapOdometerToLegacy, mapTallyUpToLegacy } from './mappers';
import queryString from 'query-string';
import { getConfig } from '../../../config';
import { getFormData } from './utils';

export * from './types';
export * from './mappers';
export * from './utils';

type TallyupsRequestOptions = {
  status?: TallyUpStatus;
  userId?: string;
  since?: string;
};

// this type is not included in API library
export type OdometerPaginatedDto = {
  pages: {
    total: number;
    size: number;
    first: number;
    previous?: number;
    current: number;
    next?: number;
    last?: number;
  };
  results: {
    count: number;
    data: Array<{ odometer: OdometerRestDto }>;
  };
};

const SINCE_ISO_DATE = '2019-01-01T00:00:00.000Z';

export const getUserOdometer = async (userId: string) => {
  try {
    const params = queryString.stringify({
      userId,
      since: SINCE_ISO_DATE,
    });

    const result = await api<OdometerPaginatedDto>(
      'get',
      `/odometers?${params}`,
      undefined,
      {
        baseUrl: getConfig().REACT_APP_ODOMETER_URL,
      },
    );

    const { results } = result;

    return results.data.map(o => mapOdometerToLegacy(o.odometer, userId));
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getTallyups = async (
  options?: TallyupsRequestOptions,
): Promise<TallyUp[]> => {
  try {
    const { status, userId, since = SINCE_ISO_DATE } = options || {};
    const params = queryString.stringify({
      status,
      userId,
      since,
    });

    const result = await api<TallyUpsPaginatedDto>(
      'get',
      `/tally-ups?${params}`,
      undefined,
      {
        baseUrl: getConfig().REACT_APP_ODOMETER_URL,
      },
    );

    const { results } = result;

    return results.data.map(mapTallyUpToLegacy);
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getTallyUp = async (id: string) => {
  try {
    const tally = await api<TallyUpDto>('get', `/tally-ups/${id}`, undefined, {
      baseUrl: getConfig().REACT_APP_ODOMETER_URL,
    });

    return mapTallyUpToLegacy(tally);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const voidTallyUp = async (tallyUpId: string) =>
  await api(
    'post',
    `/tally-ups/${tallyUpId}/status/processed/bills/void`,
    undefined,
    {
      baseUrl: getConfig().REACT_APP_ODOMETER_URL,
    },
  );

export const getOdometerImageById = async (odometerId: number) =>
  getImage(
    `/odometers/${odometerId}/image`,
    getConfig().REACT_APP_ODOMETER_URL,
  );

export const patchOdometer = async (
  odometerId: string,
  value: number | null,
  status: OdometerStatus,
) => {
  const payload: Record<string, any> = {
    id: Number(odometerId),
    status,
  };

  if (value !== null) {
    payload.reading = value;
  }

  await api('patch', `/odometers/${odometerId}`, payload, {
    baseUrl: getConfig().REACT_APP_ODOMETER_URL,
  });
};

export const uploadOdometer = async (
  userId: string,
  payload: OdometerUploadPayload,
  idempotencyKey: string,
) => {
  await api(
    'post',
    `/users/${userId}/odometers`,
    getFormData<OdometerUploadPayload>(payload),
    {
      baseUrl: getConfig().REACT_APP_ODOMETER_URL,
      idempotencyKey,
    },
  );
};

export const acceptTallyUp = async (tallyUpId: string) => {
  await api('post', `/tally-ups/${tallyUpId}/status/processed`, undefined, {
    baseUrl: getConfig().REACT_APP_ODOMETER_URL,
  });
};

export const rejectTallyUp = async (tallyUpId: string) => {
  await api('post', `/tally-ups/${tallyUpId}/status/rejected`, undefined, {
    baseUrl: getConfig().REACT_APP_ODOMETER_URL,
  });
};

export const revertTallyUp = async (tallyUpId: string) => {
  await api('post', `/tally-ups/${tallyUpId}/status/pending`, undefined, {
    baseUrl: getConfig().REACT_APP_ODOMETER_URL,
  });
};
