import React from 'react';
import { Modal } from '@material-ui/core';
import * as S from './IdentityActionModal.styled';
import TextField from '@material-ui/core/TextField';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  approveAction?: {
    inputLabel?: string;
    title: string;
    subtitle: string;
    onApprove: (reason: string) => void;
  };
}

const IdentityActionModal: React.FC<Props> = ({
  isOpen,
  approveAction,
  onClose,
}) => {
  const {
    onApprove = onClose,
    title = '',
    subtitle = '',
    inputLabel = '',
  } = approveAction || {};
  const [reason, setReason] = React.useState<string | undefined>(undefined);

  function onCloseInternal() {
    setReason(undefined);
    onClose();
  }

  function onApproveInternal() {
    setReason(undefined);
    onApprove(reason!);
  }

  return (
    <Modal open={isOpen} onClose={onCloseInternal}>
      <S.ModalContent>
        <S.Title>{title}</S.Title>
        <S.Body>{subtitle}</S.Body>
        <TextField
          label={inputLabel}
          fullWidth
          margin="normal"
          variant="filled"
          error={reason === '' ? true : undefined}
          helperText={reason === '' ? 'Cannot be empty' : undefined}
          onChange={e => {
            const value = e.target.value ?? '';
            setReason(value);
          }}
          value={reason}
        />
        <S.Row>
          <S.StyledButton variant="contained" onClick={onCloseInternal}>
            Abort
          </S.StyledButton>
          <S.StyledButton
            disabled={!reason}
            variant={'contained'}
            color={'primary'}
            onClick={() => onApproveInternal()}
          >
            Approve
          </S.StyledButton>
        </S.Row>
      </S.ModalContent>
    </Modal>
  );
};

export default IdentityActionModal;
