import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const useIdempotencyKey = () => {
  const [key, setKey] = React.useState(uuidv4());

  function resetKey() {
    setKey(uuidv4());
  }

  return {
    idempotencyKey: key,
    resetIdempotencyKey: resetKey,
  };
};

export default useIdempotencyKey;
