import { addDataToMap, loadMapStyles } from 'kepler.gl/actions';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, flatMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  TripData,
  TripDataResponse,
  TripLocationEvent,
} from '../../../types/interfaces.js';
import defaultLayerConfig from '../../config/defaultLayerConfig.json';
import { getTripData } from '../../services/repository/tripService';
import { onFetchTripData } from '../actions/tripActions';
import { RootAction, RootState } from '../types';
import {
  buildAccelerationDataSet,
  buildLocationDataSet,
  buildTripGeoLocationDataSet,
  buildTripInfoGeoLocationDataSet,
} from './mappings/keplerDataMapper';

interface KeplerPayload {
  config: any;
  datasets: any[];
}

const getTripV1Data = async (tripId: string): Promise<KeplerPayload> => {
  const trip = await getTripData<TripData>(tripId, '1');

  const payload: KeplerPayload = {
    config: defaultLayerConfig,
    datasets: [
      buildTripGeoLocationDataSet(trip),
      trip.locationDataPoints
        ? buildLocationDataSet(trip.locationDataPoints)
        : undefined,
      trip.accelerationDataPoints
        ? buildAccelerationDataSet(trip.accelerationDataPoints)
        : undefined,
    ].filter(d => d),
  };

  return payload;
};

const getTripV2Data = async (tripId: string): Promise<KeplerPayload> => {
  const trip = await getTripData<TripDataResponse>(tripId, '2');

  const locations = trip.data.events.filter(
    e => e.type === 'location',
  ) as TripLocationEvent[];

  const payload: KeplerPayload = {
    config: defaultLayerConfig,
    datasets: [buildTripInfoGeoLocationDataSet(locations)],
  };

  return payload;
};

export const fetchDataTripForMapping: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(onFetchTripData.request)),
    switchMap(({ payload }) =>
      from(
        payload.version === '1'
          ? getTripV1Data(payload.tripId)
          : getTripV2Data(payload.tripId),
      ).pipe(
        flatMap(mapPayload => {
          const mapStyle = {
            customStyle: {
              icon: '',
              id: 'streetView',
              label: 'Street View',
              style:
                'mapbox://styles/davidsfinsuretech/cjwzf4pqb20741crum21s8du7',
            },
          };

          return [
            addDataToMap(mapPayload),
            loadMapStyles(mapStyle),
            onFetchTripData.success(),
          ];
        }),
        catchError(err => {
          return of(onFetchTripData.failure(err));
        }),
      ),
    ),
  );

const epics = [fetchDataTripForMapping];

export default epics;
