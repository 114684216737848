import { GetUserPoliciesQuery } from '../../../../graphql/api';
import { UserPolicy } from './types';
import { DateTime, Interval } from 'luxon';
import { sortPoliciesByDate } from '../../../../helpers/policyHelpers';

export const useGetUserPoliciesSelector = ({
  user,
}: GetUserPoliciesQuery): UserPolicy | undefined => {
  const policies = sortPoliciesByDate(user.policies);

  const latestPolicy = policies[0];

  if (!latestPolicy) {
    return undefined;
  }

  const { start, end } = Interval.fromISO(latestPolicy.term, { setZone: true });

  return {
    policyNumber: latestPolicy.policyNumberDetails.value,
    cancellationDate: latestPolicy.cancellationDate
      ? DateTime.fromISO(latestPolicy.cancellationDate, { setZone: true })
      : undefined,
    pricePerMile: latestPolicy.totalPremium.perMile,
    start,
    end,
  };
};
