import React from 'react';
import {
  DEFAULT_TAB,
  USER_VERIFICATION_TABS,
  UserVerificationTabId,
} from './constants';
import { Container } from './UserVerificationsTable.styled';
import Tabs from '@material-ui/core/Tabs';
import { AppBar } from '@material-ui/core';
import { CreditReport } from '../../../Controller/creditCheck/types';
import CreditScore from '../CreditScore';
import { DrivingReport } from '../../../Controller/drivingHistory/types';
import DrivingHistory from '../DrivingHistory';

interface Props {
  userTimezone: string;
  creditHistoryItems: CreditReport[];
  drivingHistoryItems: DrivingReport[];
}

const UserVerificationTable: React.FC<Props> = ({
  userTimezone,
  creditHistoryItems,
  drivingHistoryItems,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(DEFAULT_TAB);

  const isTabSelected = (id: UserVerificationTabId) => {
    return (
      USER_VERIFICATION_TABS.findIndex(item => item.id === id) === selectedTab
    );
  };

  return (
    <Container>
      <AppBar position="static">
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
        >
          {USER_VERIFICATION_TABS.map(t => t.content)}
        </Tabs>
      </AppBar>
      <CreditScore.List
        isVisible={isTabSelected(UserVerificationTabId.CREDIT_SCORE)}
        items={creditHistoryItems}
        userTimezone={userTimezone}
      />
      <DrivingHistory.List
        isVisible={isTabSelected(UserVerificationTabId.DRIVING_HISTORY)}
        items={drivingHistoryItems}
        userTimezone={userTimezone}
      />
    </Container>
  );
};

export default UserVerificationTable;
