import { gql } from 'graphql-request';

export const getAuditHistoryQuery = gql`
  query getAuditHistory($userId: ID!, $input: AuditHistoryInput!) {
    user(id: $userId) {
      profile {
        timezone
      }
      auditHistory(input: $input) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          createdAt
          operation {
            namespace
            name
            userId
          }
          originator {
            type
            id
          }
          reason
          notes
          payload
        }
      }
    }
  }
`;
