import React from 'react';
import { InfoTile } from '../InfoTile';
import { UserDrivingReportStatus } from '../../../../../../graphql/types';

interface Props {
  drivingHistoryStatus: UserDrivingReportStatus;
}

const DrivingHistoryTile: React.FC<Props> = ({ drivingHistoryStatus }) => {
  function mapDrivingReportStatus(): string {
    switch (drivingHistoryStatus) {
      case UserDrivingReportStatus.Available:
        return 'Available';
      case UserDrivingReportStatus.NotEligible:
        return 'Not eligable';
      case UserDrivingReportStatus.Pending:
        return 'Pending';
      default:
        return drivingHistoryStatus;
    }
  }

  return (
    <InfoTile
      title="Driving Check Status"
      subtitle={mapDrivingReportStatus()}
    />
  );
};

export default DrivingHistoryTile;
