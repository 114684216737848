import React from 'react';
import { DetailedInfoTile, InfoItem } from '../shared/DetailedInfoTile';
import { Loader } from '../../../components/Loader';
import { betaFlagsSelector } from './betaFlagsSelector';
import { useGetUserBetaFlagsQuery } from '../../../graphql/api';
import { withErrorBoundary } from '../../../components/withErrorBoundary';
import { Props } from '../types';

const BetaFlagsContainer: React.FC<Props> = ({ userId }) => {
  const { data } = useGetUserBetaFlagsQuery<InfoItem[], Error>(
    { userId },
    {
      select: betaFlagsSelector,
      queryKey: ['userStats', userId],
    },
  );

  if (data) {
    return <DetailedInfoTile infoItems={data} />;
  }

  return <Loader />;
};

export default withErrorBoundary(BetaFlagsContainer);
