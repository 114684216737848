import { Container, Text, ErrorText } from './TableStatus.styled';

type Props = {
  isLoading: boolean;
  error?: string;
  isNoData: boolean;
  noDataMessage: string;
};

const TableStatus: React.FC<Props> = ({
  isLoading,
  error,
  isNoData,
  noDataMessage,
}) => {
  if (isLoading)
    return (
      <Container>
        <Text>Loading...</Text>
      </Container>
    );
  if (error)
    return (
      <Container>
        <ErrorText>Error: {error}</ErrorText>
      </Container>
    );
  if (isNoData)
    return (
      <Container>
        <Text>{noDataMessage}</Text>
      </Container>
    );
  return null;
};

export default TableStatus;
