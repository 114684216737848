import styled from 'styled-components';
import { Body } from '../../../../components/Typography';

export const Text = styled(Body)`
  margin: 1rem 0 2rem;
  color: ${({ theme }) => theme.palette.primary.veryDarkGrey};
`;

export const Row = styled(Body)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  color: ${({ theme }) => theme.palette.primary.veryDarkGrey};
`;
