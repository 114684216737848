import { action } from 'typesafe-actions';
import {
  SUBMIT_USER_COMPLETE,
  SUBMIT_USER_ERROR,
  SUBMIT_USER_START,
} from '../constants';
import {
  IAccount,
  ISubmitUserCompleteAction,
  ISubmitUserErrorAction,
  ISubmitUserStartAction,
} from '../interfaces';

export const onSubmitUserStart = (account: IAccount) =>
  action(SUBMIT_USER_START, { user: account }) as ISubmitUserStartAction;
export const onSubmitUserComplete = (user: any) =>
  action(SUBMIT_USER_COMPLETE, { user }) as ISubmitUserCompleteAction;
export const onSubmitUserError = (error: string) =>
  action(SUBMIT_USER_ERROR, { error }) as ISubmitUserErrorAction;
