import { fetchGraphQLQuery } from '../../../../../graphql/service';
import { GetUserAccountStateQuery } from '../../../../../graphql/types';
import {
  disablePolicyPurchaseMutation,
  enablePolicyPurchaseMutation,
  unblockUserMutation,
} from './gql';
import type { GetAccountStateResponse, RestrictPolicyResponse } from '../types';
import { accountStateSelector } from './mappers';
import {
  GetUserAccountStateDocument,
  ReinstateUserInput,
  RestrictUserInput,
  UnrestrictUserInput,
} from '../../../../../graphql/api';
import { EnablePolicyResponse, UnblockUserResponse } from '../types';

const getAccountState = async (
  userId: string,
): Promise<GetAccountStateResponse> => {
  try {
    const result = await fetchGraphQLQuery<GetUserAccountStateQuery>(
      GetUserAccountStateDocument,
      { userId },
    );

    return {
      success: true,
      data: accountStateSelector(result),
    };
  } catch (e: any) {
    return {
      success: false,
      error: e.message,
    };
  }
};

const unblockUser = async (
  payload: ReinstateUserInput,
): Promise<UnblockUserResponse> => {
  try {
    await fetchGraphQLQuery<{ reinstateUser: boolean }>(unblockUserMutation, {
      input: payload,
    });

    return {
      success: true,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e.message,
    };
  }
};

const restrictPolicyPurchase = async (
  payload: RestrictUserInput,
): Promise<RestrictPolicyResponse> => {
  try {
    await fetchGraphQLQuery(disablePolicyPurchaseMutation, {
      input: payload,
    });

    return {
      success: true,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e.message,
    };
  }
};

const enablePolicyPurchase = async (
  payload: UnrestrictUserInput,
): Promise<EnablePolicyResponse> => {
  try {
    await fetchGraphQLQuery(enablePolicyPurchaseMutation, {
      input: payload,
    });
    return {
      success: true,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e.message,
    };
  }
};

const accountStateApi = {
  getAccountState,
  unblockUser,
  restrictPolicyPurchase,
  enablePolicyPurchase,
};

export default accountStateApi;
