import {
  ActivityType,
  OdometerStatus,
  TallyUpDto,
  TallyUpStatus,
  OdometerRestDto as OdometerBase,
  BillStatus,
} from '@just-insure/api';

import { TallyUp, TallyUpOdometer } from './types';
import { setDateTimezone } from '../../../helpers/convertDate';
import {
  BaseOdometerDto,
  OdometerStatusAuthority,
} from '@just-insure/api/build/odometer-v2';

export const mapServiceVersion = (version?: number): string =>
  version ? `v${version}` : 'v1';

export const getStatusAuthorityForOdometer = (
  odometer: OdometerBase | BaseOdometerDto,
) => {
  return odometer.statusAuthority || null;
};

const activityTypeLabelMapping = {
  [ActivityType.owed]: 'Odometer photo charge',
  [ActivityType.daily]: 'Daily',
  [ActivityType.driving]: 'Driving',
  [ActivityType.tallyup]: 'Tally Up',
  [ActivityType.topup]: 'Topup',
  [ActivityType.refund]: 'Refund',
  [ActivityType.autoTopup]: 'Auto top up',
};

// NOTE: The below is a mapping change so the services team know which item to select for the 100 mile a day
// and we do not want to remap the owed data behind the scenes
export const mapActivityTypes = () => {
  const types = Object.values(ActivityType).map(v => ({
    label: activityTypeLabelMapping[v] || v,
    value: v,
  }));

  return types;
};

export function mapOdometerToLegacy(
  odometer: OdometerBase,
  userId: string,
): TallyUpOdometer {
  function gerReading() {
    switch (odometer.status) {
      case OdometerStatus.Accepted:
        return odometer.readings.accepted || 0;
      case OdometerStatus.Pending:
        return odometer.readings.user || 0;
      default:
        return 0;
    }
  }

  return {
    id: `${odometer.id}`,
    rejected: odometer.status === OdometerStatus.Rejected,
    updatedAt: setDateTimezone(odometer.updatedAt, userId),
    createdAt: setDateTimezone(odometer.createdAt, userId),
    reading: gerReading(),
    filename: odometer.photo.filename,
    serviceVersion: mapServiceVersion(odometer.serviceVersion),
    status: odometer.status,
    readings: {
      ocr: odometer.readings.ocr,
      user: odometer.readings.user,
      accepted: odometer.readings.accepted,
    },
    statusAuthority: getStatusAuthorityForOdometer(odometer),
  };
}

export function mapTallyUpToLegacy(tallyUpDto: TallyUpDto): TallyUp {
  const { tallyUp, bill, odometers } = tallyUpDto;

  function getCharge() {
    // for initial tally up, there's no estimation
    if (tallyUp.isInitial) {
      return 0;
    }

    // we assert not null, because that's the contract from API
    switch (tallyUp.status) {
      case TallyUpStatus.Pending:
        // there's a special case for estimation to not show up
        // if user enters wrong readings, making difference negative,
        // the estimation won't be created. In this scenario, we fallback to 0
        return bill.amount.estimated?.value || 0;
      case TallyUpStatus.Verified:
        return bill.amount.charged!.value;
      case TallyUpStatus.Rejected:
        return 0;
    }
  }

  function getStatusAuthorityForTallyUp(): OdometerStatusAuthority | null {
    // Status authority is returned only for service version 3 and above
    if (
      tallyUp.serviceVersion < 3 ||
      !tallyUp.isInitial ||
      !tallyUp.statusAuthority
    ) {
      return null;
    }

    return tallyUp.statusAuthority;
  }

  const startOdo: TallyUpOdometer = {
    id: `${odometers.start.id}`,
    rejected: odometers.start.status === OdometerStatus.Rejected,
    filename: odometers.start.photo.filename,
    createdAt: setDateTimezone(odometers.start.createdAt, tallyUp.userId),
    updatedAt: setDateTimezone(odometers.start.updatedAt, tallyUp.userId),
    serviceVersion: mapServiceVersion(tallyUp.serviceVersion),
    status: odometers.start.status,
    readings: {
      ocr: odometers.start.readings.ocr,
      user: odometers.start.readings.user,
      accepted: odometers.start.readings.accepted,
    },
    statusAuthority: getStatusAuthorityForOdometer(odometers.start),
  };

  const endOdo: TallyUpOdometer =
    tallyUp.isInitial && !odometers.end
      ? startOdo
      : {
          id: `${odometers.end!.id}`,
          rejected: odometers.end!.status === OdometerStatus.Rejected,
          filename: odometers.end!.photo.filename,
          createdAt: setDateTimezone(odometers.end!.createdAt, tallyUp.userId),
          updatedAt: setDateTimezone(odometers.end!.updatedAt, tallyUp.userId),
          serviceVersion: mapServiceVersion(tallyUp.serviceVersion),
          status: odometers.end!.status,
          readings: {
            ocr: odometers.end!.readings.ocr,
            user: odometers.end!.readings.user,
            accepted: odometers.end!.readings.accepted,
          },
          statusAuthority: getStatusAuthorityForOdometer(odometers.end!),
        };

  return {
    id: `${tallyUp.id}`,
    userId: tallyUp.userId,
    status: tallyUp.status,
    charge: getCharge(),
    isChargeVoided: bill.status === BillStatus.Void,
    isInitial: tallyUp.isInitial,
    pricePerMile: bill.pricePerMile,
    odometerDistance: tallyUp.distance.odometer || 0,
    distanceTracked: tallyUp.distance.tracked || 0,
    distanceUntracked: tallyUp.distance.untracked || 0,
    startOdometer: startOdo,
    endOdometer: endOdo,
    serviceVersion: mapServiceVersion(tallyUp.serviceVersion),
    statusAuthority: getStatusAuthorityForTallyUp(),
  };
}
