import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 22px;
  height: 100vh;
  overflow: auto;
`;

const styles = (theme: any) => ({
  appBarSpacer: theme.mixins.toolbar,
});

export default styles;
