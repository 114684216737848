import React from 'react';
import {
  DeviceStatus as DeviceStatusEnum,
  ObdDevice,
} from '../Controller/types';
import { RowLabel, RowValue, RowWrapper, Title } from './ObdTile.styled';

interface Props {
  device: ObdDevice | null;
  isEnabled: boolean;
}

const DeviceStatus: React.FC<Props> = ({ device, isEnabled }) => {
  const getTitle = () => {
    if (!isEnabled) {
      return 'Not a program participant';
    }

    if (!device) {
      return 'Activation pending';
    }

    if (device.status === DeviceStatusEnum.DEACTIVATED) {
      return 'Disabled';
    }

    if (device.status === DeviceStatusEnum.ACTIVATED) {
      return device.isConnected ? 'Connected' : 'Disconnected';
    }
  };

  const title = getTitle();

  return (
    <div>
      <Title>{title}</Title>
      {device ? (
        <div>
          <Row label="VIN" value={device.vin} />
          <Row label="Serial" value={device.serialNumber} />
        </div>
      ) : null}
    </div>
  );
};

const Row: React.FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <RowWrapper>
      <RowLabel>{label}</RowLabel>
      <RowValue>{value}</RowValue>
    </RowWrapper>
  );
};

export default DeviceStatus;
