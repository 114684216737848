import Button from '@material-ui/core/Button';
import React from 'react';
import { getQuoteDetail } from '../../helpers/quoteHelpers';
import { Quote } from '../../services/repository/userService';
import { Coverages } from './Coverages';
import { OptionalCoverages } from './OptionalCoverages';
import * as S from './QuoteDetailModal.style';

interface Props {
  quote: Quote;
  onClose: () => void;
}

export const QuoteDetailModal: React.FC<Props> = ({ quote, onClose }) => {
  const data = getQuoteDetail(quote.price, quote.selection);
  return (
    <S.ModalContainer open={true} onClose={onClose}>
      <S.QuoteModal>
        <S.Title>Customer Quote</S.Title>
        <S.CoverageContainer>
          {data.map(p => {
            return (
              <div key={p.title}>
                <S.SplitRow>
                  <S.PackageName>{p.title}</S.PackageName>
                  {p.selected ? <img src="/images/check.png" alt="" /> : null}
                </S.SplitRow>
                <S.PackageSubTitle>{p.subtitle}</S.PackageSubTitle>
                <S.PackageContainer>
                  <Coverages coverages={p.coverages} />
                  <OptionalCoverages optionalCoverages={p.optionalCoverages} />
                </S.PackageContainer>
              </div>
            );
          })}
        </S.CoverageContainer>

        <S.ButtonContainer>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </S.ButtonContainer>
      </S.QuoteModal>
    </S.ModalContainer>
  );
};
