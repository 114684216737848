import {
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  IdToken,
} from '@auth0/auth0-react';

type GetAccessTokenSilently = (
  options?: GetTokenSilentlyOptions,
) => Promise<string>;

type GetIdTokenSilently = (
  func?: GetIdTokenClaimsOptions | undefined,
) => Promise<IdToken>;

let getIdTokenSilentlyInternal: GetIdTokenSilently;
let getAccessTokenSilently: GetAccessTokenSilently;

export const getIdTokenSilently = async () => {
  await getAccessTokenSilently();
  const result = await getIdTokenSilentlyInternal();
  return result?.__raw;
};
export const setGetIdTokenSilently = (
  getIdToken: GetIdTokenSilently,
  getAccessToken: GetAccessTokenSilently,
) => {
  getIdTokenSilentlyInternal = getIdToken;
  getAccessTokenSilently = getAccessToken;
};
