import styled from 'styled-components';
import { theme } from '../../assets/theme';
import Modal from '@material-ui/core/Modal';

export type ConfirmationType = 'save' | 'deleting' | 'canceling';

export const ModalContainer = styled(Modal)`
  overflow: scroll;
`;

export const QuoteModal = styled.div`
  position: absolute;
  background: ${({ theme: t }) => t.palette.primary.paleLilac};
  border-radius: 10px;
  top: 5%;
  left: calc(50% - 300px);
  outline: none;
  width: 600px;
`;

export const Title = styled.div`
  color: ${({ theme: t }) => t.palette.primary.bluePurple};
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
`;

export const CoverageContainer = styled.div`
  padding: 20px;
  width: 100%;

  > div {
    padding: 0 10px 20px;

    table {
      width: 100%;
      td {
        border-bottom: 1px solid ${theme.palette.primary.lightgrey};
        padding: 3px 0;

        &:nth-child(2) {
          width: 100px;
        }
        &:nth-child(3) {
          width: 100px;
        }

        img {
          width: 13px;
          height: 13px;
          margin-left: 10px;
        }

        .optional {
          padding-left: 30px;
        }
      }
    }
  }
`;

export const SplitRow = styled.div`
  align-items: center;
  display: flex;

  > div:nth-child(2) {
    color: ${theme.palette.primary.red};
    font-weight: 500;
    line-height: 20px;
    padding-left: 10px;
  }

  img {
    width: 13px;
    height: 13px;
    margin-left: 10px;
  }
`;

export const PackageName = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

export const PackageSubTitle = styled.div`
  font-size: 13px;
  font-weight: 400;
`;

export const PackageContainer = styled.div`
  border: 1px solid ${theme.palette.primary.lightgrey};
  padding: 10px;
`;

export const PackageOptions = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding-top: 20px;
`;
