import styled from 'styled-components';
import { Card, Typography } from '@material-ui/core';

export const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 200px;
  min-height: 150px;
  padding: 12px;
`;

export const LoadingWrapper = styled.div`
  width: 200px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderTitle = styled(Typography)`
  text-align: center;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RowLabel = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 1.2px;
  color: ${({ theme }) => theme.palette.primary.grey};
  margin: 0;
`;

export const RowValue = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 1.2px;
  color: ${({ theme }) => theme.palette.primary.dark};
  margin: 0;
`;
