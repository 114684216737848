import { AxiosError } from 'axios';
import React, { useState } from 'react';
import ErrorModal from '../../../../components/ErrorModal/ErrorModal';
import { MenuItem } from '../../../../components/PopUpMenu/PopUpMenu';
import { ForwardEmailInput } from '../../../../graphql/types';
import {
  AccountEmail,
  getEmailAttachment,
} from '../../../../services/repository/communicationService';
import { ConfirmationModal } from './ConfirmationModal/ConfirmationModal';
import { EmailCardContent } from './EmailCardContent/EmailCardContent';
import { ForwardMailModal } from './ForwardMailModal/ForwardMailModal';
import { PreviewModal } from './PreviewModal/PreviewModal';
import * as communicationService from '../../../../services/repository/communicationService';
import { logError, ErrorCode } from '../../../../services/logging';

interface Props {
  email: AccountEmail;
  onRefresh: () => void;
  userId: string;
}

enum MenuItemTypes {
  Forward = 'forward',
}

const menuItems = [
  {
    label: 'Forward',
    type: MenuItemTypes.Forward,
  },
];

export const EmailCard: React.FC<Props> = ({ email, userId, onRefresh }) => {
  const [error, setError] = useState<AxiosError>();
  const [previewEmail, setPreviewEmail] = useState<AccountEmail>();
  const [forwardEmail, setForwardEmail] = useState<AccountEmail>();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleMenuClick = (item: MenuItem) => {
    if (item.type === MenuItemTypes.Forward) {
      setForwardEmail(email);
    }
  };

  const handleSendEmail = async (emailInput: ForwardEmailInput) => {
    try {
      await communicationService.forwardEmail(emailInput);
      setForwardEmail(undefined);
      setShowConfirmation(true);
      onRefresh();
    } catch (ex: any) {
      logError(ErrorCode.CommsHistory, ex, {
        email: JSON.stringify(emailInput),
      });
      setError(ex);
    }
  };

  const handleOpenAttachment = async (url: string) => {
    try {
      const pdfOutput = await getEmailAttachment(url);
      const pdfBlob = new Blob([pdfOutput], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(pdfBlob);
      window.open(fileURL, '_blank');
    } catch (ex: any) {
      logError(ErrorCode.CommsHistory, ex, { url });
      setError(ex);
    }
  };

  return (
    <>
      <EmailCardContent
        email={email}
        menuItems={menuItems}
        onOpenAttachment={handleOpenAttachment}
        onMenuItem={handleMenuClick}
        onPreview={setPreviewEmail}
      />
      <ErrorModal error={error} onClose={() => setError(undefined)} />
      {previewEmail ? (
        <PreviewModal
          email={previewEmail}
          onOpenAttachment={handleOpenAttachment}
          onClose={() => setPreviewEmail(undefined)}
        />
      ) : null}
      {forwardEmail ? (
        <ForwardMailModal
          email={forwardEmail}
          userId={userId}
          onClose={() => setForwardEmail(undefined)}
          onSend={handleSendEmail}
        />
      ) : null}
      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      />
    </>
  );
};
