import React from 'react';
import { CommunicationFilter } from '../../../../services/repository/communicationService';
import {
  FilterCommsOption,
  filterCommsOptions,
  FilterDateOption,
  filterDateOptions,
} from './filterOptions';
import * as S from './FilterSelector.styled';

interface Props {
  filter: CommunicationFilter;
  onFilterChange: (filter: CommunicationFilter) => void;
  onClose: () => void;
}

export const FilterSelector: React.FC<Props> = ({
  filter,
  onFilterChange,
  onClose,
}) => {
  const handleDateSelection = (dateOption: FilterDateOption) => {
    onFilterChange({
      ...filter,
      dateOption,
    });
  };

  const handleCommsSelection = (commsOption: FilterCommsOption) => {
    onFilterChange({
      ...filter,
      commsOption,
    });
  };

  return (
    <S.Container onMouseLeave={onClose}>
      <S.Group>
        <S.FilterSection>Filter by date</S.FilterSection>
        {filterDateOptions.map(item => (
          <S.FilterItem
            key={item.value}
            onClick={() => handleDateSelection(item)}
          >
            <input
              type="radio"
              name="filter-date"
              value={item.value}
              checked={filter.dateOption.value === item.value}
              onChange={e =>
                e.currentTarget.checked ? handleDateSelection(item) : undefined
              }
            />
            <S.FilterLabel>{item.label}</S.FilterLabel>
          </S.FilterItem>
        ))}
      </S.Group>
      <S.Group>
        <S.FilterSection>Comms methods</S.FilterSection>
        {filterCommsOptions.map(item => (
          <S.FilterItem
            key={item.value}
            onClick={() => handleCommsSelection(item)}
          >
            <input
              type="radio"
              name="filter-comms"
              value={item.value}
              checked={filter.commsOption.value === item.value}
              onChange={e =>
                e.currentTarget.checked ? handleCommsSelection(item) : undefined
              }
            />
            <S.FilterLabel>{item.label}</S.FilterLabel>
          </S.FilterItem>
        ))}
      </S.Group>
    </S.Container>
  );
};
