import React from 'react';
import { FeatureFlags } from '../Controller/types';
import { Heading, Item, Label, Value, Wrapper } from './FeatureFlagList.style';
import { Button } from '@material-ui/core';
import { theme } from '../../../../assets/theme';

interface Props {
  featureFlags: FeatureFlags | null;
  onRetry: () => void;
}
const FeatureFlagList: React.FC<Props> = ({ featureFlags, onRetry }) => {
  if (!featureFlags) {
    return (
      <>
        <Heading>Oops, something went wrong. Please try again.</Heading>
        <Button onClick={onRetry} variant="contained" color="primary">
          Try again
        </Button>
      </>
    );
  }

  const entries = Object.entries(featureFlags);

  return (
    <>
      <Heading>LaunchDarkly Feature Flags</Heading>
      <Wrapper>
        {entries.map(([key, value]) => (
          <ListItem label={key} value={value} />
        ))}
      </Wrapper>
    </>
  );
};

interface ListItemProps {
  label: string;
  value: string | number | boolean;
}
const ListItem: React.FC<ListItemProps> = ({ label, value }) => {
  const displayValue =
    typeof value === 'boolean' ? (value ? 'Enabled' : 'Disabled') : value;

  const color =
    typeof value === 'boolean'
      ? value
        ? theme.palette.primary.green
        : theme.palette.primary.red
      : theme.palette.primary.dark;

  return (
    <Item>
      <Label>{label}</Label>
      <Value color={color}>{displayValue}</Value>
    </Item>
  );
};

export default FeatureFlagList;
