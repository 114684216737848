import { useGetUserTimezoneQuery } from '../../../../graphql/api';

export type UserTimezone = {
  isLoading: boolean;
  error: Error | null;
  timezone: string | undefined;
  refetch: () => void;
};

export const useUserTimezone = (userId: string): UserTimezone => {
  const {
    isLoading,
    error,
    data: timezone,
    refetch,
  } = useGetUserTimezoneQuery<string | undefined, Error>(
    { userId },
    {
      queryKey: ['userTimezone', { userId }],
      select: data => data.user.profile?.timezone,
    },
  );
  return {
    isLoading,
    error,
    timezone,
    refetch,
  };
};
