import { connect } from 'react-redux';

import { onSubmitUserStart } from '../../actions';
import { AccountManagement } from '../../components/AccountManagement';
import {
  errorSelector,
  isFinishedSelector,
} from '../../selectors/accountManagementSelectors';

const mapStateToProps = (state: any) => ({
  isFinished: isFinishedSelector(state),
  error: errorSelector(state),
});

export default connect(mapStateToProps, {
  onSubmitUserStart,
})(AccountManagement);
