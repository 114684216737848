import { EditVehicleRequest, SavedVehicle, Vehicle } from '@just-insure/api';
import { api } from '../httpService';
import { getConfig } from '../../config';

export const getUserVehicles = async (
  userId: string,
): Promise<SavedVehicle[]> =>
  await api<SavedVehicle[]>('get', `/users/${userId}/vehicles`);

export const getVehicleByVin = async (vin: string): Promise<SavedVehicle> =>
  await api<SavedVehicle>('get', `/api/vehicle-reference/${vin}`);

export const createUserVehicle = async (
  userId: string,
  vehicle: EditVehicleRequest,
): Promise<SavedVehicle> =>
  await api<SavedVehicle>('post', `/users/${userId}/vehicles`, vehicle);

export const updateUserVehicle = async (
  userId: string,
  vehicle: Vehicle,
): Promise<void> => api<void>('post', `/users/${userId}/vehicles`, vehicle);

export const setUserVehicleDefault = async (
  userId: string,
  vehicleId: number,
): Promise<SavedVehicle> =>
  await api<SavedVehicle>('patch', `/users/${userId}/vehicles/${vehicleId}`, {
    isDefault: true,
  });

export const deleteUserVehicle = async (
  userId: string,
  vehicleId: number,
): Promise<void> =>
  await api<void>('delete', `/users/${userId}/vehicles/${vehicleId}`);

export const downloadVehicleImages = async (
  userId: string,
  vehicleId: number,
) => {
  return await api<{
    userId: number;
    vehicleId: string;
    photos: PhotoResponse[];
  }>('get', `/photos/users/${userId}/vehicles/${vehicleId}`, undefined, {
    baseUrl: getConfig().REACT_APP_VEHICLE_INSPECTION_URL,
  });
};

export type PhotoResponse = {
  url: string;
  metadata: {
    date: string; // iso
    deviceId: string;
    photoAngle: string;
    location: string; // none-for now
  };
};
