import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import { ClientError as GraphQLRequestError } from 'graphql-request';
import { getConfig, isProduction } from '../config';

const config = getConfig();

export enum ErrorCode {
  AutoPay = 'autoPay',
  CommsHistory = 'CommsHistory',
  TallyUp = 'tallyUp',
  TallyUpReading = 'TallyUpReading',
  UserDetails = 'UserDetails',
  UserPolicyCancellationReasons = 'UserPolicyCancellationReasons',
  UserPolicyCancel = 'UserPolicyCancel',
  UserPolicyRescind = 'UserPolicyRescind',
  UserOdometer = 'UserOdometer',
  UserTransactions = 'UserTransactions',
  UserTripDetails = 'UserTripDetails',
  UserVehicle = 'UserVehicle',
  VehicleMakes = 'VehicleMakes',
  VehicleModels = 'VehicleModels',
  ReferralCreateSalesperson = 'ReferralCreateSalesperson',
  ReferralUpdateSalesperson = 'ReferralUpdateSalesperson',
  ReferralCreateOrganisation = 'ReferralCreateOrganisation',
  ReferralUpdateOrganisation = 'ReferralUpdateOrganisation',
  ReferralCreateCode = 'ReferralCreateCode',
  ChargeUser = 'ChargeUser',
  EditACV = 'EditACV',
  FiftyMileCharge = 'FiftyMileCharge',
  CreateEndorsementQuote = 'CreateEndorsementQuote',
  IssuePolicyEndorsement = 'IssuePolicyEndorsement',
  SearchUsers = 'SearchUsers',
  SearchPolicies = 'SearchPolicies',
  UserPaymentMethods = 'UserPaymentMethods',
  OdometerUpload = 'OdometerUpload',
  UserDrivingHistory = 'UserDrivingHistory',
  LoginOnJustWebsite = 'LoginOnJustWebsite',
  UserV2 = 'UserV2',
}

export interface LogContext {
  [key: string]: string;
}

interface ErrorContext {
  [key: string]: string | number;
}

datadogLogs.init({
  applicationId: config.REACT_APP_DATA_DOG_APPLICATION_ID,
  clientToken: config.REACT_APP_DATA_DOG_TOKEN,
  site: config.REACT_APP_DATA_DOG_SITE,
  service: config.REACT_APP_DATA_DOG_SERVICE_NAME,
  forwardErrorsToLogs: true,
  env: config.REACT_APP_ENVIRONMENT,
  sampleRate: config.REACT_APP_DATA_DOG_SAMPLE_RATE
    ? parseInt(config.REACT_APP_DATA_DOG_SAMPLE_RATE, 10)
    : 50,
  version: config.REACT_APP_VERSION,
});

datadogRum.init({
  applicationId: config.REACT_APP_DATA_DOG_APPLICATION_ID,
  clientToken: config.REACT_APP_DATA_DOG_TOKEN,
  site: config.REACT_APP_DATA_DOG_SITE,
  service: config.REACT_APP_DATA_DOG_SERVICE_NAME,
  env: config.REACT_APP_ENVIRONMENT,
  sampleRate: config.REACT_APP_DATA_DOG_SAMPLE_RATE
    ? parseInt(config.REACT_APP_DATA_DOG_SAMPLE_RATE, 10)
    : 50,
  version: config.REACT_APP_VERSION,
  trackInteractions: true,
});

export const registerSentry = () => {
  Sentry.init({
    dsn: config.REACT_APP_SENTRY_KEY,
    environment: config.REACT_APP_ENVIRONMENT,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing(),
      Sentry.replayIntegration({
        maskAllText: isProduction,
        maskAllInputs: isProduction,
        blockAllMedia: isProduction,
      }),
    ],
  });
};

export const setSentryUser = (userEmail?: string) => {
  if (userEmail) {
    Sentry.setUser({ email: userEmail });
  } else {
    Sentry.setUser(null);
  }
};

export const logError = (
  errorCode: ErrorCode,
  error: Error | AxiosError | GraphQLRequestError,
  context: ErrorContext = {},
) => {
  if (config.REACT_APP_ENVIRONMENT === 'local') {
    // eslint-disable-next-line no-console
    return console.error(error);
  }

  datadogLogs.logger.error(error.message, { errorCode, error, ...context });
  Sentry.captureException(error, {
    level: 'error',
    extra: { errorCode },
  });
};

export const logInfo = (message: string, context: LogContext) => {
  datadogLogs.logger.info(message, context);
};
