import styled from 'styled-components';

export const ErrorText = styled.h2`
  color: ${props => props.theme.palette.primary.red};
  padding-bottom: 1rem;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid ${props => props.theme.palette.primary.red};
  align-self: center;
`;

export const Container = styled.div`
  padding-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;
