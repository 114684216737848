export interface AccidentForm {
  amountOfLoss: number;
  yearOccurred: number;
  anyoneInjured: string;
  atFault: string;
}

export enum BooleanAsString {
  True = 'true',
  False = 'false',
}

export enum ACVOption {
  Accident = 'accident',
  Claim = 'claim',
  Violation = 'violation',
}

export enum MenuOption {
  Edit = 'edit',
  Delete = 'delete',
}
