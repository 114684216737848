import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.white};
  overflow-y: hidden;
  padding: 0 20px 70px;
`;

export const Scroller = styled.div`
  height: 100%;
  overflow-y: auto;
`;
