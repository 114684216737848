import React, { memo, useState } from 'react';
import OdometerImageViewer from '../../../components/OdometerImageViewer/OdometerImageViewer';
import { Table } from '../../../components/Table';
import { TallyUpOdometer } from '../../../services/repository/odometerService';
import { odometerReadingsListColumnDefs } from './odometerReadingsListColumnDefs';
import OdometerRowTemplate from './OdometerRowTemplate';
import { Tooltip } from '@material-ui/core';
import { AddButton } from '../../../components/AddButton/AddButton';
import UploadOdometerModal from './UploadOdometerModal';
import { isFeatureEnabled, Feature } from '../../../config';
import { useGetOdometers } from './hooks/useOdometers';
import { Vehicle } from '../../../services/repository/policyService';

interface Props {
  visible: boolean;
  userId: string;
  primaryVehicle: Vehicle | null;
}

const OdometerList: React.FC<Props> = ({ visible, userId, primaryVehicle }) => {
  const [selectedReading, setSelectedReading] = useState<TallyUpOdometer>();
  const [isUploadOdometerModalOpen, setUploadOdometerModalOpen] =
    useState(false);
  const { data: odometerReadings, isLoading, error } = useGetOdometers(userId);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Table
        columns={odometerReadingsListColumnDefs}
        data={odometerReadings ?? []}
        rowTemplate={p => (
          <OdometerRowTemplate
            {...p}
            key={p.item.id}
            onImageClick={setSelectedReading}
            serviceVersion={p.item.serviceVersion}
          />
        )}
        isLoading={isLoading}
        error={error?.message}
      />
      {selectedReading ? (
        <OdometerImageViewer
          userId={parseInt(userId, 10)}
          readingId={parseInt(selectedReading.id, 10)}
          visible={true}
          onClose={() => setSelectedReading(undefined)}
        />
      ) : null}

      {primaryVehicle && isFeatureEnabled(Feature.OdometerUpload) ? (
        <Tooltip title="Upload odometer">
          <AddButton onClick={() => setUploadOdometerModalOpen(true)}>
            +
          </AddButton>
        </Tooltip>
      ) : null}

      {primaryVehicle && isUploadOdometerModalOpen ? (
        <UploadOdometerModal
          userId={userId}
          primaryVehicle={primaryVehicle}
          onClose={() => setUploadOdometerModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export default memo(OdometerList);
