import React from 'react';
import { DrivingReport } from './types';
import useDrivingHistoryApi from './api';
import {
  useGetUserDrivingHistoryStatusQuery,
  UserDrivingReportStatus,
} from '../../../../../graphql/api';
import { UseQueryResult } from 'react-query';
import { logError, ErrorCode } from '../../../../../services/logging';

interface DrivingHistoryState {
  drivingReports: DrivingReport[];
  isLoading: boolean;
  isProcessing: boolean;
  error: string;
}

export interface UseDrivingHistory {
  state: {
    status: UseQueryResult<UserDrivingReportStatus, Error>;
    report: DrivingHistoryState;
  };
  getDrivingHistoryReport: () => Promise<void>;
  runDrivingHistoryCheck: (note: string) => Promise<void>;
}

const initialState: DrivingHistoryState = {
  drivingReports: [],
  isLoading: false,
  isProcessing: false,
  error: '',
};

export const useDrivingHistory = (userId: string): UseDrivingHistory => {
  const api = useDrivingHistoryApi(userId);
  const [drivingHistoryState, updateState] = React.useReducer(
    (
      prevState: DrivingHistoryState,
      nextState: Partial<DrivingHistoryState>,
    ) => {
      return { ...prevState, ...nextState };
    },
    initialState,
  );

  const getDrivingHistoryReport = async () => {
    updateState({
      isLoading: true,
    });
    const response = await api.getDrivingHistory();
    if (response.success) {
      updateState({
        drivingReports: response.data.drivingReports,
        isLoading: false,
      });
    } else {
      updateState({
        isLoading: false,
        error: response.error,
      });
    }
  };

  const runDrivingHistoryCheck = async (note: string) => {
    updateState({
      isProcessing: true,
    });
    const response = await api.runDrivingHistoryCheck(note);
    if (response.success) {
      updateState({
        drivingReports: [
          response.data.drivingReport,
          ...drivingHistoryState.drivingReports,
        ],
        isProcessing: false,
      });
    } else {
      updateState({
        isProcessing: false,
        error: response.error,
      });
    }
  };

  const drivingHistoryStatus = useGetUserDrivingHistoryStatusQuery<
    UserDrivingReportStatus,
    Error
  >(
    { userId },
    {
      queryKey: ['drivingHistoryStatus', { userId }],
      select: data => data.user.drivingReportStatus,
      onError: e => logError(ErrorCode.UserDrivingHistory, e),
      useErrorBoundary: false,
    },
  );

  return {
    state: {
      status: drivingHistoryStatus,
      report: drivingHistoryState,
    },
    getDrivingHistoryReport,
    runDrivingHistoryCheck,
  };
};
