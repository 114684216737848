import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-start;
  align-self: flex-start;
  background-color: ${({ theme }) => theme.palette.background.white};
  display: flex;
  position: absolute;
  padding: 10px;
  right: 42px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.palette.shades.boxBackground};
  margin-top: 15px;
  z-index: 1;
`;

export const Group = styled.div`
  &:nth-child(1) {
    margin-right: 10px;
  }
`;

export const FilterSection = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const FilterItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;
`;

export const FilterLabel = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 14px;
  line-height: 2;
  letter-spacing: 1px;
  margin-left: 5px;
`;
