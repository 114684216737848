import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const Title = styled.h1`
  text-align: center;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.palette.primary.bluePurple};
`;

export const Body = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
`;

export const Container = styled.div`
  padding: 20px 0;
`;

export const ModalContent = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 12px;
  top: 10%;
  bottom: 10%;
  left: calc(50% - 200px);
  outline: none;
  padding: 16px 32px;
  width: 600px;
  flex-direction: column;
  display: flex;
`;

export const ActionButton = styled(Button)`
  && {
    align-self: flex-end;
    width: 110px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;

export const ListContainer = styled.div`
  margin: 16px 0px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

export const ColumnLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: left;
  color: ${({ theme }) => theme.palette.primary.grey};
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.grey};
  width: 100%;
`;

export const Column = styled.div`
  flex: ${(props: { width?: number }) => props.width || 1};
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0px 8px;
`;

export const SLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  cursor: pointer;
`;

export const Value = styled.p`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-align: left;
`;
