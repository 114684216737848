import { PolicyholderLocationEvent } from './types';
import { useQuery } from 'react-query';
import {
  PolicyDateFormat,
  UserPolicy,
} from '../../../../services/repository/policyService';
import { DateTime } from 'luxon';
import { getConfig } from '../../../../config';

type Result =
  | { status: 'success'; data: PolicyholderLocationEvent[] }
  | { status: 'fail'; error: string }
  | { status: 'loading' }
  | { status: 'updating'; data: PolicyholderLocationEvent[] };

function usePolicyholderLocation(userId: string, policy: UserPolicy): Result {
  const dt = DateTime.fromFormat(policy.start, PolicyDateFormat);
  const startDate = dt.minus({ day: 1 }).toFormat('yyyy-MM-dd');
  const endDate = dt.plus({ day: 1 }).toFormat('yyyy-MM-dd');

  const {
    data: events,
    isFetching,
    isLoading,
    error,
  } = useQuery<PolicyholderLocationEvent[], Error>(
    ['policyholder-location', userId, policy.policyNr, startDate, endDate],
    () => getLocationEvents(userId, policy.policyNr, startDate, endDate),
    {
      retry: false,
      staleTime: 30 * 1000,
      useErrorBoundary: false,
    },
  );

  if (error) {
    return { status: 'fail', error: error.message };
  }
  if (isLoading) {
    return { status: 'loading' };
  }
  return {
    status: isFetching ? 'updating' : 'success',
    data: events || [],
  };
}

async function getLocationEvents(
  userId: string,
  policyId: string,
  startDate: string, // yyyy-MM-dd
  endDate: string, // yyyy-MM-dd, inclusive
): Promise<PolicyholderLocationEvent[]> {
  const endpoint = getConfig().REACT_APP_POLICYHOLDER_LOCATION_URL;

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const result = await fetch(endpoint, {
    method: 'POST',
    headers,
    mode: 'cors',
    body: JSON.stringify({
      startDate,
      endDate,
      userId,
      policyId,
    }),
  });

  return (await result.json()) as PolicyholderLocationEvent[];
}

export default usePolicyholderLocation;
