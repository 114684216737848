import * as yup from 'yup';

const phoneRegExp = /^\+1\d{10}$/;

export default yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  gender: yup.string().required('Gender is required'),
  address1: yup.string().required('Address1 is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string().required('Zip Code is required'),
  dob: yup.string().required('Date of Birth is required'),
  phoneNumber: yup
    .string()
    .required('Phone Number is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
});
