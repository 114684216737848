const drawerWidth = 240;

const styles = (theme: any) => ({
  toolbar: {
    paddingRight: 24,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  toolbarRight: {
    marginLeft: 'auto',
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
  },
  stagingLabel: {
    flex: 1,
    textAlign: 'center',
    marginRight: 30,
  },
  logoutButton: {
    fontSize: 10,
    fontWeight: 400,
  },
  logoutLabel: {
    display: 'flex',
    flexDirection: 'column',

    '& svg': {
      marginBottom: 5,
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
});

export default styles as any;
