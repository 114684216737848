import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const SaveModal = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 10%;
  left: calc(50% - 200px);
  outline: none;
  padding: 30px;
  width: 500px;
`;

export const Headline = styled.h2``;

export const Comment = styled.div`
  font-size: 14px;
`;

export const Row = styled.div`
  && {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
  }
`;

export const StyledButton = styled(Button)``;

export const CancelButton = styled(Button)`
  && {
    margin-right: 10px;
  }
`;
