import React from 'react';
import LoadingButton from '../../../components/LoadingButton/LoadingButton';
import {
  useCreateReferralCodesMutation,
  ReferralCodesInput,
  ReferralType,
} from '../../../graphql/api';
import { GenericComponentError } from '../../../components/GenericComponentError';
import { ErrorCode, logError } from '../../../services/logging';

interface Props {
  id: number;
  onSave: () => void;
}

const CreateReferralCodeButton: React.FC<Props> = ({ id, onSave }) => {
  const { mutate, error, isError, isLoading } = useCreateReferralCodesMutation({
    onSuccess: onSave,
    onError: (e: Error) => logError(ErrorCode.ReferralCreateCode, e),
  });

  const referralCodesInput: ReferralCodesInput = {
    referrerIds: [id],
    type: ReferralType.SalesPerson,
  };

  const onClick = () => {
    mutate({ referralCodesInput });
  };

  if (isError) {
    return <GenericComponentError error={error!} />;
  }

  return (
    <LoadingButton onClick={onClick} isLoading={isLoading}>
      Create code
    </LoadingButton>
  );
};

export default CreateReferralCodeButton;
