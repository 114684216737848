import React from 'react';
import { useGetPolicyDetails } from './hooks/usePolicy';
import { useGetDaysSinceOdometer } from './hooks/useOdometer';
import TransactionModal from './TransactionModal';

interface Props {
  userId: string;
  onClose: () => void;
  onSave: () => void;
}

const FiftyMileSinceOdometer: React.FC<Props> = ({
  userId,
  onClose,
  onSave,
}) => {
  const {
    isLoading: isLoadingPolicy,
    error: policyError,
    data: policyPenalty,
  } = useGetPolicyDetails(userId);
  const {
    isLoading: isLoadingOdometer,
    error: odometerError,
    data: daysSinceOdometer,
  } = useGetDaysSinceOdometer(userId);

  return (
    <TransactionModal
      userId={userId}
      onClose={onClose}
      onSave={onSave}
      isLoading={isLoadingPolicy || isLoadingOdometer}
      errorMessage={policyError?.message || odometerError?.message}
      policyDetails={policyPenalty}
      numberofCharges={daysSinceOdometer}
    />
  );
};

export default FiftyMileSinceOdometer;
