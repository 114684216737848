import React from 'react';
import FormModal from '../../../../components/FormModal/FormModal';
import schema from './schema';
import FormSelect from '../../../../components/FormSelect/FormSelect';
import { DateTime } from 'luxon';
import { createRange } from '../../../../helpers/arrayHelpers';
import { Violation } from '../../../../graphql/api';

interface Props {
  activeViolation?: Violation;
  isOpen: boolean;
  isLoading: boolean;
  onSave: (violation: Violation) => void;
  onClose: () => void;
  error?: Error;
}

const ViolationModal: React.FC<Props> = ({
  activeViolation,
  isOpen,
  isLoading,
  onSave,
  onClose,
  error,
}) => {
  const onSubmit = async (violation: Violation) => {
    onSave(violation);
  };

  const initialValues = activeViolation || {
    violationType: '',
    yearOccurred: '',
  };

  const thisYear = DateTime.local().year;
  const yearRange = createRange(thisYear - 4, thisYear).reverse();

  return (
    <FormModal
      isOpen={isOpen}
      isLoading={isLoading}
      isError={!!error}
      error={error}
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={schema}
      title="Add an violation"
    >
      <FormSelect
        name="yearOccurred"
        options={yearRange.map(year => ({
          label: year.toString(),
          value: year,
        }))}
        editable
        label="Year occurred"
      />
      <FormSelect
        name="violationType"
        options={[
          {
            label: 'Major',
            value: 1,
          },
          {
            label: 'Minor',
            value: 2,
          },
        ]}
        editable
        label="Violation type"
      />
    </FormModal>
  );
};

export default ViolationModal;
