export const policyListColumnDefs = [
  {
    id: 'policyNr',
    title: 'Policy Number',
  },
  {
    id: 'quoteId',
    title: 'Quote Id',
  },
  {
    id: 'effectiveDate',
    title: 'Effective Date',
  },
  {
    id: 'expirationDate',
    title: 'Expiration Date',
  },
  {
    id: 'duration',
    title: 'Duration',
  },
  {
    id: 'coverages',
    title: 'Type of Coverage',
  },
  {
    id: 'vehicle',
    title: 'Vehicle',
  },
  {
    id: 'price',
    title: 'Price',
  },
  {
    id: 'status',
    title: 'Policy Status',
  },
  {
    id: 'odometersDue',
    title: 'Odometers Due',
  },
  {
    id: 'cancellationDate',
    title: 'Cancellation Date',
  },
  {
    id: 'cancellationReason',
    title: 'Cancellation Reason',
  },
  {
    id: 'rowOptions',
    title: '',
    sortable: false,
  },
];
