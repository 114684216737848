import styled from 'styled-components';
import { Card } from '../../../../../components/Card';
import { H3 } from '../../../../../components/Typography';
import React from 'react';

export const Tile = styled(Card)`
  width: 200px;
  height: 150px;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const TileTitle = styled(H3)`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.m};
  color: ${({ theme }) => theme.palette.primary.veryDarkGrey};
`;

const TileSubTitle = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 18px;
`;

interface Props {
  title: string;
  subtitle: string;
}

export const InfoTile: React.FC<Props> = ({ subtitle, title: tileTitle }) => (
  <Tile>
    <TileTitle>{tileTitle}</TileTitle>
    <TileSubTitle>{subtitle}</TileSubTitle>
  </Tile>
);
