export type AssociatedUser = {
  id: string;
  name: string;
  accountState: AccountState;
};

export type DuplicateAccountStatus = {
  associatedUsers: Array<AssociatedUser>;
};

export enum ControllerStatus {
  Initial = 'initial',
  Loading = 'loading',
  Processing = 'processing',
  Success = 'success',
  Error = 'error',
}

export type DuplicateAccountState = {
  status: ControllerStatus;
  data: DuplicateAccountStatus | null;
  error: string | null;
};

export type GetDuplicateAccountStatusSuccess = {
  success: true;
  data: DuplicateAccountStatus;
};
export type GetDuplicateAccountStatusFailure = {
  success: false;
  error: string;
};

export type GetDuplicateAccountStatusResponse =
  | GetDuplicateAccountStatusSuccess
  | GetDuplicateAccountStatusFailure;

export enum AccountState {
  Active = 'Active',
  Lapsed = 'Lapsed',
  Registration = 'Registration',
  Suspended = 'Suspended',
  NoState = '',
}
