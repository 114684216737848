import React from 'react';
import { CreditCheckResult } from './types';
import CreditCheckApi from './api';

type CreditCheckState =
  | { type: 'loading' }
  | { type: 'error'; message: string }
  | { type: 'success'; data: CreditCheckResult };

export type UseCreditCheck = {
  state: CreditCheckState;
  getCreditReports: () => Promise<void>;
  runCreditCheck: (note: string) => Promise<void>;
};

export const useCreditCheck = (userId: string): UseCreditCheck => {
  const [creditCheckApi] = React.useState(() => new CreditCheckApi(userId));
  const [state, updateState] = React.useReducer(
    (prev: CreditCheckState, current: CreditCheckState) => {
      return { ...prev, ...current };
    },
    {
      type: 'loading',
    },
  );

  async function getCreditReports() {
    updateState({ type: 'loading' });
    const result = await creditCheckApi.getCreditReports();
    if (!result.success) {
      updateState({
        type: 'error',
        message: result.error,
      });
    } else {
      updateState({
        type: 'success',
        data:
          result.data.length > 0
            ? {
                isCreditCheckAvailable: true,
                lastCreditCheck: result.data[0],
                creditReports: result.data,
              }
            : {
                isCreditCheckAvailable: false,
              },
      });
    }
  }

  async function runCreditCheck(note: string) {
    const result = await creditCheckApi.runCreditCheck(note);
    if (!result.success) {
      updateState({
        type: 'error',
        message: result.error,
      });
    } else {
      const prevCreditReports =
        state.type === 'success' && state.data?.isCreditCheckAvailable
          ? state.data.creditReports
          : [];

      updateState({
        type: 'success',
        data: {
          isCreditCheckAvailable: true,
          lastCreditCheck: result.data,
          creditReports: [result.data, ...prevCreditReports],
        },
      });
    }
  }

  return {
    state,
    getCreditReports,
    runCreditCheck,
  };
};
