import { Button } from '@material-ui/core';
import styled from 'styled-components';
import TextInput from '../../../../components/TextInput/TextInput';
import { theme } from '../../../../assets/theme';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme: t }) => t.palette.background.white};
  border: 1px solid ${({ theme: t }) => t.palette.primary.grey};
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 ${({ theme: t }) => t.palette.shades.boxBackground};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 300px;
  height: 300px;
  padding: 8px;
  margin-top: 0;
  margin-right: 50px;
`;

const OdometerImageWidth = 320;
const OdometerImageHeight = 183;
export const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
`;
export const IconContainer = styled.div`
  position: absolute;
  cursor: pointer;
  color: ${({ theme: t }) => t.palette.primary.white};
  width: 24px;
  height: 24px;
  top: 4px;
  left: 4px;
`;

export const OdometerImage = styled.img.attrs({
  alt: '',
})`
  object-fit: cover;
  padding-bottom: 10px;
  height: ${OdometerImageHeight}px;
  width: ${OdometerImageWidth}px;
`;

export const Label = styled.div`
  color: ${({ theme: t }) => t.palette.primary.black};
`;

export const RejectButton = styled.button`
  color: ${({ theme: t }) => t.palette.primary.red};
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  background: none;
  border: none;
  padding: 0;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px;
  width: 100%;
`;

export const SaveButton = styled(Button)`
  background-color: ${({ theme: t, disabled }) =>
    disabled ? t.palette.primary.grey : t.palette.primary.green} !important;
  margin-left: 10px !important;
`;

const ReadingRow = styled.div<{ moveToRight?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  margin: 0;
  justify-content: ${({ moveToRight }) =>
    moveToRight ? 'flex-end' : 'normal'};
`;

export const ReadingGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 4px;
  justify-content: space-between;
`;
const ReadingEntry = styled.div<{ spaceFromLeft?: number; maxSize?: boolean }>`
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: ${({ spaceFromLeft = 0 }) => `${spaceFromLeft}px`};
  flex: ${({ maxSize }) => (maxSize ? '1' : '')};
`;

const ReadingLabel = styled.p<{ isValue?: boolean }>`
  padding: 0;
  margin: 0;
  color: ${({ theme: t, isValue }) =>
    isValue ? t.palette.primary.black : t.palette.primary.grey};
`;

const LabelColorVariant = {
  accepted: theme.palette.primary.green,
  rejected: theme.palette.primary.red,
  value: theme.palette.primary.bluePurple,
};

const ReadingFinalLabel = styled.p<{
  variant?: keyof typeof LabelColorVariant;
}>`
  align-self: flex-end;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-size: ${({ variant }) => (variant === 'value' ? '18px' : '14px')};
  font-weight: ${({ variant }) => (variant === 'value' ? '600' : 'normal')};
  color: ${({ theme: t, variant }) =>
    variant ? LabelColorVariant[variant] : t.palette.primary.grey};
`;

export const Reading = {
  Row: ReadingRow,
  Group: ReadingGroup,
  Entry: ReadingEntry,
  Label: ReadingLabel,
  FinalLabel: ReadingFinalLabel,
};

export const InputContainer = styled.div`
  border: 1px solid ${({ theme: t }) => t.palette.primary.grey};
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
`;

export const StyledTextInput = styled(TextInput)`
  height: 30px;
  max-width: 80px;
  margin: 0 !important;
`;
