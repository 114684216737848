import React from 'react';
import { TableRow } from '@material-ui/core';
import { StyledTableCell } from './Modal.styled';

interface Props {
  isAutomaticPaymentsEnabled: boolean;
}

const AutopaymentsRow: React.FC<Props> = ({ isAutomaticPaymentsEnabled }) => {
  return (
    <TableRow>
      <StyledTableCell component="th">Auto payments</StyledTableCell>
      <StyledTableCell align="right">
        {isAutomaticPaymentsEnabled ? 'Enabled' : 'Disabled'}
      </StyledTableCell>
    </TableRow>
  );
};

export default AutopaymentsRow;
