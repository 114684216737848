import { AxiosError } from 'axios';
import * as React from 'react';
import { useState } from 'react';
import ErrorModal from '../../../components/ErrorModal/ErrorModal';
import { QuoteDetailModal } from '../../../components/QuoteDetailModal/QuoteDetailModal';
import { Table } from '../../../components/Table';
import { Quote } from '../../../services/repository/userService';
import { quoteListColumnDefs } from './quoteColumnDefs';
import * as S from './QuoteList.styled';
import { QuoteRowTemplate } from './QuoteRowTemplate';
import { Tooltip } from '@material-ui/core';
import { AddButton } from '../../../components/AddButton/AddButton';
import CreateEndorsementQuoteModal from './CreateEndorsementQuoteModal';
import IssuePolicyEndorsementModal from './IssuePolicyEndorsementModal';
import { UserPolicy } from '../../../services/repository/policyService';

interface Props {
  quoteOverviews: Quote[];
  visible: boolean;
  userId: string;
  onRefresh: () => void;
  activePolicy: UserPolicy | undefined;
}

const QuoteList: React.FC<Props> = ({
  quoteOverviews,
  visible,
  userId,
  onRefresh,
  activePolicy,
}) => {
  const [selectedQuote, setSelectedQuote] = useState<Quote>();
  const [isCreateQuoteModalOpen, setCreateQuoteModalOpen] = useState(false);
  const [selectedEndorsementQuoteId, setEndorsementQuoteId] = useState<
    string | null
  >(null);
  const [error, setError] = useState<AxiosError>();

  if (!visible) {
    return null;
  }

  return (
    <>
      <S.TableContainer>
        <Table
          columns={quoteListColumnDefs}
          data={quoteOverviews}
          pageSize={10}
          defaultOrder="issueDate"
          defaultDir="desc"
          rowTemplate={({ rowIndex, ...rest }) => (
            <QuoteRowTemplate
              key={rowIndex}
              {...rest}
              onSelectQuote={setSelectedQuote}
              onMenuClick={quoteId => setEndorsementQuoteId(quoteId.toString())}
            />
          )}
        />
      </S.TableContainer>
      {activePolicy ? (
        <Tooltip title="Create an endorsement quote">
          <AddButton onClick={() => setCreateQuoteModalOpen(true)}>+</AddButton>
        </Tooltip>
      ) : null}
      <ErrorModal error={error} onClose={() => setError(undefined)} />
      {selectedQuote ? (
        <QuoteDetailModal
          quote={selectedQuote}
          onClose={() => setSelectedQuote(undefined)}
        />
      ) : null}
      <CreateEndorsementQuoteModal
        userId={userId}
        isOpen={isCreateQuoteModalOpen}
        onSave={() => {
          setCreateQuoteModalOpen(false);
          onRefresh();
        }}
        onClose={() => setCreateQuoteModalOpen(false)}
      />
      {selectedEndorsementQuoteId && activePolicy ? (
        <IssuePolicyEndorsementModal
          userId={userId}
          endorsementQuoteId={selectedEndorsementQuoteId}
          originalQuoteId={activePolicy.quoteId}
          policyNumber={activePolicy.policyNr}
          onSave={() => {
            setEndorsementQuoteId(null);
            onRefresh();
          }}
          onClose={() => setEndorsementQuoteId(null)}
        />
      ) : null}
    </>
  );
};

export default QuoteList;
