import { TableCell, TableRow } from '@material-ui/core';
import { PaymentMethodsFragment as PaymentMethod } from '../../../graphql/api';

interface Props {
  item: PaymentMethod;
  autoPayToken?: string;
}

const PaymentMethodsRowTemplate: React.FC<Props> = ({ item, autoPayToken }) => {
  function getDetails() {
    switch (item.__typename) {
      case 'CreditCard':
        return `${item.cardType} ending in ${item.last4}; Expiry: ${item.expirationMonth}/${item.expirationYear}`;
      case 'PayPal':
        return item.email;
      case 'Venmo':
        return item.username;
      default:
        return '';
    }
  }

  function getError() {
    return item.error ? `${item.error.type}: ${item.error.description}` : '-';
  }

  return (
    <TableRow>
      <TableCell>{item.__typename}</TableCell>
      <TableCell>{getDetails()}</TableCell>
      <TableCell>{item.token}</TableCell>
      <TableCell>{item.token === autoPayToken ? 'Yes' : 'No'}</TableCell>
      <TableCell>{getError()}</TableCell>
    </TableRow>
  );
};

export default PaymentMethodsRowTemplate;
