import React from 'react';
import VehicleListContent from './VehicleListContent';
import useVehicleList from './useVehicleList';
import { useUserDetails } from '../UserDetails/Controller';
import { UserPolicy } from '../../../services/repository/policyService';

interface Props {
  userId: string;
  visible: boolean;
  policies: UserPolicy[];
}

const VehicleList: React.FC<Props> = ({ visible, userId, policies }) => {
  const {
    timezone: { timezone = 'America/Phoenix' },
  } = useUserDetails();

  const policyVehicles = policies.flatMap(policy => policy.vehicles);

  const {
    userVehicles,
    error,
    clearError,
    getUserVehicles,
    updateVehicleInfo,
    setDefaultVehicle,
    addTemporaryVehicle,
    removeTemporaryVehicle,
    deleteVehicleById,
    downloadVehicleImages,
    saveVehicle,
    getPhotosForVehicle,
  } = useVehicleList(userId, policyVehicles);

  React.useEffect(() => {
    getUserVehicles();
  }, [policies]);

  if (!visible) {
    return null;
  }

  return (
    <VehicleListContent
      userVehicles={userVehicles}
      error={error}
      clearError={clearError}
      updateVehicleInfo={updateVehicleInfo}
      setDefaultVehicle={setDefaultVehicle}
      deleteVehicleById={deleteVehicleById}
      downloadVehicleImages={downloadVehicleImages}
      saveVehicle={saveVehicle}
      addTemporaryVehicle={addTemporaryVehicle}
      removeTemporaryVehicle={removeTemporaryVehicle}
      getPhotosForVehicle={getPhotosForVehicle}
      timezone={timezone}
    />
  );
};

export default VehicleList;
