import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as S from './MissingPage.styled';

type PortalProps = RouteComponentProps;

const MissingPage: React.FC<PortalProps> = () => {
  return (
    <S.Container>
      <S.Text>
        Oops, this page doesn't exist. Click <a href="/">here</a> to get back to
        the dashboard.
      </S.Text>
    </S.Container>
  );
};

export default MissingPage;
