import '../styles/output.css';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getDestinationFromPathname } from '../Destinations';
import NavBar from './NavBar';

type Props = RouteComponentProps & {
  radrV1Path?: string;
};

const Layout: React.FC<Props> = ({
  location,
  history,
  radrV1Path,
  children,
}) => {
  const activeDestination = getDestinationFromPathname(location.pathname);

  return (
    <>
      <NavBar
        activeDestination={activeDestination}
        navigateToPath={pathName => history.push(pathName)}
        radrV1Path={radrV1Path}
      />
      <>{children}</>
    </>
  );
};

export default withRouter(Layout);
