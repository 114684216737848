import React, { useState } from 'react';
import { Dial, DialIcon, Action } from './SpeedDialNav.styled';
import { SpeedDialAction } from '../../../types/interfaces';

interface Props {
  actions: SpeedDialAction[];
}

const SpeedDialNav: React.FC<Props> = ({ actions }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Dial
      ariaLabel="Add an entry"
      icon={<DialIcon />}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      FabProps={{
        color: 'secondary',
      }}
    >
      {actions.map((action, i) => (
        <Action
          key={i}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.onClick}
        />
      ))}
    </Dial>
  );
};

export default SpeedDialNav;
