import { ActionType, createAsyncAction } from 'typesafe-actions';
import { TripRequest } from '../../../types/interfaces';

export const onFetchTripData = createAsyncAction(
  'trip/FETCH_TRIP_DATA_REQUEST',
  'trip/FETCH_TRIP_DATA_SUCCESS',
  'trip/FETCH_TRIP_DATA_FAILURE',
)<TripRequest, void, Error>();

export type TripActions = ActionType<typeof onFetchTripData>;
