import { DateTime } from 'luxon';
import { ActivityType, TransactionType } from '../../../../../graphql/api';
import { Transaction } from '../types';

const getFiftyMileChargedAmount = (
  transactions: Transaction[],
  policyStartDate: DateTime,
): number => {
  return transactions.reduce((totalAmount, transaction) => {
    const transactionDate = DateTime.fromISO(transaction.createdAt, {
      setZone: true,
    });

    if (
      transactionDate >= policyStartDate &&
      transaction.activityType === ActivityType.Owed
    ) {
      if (transaction.type === TransactionType.Debit) {
        return totalAmount + transaction.amount;
      } else if (transaction.type === TransactionType.Credit) {
        return totalAmount - transaction.amount;
      }
    }
    return totalAmount;
  }, 0);
};

export default getFiftyMileChargedAmount;
