import { StyledTable, StyledTableCell } from './TransactionModal.styled';
import {
  StyledDialogTitle,
  StyledDialogActions,
} from '../../../../../components/ConfirmationModalV2/ConfirmationModalV2.styled';
import { Button, DialogContent, TableBody, TableRow } from '@material-ui/core';
import LoadingButton from '../../../../../components/LoadingButton/LoadingButton';
import ModalError from '../../../../../components/ModalError/ModalError';

type Props = {
  milesToCharge: number;
  premiumPerMile: string;
  chargeAmount: string;
  isLoadingTransaction: boolean;
  onCreateTransaction: () => Promise<void>;
  onClose: () => void;
  errorMessage?: string;
};

const TransactionDetailsContent: React.FC<Props> = ({
  milesToCharge,
  premiumPerMile,
  chargeAmount,
  isLoadingTransaction,
  onCreateTransaction,
  onClose,
  errorMessage,
}) => {
  return (
    <>
      <StyledDialogTitle>Apply odometer photo charge</StyledDialogTitle>
      <DialogContent>
        <StyledTable size="small">
          <TableBody>
            <TableRow>
              <StyledTableCell component="th">
                Total mileage charge
              </StyledTableCell>
              <StyledTableCell align="right">
                {milesToCharge} miles
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell component="th">Price per-mile</StyledTableCell>
              <StyledTableCell align="right">{premiumPerMile}</StyledTableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
        {errorMessage ? <ModalError error={errorMessage} /> : null}
      </DialogContent>
      <StyledDialogActions disableSpacing>
        <LoadingButton
          onClick={onCreateTransaction}
          isLoading={isLoadingTransaction}
          buttonType="confirm"
        >
          {`Charge ${chargeAmount}`}
        </LoadingButton>
        <Button onClick={onClose} variant="outlined" color="primary">
          Abort
        </Button>
      </StyledDialogActions>
    </>
  );
};

export default TransactionDetailsContent;
