import React from 'react';
import { InfoTile } from '../InfoTile';
import { CreditScoreStatus } from '../../../Controller/creditCheck/types';
interface Props {
  isAvailable: boolean;
  status?: CreditScoreStatus;
}

const CreditScoreTile: React.FC<Props> = ({ isAvailable, status }) => {
  function mapCreditCheckStatusToTile(): string {
    if (!isAvailable || !status) {
      return 'Pending';
    }

    switch (status) {
      case CreditScoreStatus.InsufficientData: {
        return 'Insufficient Data';
      }
      case CreditScoreStatus.Unavailable: {
        return 'Failed';
      }
      case CreditScoreStatus.Available: {
        return 'Received';
      }
    }
  }

  return (
    <InfoTile
      title="Credit Score Status"
      subtitle={mapCreditCheckStatusToTile()}
    />
  );
};

export default CreditScoreTile;
