import {
  AccountFinalisationSettlementPendingState,
  ActiveStateActionsFragment,
  LapsedStateFinalisationFragment,
} from '../../../../../graphql/api';
import { formatBalance } from '../../../../../helpers/formatHelpers';
import { InfoItem } from '../types';

export const mapActiveStateActions = (
  actions: ActiveStateActionsFragment[],
): InfoItem[] => {
  return actions.length > 0
    ? actions.map(action => {
        switch (action.__typename) {
          case 'PaymentAction':
            return {
              title: 'Payment required',
              value: formatBalance(action.payload.amount),
            };
          case 'NewQuoteAvailableAction': {
            return { title: action.title };
          }
          case 'NegativeBalanceAction':
            return { title: 'Negative balance settlement' };
          case 'OdometerDueAction':
          case 'OdometerActionV2': {
            return { title: 'Odometer photo required' };
          }
          default:
            return { title: '' };
        }
      })
    : [noPendingActions()];
};

export const mapLapsedStateAction = (
  finalisation: LapsedStateFinalisationFragment,
): InfoItem => {
  switch (finalisation.__typename) {
    case 'AccountFinalisationComplete':
      return { title: 'Account finalisation complete' };
    case 'AccountFinalisationSettlementPending':
      return {
        title: mapFinalisationSettlementTitle(finalisation.state),
      };
    case 'AccountFinalisationTallyUpDue':
      return { title: 'Tally up due' };
    case 'AccountFinalisationTallyUpRejected':
      return { title: 'Tally up rejected' };
    case 'AccountFinalisationTallyUpSubmitted':
      return { title: 'Tally up submitted' };
    default:
      return { title: '' };
  }
};

export const noPendingActions = (): InfoItem => {
  return {
    title: 'No pending actions',
  };
};

const mapFinalisationSettlementTitle = (
  finalisationSettlementState: AccountFinalisationSettlementPendingState,
) => {
  switch (finalisationSettlementState) {
    case AccountFinalisationSettlementPendingState.BalanceSettlementComplete:
      return 'Balance settlement complete';
    case AccountFinalisationSettlementPendingState.BalanceSettlementRequired:
      return 'Balance settlement required';
    default:
      return '';
  }
};
