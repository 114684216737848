import React from 'react';
import Menu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import * as S from './PopUpMenu.styled';

export interface MenuItem {
  label: string;
  type: string;
}

interface Props {
  menuItems: MenuItem[];
  onClick: (item: MenuItem) => void;
}

export const PopUpMenu: React.FC<Props> = ({ menuItems, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>();

  const handleShowMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const handleMenuSelect = (item: MenuItem) => {
    onClick(item);
    handleClose();
  };

  return (
    <S.Container>
      <S.MenuButton onClick={handleShowMenu}>
        <S.MenuIcon src="/images/button-menu.svg" alt="" />
      </S.MenuButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems.map(item => (
          <MuiMenuItem key={item.label} onClick={() => handleMenuSelect(item)}>
            {item.label}
          </MuiMenuItem>
        ))}
      </Menu>
    </S.Container>
  );
};
