import React, { Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { PageLoader } from '../components/PageLoader';
import { Feature, isFeatureEnabled } from '../config';
import ErrorPage from './pages/ErrorPage';
import UserPage from './pages/UserPage';
import { Destinations } from './Destinations';

const Portal: React.FC = () => (
  <Suspense fallback={<PageLoader text="Loading..." />}>
    <Switch>
      {isFeatureEnabled(Feature.UserPageV2) ? (
        <Route path={Destinations.User} exact component={UserPage} />
      ) : null}
      <Route component={ErrorPage} />
    </Switch>
  </Suspense>
);

export default withRouter(Portal);
