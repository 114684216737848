import { Interval } from 'luxon';

interface PolicyWithTerm {
  term: string;
}

export function sortPoliciesByDate<T extends PolicyWithTerm>(
  policies: T[],
): T[] {
  return [...policies].sort((a, b) => {
    const { start: aPolicyStart } = Interval.fromISO(a.term, { setZone: true });
    const { start: bPolicyStart } = Interval.fromISO(b.term, { setZone: true });

    return aPolicyStart < bPolicyStart ? 1 : -1;
  });
}
