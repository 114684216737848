import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Table } from '../../../../components/Table';
import { columnDefs } from './columnDefs';
import { sortByYear } from '../common/helpers';
import { Violation } from '../../../../graphql/api';
import RowTemplate from './RowTemplate';
import ViolationModal from './ViolationModal';
import { MenuItem } from '../../../../components/PopUpMenu/PopUpMenu';
import { MenuOption } from '../common/types';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import * as S from '../AcvList.styled';

interface Props {
  violations: Violation[];
  isFormModalOpen: boolean;
  isConfirmModalOpen: boolean;
  isLoading: boolean;
  onOpenFormModal: () => void;
  onOpenConfirmModal: () => void;
  onClose: () => void;
  onCreate: (violation: Violation) => void;
  onEdit: (
    oldViolation: Violation,
    newViolation: Violation,
    successCallback: () => void,
  ) => void;
  onDelete: (violation: Violation, successCallback: () => void) => void;
  error?: Error;
}

const Violations: React.FC<Props> = ({
  violations,
  isFormModalOpen,
  isConfirmModalOpen,
  isLoading,
  onOpenFormModal,
  onOpenConfirmModal,
  onClose,
  onCreate,
  onEdit,
  onDelete,
  error,
}) => {
  const [activeViolation, setActiveViolation] = useState<
    Violation | undefined
  >();

  const onMenuClick = (menuItem: MenuItem, violation: Violation) => {
    setActiveViolation(violation);

    if (menuItem.type === MenuOption.Edit) {
      onOpenFormModal();
    } else if (menuItem.type === MenuOption.Delete) {
      onOpenConfirmModal();
    }
  };

  const onSave = (violation: Violation) => {
    if (activeViolation) {
      onEdit(activeViolation, violation, () => setActiveViolation(undefined));
    } else {
      onCreate(violation);
    }
  };

  const onConfirmDelete = () => {
    if (!activeViolation) {
      return;
    }

    onDelete(activeViolation, () => setActiveViolation(undefined));
  };

  const onCloseModal = () => {
    setActiveViolation(undefined);
    onClose();
  };

  return (
    <S.Section>
      <Typography variant="h6" color="primary">
        Violations
      </Typography>
      <Table
        disableGutters
        disableElevation
        disablePagination
        columns={columnDefs}
        data={sortByYear(violations)}
        rowTemplate={({ rowIndex: _, ...rest }) => (
          <RowTemplate {...rest} onMenuClick={onMenuClick} />
        )}
        noDataMessage="No violations"
      />
      <ViolationModal
        activeViolation={activeViolation}
        isOpen={isFormModalOpen}
        isLoading={isLoading}
        error={error}
        onSave={onSave}
        onClose={onCloseModal}
      />
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        isLoading={isLoading}
        confirmButtonText="Remove Violation"
        text="Please confirm you would like to remove this violation from the driving history."
        type="deleting"
        errorMessage={error?.message}
        onConfirm={onConfirmDelete}
        onCancel={onCloseModal}
      />
    </S.Section>
  );
};

export default Violations;
