import React from 'react';
import { AccountSms } from '../../../../services/repository/communicationService';
import { filterCommsOptions } from '../FilterSelector/filterOptions';
import * as S from './SmsCard.styled';

interface Props {
  sms: AccountSms;
}

export const SmsCard: React.FC<Props> = ({ sms }) => {
  return (
    <S.Container>
      <div>
        <S.Icon src="/images/icon-sms.svg" alt="" />
      </div>
      <div>
        <S.Header>
          <S.HorizontalGroup>
            <S.SectionTitle>SMS</S.SectionTitle>
            {sms.tags.map(tag => {
              const option = filterCommsOptions.find(
                ({ value }) => value === tag,
              );
              return (
                <S.Tag key={tag} title={tag}>
                  <S.TagImage src="/images/icon-tag.svg" alt="" />
                  <div>{option?.label || tag}</div>
                </S.Tag>
              );
            })}
          </S.HorizontalGroup>

          <S.HorizontalGroup>
            <S.UserLabel>{sms.status}</S.UserLabel>
            <S.Timestamp>{sms.sendingAt.toFormat('FF')}</S.Timestamp>
          </S.HorizontalGroup>
        </S.Header>
        <S.Content>
          <S.VerticalGroup>
            <S.To>{sms.to}</S.To>
            <S.CommsContent>{sms.content}</S.CommsContent>
          </S.VerticalGroup>
        </S.Content>
      </div>
    </S.Container>
  );
};
