import React from 'react';
import styled from 'styled-components';
import { Loader } from './Loader';

interface Props {
  text?: string;
  className?: string;
}

const Container = styled.div`
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PageLoader: React.FC<Props> = ({ text, className }) => (
  <Container className={className}>
    <Loader text={text} />
  </Container>
);
