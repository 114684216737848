import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';

export const StyledModal = styled(Modal)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.primary.white};
  padding: 32px 48px;
  border-radius: 16px;
  min-height: 400px;
  width: 70%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.palette.primary.bluePurple};
  font-weight: bold;
  font-size: 28px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
`;

export const Text = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-bottom: 8px;
  font-weight: 500;
`;

export const LastLookup = styled.div`
  margin-bottom: 24px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.palette.primary.veryDarkGrey};
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;
