import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import ModalContent from './ModalContent';
import { Vehicle } from '../../../../services/repository/policyService';

type Props = {
  userId: string;
  primaryVehicle: Vehicle;
  onClose: () => void;
};

const UploadOdometerModal: React.FC<Props> = ({
  userId,
  primaryVehicle,
  onClose,
}) => {
  return (
    <Dialog open scroll="body" fullWidth>
      <DialogTitle>Upload Odometer</DialogTitle>
      <ModalContent
        userId={userId}
        primaryVehicle={primaryVehicle}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default UploadOdometerModal;
