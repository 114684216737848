import { PolicyDetails } from '../types';
import { LoadingContainer } from './TransactionModal.styled';
import ModalError from '../../../../../components/ModalError/ModalError';
import { Dialog, CircularProgress } from '@material-ui/core';
import TransactionDetails from './TransactionDetails';

type Props = {
  userId: string;
  onClose: () => void;
  onSave: () => void;
  isLoading: boolean;
  errorMessage: string | undefined;
  policyDetails: PolicyDetails | undefined;
  numberofCharges: number | undefined;
};

const TransactionModal: React.FC<Props> = ({
  userId,
  onClose,
  onSave,
  isLoading,
  errorMessage,
  policyDetails,
  numberofCharges,
}) => (
  <Dialog open onClose={onClose} scroll="body" fullWidth maxWidth="xs">
    <DialogContent
      userId={userId}
      onClose={onClose}
      onSave={onSave}
      isLoading={isLoading}
      errorMessage={errorMessage}
      policyDetails={policyDetails}
      numberofCharges={numberofCharges}
    />
  </Dialog>
);

const DialogContent: React.FC<Props> = ({
  userId,
  onClose,
  onSave,
  isLoading,
  errorMessage,
  policyDetails,
  numberofCharges,
}) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress size={20} />
      </LoadingContainer>
    );
  }

  if (errorMessage) {
    return <ModalError error={errorMessage} />;
  }

  if (!policyDetails) {
    return <ModalError error="Error fetching user policy" />;
  }

  if (numberofCharges === undefined) {
    return <ModalError error="Error getting the amount of miles to charge" />;
  }

  return (
    <TransactionDetails
      userId={userId}
      policyDetails={policyDetails}
      numberOfCharges={numberofCharges}
      onClose={onClose}
      onSave={onSave}
    />
  );
};

export default TransactionModal;
