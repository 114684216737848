export enum DeviceStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum ObdErrorType {
  NO_FIRST_POlICY = 'NO_FIRST_POLICY',
  DEVICE_NOT_REGISTERED = 'DEVICE_NOT_REGISTERED',
  DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type ObdError = {
  type: ObdErrorType;
  message: string;
  isPermanent: boolean;
};

export interface ApiObdDevice {
  userId: number;
  vin: string;
  status: DeviceStatus;
  serialNumber: string;
}

export interface ApiObdStatus {
  status: string;
  serialNumber: string;
  createdAt: string;
  updatedAt: string;
}

export type ObdDevice = {
  vin: string;
  serialNumber: string;
  status: DeviceStatus;
  isConnected: boolean;
};

// useObdApi types

type GetVinNumberSuccess = {
  success: true;
  result: {
    vin: string;
  };
};

type GetVinNumberError = {
  success: false;
  error: ObdError;
};

export type GetVinNumberResponse = GetVinNumberSuccess | GetVinNumberError;

type GetDeviceDetailsSuccess = {
  success: true;
  result: ObdDevice | null;
};

type GetDeviceDetailsError = {
  success: false;
  error: ObdError;
};

export type GetDeviceDetailsResponse =
  | GetDeviceDetailsSuccess
  | GetDeviceDetailsError;

type DeactivateDeviceSuccess = {
  success: true;
  result: {
    vin: string;
    serialNumber: string;
    status: DeviceStatus;
  };
};

export type DeactivateDeviceError = {
  success: false;
  error: ObdError;
};

export type DeactivateDeviceResponse =
  | DeactivateDeviceSuccess
  | DeactivateDeviceError;
