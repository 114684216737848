import { GetUserAccountStateQuery } from '../../../../../graphql/types';
import { AccountStateAndAction, AccountState } from '../types';
import {
  mapActiveStateActions,
  mapLapsedStateAction,
  noPendingActions,
} from './util';

export const accountStateSelector = ({
  user,
}: GetUserAccountStateQuery): AccountStateAndAction => {
  const accountState = user.underwriting.account?.state;
  const isRestricted = user.underwriting.account?.state?.isRestricted ?? false;

  switch (accountState?.__typename) {
    case 'ActiveState':
      return {
        accountState: AccountState.Active,
        actions: mapActiveStateActions(accountState.actions),
        isRestricted,
      };
    case 'LapsedState':
      return {
        accountState: AccountState.Lapsed,
        actions: [mapLapsedStateAction(accountState.finalisation)],
        isRestricted,
      };
    case 'RegistrationState':
      return {
        accountState: AccountState.Registration,
        actions: [noPendingActions()],
        isRestricted,
      };
    case 'SuspendedState':
      return {
        accountState: AccountState.Suspended,
        actions: [],
        isRestricted,
      };
    default:
      return {
        accountState: AccountState.NoState,
        actions: [],
        isRestricted: false,
      };
  }
};
