import React from 'react';
import EditEmailModalV1, { Props as V1Props } from './v1';
import EditEmailModalV2 from './v2';
import { UpdateUserEmailReason } from '../../../../graphql/api';

interface Props extends V1Props {
  isDuplicateUserFeatureEnabled?: boolean;
  onSave: (payload: {
    email: string;
    reason: UpdateUserEmailReason;
    notes: string;
  }) => Promise<void>;
}

const EditEmailModal: React.FC<Props> = ({
  isOpen,
  email,
  onSaveV1,
  onSave,
  onClose,
  isDuplicateUserFeatureEnabled,
}) => {
  if (!isDuplicateUserFeatureEnabled) {
    return (
      <EditEmailModalV1
        isOpen={isOpen}
        email={email}
        onSaveV1={onSaveV1}
        onClose={onClose}
      />
    );
  }

  return (
    <EditEmailModalV2 isOpen={isOpen} onClose={onClose} onSubmit={onSave} />
  );
};

export default EditEmailModal;
