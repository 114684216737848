import React from 'react';
import FormModal from '../../../../components/FormModal/FormModal';
import FormSelect from '../../../../components/FormSelect/FormSelect';
import {
  useIssuePolicyEndorsementMutation,
  IssuePolicyEndorsementInput,
  IssuePolicyEndorsementReason,
} from '../../../../graphql/api';
import { ErrorCode, logError } from '../../../../services/logging';
import schema from './schema';
import { mapReason } from './util';
import { Text, Row } from './StyledComponents.style';

interface Props {
  userId: string;
  endorsementQuoteId: string;
  originalQuoteId: string;
  policyNumber: string;
  onSave: () => void;
  onClose: () => void;
}

const IssuePolicyEndorsementModal: React.FC<Props> = ({
  userId,
  endorsementQuoteId,
  originalQuoteId,
  policyNumber,
  onSave,
  onClose,
}) => {
  const { isLoading, error, mutate, reset } = useIssuePolicyEndorsementMutation(
    {
      onSuccess: onSave,
      onError: (e: Error) => logError(ErrorCode.IssuePolicyEndorsement, e),
    },
  );

  const onSubmit = async ({
    reason,
  }: {
    reason: IssuePolicyEndorsementReason;
  }) => {
    const input: IssuePolicyEndorsementInput = {
      userId,
      quoteId: endorsementQuoteId,
      reason,
    };
    mutate({ input });
  };

  const initialValues = {
    reason: undefined,
  };

  return (
    <FormModal
      isOpen
      isLoading={isLoading}
      isError={!!error}
      error={error}
      onSubmit={onSubmit}
      onClose={() => {
        reset();
        onClose();
      }}
      initialValues={initialValues}
      validationSchema={schema}
      title="Issue policy endorsement"
    >
      <Text>
        Please confirm you would like to add a new quote ID to the current
        active policy.
      </Text>
      <Row>
        <div>Policy number</div>
        <div>{policyNumber}</div>
      </Row>
      <Row>
        <div>Original quote</div>
        <div>{originalQuoteId}</div>
      </Row>
      <Row>
        <div>Endorsement quote</div>
        <div>{endorsementQuoteId}</div>
      </Row>
      <FormSelect
        name="reason"
        options={Object.values(IssuePolicyEndorsementReason).map(reason => ({
          label: mapReason(reason),
          value: reason,
        }))}
        editable
        label="Select a reason"
      />
    </FormModal>
  );
};

export default IssuePolicyEndorsementModal;
