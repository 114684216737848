import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { Formik, FormikHelpers } from 'formik';
import { path } from 'ramda';
import React, { useState } from 'react';
import { Maybe } from '../../../types/interfaces';
import {
  convertError,
  isServerValidationError,
} from '../../helpers/serverErrToFormik';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import LoadingModal from '../LoadingModal/LoadingModal';
import * as S from './Form.styled';

interface Props
  extends Omit<
    React.ComponentProps<typeof Formik>,
    'initialValues' | 'onSubmit'
  > {
  submit: (values: any) => Promise<any>;
  initialValues?: any;
  showConfirmation?: boolean;
}

interface FormikSettingsProps {
  values: any;
  settings: FormikHelpers<any>;
}

const Form: React.FC<Props> = ({ showConfirmation = true, ...props }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [funcs, setFuncs] = useState<Maybe<FormikSettingsProps>>(undefined);
  const [serverError, setServerError] = useState<Maybe<Error>>(undefined);

  const submitAfterApproval = async (
    values: any,
    settings: FormikHelpers<any>,
  ) => {
    const { setErrors, setSubmitting } = settings;
    try {
      setOpenModal(false);
      setOpenLoading(true);
      await props.submit(values);
    } catch (error: any) {
      const validationErr = path(['response', 'data'])(error);
      if (validationErr && isServerValidationError(validationErr)) {
        setErrors(convertError(error.response.data.errors));
      } else {
        setServerError(error);
      }
    } finally {
      setOpenLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        {...props}
        initialValues={props.initialValues || {}}
        onSubmit={(values, settings) => {
          if (showConfirmation) {
            setFuncs({ values, settings });
            setOpenModal(true);
          } else {
            submitAfterApproval(values, settings);
          }
        }}
      />
      <LoadingModal isOpen={openLoading} text="Saving, please wait..." />
      <ConfirmationModal
        isOpen={openModal}
        type="save"
        confirmButtonText="Save"
        text="Are you sure you want to save your changes? Any changes that you have made will be visible to your customers from now on."
        onConfirm={() => {
          if (funcs) {
            submitAfterApproval(funcs.values, funcs.settings);
          }
        }}
        onCancel={() => setOpenModal(false)}
      />
      <Modal open={!!serverError} onClose={() => setServerError(undefined)}>
        <S.ErrorModal>
          <h2>Errors</h2>
          <p>{serverError ? serverError.message : ''}</p>
          <div className="row">
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              color="primary"
              onClick={() => setServerError(undefined)}
            >
              Close
            </Button>
          </div>
        </S.ErrorModal>
      </Modal>
    </>
  );
};

export default Form;
