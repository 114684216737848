import React, { useState } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import { TabItem, ClassNames } from '../../../types/interfaces';
import styles from './TabsList.styled';
import classNames from 'classnames';

interface Props {
  classes: ClassNames;
  tabs: TabItem[];
  initialIndex?: number;
  size?: 'large' | 'small';
  isFlush?: boolean;
}

const TabsList = ({
  classes,
  tabs,
  initialIndex = 0,
  size = 'large',
  isFlush = false,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  return (
    <>
      <AppBar
        position="static"
        className={classNames(
          size === 'small' && classes.smallAppBar,
          isFlush && classes.flushTabs,
        )}
      >
        <Tabs
          value={activeIndex}
          onChange={(_, i) => setActiveIndex(i)}
          textColor={size === 'small' ? 'primary' : 'inherit'}
          className={classNames(size === 'small' && classes.smallTabs)}
        >
          {tabs.map(({ label }, i) => (
            <Tab label={label} key={i} />
          ))}
        </Tabs>
      </AppBar>

      {tabs.map(({ component }, i) =>
        i === activeIndex ? (
          <React.Fragment key={i}>{component}</React.Fragment>
        ) : null,
      )}
    </>
  );
};

export default withStyles(styles)(TabsList);
