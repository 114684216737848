import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Salesperson } from '../../../../../types/interfaces';
import EditPageDetailsForm from '../../EditPageDetailsForm';
import {
  Row,
  Loading,
  CopyToClipboardContainer,
  ReferralCodeContainer,
  CreateReferralCodeContainer,
} from './SalespersonDetailsForm.styled';
import FormTextInput from '../../../../components/FormTextInput/FormTextInput';
import { useGetOrganisationsSelectOptions } from '../useGetOrganisationsSelect';
import { useGetOrganisationsQuery } from '../../../../graphql/api';
import FormSelect, {
  SelectOption,
} from '../../../../components/FormSelect/FormSelect';
import Form from '../../../../components/Form/Form';
import schema from '../../schema/salespersonSchema';
import { salesPersonLevelOptions } from '../../helpers/selectOptions';
import CopyToClipboard from '../../../../components/CopyToClipboard/CopyToClipboard';
import { GenericComponentError } from '../../../../components/GenericComponentError';
import CreateReferralCodeButton from '../../CreateReferralCodeButton/CreateReferralCodeButton';

interface Props {
  salesPerson: Salesperson;
  onSubmit: (salesPerson: Salesperson) => Promise<void>;
  onCreateCode: () => void;
}

const SalespersonDetailsForm: React.FC<Props> = ({
  salesPerson,
  onSubmit,
  onCreateCode,
}) => {
  const [editing, setEditing] = useState(false);
  const { isLoading, isError, error, data } = useGetOrganisationsQuery<
    SelectOption[],
    Error
  >(
    {},
    {
      select: useGetOrganisationsSelectOptions,
    },
  );

  if (isLoading) {
    return <Loading text="Loading organisations, please wait..." />;
  }

  const onFormSubmit = async (updatedSalesperson: Salesperson) => {
    setEditing(false);
    await onSubmit(updatedSalesperson);
  };

  return (
    <>
      {isError ? <GenericComponentError error={error!} /> : null}

      <Form
        initialValues={salesPerson}
        submit={onFormSubmit}
        validationSchema={schema}
        enableReinitialize
      >
        {({ resetForm }) => (
          <>
            <Row>
              <Grid>
                <EditPageDetailsForm
                  onToggleEdit={() => setEditing(!editing)}
                  isEditing={editing}
                  onCancelEdit={() => {
                    resetForm();
                    setEditing(false);
                  }}
                  label={'salesperson'}
                />
                {!salesPerson.personalCode && salesPerson.id ? (
                  <CreateReferralCodeContainer>
                    <CreateReferralCodeButton
                      id={salesPerson.id}
                      onSave={onCreateCode}
                    />
                  </CreateReferralCodeContainer>
                ) : null}
              </Grid>
            </Row>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4} md={2}>
                <FormTextInput
                  label="First Name"
                  name="firstName"
                  editable={editing}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormTextInput
                  label="Last Name"
                  name="lastName"
                  editable={editing}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormTextInput
                  label="Phone Number"
                  name="phoneNumber"
                  editable={editing}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormTextInput
                  label="Email Address"
                  name="email"
                  editable={editing}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormSelect
                  name="level"
                  options={salesPersonLevelOptions}
                  editable={editing}
                  label="Level"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormSelect
                  name="organisationId"
                  options={data || []}
                  editable={editing}
                  label="Organisation"
                  value={salesPerson.organisationId.toString()}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <ReferralCodeContainer>
                  <FormTextInput
                    label="Personal Code"
                    name="personalCode"
                    editable={false}
                    InputLabelProps={{ shrink: true }}
                  />
                  {salesPerson.personalCode ? (
                    <CopyToClipboardContainer>
                      <CopyToClipboard text={salesPerson.personalCode} />
                    </CopyToClipboardContainer>
                  ) : null}
                </ReferralCodeContainer>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormTextInput
                  label="Tax ID"
                  name="taxId"
                  editable={editing}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Form>
    </>
  );
};

export default SalespersonDetailsForm;
