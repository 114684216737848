import { GraphQLClient } from 'graphql-request';
import { v4 as uuidv4 } from 'uuid';
import { getConfig } from '../config';
import { getIdTokenSilently } from '../services/auth';

const config = getConfig();

const client = new GraphQLClient(
  `${config.REACT_APP_BASE_SERVICE_URL}/graphql`,
);

export const setDefaultHeaders = async () => {
  const token = await getIdTokenSilently();
  const correlationId = uuidv4();
  const idempotencyKey = uuidv4();
  client.setHeader('authorization', `Bearer ${token}`);
  client.setHeader('x-correlation-id', correlationId);
  client.setHeader('Idempotency-Key', idempotencyKey);
};

export const fetchGraphQLQuery = async <T, V = {}>(
  query: string,
  variables?: V,
) => {
  await setDefaultHeaders();
  return client.request<T, V>(query, variables);
};

export const getGraphClient = async () => {
  await setDefaultHeaders();
  return client;
};
