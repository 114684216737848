import React from 'react';
import { Container, ErrorWrapper, ErrorText } from './AuditTrailScreen.styled';
import { useAuditTrail } from '../Controller';
import InfiniteScrollTable from '../../../../components/Table/InfiniteScrollTable';
import { auditTrailColumnDefs } from './auditTrailColumnDefs';
import Button from '@material-ui/core/Button';

const AuditTrailScreen: React.FC = () => {
  const {
    getAuditTrail,
    state: { data, pageInfo, isLoading, isError },
  } = useAuditTrail();

  if (isError) {
    return (
      <Container>
        <ErrorWrapper>
          <ErrorText>Something went wrong... Please try again</ErrorText>
          <Button variant="outlined" color="primary" onClick={getAuditTrail}>
            Retry
          </Button>
        </ErrorWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <InfiniteScrollTable
        fetchMoreItems={getAuditTrail}
        isLoading={isLoading}
        hasMoreItems={pageInfo.hasNextPage}
        data={data}
        columns={auditTrailColumnDefs}
      />
    </Container>
  );
};

export default AuditTrailScreen;
