import {
  Container,
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { QueryOptions } from '../../../types/interfaces';
import DefaultRowTemplate from './DefaultRowTemplate';
import {
  SearchTextField,
  StyledTable,
  Loading,
  SearchRow,
} from './Table.styled';
import { useDebounce } from '../../hooks/useDebounce';
import TableStatus from './TableStatus';

export enum CellTypes {
  Tex = 'Text',
  Image = 'Image',
}

interface RowTemplateProps {
  columns: Column[];
  item: any;
  rowActions?: RowAction[];
  rowIndex: number;
}

export interface Column {
  id: string;
  hasContent?: (row: any) => boolean;
  contentQuery?: (row: any) => Promise<string>;
  rowFormatter?: (row: any) => React.ReactNode;
  sortable?: boolean;
  cellStyle?: any;
  title: string;
  type?: CellTypes;
}

export interface RowAction {
  name: string;
  icon?: string;
  text?: string;
  onClick?: (data: any, index: number) => void;
  isEnabled?: (data: any) => boolean;
}

interface Props {
  data: any[];
  columns: Column[];
  onFilterData?: (filter: string) => void;
  onFetchData?: (options: QueryOptions, search: string) => void;
  pageSize?: number;
  total?: number;
  searchLabel?: string;
  searchTerm?: string;
  rowTemplate?: (props: RowTemplateProps) => React.ReactNode;
  defaultOrder?: string;
  defaultDir?: 'asc' | 'desc';
  onRowClick?: (data: any) => void;
  searchOnClient?: boolean;
  rowActions?: RowAction[];
  disablePagination?: boolean;
  disableElevation?: boolean;
  disableGutters?: boolean;
  useAutoFocus?: boolean;
  isLoading?: boolean;
  noDataMessage?: string;
  error?: string;
}

const ContentWrapper: React.FC = ({ children }) => <div>{children}</div>;

const Table: React.FC<Props> = ({
  columns,
  data,
  onFilterData,
  onFetchData,
  total,
  rowTemplate,
  onRowClick,
  searchLabel = 'Search',
  searchTerm,
  defaultOrder,
  defaultDir,
  pageSize = 25,
  searchOnClient = false,
  rowActions = [],
  disablePagination = false,
  disableElevation = false,
  disableGutters = false,
  useAutoFocus = false,
  isLoading = false,
  noDataMessage = 'No rows found',
  error,
}) => {
  const sortableColumns = columns.filter(c => c.sortable !== false);
  const [query, setQuery] = useState<QueryOptions>({
    take: pageSize,
    skip: 0,
    orderBy: defaultOrder
      ? defaultOrder
      : sortableColumns.length > 0
      ? sortableColumns[0].id
      : '',
    orderDir: defaultDir ? defaultDir : 'asc',
  });

  const [search, setSearch] = useState(searchTerm ?? '');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => setSearch(searchTerm ?? ''), [searchTerm]);

  useEffect(() => {
    const doFetch = (options: QueryOptions, searchText: string) =>
      onFetchData ? onFetchData(options, searchText) : {};

    doFetch(query, debouncedSearch);
  }, [query]);

  useEffect(() => {
    // We want to go back to page 1 if searching
    // Switching page will trigger a fetch - so need to here
    setQuery({
      ...query,
      skip: 0,
    });
  }, [debouncedSearch]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
    if (onFilterData) {
      onFilterData(value);
    }
  };

  const handleRowClick = (newData: any) => {
    if (onRowClick) {
      onRowClick(newData);
    }
  };

  const renderData =
    onFetchData || disablePagination
      ? data
      : data.slice(query.take * query.skip, query.take * (query.skip + 1));

  const rows = [10, 25, 50, 75, 100];

  const Wrapper = disableElevation ? ContentWrapper : Paper;

  const showSearchInput =
    searchTerm === undefined && (onFetchData || searchOnClient);

  return (
    <StyledTable className="radr-table">
      <Wrapper>
        <Container disableGutters={disableGutters} maxWidth="xl">
          {showSearchInput ? (
            <SearchRow>
              <SearchTextField
                autoFocus={useAutoFocus}
                label={searchLabel}
                variant="outlined"
                margin="normal"
                onChange={handleSearch}
              />
              {isLoading ? <Loading /> : null}
            </SearchRow>
          ) : null}

          <MUITable>
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell
                    key={`${column.id}-${idx}`}
                    style={{ userSelect: 'none' }}
                  >
                    {column.sortable !== false ? (
                      <TableSortLabel
                        active={query.orderBy === column.id}
                        direction={
                          query.orderBy === column.id ? query.orderDir : 'asc'
                        }
                        onClick={
                          onFetchData
                            ? () =>
                                setQuery({
                                  ...query,
                                  skip: 0,
                                  orderBy: column.id,
                                  orderDir:
                                    query.orderBy !== column.id
                                      ? 'desc'
                                      : query.orderDir === 'asc'
                                      ? 'desc'
                                      : 'asc',
                                })
                            : undefined
                        }
                      >
                        {column.title}
                      </TableSortLabel>
                    ) : (
                      <>{column.title}</>
                    )}
                  </TableCell>
                ))}
                {rowActions.map((action, idx) => (
                  <TableCell key={`${action.name}_${idx}`}>
                    {action.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderData && !isLoading
                ? renderData.map((item, rowIndex) => {
                    const template =
                      rowTemplate &&
                      rowTemplate({
                        columns,
                        item,
                        rowIndex,
                        rowActions,
                      });

                    return (
                      template || (
                        <DefaultRowTemplate
                          key={rowIndex}
                          columns={columns}
                          item={item}
                          rowActions={rowActions}
                          rowIndex={rowIndex}
                          onRowClick={() => handleRowClick(item)}
                        />
                      )
                    );
                  })
                : null}
            </TableBody>
          </MUITable>
          <TableStatus
            isLoading={isLoading}
            isNoData={!renderData.length}
            noDataMessage={noDataMessage}
            error={error}
          />
          {!disablePagination && data ? (
            <TablePagination
              rowsPerPageOptions={rows}
              component="div"
              count={total ? total : data.length}
              rowsPerPage={query.take}
              page={query.skip}
              onChangePage={(_, page) =>
                setQuery({
                  ...query,
                  skip: page,
                })
              }
              onChangeRowsPerPage={e =>
                setQuery({
                  ...query,
                  skip: 0,
                  take: Number.parseInt(e.target.value, 10),
                })
              }
            />
          ) : null}
        </Container>
      </Wrapper>
    </StyledTable>
  );
};

export default memo(Table);
