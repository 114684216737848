import Modal from '@material-ui/core/Modal';
import React from 'react';
import Loading from '../Loading/Loading';

interface Props {
  isOpen: boolean;
  text: string;
}

const LoadingModal: React.FC<Props> = ({ isOpen, text }) => {
  return (
    <Modal open={isOpen}>
      <>
        <Loading text={text} />
      </>
    </Modal>
  );
};

export default LoadingModal;
