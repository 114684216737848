import { OrganisationType, SalesPersonLevel } from '../../../graphql/api';
import {
  mapOrganisationTypeLabel,
  mapSalesPersonLevelLabel,
} from '../helpers/mappers';

export const organisationTypeOptions = Object.values(OrganisationType).map(
  value => ({
    label: mapOrganisationTypeLabel(value),
    value: value,
  }),
);

export const salesPersonLevelOptions = Object.values(SalesPersonLevel).map(
  value => ({
    label: mapSalesPersonLevelLabel(value),
    value: value,
  }),
);
