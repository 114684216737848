import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { ButtonType } from './LoadingButton';

interface Props {
  buttonType: ButtonType;
}

export const StyledButton = styled(Button)<Props>`
  ${({ buttonType, theme }) => {
    if (buttonType === 'delete' || buttonType === 'cancel') {
      return `
        background-color: ${theme.palette.primary.red} !important;
      `;
    } else if (buttonType === 'confirm') {
      return `
        background-color: ${theme.palette.primary.green} !important;
        &:disabled {
          background-color: ${theme.palette.primary.lighterGrey} !important;
        }
      `;
    }
  }}
`;
