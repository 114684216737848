import { AxiosError } from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { Maybe } from '../../../../types/interfaces';
import ErrorModal from '../../../components/ErrorModal/ErrorModal';
import OdometerImageViewer from '../../../components/OdometerImageViewer/OdometerImageViewer';
import { Table } from '../../../components/Table';
import { ErrorCode, logError } from '../../../services/logging';
import * as odometerService from '../../../services/repository/odometerService';
import {
  TallyUpOdometer,
  TallyUp,
  sortTallyUpDateToByNewestToOldest,
} from '../../../services/repository/odometerService';
import TallyUpReadingRowTemplate from '../../odometer/TallyUpReading/components/TallyUpReadingRowTemplate';
import { tallyUpListColumnDefs } from './tallyUpListReadingsListColumnDefs';

interface Props {
  onEditTallyUp: (tallyup: TallyUp) => void;
  visible: boolean;
  userId: string;
}

const TallyUpList: React.FC<Props> = ({ onEditTallyUp, visible, userId }) => {
  const [error, setError] = useState<Maybe<AxiosError>>();
  const [selectedReading, setSelectedReading] = useState<TallyUpOdometer>();
  const [tallyUps, setTallyUps] = useState<TallyUp[]>([]);

  useEffect(() => {
    const getTallyUps = async () => {
      try {
        const tallyUpsResult = await odometerService.getTallyups({
          userId,
        });

        setTallyUps(tallyUpsResult.sort(sortTallyUpDateToByNewestToOldest));
      } catch (ex: any) {
        logError(ErrorCode.TallyUp, ex);
        setError(ex);
      }
    };

    getTallyUps();
  }, [userId]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Table
        data={tallyUps}
        total={tallyUps.length}
        columns={tallyUpListColumnDefs}
        rowTemplate={p => (
          <TallyUpReadingRowTemplate
            {...p}
            key={p.item.id}
            onEdit={() => onEditTallyUp(p.item)}
            onImageClick={setSelectedReading}
          />
        )}
      />
      {selectedReading ? (
        <OdometerImageViewer
          userId={parseInt(userId, 10)}
          readingId={parseInt(selectedReading.id, 10)}
          visible
          onClose={() => setSelectedReading(undefined)}
        />
      ) : null}
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </>
  );
};

export default memo(TallyUpList);
