import React from 'react';
import { QuoteCoverageDetail } from '../../helpers/quoteHelpers';
import * as S from './QuoteDetailModal.style';

interface Props {
  optionalCoverages: QuoteCoverageDetail[];
}

export const OptionalCoverages: React.FC<Props> = ({ optionalCoverages }) => (
  <>
    <S.PackageOptions>Optionals</S.PackageOptions>
    <table>
      <tbody>
        {optionalCoverages.map(oc => (
          <tr key={oc.id}>
            <td>{oc.name}</td>
            <td>
              {oc.prices.map(s => (
                <div key={s}>{s}</div>
              ))}
            </td>
            <td>
              {oc.selected ? <img src="/images/check.png" alt="" /> : null}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);
