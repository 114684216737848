import { GetPolicyCancellationReasonsQuery } from '../../../../graphql/api';
import { SelectOption } from '../../../../components/FormSelect/FormSelect';

export const cancellationReasonsSelector = ({
  underwriting,
}: GetPolicyCancellationReasonsQuery): SelectOption[] => {
  return underwriting.cancellationReasons.map(reason => ({
    label: reason.message,
    value: reason.code,
  }));
};
