import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SnackBar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { memo, useEffect, useState } from 'react';
import { IAccountManagementProps } from '../../interfaces';

import {
  Container,
  FormContent,
  FormItem,
  SubmitButton,
} from './AccountManagement.styled';

const AccountManagement: React.FC<IAccountManagementProps> = (
  props: IAccountManagementProps,
) => {
  const { onSubmitUserStart, isFinished, error } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(isFinished);

  useEffect(() => {
    // open snackbar if isFinished is true, which would mean user was created successfully
    // or if error is not null, which means an error occurred when creating user
    if (isFinished || error) {
      setOpen(isFinished);
    }
  }, [isFinished, error]);

  const handleClose = () => {
    // set open to false and clear form fields
    setOpen(false);
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  return (
    <Container>
      <SnackBar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        message={
          isFinished ? (
            <span>Successfully created user!</span>
          ) : (
            <span>{error}</span>
          )
        }
        action={[
          <IconButton key={'closeButton'} onClick={handleClose}>
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>,
        ]}
        onClose={handleClose}
      />
      <Typography variant="h4">Create Account</Typography>
      <FormContent>
        <FormItem>
          <TextField
            fullWidth={true}
            required
            label="First Name"
            id="firstname"
            variant="outlined"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth={true}
            required
            label="Last Name"
            id="lastname"
            variant="outlined"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth={true}
            required
            label="Email Address"
            id="emailaddress"
            variant="outlined"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormItem>
        <SubmitButton>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => onSubmitUserStart({ firstName, lastName, email })}
          >
            Submit
          </Button>
        </SubmitButton>
      </FormContent>
    </Container>
  );
};

export default memo(AccountManagement);
