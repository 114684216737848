import * as yup from 'yup';
import { ReinstateReason } from '../../../../../graphql/api';

export default yup.object().shape({
  reason: yup.string().required('Please select a reason'),
  description: yup
    .string()
    .trim()
    .when('reason', {
      is: ReinstateReason.Other,
      then: yup
        .string()
        .trim()
        .required('Description is required for "Other" reason'),
      otherwise: yup.string().trim().optional(),
    }),
});
