import { getConfig } from '../../../../config';
import { api } from '../../../../services/httpService';

type FeatureFlagsResponse = {
  user: {
    flags: Array<{ key: string; value: string }>;
  };
};

const SERVICE_URL = getConfig().REACT_APP_USER_CONFIG_URL;

export const getFeatureFlags = async (userId: string) => {
  const response = await api<FeatureFlagsResponse>(
    'get',
    `/users/${userId}/flags`,
    undefined,
    {
      baseUrl: SERVICE_URL,
    },
  );

  return response.user.flags;
};
