import { Button, CircularProgress, Modal, Typography } from '@material-ui/core';
import React from 'react';
import * as S from './ConfirmationModal.styled';

interface ConfirmationModalProps {
  isOpen: boolean;
  confirmButtonText: string;
  text: string;
  title?: string;
  type: S.ConfirmationType;
  isLoading?: boolean;
  errorMessage?: string | null;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  confirmButtonText,
  text,
  title = 'Are you sure?',
  type,
  isLoading = false,
  errorMessage,
  onConfirm,
  onCancel,
}) => (
  <Modal open={isOpen} onClose={onCancel}>
    <S.SaveModal>
      <h2>{title}</h2>
      <p>{text}</p>
      {errorMessage ? (
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
      ) : null}
      <div className="row">
        {!isLoading ? (
          <Button variant="contained" onClick={onCancel}>
            Abort
          </Button>
        ) : null}

        <S.StyledButton
          variant="contained"
          buttonType={type}
          color="primary"
          onClick={onConfirm}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : confirmButtonText}
        </S.StyledButton>
      </div>
    </S.SaveModal>
  </Modal>
);

export default ConfirmationModal;
