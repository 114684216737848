import { FormikErrors } from 'formik';
import { assocPath, path, reduce } from 'ramda';
import {
  ServerError,
  ServerValidationError,
  ValidationError,
} from '../../types/interfaces';

export const convertError = (errors: ValidationError[]): FormikErrors<any> =>
  reduce(
    (formikErr, err) => assocPath(err.path, err.msg, formikErr),
    {},
    errors,
  );

export const isServerError = (obj: any): obj is ServerError => {
  const error = obj as ServerError;
  return !!error.errorCode && !!error.errorId && !!error.msg;
};

export const isServerValidationError = (
  obj: any,
): obj is ServerValidationError => {
  const error = obj as ServerValidationError;
  return !!error.errorCode && !!error.errorId && !!error.msg && !!error.errors;
};

export const convertFieldError = (field: string, error: any) => {
  const validationErr = path(['response', 'data'])(error);
  if (validationErr && isServerValidationError(validationErr)) {
    return convertError(error.response.data.errors);
  } else if (error?.response?.data?.msg) {
    return { [field]: error.response.data.msg };
  } else {
    return { [field]: error.message };
  }
};
