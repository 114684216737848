export interface ClassNames {
  smallAppBar: string;
  smallTabs: string;
  flushTabs: string;
}

const styles: any = (theme: any) => ({
  smallAppBar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.lightgrey}`,
    width: 'auto',
  },
  smallTabs: {
    minHeight: 32,
    '& button': {
      minHeight: 32,
    },
  },
  flushTabs: {
    margin: '0 -22px',
    padding: '0 22px',
  },
});

export default styles;
