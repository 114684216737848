import { Button, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { OdometerImageRequest } from '../../../../types/interfaces';
import { Column } from '../../../components/Table/Table';
import { TallyUp } from '../../../services/repository/odometerService';
import TallyUpOdometer from '../TallyUpOdometer/TallyUpOdometer';
import { Label, UserLink } from './TallyUpListing.styled';

interface Props {
  columns: Column[];
  item: TallyUp;
  rowIndex: number;
  onUserClick: (userId: number) => void;
  onVerify: (item: TallyUp) => void;
  onImageClick: (reading: OdometerImageRequest) => void;
}

const TallyUpRowTemplate: React.FC<Props> = ({
  item,
  onUserClick,
  onImageClick,
  onVerify,
}) => {
  return (
    <TableRow>
      <TableCell>
        <UserLink onClick={() => onUserClick(item.userId)}>
          <Label>{item.userId}</Label>
        </UserLink>
      </TableCell>
      <TableCell>
        <Label>{item.endOdometer.createdAt.toFormat('dd MMM')}</Label>
      </TableCell>
      <TableCell>
        <TallyUpOdometer
          odometer={item.startOdometer}
          onImageClick={() =>
            onImageClick({
              userId: item.userId,
              readingId: parseInt(item.startOdometer.id, 10),
            })
          }
        />
      </TableCell>
      <TableCell>
        {!item.isInitial ? (
          <TallyUpOdometer
            odometer={item.endOdometer}
            onImageClick={() =>
              onImageClick({
                userId: item.userId,
                readingId: parseInt(item.endOdometer.id, 10),
              })
            }
          />
        ) : null}
      </TableCell>
      <TableCell align="center">
        <Label>{item.serviceVersion}</Label>
      </TableCell>
      <TableCell align="center">
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => onVerify(item)}
        >
          VERIFY
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default TallyUpRowTemplate;
