export const odometerReadingsListColumnDefs = [
  {
    id: 'date',
    title: 'Date',
  },
  {
    id: 'reading',
    title: 'Reading',
  },
  {
    id: 'note',
    title: 'Notes',
  },
];
