import React from 'react';
import {
  DeactivateDeviceResponse,
  ObdDevice,
  ObdError,
  ObdErrorType,
} from './types';
import useObdApi from './useObdApi';

interface ObdContextType {
  device: ObdDevice | null;
  isProcessing: boolean;
  error: ObdError | null;
  getDeviceDetails: () => Promise<void>;
  deactivateDevice: () => Promise<DeactivateDeviceResponse>;
}

const ObdContext = React.createContext<ObdContextType | null>(null);

export const ObdController: React.FC<{ userId: string }> = ({
  children,
  userId,
}) => {
  const obdApi = useObdApi();
  const [obdDevice, setObdDevice] = React.useState<ObdDevice | null>(null);
  const [isProcessing, setProcessing] = React.useState(false);
  const [error, setError] = React.useState<ObdError | null>(null);
  const [vinNumber, setVinNumber] = React.useState<string | null>(null);

  const getVinNumber = async (id: string) => {
    if (vinNumber) {
      return vinNumber;
    }
    const response = await obdApi.getVinNumber(id);
    if (response.success) {
      setVinNumber(response.result.vin);
      return response.result.vin;
    } else {
      setError(response.error);
    }
  };

  const getDeviceDetails = async () => {
    setProcessing(true);
    try {
      const vin = await getVinNumber(userId);
      if (!vin) {
        return;
      }

      const response = await obdApi.getDeviceDetails(vin);
      if (!response.success) {
        setError(response.error);
        return;
      }
      setError(null);
      setObdDevice(response.result);
    } catch (e: any) {
      setError({
        type: ObdErrorType.DEFAULT_ERROR,
        message: e.message,
        isPermanent: false,
      });
    } finally {
      setProcessing(false);
    }
  };

  const deactivateDevice = async () => {
    if (!vinNumber) {
      return {
        success: false,
        error: {
          type: ObdErrorType.DEFAULT_ERROR,
          message: 'No VIN number, contact dev team',
          isPermanent: true,
        },
      } as DeactivateDeviceResponse;
    }
    setProcessing(true);
    const response = await obdApi.deactivateDeviceForVin(vinNumber);

    if (response.success) {
      setObdDevice({
        ...response.result,
        isConnected: false,
      });
    }
    setProcessing(false);

    return response;
  };

  return (
    <ObdContext.Provider
      value={{
        device: obdDevice,
        error,
        isProcessing,
        getDeviceDetails,
        deactivateDevice,
      }}
    >
      {children}
    </ObdContext.Provider>
  );
};

export const useObdContext = () => {
  const context = React.useContext(ObdContext);

  if (!context) {
    throw new Error(
      '[ObdController] Context must be used within a ObdContextProvider',
    );
  }

  return context;
};
