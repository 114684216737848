export const paymentMethodsColumnDefs = [
  {
    id: 'type',
    title: 'Type',
    sortable: false,
  },
  {
    id: 'details',
    title: 'Details',
    sortable: false,
  },
  {
    id: 'token',
    title: 'Token',
    sortable: false,
  },
  {
    id: 'autoPayMethod',
    title: 'AutoPay Method',
    sortable: false,
  },
  {
    id: 'error',
    title: 'Error',
    sortable: false,
  },
];
