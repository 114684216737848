import { Table } from '../../../components/Table';
import { paymentMethodsColumnDefs } from './paymentMethodsColumnDefs';
import { useGetUserPaymentMethodsQuery } from '../../../graphql/api';
import { logError, ErrorCode } from '../../../services/logging';
import PaymentMethodsRowTemplate from './PaymentMethodsRowTemplate';
import { useGetUserPaymentMethodsSelector } from './useGetUserPaymentMethodsSelector';
import { Wallet } from './types';

type Props = {
  userId: string;
};

const PaymentMethods: React.FC<Props> = ({ userId }) => {
  const {
    isLoading,
    error,
    data: wallet,
  } = useGetUserPaymentMethodsQuery<Wallet, Error>(
    { userId },
    {
      select: useGetUserPaymentMethodsSelector,
      onError: (e: Error) => logError(ErrorCode.UserPaymentMethods, e),
      useErrorBoundary: false,
    },
  );

  return (
    <Table
      columns={paymentMethodsColumnDefs}
      data={wallet?.paymentMethods || []}
      disablePagination
      isLoading={isLoading}
      error={error?.message}
      rowTemplate={({ item }) => (
        <PaymentMethodsRowTemplate
          item={item}
          autoPayToken={
            wallet?.autoPay.isEnabled ? wallet?.autoPay.token : undefined
          }
        />
      )}
      noDataMessage="No payment methods"
    />
  );
};

export default PaymentMethods;
