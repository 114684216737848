import { PolicyDetails } from '../types';
import { useCreateFiftyMileTransaction } from '../hooks/useTransaction';
import { formatBalance } from '../../../../../helpers/formatHelpers';
import TransactionDetailsContent from './TransactionDetailsContent';

interface Props {
  userId: string;
  policyDetails: PolicyDetails;
  numberOfCharges: number;
  onClose: () => void;
  onSave: () => void;
}

const milesPerCharge = 50;

const TransactionDetails: React.FC<Props> = ({
  userId,
  policyDetails: { policyNumber, premiumPerMile },
  numberOfCharges,
  onClose,
  onSave,
}) => {
  const milesToCharge = milesPerCharge * numberOfCharges;
  const chargeAmount = milesToCharge * premiumPerMile;

  const {
    isLoading,
    error,
    mutate: createTransaction,
  } = useCreateFiftyMileTransaction(userId, onSave);

  const onCreateTransaction = async () =>
    createTransaction({
      reason: `50-mile odometer photo charge (${milesToCharge} miles): policy ${policyNumber}`,
      amount: chargeAmount,
    });

  return (
    <TransactionDetailsContent
      milesToCharge={milesToCharge}
      premiumPerMile={formatBalance(premiumPerMile)}
      chargeAmount={formatBalance(chargeAmount)}
      isLoadingTransaction={isLoading}
      onCreateTransaction={onCreateTransaction}
      onClose={onClose}
      errorMessage={error?.message}
    />
  );
};

export default TransactionDetails;
