import { api } from '../../../../services/httpService';
import { getConfig } from '../../../../config';
import { ApiObdDevice, ApiObdStatus } from './types';
import { fetchGraphQLQuery } from '../../../../graphql/service';
import { getActivePolicy } from './gql';
import { Query } from '../../../../graphql/types';

const SERVICE_URL = getConfig().REACT_APP_VEHICLE_TELEMATICS_URL;

class ObdApi {
  getVinNumber = async (userId: string) => {
    const response = await fetchGraphQLQuery<Query>(getActivePolicy, {
      userId,
    });
    const policies = response.user?.policies;
    const lastIndex = policies?.length;
    return policies?.[lastIndex - 1]?.vehicles[0]?.vin;
  };

  getDeviceDetails = async (vin: string) => {
    const data = await api<ApiObdDevice>(
      'get',
      `/obd2/status/${vin}`,
      undefined,
      {
        baseUrl: SERVICE_URL,
      },
    );

    return {
      vin,
      serialNumber: data.serialNumber,
      status: data.status,
    };
  };

  getDeviceStatus = async (serialNumber: string) => {
    const data = await api<ApiObdStatus>(
      'get',
      `/dongle/${serialNumber}`,
      undefined,
      {
        baseUrl: SERVICE_URL,
      },
    );

    return {
      isConnected: data.status === 'CONNECTED',
    };
  };

  deactivateDeviceForVin = async (vin: string) => {
    const data = await api<ApiObdDevice>(
      'patch',
      `/obd2/status/${vin}`,
      {
        status: 'DEACTIVATED',
      },
      {
        baseUrl: SERVICE_URL,
      },
    );

    return {
      vin,
      serialNumber: data.serialNumber,
      status: data.status,
    };
  };
}

const obdApi = new ObdApi();
export default obdApi;
