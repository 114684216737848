import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-start;
  border-top: solid 2px ${({ theme }) => theme.palette.primary.darkGrey};
  display: flex;
  padding: 15px 10px 15px 0;

  > div:nth-child(2) {
    width: 100%;
  }
`;

export const Icon = styled.img`
  position: relative;
  top: 3px;
  height: 13px;
  margin-right: 9px;
  width: 16px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const HorizontalGroup = styled.div`
  align-items: center;
  display: flex;
`;

export const VerticalGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.79px;
  text-transform: uppercase;
`;

export const Tag = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.darkGrey};
  color: ${({ theme }) => theme.typography.body1.color};
  border-radius: 8px;
  display: flex;
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.64px;
  justify-content: center;
  padding: 2px 5px;
  margin-left: 5px;
`;

export const TagImage = styled.img`
  height: 8px;
  margin-right: 4px;
  object-fit: contain;
  width: 8px;
`;

export const UserLabel = styled.div`
  color: ${({ theme }) => theme.palette.primary.lighterGrey};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.86px;
  margin-right: 10px;
`;

export const Timestamp = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
`;

export const MenuIcon = styled.img`
  cursor: pointer;
  height: 33px;
  width: 33px;
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const EmailAddress = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: 700;
  letter-spacing: 1px;
  padding: 5px 0;
`;

export const Subject = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: 14px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.86px;
`;

export const LinkButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.primary.bluePurple};
    font-family: ${({ theme }) => theme.typography.body1.fontFamily};
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.86px;
    justify-content: flex-start;
    padding: 0;
    text-transform: none;
  }
`;

export const DetailsContent = styled.div`
  margin-top: 5px;
`;

export const DetailsTitle = styled.div`
  color: ${({ theme }) => theme.palette.primary.lighterGrey};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.79px;
`;

export const Comment = styled.div`
  color: ${({ theme }) => theme.typography.body1.color};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;
