import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const ModalContent = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 20%;
  left: calc(50% - 200px);
  outline: none;
  padding: 10px 30px;
  width: 500px;
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.481px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.bluePurple};
`;

export const Body = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.296px;
  color: ${({ theme }) => theme.palette.primary.veryDarkGrey};
`;

export const Row = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin: 8px !important;
`;
