import React from 'react';
import { DateTime } from 'luxon';
import { Table } from '../../../../../../components/Table';
import { CreditReport } from '../../../Controller/creditCheck/types';

const getColumns = (userTimezone: string) => [
  {
    id: 'reportedAt',
    title: 'Date',
    rowFormatter: ({ reportedAt }: CreditReport) => {
      return DateTime.fromISO(reportedAt, { zone: userTimezone }).toFormat(
        'd LLL yyyy HH:mm:ss.SSS',
      );
    },
  },
  {
    title: 'Credit status',
    id: 'status',
    rowFormatter: ({ status }: CreditReport): string => {
      const result = status.match(/[A-Z][a-z]+|[0-9]+/g);
      return result ? result.join(' ') : '';
    },
  },
  {
    title: 'Credit score',
    id: 'score',
    rowFormatter: ({ score }: CreditReport) => {
      return score ?? '-';
    },
  },
  {
    title: 'Key factors',
    id: 'keyFactors',
    rowFormatter: ({ keyFactors }: CreditReport) => {
      return keyFactors.join(', ');
    },
  },
];

interface Props {
  userTimezone: string;
  items: CreditReport[];
  isVisible: boolean;
}

const CreditHistoryList: React.FC<Props> = ({
  userTimezone,
  items,
  isVisible,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Table
      columns={getColumns(userTimezone)}
      data={items}
      defaultOrder="start"
      defaultDir="desc"
      pageSize={10}
    />
  );
};

export default CreditHistoryList;
