import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { Body } from './Typography';

const InfoItemTitle = styled(Body)`
  color: ${({ theme }) => theme.palette.primary.grey};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  text?: string;
  className?: string;
}

export const Loader: React.FC<Props> = ({ text, className }) => (
  <Container className={className}>
    <CircularProgress />
    {text && <InfoItemTitle>{text}</InfoItemTitle>}
  </Container>
);
