import { TransactionRequest } from '@just-insure/api';
import { api } from '../httpService';

export interface RawUserTransaction {
  id: number;
  transactionId: string;
  type: string;
  activityType: string;
  amount: number;
  status: string;
  reason?: string;
  createdAt: string;
  updatedAt: string;
}

export const createTransaction = async (
  userId: string,
  transaction: TransactionRequest,
) =>
  await api<RawUserTransaction[]>(
    'post',
    `/users/${userId}/transactions`,
    transaction,
  );
